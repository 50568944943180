/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { Regex, ValidationType } from '../utils/validations';

const businessValidations = {
    name: {
        validations: [ValidationType.NotBlank],
    },
    email: {
        validations: [ValidationType.NotBlank],
    },
    phoneNumber: {
        validations: [ValidationType.Number, ValidationType.Min, ValidationType.Max, ValidationType.NotDecimal],
        min: 100000000,
        max: 999999999,
    },
    bankAccount: {
        validations: [ValidationType.NotBlank],
    },
    fiscalCode: {
        validations: [ValidationType.NotBlank, ValidationType.SizeExact],
        size: 9,
    },
    street: {
        validations: [ValidationType.NotBlank],
    },
    country: {
        validations: [ValidationType.NotBlank],
    },
    postalCode: {
        validations: [ValidationType.NotBlank],
    },
    city: {
        validations: [ValidationType.NotBlank],
    },
    lat: {
        validations: [ValidationType.NotBlank],
    },
    lng: {
        validations: [ValidationType.NotBlank],
    },
};

const areaValidations = {
    businessId: {
        validations: [ValidationType.NotBlank],
    },
    requestedBollards: {
        validations: [ValidationType.NotBlank],
    },
    bollardRadius: {
        validations: [ValidationType.NotBlank, ValidationType.Min, ValidationType.Decimal],
        decimalPoints: 1,
        min: .5,
    },
    name: {
        validations: [ValidationType.NotBlank],
    },
};

const bookingValidationsUnregistered = {
    reservationPeriodId: {
        validations: [ValidationType.Number, ValidationType.Min],
        min: 1,
    },
    days: {
        validations: [ValidationType.NotEmpty],
    },
    numberOfPeople: {
        validations: [ValidationType.Number, ValidationType.Min, ValidationType.Max, ValidationType.NotDecimal],
        min: 1,
        max: 999,
    },
    phoneNumber: {
        validations: [ValidationType.Number, ValidationType.Min, ValidationType.Max, ValidationType.NotDecimal],
        min: 100000000,
        max: 999999999,
    },
    email: {
        validations: [ValidationType.Regex],
        regex: Regex.Email,
    },
};

const bookingValidations = {
    ...bookingValidationsUnregistered,
    email: {
        validations: [ValidationType.NotBlank, ValidationType.Regex],
        regex: Regex.Email,
    },
    firstName: {
        validations: [ValidationType.NotBlank],
    },
    lastName: {
        validations: [ValidationType.NotBlank],
    },
};

const merchantValidations = {
    email: {
        validations: [ValidationType.NotBlank],
    },
    firstName: {
        validations: [ValidationType.NotBlank],
    },
    lastName: {
        validations: [ValidationType.NotBlank],
    },
    phoneNumber: {
        validations: [ValidationType.NotBlank],
    },
};

export const validations = {
    login: {
        email: {
            validations: [ValidationType.NotBlank],
        },
        password: {
            validations: [ValidationType.NotBlank],
        },
    },
    registration: {
        email: {
            validations: [ValidationType.NotBlank],
        },
        firstName: {
            validations: [ValidationType.NotBlank],
        },
        lastName: {
            validations: [ValidationType.NotBlank],
        },
        password: {
            validations: [ValidationType.NotBlank],
        },
        repeatPassword: {
            validations: [ValidationType.NotBlank],
        },
        phoneNumber: {
            validations: [ValidationType.NotBlank, ValidationType.Number, ValidationType.Min],
            min: 100000000,
        },
        terms: {
            validations: [ValidationType.Checked],
        },
    },
    acceptInvite: {
        firstName: {
            validations: [ValidationType.NotBlank],
        },
        lastName: {
            validations: [ValidationType.NotBlank],
        },
        password: {
            validations: [ValidationType.NotBlank],
        },
        repeatPassword: {
            validations: [ValidationType.NotBlank],
        },
        phoneNumber: {
            validations: [ValidationType.Number, ValidationType.Min, ValidationType.Max, ValidationType.NotDecimal],
            min: 100000000,
            max: 999999999,
        },
        terms: {
            validations: [ValidationType.Checked],
        },
    },
    recover: {
        email: {
            validations: [ValidationType.NotBlank],
        },
    },
    reset: {
        newPassword: {
            validations: [ValidationType.NotBlank],
        },
        repeatPassword: {
            validations: [ValidationType.NotBlank],
        },
    },
    profile: {
        firstName: {
            validations: [ValidationType.NotBlank],
        },
        lastName: {
            validations: [ValidationType.NotBlank],
        },
    },
    businessCreate: {
        ...businessValidations,
        terms: {
            validations: [ValidationType.Checked],
        },
    },
    businessUpdate: businessValidations,
    predictArea: {
        businessId: {
            validations: [ValidationType.NotBlank],
        },
        bollardRadius: {
            validations: [ValidationType.NotBlank, ValidationType.Min, ValidationType.Decimal],
            decimalPoints: 1,
            min: .5,
        },
    },
    businessEuPago: {
        username: {
            validations: [ValidationType.NotBlank],
        },
        password: {
            validations: [ValidationType.NotBlank],
        },
        client_secret: {
            validations: [ValidationType.NotBlank],
        },
        client_id: {
            validations: [ValidationType.NotBlank],
        },
        chave: {
            validations: [ValidationType.NotBlank],
        },
    },
    invitation: {
        email: {
            validations: [ValidationType.NotBlank, ValidationType.Regex],
            regex: Regex.Email,
        },
    },
    areaCreate: areaValidations,
    areaUpdate: { ...areaValidations, areaId: { validations: [ValidationType.NotBlank] } },
    bookingCreate: bookingValidations,
    bookingCreateUnregistered: bookingValidationsUnregistered,
    bookingUpdate: bookingValidations,
    bookingUpdateUnregistered: bookingValidationsUnregistered,
    merchantCreate: merchantValidations,
    merchantUpdate: merchantValidations,
};
