/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { UserStatus } from './users';

export interface ReservationManager {
    id: number;
    name: string;
    merchantId: number;
    merchantName: string;
    email: string;
    status: UserStatus;
}

export enum ListViewDetail {
    AreasManagement = 'AreasManagement',
    PersonalInformation = 'PersonalInformation',
}
