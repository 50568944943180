/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import axios, { AxiosError } from 'axios';
import { connect } from 'react-redux';

import { InvitationsContextProvider } from './InvitationsContext';
import {
    acceptInviteURL,
    invitationsMerchantURL, invitationReservationManagerByAdminURL,
    invitationReservationManagerByMerchantURL,
    invitationsURL, invitationURL,
} from '../../services/invitations';
import { AcceptInvitePayload, Invitation, InvitationPayload } from '../../types/invitations';
import { KeyedObject, ListResponse } from '../../types/general';
import { COUNT_HEADER } from '../../utils/constants';
import { validateForm } from '../../utils/validations';
import { validations } from '../../types/validations';
import { GenericFunction } from '../../utils/misc';
import { AuthorizationContext, withAuthorizationContext } from './AuthorizationContext';
import { Permission } from '../../types/authorization';
import { AppState } from '../../reducers/types';

interface OwnProps extends AuthorizationContext {
    children: React.ReactNode;
}

interface StateProps {
    selectedMerchantId: number | null;
}

type Props = OwnProps & StateProps;

export class InvitationsController extends Component<Props> {
    acceptInvite = async (token: string, fields: AcceptInvitePayload, onSuccess: () => void, onFailure: () => void): Promise<void> => {
        try {
            await axios.post(acceptInviteURL(token), fields);
            onSuccess();
        } catch {
            onFailure();
        }
    };

    getInvitations = async (filters?: KeyedObject): Promise<ListResponse<Invitation> | null> => {
        const { checkPermission, selectedMerchantId } = this.props;
        try {
            let url = invitationsMerchantURL(filters);
            if (checkPermission([Permission.ALL_RESERVATION_MANAGERS])) {
                url = invitationsURL({
                    ...filters,
                    merchantId: selectedMerchantId,
                });
            }

            const { data, headers } = await axios.get(url);
            const total = headers[COUNT_HEADER] ? Number(headers[COUNT_HEADER]) : 0;
            return { data, total };
        } catch {
            return null;
        }
    };

    validateInvitation = (fields: InvitationPayload): KeyedObject | null => {
        const errors: KeyedObject | null = validateForm(fields, validations.invitation);

        if (!errors || Object.keys(errors).length === 0) return null;
        return { fields: errors };
    };

    sendMerchantInvitation = async (payload: InvitationPayload, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void> => {
        try {
            await axios.post(invitationsMerchantURL(), payload);
            onSuccess();
        } catch (error) {
            const err = error as AxiosError;
            if (onFailure) onFailure((err)?.response?.data?.errors[0]?.errorCode);
        }
    };

    sendReservationManagerInvitation = async (payload: InvitationPayload, onSuccess: GenericFunction, onFailure: GenericFunction) => {
        const { selectedMerchantId } = this.props;

        let url = invitationReservationManagerByMerchantURL();
        if (selectedMerchantId) url = invitationReservationManagerByAdminURL(selectedMerchantId);

        try {
            await axios.post(url, payload);
            onSuccess();
        } catch (error) {
            const err = error as AxiosError;
            if (onFailure) onFailure((err)?.response?.data?.errors[0]?.errorCode);
        }
    }

    deleteInvitation = async (id: number, onSuccess: () => void, onFailure: () => void): Promise<void> => {
        try {
            await axios.delete(invitationURL(id));
            onSuccess();
        } catch {
            onFailure();
        }
    }

    render() {
        const {
            children,
        } = this.props;

        return (
            <InvitationsContextProvider
                value={{
                    acceptInvite: this.acceptInvite,
                    getInvitations: this.getInvitations,
                    validateInvitation: this.validateInvitation,
                    sendMerchantInvitation: this.sendMerchantInvitation,
                    sendReservationManagerInvitation: this.sendReservationManagerInvitation,
                    deleteInvitation: this.deleteInvitation,
                }}
            >
                {children}
            </InvitationsContextProvider>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => {
    return {
        selectedMerchantId: state.merchants.selectedMerchantId,
    };
};

export const ConnectedInvitationsController = connect(mapStateToProps)(withAuthorizationContext(InvitationsController));
