/**
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react';
import {
    Button,
    CircularProgress, Dialog, Divider, IconButton, Input,
} from '@material-ui/core';
import { Close, ArrowBack } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import { throttle } from 'lodash';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { OrderQuery } from '../../types/general';
import { BusinessesContext, withBusinessesContext } from '../controllers/BusinessesContext';
import { Business } from '../../types/businesses';
import { ReservationManagersContext, withReservationManagersContext } from '../controllers/ReservationManagersContext';
import { Area } from '../../types/areas';
import { MerchantsContext, withMerchantsContext } from '../controllers/MerchantsContext';
import { displayNotification, NotificationType } from '../../utils/notifications';

enum modalView {
    business = 'business',
    area = 'area',
}

interface OwnProps extends TranslationContext, BusinessesContext, ReservationManagersContext, MerchantsContext {
    isOpen: boolean;
    onClose: () => void;
    reservationManagerId: number;
}

type Props = OwnProps;
const AddAreaModal = (props: Props) => {
    const {
        isOpen,
        onClose,
        t,
        getBusinesses,
        getReservationManagerAvailableAreasRelatedToABusiness,
        reservationManagerId,
        associateReservationManagerToAnArea,
        disassociateReservationManagerFromAnArea,
        selectedMerchantId,
    } = props;

    const [modalCurrentView, setModalCurrentView] = React.useState<modalView>(modalView.business);
    const [searchInputValue, setSearchInputValue] = React.useState('');
    const [businesses, setBusinesses] = React.useState<Business[]>([]);
    const [areas, setAreas] = React.useState<Area[]>([]);
    const [businessIdSelected, setBusinessIdSelected] = React.useState<number | null>(null);
    const [areaIdSelected, setAreaIdSelected] = React.useState<number | null>(null);
    const [manipulatedAreasIds, setManipulatedAreasIds] = React.useState<number[]>([]);
    const [isFetching, setIsFetching] = React.useState(true);

    const renderHeader = (view: modalView) => {
        if (view === modalView.business) {
            return (
                <div className="add-area-modal__top__header">
                    {t('addAreaModal.chooseBusiness')}
                </div>
            );
        }
        return (
            <div className="add-area-modal__top__header">
                <IconButton
                    data-testid="modal-back-button"
                    aria-label="back"
                    onClick={handleBackClick}
                    disableRipple
                >
                    <ArrowBack color="primary" />
                </IconButton>
                {t('addAreaModal.chooseArea')}
            </div>
        );
    };

    const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInputValue(event.target.value);
    };

    const fetchBusiness = async () => {
        setIsFetching(true);
        const params = {
            _q: searchInputValue,
            _order: OrderQuery.Ascending,
            _sort: 'name',
            _limit: 10,
            merchantId: selectedMerchantId,
        };

        const data = await getBusinesses(params);
        if (data?.data) setBusinesses(data.data);
        setIsFetching(false);
    };
    const fetchAreas = async () => {
        if (businessIdSelected) {
            setIsFetching(true);
            const data = await getReservationManagerAvailableAreasRelatedToABusiness(reservationManagerId, businessIdSelected);
            if (data?.data) setAreas(data.data);
            setIsFetching(false);
        }
    };

    const throttledFetchMerchants = throttle(fetchBusiness, 500);

    const handleOnAreaClick = (id: number) => {
        setAreaIdSelected(id);
        displayNotification({
            message: isIdInManipulatedAreas(id) ? t('addAreaModal.removeSuccess') : t('addAreaModal.addSuccess'),
            type: NotificationType.Success,
        });
    };

    const removeAreaId = (remove: boolean) => {
        if (!areaIdSelected) return;
        if (remove) {
            setManipulatedAreasIds(manipulatedAreasIds.filter(id => id !== areaIdSelected));
        }
        setAreaIdSelected(null);
    };

    const performAreaAction = async () => {
        if (!areaIdSelected) return;
        if (manipulatedAreasIds.includes(areaIdSelected)) {
            await disassociateReservationManagerFromAnArea(reservationManagerId, areaIdSelected, () => removeAreaId(true));
        } else {
            await associateReservationManagerToAnArea(reservationManagerId, areaIdSelected, removeAreaId);
            setManipulatedAreasIds(prevState => [...prevState, areaIdSelected]);
        }
    };

    const renderAreaButtonText = (id: number) => {
        if (isIdInManipulatedAreas(id)) return t('addAreaModal.remove');
        return t('addAreaModal.add');
    };

    const isIdInManipulatedAreas = (id: number) => {
        return manipulatedAreasIds.includes(id);
    };

    const renderModalContent = (id: number, name: string, view: modalView = modalView.business) => {
        const isRemoveButton = isIdInManipulatedAreas(id) ? '--remove' : '';
        return (
            <div className="add-area-modal__content__item-wrapper" id={String(id)}>
                <div className={`add-area-modal__content__item-wrapper__item${isRemoveButton}`}>
                    <h5>{name}</h5>
                    <Button
                        variant="contained"
                        color="primary"
                        data-testid={view === modalView.business ? 'select-business-button' : 'select-area-button'}
                        onClick={view === modalView.business ? () => handleOnBusinessClick(id) : () => handleOnAreaClick(id)}
                    >
                        {view === modalView.business ? t('addAreaModal.select') : renderAreaButtonText(id)}
                    </Button>
                </div>
                <Divider />
            </div>
        );
    };

    const handleOnBusinessClick = (id: number) => {
        setBusinessIdSelected(id);
        setModalCurrentView(modalView.area);
        setIsFetching(true);
    };

    const handleBackClick = () => {
        setAreas([]);
        setModalCurrentView(modalView.business);
    };

    useEffect(() => {
        throttledFetchMerchants();
    }, [searchInputValue]);

    useEffect(() => {
        if (businessIdSelected) {
            fetchAreas();
        }
    }, [businessIdSelected]);

    useEffect(() => {
        if (!areaIdSelected) return;
        performAreaAction();
    }, [areaIdSelected]);

    return (
        <Dialog open={isOpen} onClose={onClose} className="add-area-modal">
            <div className="add-area-modal__top">
                {renderHeader(modalCurrentView)}
                <IconButton
                    data-testid="modal-close-button"
                    aria-label="close"
                    onClick={onClose}
                    disableRipple
                >
                    <Close color="primary" />
                </IconButton>
            </div>
            <div className="add-area-modal__input-wrapper">
                <Input
                    className="add-area-modal__input-wrapper__input"
                    value={searchInputValue}
                    onChange={handleSearchInputChange}
                    placeholder={t('drawer.search')}
                    disableUnderline
                    data-testid="business-search-input"
                    startAdornment={<SearchIcon />}
                    endAdornment={isFetching
                        ? <CircularProgress data-testid="loader" size={20} /> : null}
                />
            </div>
            <div className="add-area-modal__content">
                {modalCurrentView === modalView.business
                    ? businesses.map(business => (
                        renderModalContent(business.id, business.name)
                    ))
                    : areas.map(area => (
                        renderModalContent(area.id, area.name, modalView.area)
                    ))}
            </div>
        </Dialog>
    );
};

export default withMerchantsContext(withReservationManagersContext(withBusinessesContext(withTranslationContext(AddAreaModal))));
