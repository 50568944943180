/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

export interface KeyedObject {
    [key: string]: any;
}

export interface Header {
    label: string;
    key: string;
}

export type SelectOption = {
    value: any;
    label: any;
};

export enum Status {
    Active = 'Ativo',
    Inactive = 'Desativo',
    Pending = 'Pendente',
}

export enum Order {
    Ascending = 'asc',
    Descending = 'desc',
}

export enum OrderQuery {
    Ascending = 'Ascending',
    Descending = 'Descending',
}

export interface QueryParams {
    _limit: number;
    _order?: OrderQuery;
    _page: number;
    _sort?: string;
    _q?: string;
}

export interface Country {
    code: string;
    countryName: string;
    phoneCode: string;
}

export interface GeoPoint {
    lat: number;
    lng: number;
}

export enum WeekDays {
    Sunday = 'SUNDAY',
    Monday = 'MONDAY',
    Tuesday = 'TUESDAY',
    Wednesday = 'WEDNESDAY',
    Thursday = 'THURSDAY',
    Friday = 'FRIDAY',
    Saturday = 'SATURDAY',
}

export interface Time {
    hour: number;
    minutes: number;
}

export interface ListResponse<T> {
    data: T[];
    total: number;
}