/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, ReactElement } from 'react';
import moment from 'moment';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import OptionsIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { throttle } from 'lodash';
import momentTz from 'moment-timezone';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    IconButton,
    InputAdornment,
} from '@material-ui/core';

import { KeyboardArrowDown } from '@material-ui/icons';
import Lottie from 'react-lottie';
import { ReactComponent as IconSearch } from '../../assets/images/icon_search.svg';
import Pagination from '../elements/Pagination';

import FormSelectField from '../elements/FormSelectField';
import FormTextField from '../elements/FormTextField';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { AreasContext, withAreasContext } from '../controllers/AreasContext';
import { BusinessesContext, withBusinessesContext } from '../controllers/BusinessesContext';
import { BookingsContext, withBookingsContext } from '../controllers/BookingsContext';
import { AppRoute } from '../../types/routes';
import { buildRoute } from '../../utils/misc';
import {
    Booking, BookingParams, BookingPaymentStatus, BookingStatus,
} from '../../types/bookings';
import { Area } from '../../types/areas';
import { Business } from '../../types/businesses';
import { Order, OrderQuery, SelectOption } from '../../types/general';
import { displayNotification, NotificationType } from '../../utils/notifications';
import { ErrorCode } from '../../types/errors';
import { Permission } from '../../types/authorization';
import Can from '../containers/Can';
import Loader from '../elements/Loader';
import { BookingFormField } from '../views/BookingForm';
import { MerchantsContext, withMerchantsContext } from '../controllers/MerchantsContext';

import { ReactComponent as IconReserved } from '../../assets/images/icon_check.svg';
import { ReactComponent as IconCanceled } from '../../assets/images/icon_x.svg';
import { ReactComponent as IconChecked } from '../../assets/images/icon_out.svg';
import { ReactComponent as IconPending } from '../../assets/images/icon_dotted.svg';
import { ReactComponent as IconAbsence } from '../../assets/images/icon_minus.svg';
import { MultipleDatePicker } from '../elements/MultipleDatePicker';
import NoDataLottie from '../../assets/lottie/no-data.json';

interface RouteState {
    businessId?: number;
}

interface OwnProps extends TranslationContext, BusinessesContext, AreasContext, BookingsContext, MerchantsContext,
    RouteComponentProps<Record<string, string | undefined>, Record<string, unknown>, RouteState> {}

interface OwnState {
    anchorElement: any;
    openMenu: number;
    order: Order;
    bookings: Booking[];
    businessOptions: SelectOption[];
    areaOptions: SelectOption[];
    params: BookingParams;
    totalBookings: number;
    showDialog: boolean;
    dialogTitle: string;
    dialogMessage: string;
    dialogSuccess: () => void;
    isFetching: boolean;
    daysSelectedFilter: Array<moment.Moment>;
    showDatePicker: boolean;
}

const initialState: OwnState = {
    anchorElement: null,
    openMenu: -1,
    order: Order.Descending,
    bookings: [],
    businessOptions: [],
    areaOptions: [],
    totalBookings: 0,
    params: {
        _limit: 20,
        _page: 0,
        _order: OrderQuery.Descending,
        _q: '',
        _sort: 'createdDate',
        status: BookingStatus.Reserved,
        businessId: undefined,
        businessAreaId: undefined,
        days: null,
    },
    showDialog: false,
    dialogTitle: '',
    dialogMessage: '',
    dialogSuccess: () => {},
    isFetching: true,
    daysSelectedFilter: [],
    showDatePicker: false,
};

enum BookingFilterName {
    Business = 'businessId',
    Area = 'businessAreaId',
}

let throttledFetchBookings = () => {};

class BookingsScreen extends Component<OwnProps, OwnState> {
    constructor(props: OwnProps) {
        super(props);
        this.state = {
            ...initialState,
            params: {
                ...initialState.params,
                businessId: props.location.state?.businessId,
            },
        };
        throttledFetchBookings = throttle(this.fetchBookings, 500);
    }

    fetchBookings = () => {
        const { getBookings } = this.props;
        const { params, daysSelectedFilter } = this.state;

        const days = daysSelectedFilter.map(daySelected => daySelected.format('yyyy-MM-DD'));

        const newParams = {
            ...params,
            days,
        };

        getBookings(newParams).then(bookingsData => {
            if (bookingsData) {
                this.setState({
                    bookings: bookingsData.data,
                    totalBookings: bookingsData.total,
                });
            }
        });
    };

    componentDidMount() {
        this.prepare();
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>) {
        const { selectedMerchantId } = this.props;
        if (selectedMerchantId !== prevProps.selectedMerchantId) this.fetchBusinesses();
    }

    prepare = async () => {
        await this.fetchBusinesses();
        this.getPreSelectedDaysFilter();
    }

    fetchBusinesses = async () => {
        const { getBusinesses, selectedMerchantId } = this.props;
        const businessesData = await getBusinesses({
            _limit: 999, _sort: 'name', _order: 'Ascending', merchantId: selectedMerchantId,
        });
        if (businessesData) {
            const businessOptions: SelectOption[] = [];
            businessesData.data.forEach((business: Business) => {
                if (business.numberOfAreas > 0) {
                    businessOptions.push({ label: business.name, value: business.id });
                }
            });
            this.setState({ businessOptions });

            if (businessOptions.length > 0) return this.onParamsChange(BookingFilterName.Business, businessOptions[0].value);

            this.setState({
                isFetching: false,
                areaOptions: [],
            });
        }
    };

    fetchAreas = async (businessId: string) => {
        const { getAreas } = this.props;

        const areasData = await getAreas({
            _limit: 999, _sort: 'name', _order: OrderQuery.Ascending,
        }, String(businessId));

        if (!areasData) return this.setState({ isFetching: false });

        const areaOptions: SelectOption[] = [];
        areasData.data.forEach((area: Area) => {
            areaOptions.push({ label: area.name, value: area.id });
        });

        this.setState({ areaOptions },
            () => this.setState({ isFetching: false }));

        if (areaOptions.length > 0) return this.onParamsChange(BookingFilterName.Area, areaOptions[0].value);
    };

    getPreSelectedDaysFilter = () => {
        const daysSelectedFilterArray = [];

        for (let i = 0; i <= 7; i++) {
            daysSelectedFilterArray.push(moment().add(i, 'days'));
        }
        
        this.setState({ daysSelectedFilter: daysSelectedFilterArray });
    }

    onParamsChange = (name: string, value: string, throttle = false): void => {
        this.setState(
            {
                params: {
                    ...this.state.params,
                    _page: throttle ? 0 : this.state.params._page,
                    [name]: value,
                },
            },
            () => (throttle ? throttledFetchBookings() : this.fetchBookings()),
        );
        if (name === BookingFormField.Business) this.fetchAreas(value);
    };

    activateMenu = (evt: React.MouseEvent<SVGSVGElement>, menuId: number) => {
        evt.stopPropagation();
        this.setState({
            anchorElement: evt.currentTarget,
            openMenu: menuId,
        });
    };

    resetMenu = () => {
        this.setState({
            anchorElement: null,
            openMenu: -1,
        });
    };

    onPageChange = (evt: any, page: number) => {
        this.setState(
            {
                params: { ...this.state.params, _page: page - 1 },
            },
            this.fetchBookings,
        );
    };

    onRowsPerPageChange = (evt: any) => {
        this.setState(
            {
                params: { ...this.state.params, _limit: evt.target.value },
            },
            this.fetchBookings,
        );
    };

    onChangePaymentStatus = (reservationId: React.Key, newStatus: BookingPaymentStatus) => {
        const { t } = this.props;
        this.setState({
            showDialog: true,
            dialogTitle: newStatus === BookingPaymentStatus.Paid ? t('bookingsScreen.markAsPaid.title') : t('bookingsScreen.markAsNotPaid.title'),
            dialogMessage: newStatus === BookingPaymentStatus.Paid ? t('bookingsScreen.markAsPaid.message') : t('bookingsScreen.markAsNotPaid.message'),
            dialogSuccess: () => this.changePaymentStatus(reservationId, newStatus),
        });
    };

    onCancelReservation = (reservationId: React.Key) => {
        const { t, cancelBooking } = this.props;

        this.setState({
            showDialog: true,
            dialogTitle: t('bookingsScreen.cancel.title'),
            dialogMessage: t('bookingsScreen.cancel.message'),
            dialogSuccess: () => cancelBooking(
                reservationId,
                () => {
                    displayNotification({
                        type: NotificationType.Success,
                        message: t('bookingsScreen.cancel.success'),
                    });
                    this.setState({ showDialog: false }, this.fetchBookings);
                    this.resetMenu();
                },
                errorCode => {
                    displayNotification({
                        type: NotificationType.Danger,
                        message: t(errorCode ? `errors.${ErrorCode[errorCode]}` : 'errors.general'),
                    });
                    this.setState({ showDialog: false });
                    this.resetMenu();
                },
            ),
        }, () => this.fetchBookings());
    }

    selectSort = (col: string) => {
        const { order, params } = this.state;
        const sort = params._sort;
        const newOrder = sort !== col ? Order.Ascending : order === Order.Ascending ? Order.Descending : Order.Ascending;
        const newOrderParam = newOrder === Order.Ascending ? OrderQuery.Ascending : OrderQuery.Descending;
        this.setState(
            {
                order: newOrder,
                params: {
                    ...params,
                    _sort: col,
                    _order: newOrderParam,
                },
            },
            this.fetchBookings,
        );
    };

    changePaymentStatus = (reservationId: React.Key, newStatus: BookingPaymentStatus) => {
        const { t, setBookingPaymentStatus } = this.props;

        setBookingPaymentStatus(
            reservationId,
            newStatus,
            () => {
                if (newStatus === BookingPaymentStatus.Paid) {
                    displayNotification({
                        type: NotificationType.Success,
                        message: t('bookingsScreen.markAsPaid.success'),
                    });
                } else {
                    displayNotification({
                        type: NotificationType.Success,
                        message: t('bookingsScreen.markAsNotPaid.success'),
                    });
                }
                this.fetchBookings();
                this.setState({ showDialog: false });
            },
            errorCode => {
                displayNotification({
                    type: NotificationType.Danger,
                    message: t(errorCode ? `errors.${ErrorCode[errorCode]}` : 'errors.general'),
                });
                this.setState({ showDialog: false });
            },
        );

        this.resetMenu();
    };

    getPaymentColorClass = (status: BookingPaymentStatus): string => {
        if (status === BookingPaymentStatus.Paid) return 'booking-paid';
        if (status === BookingPaymentStatus.NotPaid) return 'booking-not-paid';
        return '';
    };

    getPaymentTooltip = (status: BookingPaymentStatus): string => {
        const { t } = this.props;

        if (status === BookingPaymentStatus.Paid) return t('bookingsScreen.paid');
        if (status === BookingPaymentStatus.NotPaid) return t('bookingsScreen.notPaid');
        return '';
    };

    getStatusClass = (status: string): string => {
        switch (status) {
            case BookingStatus.ATMPending:
            case BookingStatus.CCPending:
            case BookingStatus.MBWayPending:
                return 'pending';
            case BookingStatus.CheckedIn:
            case BookingStatus.CheckedOut:
                return 'checked';
            case BookingStatus.Reserved:
            case BookingStatus.MerchantReservationConcluded:
                return 'reserved';
            case BookingStatus.Canceled:
            case BookingStatus.CanceledByMerchant:
            case BookingStatus.CanceledByCustomer:
            case BookingStatus.NotPaid:
                return 'canceled';
            case BookingStatus.Absence:
                return 'absence';
            default:
                return '';
        }
    }

    getStatusIcon = (status: string): ReactElement | null => {
        switch (status) {
            case BookingStatus.ATMPending:
            case BookingStatus.CCPending:
            case BookingStatus.MBWayPending:
                return <IconPending />;
            case BookingStatus.CheckedIn:
            case BookingStatus.CheckedOut:
                return <IconChecked />;
            case BookingStatus.Reserved:
            case BookingStatus.MerchantReservationConcluded:
                return <IconReserved />;
            case BookingStatus.Canceled:
            case BookingStatus.CanceledByMerchant:
            case BookingStatus.CanceledByCustomer:
            case BookingStatus.NotPaid:
                return <IconCanceled />;
            case BookingStatus.Absence:
                return <IconAbsence />;
            default:
                return null;
        }
    }

    onSelectDays = (dates: moment.Moment[]) => {
        this.setState({
            daysSelectedFilter: dates,
            params: {
                ...this.state.params,
                _page: 0,
                days: dates.map(date => date.format('yyyy-MM-DD')),
            },
        }, () => {
            this.fetchBookings();
        });
    };

    renderTableHeadCell(name: string, label?: string): ReactElement {
        const { t } = this.props;
        const { order, params } = this.state;
        const sort = params._sort;

        if (name === 'status') {
            return (
                <TableCell align="center">
                    <TableHead>
                        {t(`bookingsScreen.${label || name}`)}
                    </TableHead>
                </TableCell>
            );
        }

        return (
            <TableCell align="center">
                <TableSortLabel
                    active={sort === name}
                    direction={sort === name ? order : Order.Ascending}
                    onClick={() => this.selectSort(name)}
                >
                    {t(`bookingsScreen.${label || name}`)}
                </TableSortLabel>
            </TableCell>
        );
    }

    renderCustomerName = (booking: Booking) => {
        const { t } = this.props;

        const { customer, unregisteredReservationInfo } = booking;

        if (customer) {
            return (
                <TableCell align="center">
                    {`${customer.firstName} ${customer.lastName}`}
                </TableCell>
            );
        }

        if (unregisteredReservationInfo && (unregisteredReservationInfo.firstName || unregisteredReservationInfo.lastName)) {
            let name = '';
            if (unregisteredReservationInfo.firstName) {
                name += unregisteredReservationInfo.firstName;
            }
            if (unregisteredReservationInfo.lastName) {
                name += ` ${unregisteredReservationInfo.lastName}`;
            }

            return (
                <TableCell align="center">
                    {name}
                </TableCell>
            );
        }

        return (
            <TableCell align="center">
                {t('bookingsScreen.finalClient')}
            </TableCell>
        );
    }

    renderMenuItem(bookingStatus: string, bookingId: number): ReactElement {
        const { t } = this.props;

        switch (bookingStatus) {
            case BookingStatus.Reserved:
            case BookingStatus.CheckedIn:
            case BookingStatus.MBWayPending:
            case BookingStatus.CCPending:
            case BookingStatus.ATMPending:
                return (
                    <MenuItem
                        onClick={() => this.onCancelReservation(bookingId)}
                        data-testid="cancel-reservation-btn"
                    >
                        {t('general.cancel')}
                    </MenuItem>
                );
            default:
                return <></>;
        }
    }

    renderTable(): ReactElement {
        const { t } = this.props;
        const {
            bookings, anchorElement, openMenu, totalBookings, params,
        } = this.state;

        if (bookings.length === 0) {
            const defaultOptions = {
                loop: true,
                autoplay: true,
                animationData: NoDataLottie,
                rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                },
            };
            
            return (
                <div className="placeholder-no-data">
                    <Lottie
                        options={defaultOptions}
                        height={194}
                        width={194}
                    />
                    <p className="placeholder-no-data__title">{t('bookingsScreen.noDataTitle')}</p>
                    <p className="placeholder-no-data__message">{t('bookingsScreen.noDataMessage')}</p>
                </div>
            );
        }

        return (
            <Paper>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {this.renderTableHeadCell('id', 'id')}
                                {this.renderTableHeadCell('customer.name', 'customer')}
                                {this.renderTableHeadCell('startDateTime')}
                                {this.renderTableHeadCell('status')}
                                {this.renderTableHeadCell('price')}
                                <TableCell align="center">{t('bookingsScreen.options')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {bookings.map(booking => {
                                const startDateTimeTz = momentTz.utc(booking.startDateTime);
                                const endDateTimeTz = momentTz.utc(booking.endDateTime);
                                const startDay = startDateTimeTz.clone().tz(booking.timeZone).format('DD-MM-YYYY');
                                const startTime = startDateTimeTz.clone().tz(booking.timeZone).format('HH:mm');
                                const endTime = endDateTimeTz.clone().tz(booking.timeZone).format('HH:mm');

                                return (
                                    <TableRow key={booking.id}>
                                        <TableCell align="center">{booking.id}</TableCell>
                                        {this.renderCustomerName(booking)}
                                        <TableCell align="center">
                                            {`${startDay} ${startTime} - ${endTime}`}
                                        </TableCell>
                                        <TableCell align="center">
                                            <div className={`table-cell-status ${this.getStatusClass(booking.status)}`}>
                                                {this.getStatusIcon(booking.status)}
                                                {t(`status.${booking.status}`)}
                                            </div>
                                        </TableCell>
                                        <TableCell align="center">
                                            <div
                                                className={`price-tag ${this.getPaymentColorClass(booking.paymentStatus)}`}
                                                title={this.getPaymentTooltip(booking.paymentStatus)}
                                            >
                                                {booking.price ? booking.price : 0} €
                                            </div>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Menu
                                                className="area-menu-items"
                                                anchorEl={anchorElement}
                                                keepMounted
                                                open={openMenu === booking.id}
                                                onClose={this.resetMenu}
                                            >
                                                <Link
                                                    to={buildRoute(AppRoute.BookingDetails, {
                                                        bookingId: booking.id,
                                                    })}
                                                >
                                                    <MenuItem data-testid="reservation-details-btn">
                                                        {t('bookingsScreen.details')}
                                                    </MenuItem>
                                                </Link>
                                                {booking.price > 0 && booking.paymentStatus !== BookingPaymentStatus.Paid && (
                                                    <MenuItem
                                                        onClick={() => this.onChangePaymentStatus(booking.id, BookingPaymentStatus.Paid)}
                                                        data-testid="mark-as-paid-btn"
                                                    >
                                                        {t('bookingsScreen.markAsPaidMenuItem')}
                                                    </MenuItem>
                                                )}
                                                {booking.price > 0 && booking.paymentStatus !== BookingPaymentStatus.NotPaid && (
                                                    <MenuItem
                                                        onClick={() => this.onChangePaymentStatus(booking.id, BookingPaymentStatus.NotPaid)}
                                                        data-testid="mark-as-not-paid-btn"
                                                    >
                                                        {t('bookingsScreen.markAsNotPaidMenuItem')}
                                                    </MenuItem>
                                                )}
                                                {this.renderMenuItem(booking.status, booking.id)}
                                            </Menu>
                                            <OptionsIcon
                                                className="options-icon"
                                                onClick={evt => this.activateMenu(evt, booking.id)}
                                                data-testid="action-btn"
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Pagination
                    numberOfPages={Math.floor(Number((totalBookings / params._limit)))}
                    onPageChange={this.onPageChange}
                    currentPage={params._page + 1}
                />
            </Paper>
        );
    }

    renderDialog = () => {
        const { t } = this.props;
        const {
            showDialog, dialogTitle, dialogMessage, dialogSuccess,
        } = this.state;

        return (
            <Dialog
                open={showDialog}
                onClose={() => this.setState({ showDialog: false })}
            >
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{dialogMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.setState({ showDialog: false })} color="primary">
                        {t('general.cancel')}
                    </Button>
                    <Button onClick={() => dialogSuccess()} color="primary" autoFocus>
                        {t('general.yes')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    render() {
        const {
            businessOptions, areaOptions, isFetching, daysSelectedFilter, showDatePicker,
        } = this.state;
        const {
            businessId, businessAreaId, status, _q,
        } = this.state.params;
        const { t } = this.props;
        if (isFetching) {
            return <Loader />;
        }

        const statuses: SelectOption[] = [
            { label: t('statuses.all'), value: BookingStatus.All },
            { label: t('statuses.reserved'), value: BookingStatus.Reserved },
            { label: t('statuses.checkedIn'), value: BookingStatus.CheckedIn },
            { label: t('statuses.checkedOut'), value: BookingStatus.CheckedOut },
            { label: t('statuses.canceledByMerchant'), value: BookingStatus.CanceledByMerchant },
            { label: t('statuses.canceledByCustomer'), value: BookingStatus.CanceledByCustomer },
            { label: t('statuses.pendingATM'), value: BookingStatus.ATMPending },
            { label: t('statuses.pendingMBWay'), value: BookingStatus.MBWayPending },
            { label: t('statuses.pendingCC'), value: BookingStatus.CCPending },
            { label: t('statuses.absence'), value: BookingStatus.Absence },
            { label: t('statuses.notPaid'), value: BookingStatus.NotPaid },
            { label: t('statuses.merchantReservationConcluded'), value: BookingStatus.MerchantReservationConcluded },
        ];

        return (
            <div className="bookings-page">
                <div className="bookings-page__header">
                    <h2>{t('bookingsScreen.title')}</h2>
                    <div>
                        <Can
                            actions={[Permission.BOOKING_CREATE]}
                            yes={() => (
                                <Link to={AppRoute.AddBooking}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="button"
                                        data-testid="booking-create-button"
                                    >
                                        {t('bookingsScreen.addButton')}
                                    </Button>
                                </Link>
                            )}
                        />
                    </div>
                </div>
                <div className="bookings-page__filters">
                    <div className="bookings-page__filters__input-group">
                        <FormSelectField
                            name="businessId"
                            onChange={(name: string, value: string) => this.onParamsChange(name, value, true)}
                            options={businessOptions}
                            value={businessId}
                            errors={null}
                            label={t('bookingsScreen.businessFilter')}
                            testId="bookings-business-filter"
                        />
                        <FormSelectField
                            name="businessAreaId"
                            onChange={(name: string, value: string) => this.onParamsChange(name, value, true)}
                            options={areaOptions}
                            value={businessAreaId}
                            errors={null}
                            label={t('bookingsScreen.areaFilter')}
                            testId="bookings-area-filter"
                        />
                        <FormSelectField
                            name="status"
                            onChange={(name: string, value: string) => this.onParamsChange(name, value, true)}
                            options={statuses}
                            value={status}
                            errors={null}
                            label={t('bookingsScreen.statusFilter')}
                            testId="bookings-status-filter"
                        />
                        <div className="picker-container">
                            <FormControl variant="standard">
                                <FormTextField
                                    id="booking-day"
                                    name="days"
                                    label={t('bookingsScreen.dayFilter')}
                                    value={daysSelectedFilter.map(daySelectedFilter => daySelectedFilter.format('DD/MM/yyyy')).join(', ')}
                                    disabled={isFetching}
                                    errors={null}
                                    onClick={() => this.setState({ showDatePicker: true })}
                                    endAdornment={(
                                        <InputAdornment position="end">
                                            <IconButton
                                                data-testid="date-picker-icon"
                                                onClick={() => this.setState({ showDatePicker: true })}
                                                onMouseDown={() => this.setState({ showDatePicker: true })}
                                            >
                                                <KeyboardArrowDown />
                                            </IconButton>
                                        </InputAdornment>
                                    )}
                                />
                            </FormControl>
                        </div>
                    </div>
                    <FormTextField
                        name="_q"
                        value={_q}
                        onChange={(name: string, value: string) => this.onParamsChange(name, value, true)}
                        placeholder={t('bookingsScreen.search')}
                        errors={null}
                        className="search-field"
                        startAdornment={(
                            <InputAdornment position="start">
                                <IconSearch />
                            </InputAdornment>
                        )}
                    />
                </div>
                {this.renderTable()}
                {this.renderDialog()}
                <MultipleDatePicker
                    onClose={() => this.setState({ showDatePicker: false })}
                    onSubmit={sortedSelectedDays => this.onSelectDays(sortedSelectedDays)}
                    open={showDatePicker}
                    preSelectedDates={daysSelectedFilter}
                    name="days"
                    beforeToday
                    isRanged
                    limit={90}
                />
            </div>
        );
    }
}

export default withTranslationContext(withBusinessesContext(withAreasContext(withBookingsContext(withMerchantsContext(withRouter(BookingsScreen))))));
