/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { Business } from './businesses';
import { QueryParams, WeekDays, GeoPoint } from './general';
import { Bollard } from './bollards';

export interface Area {
    createdDate: Date;
    id: number;
    lastModifiedDate: Date;
    area: number;
    bollardRadius: number;
    maxBollards: number;
    name: string;
    photo: string;
    requestedBollards: number;
    reservationPeriods?: ReservationPeriod[];
    status: AreaStatus;
    vertices?: GeoPoint[];
    businessId?: number;
    business: Business;
    bollards: Bollard[];
    boundSW: GeoPoint;
    boundNE: GeoPoint;
    timeZone: string;
    description: string;
}

export interface AreaParams extends QueryParams {
    _q: string;
    businessId: string;
    status?: AreaStatus;
}

export enum AreaStatus {
    All = '',
    Active = 'ACTIVE',
    Disabled = 'DISABLED',
    Pending = 'PENDING',
}

export interface ReservationPeriod {
    createdDate: Date;
    id: number;
    lastModifiedDate: Date;
    dayOfWeek?: WeekDays | null;
    dayOfYear?: Date | null;
    endTime: string;
    localEndTime?: string;
    price: number;
    startTime: string;
    localStartTime?: string;
    designation: string | null;
}

export interface FormattedReservationPeriod {
    id?: number | undefined;
    dayOfWeek?: WeekDays | null;
    dayOfYear?: Date | null;
    endTime: string;
    price: string | number;
    startTime: string;
}

export interface ReservationPeriodFormEntry {
    id?: number;
    dayOfWeek?: WeekDays | null;
    dayOfYear?: Date | null;
    endTime: string;
    price: string;
    startTime: string;
    designation: string;
    edited?: boolean;
}

export interface AreaPredictBollard {
    geoCenter: GeoPoint;
}

export interface AreaPredictBollardsResponse {
    area: number;
    bollards: AreaPredictBollard[];
}
