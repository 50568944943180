/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import axios from 'axios';

import { connect } from 'react-redux';
import { ReservationManagersContextProvider } from './ReservationManagersContext';
import { KeyedObject, ListResponse } from '../../types/general';
import { COUNT_HEADER } from '../../utils/constants';
import { AppState } from '../../reducers/types';
import { ReservationManager } from '../../types/reservationManager';
import {
    associateReservationManagerToAreaURL,
    disassociateReservationManagerFromAreaURL,
    disassociateReservationManagerFromBusinessURL, removeReservationManagerURL,
    reservationManagerAreasForABusinessURL,
    reservationManagerAvailableAreasURL,
    reservationManagerBusinessesURL,
    reservationManagersURL,
    reservationManagerURL,
} from '../../services/reservationManagers';
import { User, UsersRoles } from '../../types/users';
import { Business } from '../../types/businesses';
import { Area } from '../../types/areas';
import { GenericFunction } from '../../utils/misc';

interface OwnProps {
    children: React.ReactNode;
}

interface StateProps {
    selectedMerchantId: number | null;
    authenticatedUser: User | null;
}

type Props = OwnProps & StateProps;

export class ReservationManagersController extends Component<Props> {
    getReservationManagers = async (filters?: KeyedObject): Promise<ListResponse<ReservationManager>> => {
        const { selectedMerchantId, authenticatedUser } = this.props;
        const isAdmin = authenticatedUser?.role === UsersRoles.Admin;
        try {
            const url = reservationManagersURL(isAdmin, {
                ...filters,
                merchant: selectedMerchantId,
            });

            const { data, headers } = await axios.get(url);
            const total = headers[COUNT_HEADER] ? Number(headers[COUNT_HEADER]) : 0;

            return { data, total };
        } catch {
            return { data: [], total: 0 };
        }
    };

    getReservationManager = async (reservationManagerId: string): Promise<ReservationManager | null> => {
        try {
            const url = reservationManagerURL(reservationManagerId);

            const { data } = await axios.get(url);

            return data;
        } catch {
            return null;
        }
    };

    getReservationManagerBusinesses = async (reservationManagerId: string, filters?: KeyedObject): Promise<ListResponse<Business>> => {
        try {
            const url = reservationManagerBusinessesURL(reservationManagerId, {
                ...filters,
            });

            const { data, headers } = await axios.get(url);
            const total = headers[COUNT_HEADER] ? Number(headers[COUNT_HEADER]) : 0;

            return { data, total };
        } catch {
            return { data: [], total: 0 };
        }
    };

    getReservationManagerAreasRelatedToABusiness = async (reservationManagerId: number, businessId: number, filters?: KeyedObject): Promise<ListResponse<Area>> => {
        try {
            const url = reservationManagerAreasForABusinessURL(reservationManagerId, businessId, {
                ...filters,
            });

            const { data, headers } = await axios.get(url);
            const total = headers[COUNT_HEADER] ? Number(headers[COUNT_HEADER]) : 0;

            return { data, total };
        } catch {
            return { data: [], total: 0 };
        }
    };

    getReservationManagerAvailableAreasRelatedToABusiness = async (reservationManagerId: number, businessId: number): Promise<ListResponse<Area>> => {
        try {
            const {
                data,
                headers,
            } = await axios.get(reservationManagerAvailableAreasURL(reservationManagerId, businessId));
            return { data, total: headers[COUNT_HEADER] ? Number(headers[COUNT_HEADER]) : 0 };
        } catch {
            return { data: [], total: 0 };
        }
    };

    associateReservationManagerToAnArea = async (reservationManagerId: number, areaId: number, onSuccess?: GenericFunction): Promise<void | null> => {
        try {
            await axios.put(associateReservationManagerToAreaURL(reservationManagerId, areaId));
            if (onSuccess) onSuccess();
        } catch {
            return null;
        }
    };

    disassociateReservationManagerFromAnArea = async (reservationManagerId: number, areaId: number, onSuccess?: GenericFunction): Promise<void | null> => {
        try {
            await axios.put(disassociateReservationManagerFromAreaURL(reservationManagerId, areaId));
            if (onSuccess) onSuccess();
        } catch {
            return null;
        }
    };

    disassociateReservationManagerFromBusiness = async (reservationManagerId: number, areaId: number, onSuccess?: GenericFunction): Promise<void | null> => {
        try {
            await axios.put(disassociateReservationManagerFromBusinessURL(reservationManagerId, areaId));
            if (onSuccess) onSuccess();
        } catch {
            return null;
        }
    }

    removeReservationManager = async (reservationManagerId: number, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void> => {
        try {
            await axios.put(removeReservationManagerURL(reservationManagerId));
            onSuccess();
        } catch {
            onFailure();
        }
    }

    render() {
        const {
            children,
        } = this.props;

        return (
            <ReservationManagersContextProvider
                value={{
                    getReservationManagers: this.getReservationManagers,
                    getReservationManager: this.getReservationManager,
                    getReservationManagerBusinesses: this.getReservationManagerBusinesses,
                    getReservationManagerAreasRelatedToABusiness: this.getReservationManagerAreasRelatedToABusiness,
                    getReservationManagerAvailableAreasRelatedToABusiness: this.getReservationManagerAvailableAreasRelatedToABusiness,
                    associateReservationManagerToAnArea: this.associateReservationManagerToAnArea,
                    disassociateReservationManagerFromAnArea: this.disassociateReservationManagerFromAnArea,
                    disassociateReservationManagerFromBusiness: this.disassociateReservationManagerFromBusiness,
                    removeReservationManager: this.removeReservationManager,
                }}
            >
                {children}
            </ReservationManagersContextProvider>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => {
    return {
        selectedMerchantId: state.merchants.selectedMerchantId,
        authenticatedUser: state.authentication.user,
    };
};

export const ConnectedReservationManagersController = connect(mapStateToProps)(ReservationManagersController);
