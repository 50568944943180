/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

// Login

import axios, { AxiosError } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

import { KeyedObject } from '../types/general';
import { AreasAction, AreasActionTypes } from './areas_types';
import { areaURL, businessAreasURL } from '../services/businesses';

// Create Area

export const newAreaRequestActionCreator = (): AreasActionTypes => {
    return {
        type: AreasAction.CREATE_AREA_REQUEST,
    };
};

export const newAreaSuccessActionCreator = (): AreasActionTypes => {
    return {
        type: AreasAction.CREATE_AREA_SUCCESS,
    };
};

export const newAreaFailureActionCreator = (error: KeyedObject | null): AreasActionTypes => {
    return {
        type: AreasAction.CREATE_AREA_FAILURE,
        payload: error,
    };
};

export const requestCreateArea = (
    businessId: number,
    payload: FormData,
    onSuccess?: Function,
    onFailure?: Function,
) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(newAreaRequestActionCreator());
        try {
            const { status, data } = await axios.post(businessAreasURL(businessId), payload);

            if (status === 200) {
                dispatch(newAreaSuccessActionCreator());

                if (onSuccess) onSuccess(data);
            }
        } catch (error) {
            let formErrors = {};
            if (error) {
                formErrors = (error as AxiosError).response?.data;
            }
            dispatch(newAreaFailureActionCreator(formErrors));
            if (onFailure) onFailure();
        }
    };
};

// Update Area

export const editAreaRequestActionCreator = (): AreasActionTypes => {
    return {
        type: AreasAction.UPDATE_AREA_REQUEST,
    };
};

export const editAreaSuccessActionCreator = (): AreasActionTypes => {
    return {
        type: AreasAction.UPDATE_AREA_SUCCESS,
    };
};

export const editAreaFailureActionCreator = (error: KeyedObject | null): AreasActionTypes => {
    return {
        type: AreasAction.UPDATE_AREA_FAILURE,
        payload: error,
    };
};

export const requestUpdateArea = (
    businessId: number,
    areaId: number,
    payload: FormData,
    onSuccess?: Function,
    onFailure?: Function,
) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(editAreaRequestActionCreator());
        try {
            const { status, data } = await axios.put(areaURL(businessId, areaId), payload);

            if (status === 200) {
                dispatch(editAreaSuccessActionCreator());

                if (onSuccess) onSuccess(data);
            }
        } catch (error) {
            let formErrors = {};
            if (error) {
                formErrors = (error as AxiosError).response?.data;
            }
            dispatch(editAreaFailureActionCreator(formErrors));
            if (onFailure) onFailure();
        }
    };
};
