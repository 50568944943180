/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, FormEvent } from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import {
    Backdrop, Button, CircularProgress, FormGroup,
} from '@material-ui/core';
import IconPerson from '@material-ui/icons/AccountCircleOutlined';
import IconLock from '@material-ui/icons/LockOutlined';

import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/AuthenticationContext';
import FormTextField from '../elements/FormTextField';
import FormPasswordField from '../elements/FormPasswordField';
import { KeyedObject } from '../../types/general';
import { AppRoute } from '../../types/routes';
import { handleFormSubmitFailure } from '../../utils/validations';
import IconEyeClosed from '../assets/IconEyeClosed';
import IconEye from '../assets/IconEye';
import loginImage from '../../assets/images/login.svg';
import { UsersRoles } from '../../types/users';

enum LoginFormField {
    Username = 'email',
    Password = 'password',
}

export interface LoginFormFields {
    [LoginFormField.Username]: string;
    [LoginFormField.Password]: string;
}

interface MatchParams {
    token?: string;
}

interface OwnProps extends TranslationContext, AuthenticationContext, RouteComponentProps<MatchParams> {}

interface OwnState {
    isReady: boolean;
    validated: boolean | null;
    fields: LoginFormFields;
    errors: KeyedObject | null;
}

const initialState: OwnState = {
    isReady: false,
    validated: null,
    fields: {
        [LoginFormField.Username]: '',
        [LoginFormField.Password]: '',
    },
    errors: null,
};

class LoginForm extends Component<OwnProps, OwnState> {
    state = initialState;

    onInputChange = (name: string, value: string): void => {
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                [name]: value,
            },
        });
    };

    onFormSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        const { submitLogin, validateLogin } = this.props;
        const { fields } = this.state;

        const errors = validateLogin(fields);

        this.setState(
            {
                ...this.state,
                errors,
            },
            () => {
                if (!errors) {
                    submitLogin(fields, this.onFormSubmitSuccess, this.onFormSubmitFailure);
                }
            },
        );
    };

    onFormSubmitSuccess = () => {
        const { history, authenticatedUser } = this.props;

        if (authenticatedUser?.role === UsersRoles.Admin) return history.push(AppRoute.Clients);
        history.push(AppRoute.BookingsCalendar);
    };

    onFormSubmitFailure = () => {
        const { t, loginErrors } = this.props;

        this.setState({ errors: handleFormSubmitFailure(t, loginErrors) });
    };

    render() {
        const { t, loginFetching } = this.props;
        const { fields, errors } = this.state;
        return (
            <form onSubmit={this.onFormSubmit} className="narrow-form">
                <Backdrop open={loginFetching}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <img className="login-image" src={loginImage} alt="login" />
                <span className="login-text">{t('loginForm.text')}</span>
                <FormGroup row className="login-inputs">
                    <FormTextField
                        name={LoginFormField.Username}
                        value={fields[LoginFormField.Username]}
                        onChange={this.onInputChange}
                        placeholder={t('loginForm.usernameLabel')}
                        errors={errors}
                        disabled={loginFetching}
                        startAdornment={<IconPerson />}
                    />
                    <FormPasswordField
                        name={LoginFormField.Password}
                        value={fields[LoginFormField.Password]}
                        onChange={this.onInputChange}
                        placeholder={t('loginForm.passwordLabel')}
                        errors={errors}
                        disabled={loginFetching}
                        showButton
                        startAdornment={<IconLock />}
                        icon={<IconEye />}
                        iconToggled={<IconEyeClosed />}
                    />
                </FormGroup>
                <FormGroup row className="login-bottom">
                    <div>
                        <Button
                            className="white-button"
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={loginFetching}
                        >
                            {t('loginForm.submitButton')}
                        </Button>
                        <br />
                        <Link className="login-bottom__recover" to={AppRoute.Recover}>
                            {t('loginForm.recoverPassword')}
                        </Link>
                    </div>
                </FormGroup>
            </form>
        );
    }
}

export default withTranslationContext(withAuthenticationContext(withRouter(LoginForm)));
