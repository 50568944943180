/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';

import { ConnectedClientsController } from './ClientsController';
import { KeyedObject } from '../../types/general';
import { ListData } from '../../types/clients';

export interface ClientsContext {
    getClients(filters?: KeyedObject): Promise<ListData | null>;
}

export const clientsContextDefaultValue = {
    getClients: async (): Promise<null> => null,
};

const ClientsContextInstance = createContext<ClientsContext | null>(clientsContextDefaultValue);

export const ClientsContextProvider = ClientsContextInstance.Provider;
export const ClientsContextConsumer = ClientsContextInstance.Consumer;

export const withClientsContext = <P extends object>(
    Component: ComponentType<P>
): FC<Omit<P, keyof ClientsContext>> => (props) => (
    <ConnectedClientsController>
        <ClientsContextConsumer>{(ctx) => <Component {...(props as P)} {...ctx} />}</ClientsContextConsumer>
    </ConnectedClientsController>
);
