/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import axios from 'axios';

import { MerchantsContextProvider } from './MerchantsContext';
import { AppState } from '../../reducers/types';
import { merchantsURL, merchantURL } from '../../services/merchants';
import { KeyedObject, ListResponse } from '../../types/general';
import { validateForm } from '../../utils/validations';
import { ApiError } from '../../types/errors';
import {
    requestCreateMerchant,
    requestUpdateMerchant,
    setSelectedMerchantIdActionCreator,
} from '../../actions/merchants';
import { validations } from '../../types/validations';
import { Merchant, MerchantToggleStatusParam } from '../../types/users';
import { COUNT_HEADER } from '../../utils/constants';

interface StateProps {
    createMerchantFetching: boolean;
    createMerchantErrors: ApiError | null;
    updateMerchantFetching: boolean;
    updateMerchantErrors: ApiError | null;
    selectedMerchantId: number | null;
}

interface DispatchProps {
    dispatchRequestCreateMerchant: Function;
    dispatchRequestUpdateMerchant: Function;
    dispatchMerchantId: Function;
}

interface OwnProps {
    children: any;
}

interface OwnState {}

type Props = OwnProps & StateProps & DispatchProps;
type State = OwnState;

export class MerchantsController extends Component<Props, State> {
    // Requests

    getMerchant = async (merchantId: string | number): Promise<Merchant | null> => {
        try {
            const { data } = await axios.get(merchantURL(merchantId));
            return data;
        } catch {
            return null;
        }
    };

    getMerchants = async (filters?: KeyedObject): Promise<ListResponse<Merchant> | null> => {
        try {
            const { data, headers } = await axios.get(merchantsURL(filters));
            const total = headers[COUNT_HEADER] ? Number(headers[COUNT_HEADER]) : 0;
            return { data, total };
        } catch {
            return null;
        }
    };

    toggleMerchantStatus = async (merchantId: number, newStatus: MerchantToggleStatusParam, onSuccess: () => void, onFailure: () => void): Promise<void> => {
        try {
            await axios.patch(merchantURL(merchantId, { status: newStatus }));
            onSuccess();
        } catch {
            onFailure();
        }
    };

    // Create merchant

    validateNewMerchant = (fields: any): KeyedObject | null => {
        const errors: KeyedObject | null = validateForm(fields, validations.merchantCreate);

        if (!errors || Object.keys(errors).length === 0) return null;
        return { fields: errors };
    };

    submitNewMerchant = (payload: any, onSuccess: Function, onFailure: Function) => {
        const { dispatchRequestCreateMerchant } = this.props;

        dispatchRequestCreateMerchant(payload, onSuccess, onFailure);
    };

    // Update merchant

    validateEditMerchant = (fields: any): KeyedObject | null => {
        const errors: KeyedObject | null = validateForm(fields, validations.merchantUpdate);

        if (!errors || Object.keys(errors).length === 0) return null;
        return { fields: errors };
    };

    submitEditMerchant = (merchantId: string, payload: FormData, onSuccess: Function, onFailure: Function) => {
        const { dispatchRequestUpdateMerchant } = this.props;

        dispatchRequestUpdateMerchant(merchantId, payload, onSuccess, onFailure);
    };

    setSelectedMerchantId = (merchantId: number | null) => {
        const { dispatchMerchantId } = this.props;
        dispatchMerchantId(merchantId);
    }

    render() {
        const {
            children,
            createMerchantErrors,
            createMerchantFetching,
            updateMerchantErrors,
            updateMerchantFetching,
            selectedMerchantId,
        } = this.props;

        return (
            <MerchantsContextProvider
                value={{
                    createMerchantErrors,
                    createMerchantFetching,
                    updateMerchantErrors,
                    updateMerchantFetching,
                    selectedMerchantId,
                    getMerchant: this.getMerchant,
                    getMerchants: this.getMerchants,
                    toggleMerchantStatus: this.toggleMerchantStatus,
                    validateNewMerchant: this.validateNewMerchant,
                    validateEditMerchant: this.validateEditMerchant,
                    submitNewMerchant: this.submitNewMerchant,
                    submitEditMerchant: this.submitEditMerchant,
                    setSelectedMerchantId: this.setSelectedMerchantId,
                }}
            >
                {children}
            </MerchantsContextProvider>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => {
    return {
        createMerchantFetching: state.merchants.createMerchantRequest.isFetching,
        createMerchantErrors: state.merchants.createMerchantRequest.errors,
        updateMerchantFetching: state.merchants.updateMerchantRequest.isFetching,
        updateMerchantErrors: state.merchants.updateMerchantRequest.errors,
        selectedMerchantId: state.merchants.selectedMerchantId,
    };
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => ({
    dispatchRequestCreateMerchant: (payload: FormData, onSuccess: Function, onFailure: Function) => dispatch(requestCreateMerchant(payload, onSuccess, onFailure)),
    dispatchRequestUpdateMerchant: (merchantId: string, payload: FormData, onSuccess: Function, onFailure: Function) => dispatch(requestUpdateMerchant(merchantId, payload, onSuccess, onFailure)),
    dispatchMerchantId: (merchantId: number | null) => dispatch(setSelectedMerchantIdActionCreator(merchantId)),
});

export const ConnectedMerchantsController = connect(mapStateToProps, mapDispatchToProps)(MerchantsController);
