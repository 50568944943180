/*
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, ReactElement } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
    Backdrop,
    Button,
    CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle,
    IconButton,
    TextField, Card, CardHeader, CardContent,
} from '@material-ui/core';
import moment from 'moment';
import momentTz from 'moment-timezone';

import EventIcon from '@material-ui/icons/Event';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import ClearIcon from '@material-ui/icons/Clear';
import { AreasContext, withAreasContext } from '../controllers/AreasContext';
import { BookingsContext, withBookingsContext } from '../controllers/BookingsContext';
import { BusinessesContext, withBusinessesContext } from '../controllers/BusinessesContext';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import {
    SelectOption, WeekDays, Country, OrderQuery, KeyedObject,
} from '../../types/general';
import { Business } from '../../types/businesses';
import { DATE_FORMAT } from '../../utils/constants';
import { Bollard, BollardStatus } from '../../types/bollards';
import { Area, ReservationPeriod } from '../../types/areas';
import {
    Booking,
    RawScheduleEntry,
    BollardScheduleReservation,
    BollardAvailability,
    BollardAvailabilityList,
    BookingDaysParams,
    BookingStatus,
    BollardDaysAvailabilityParams,
} from '../../types/bookings';
import { MultipleDatePicker } from '../elements/MultipleDatePicker';
import { BollardTable } from '../elements/bollardTable/BollardTable';
import { MerchantsContext, withMerchantsContext } from '../controllers/MerchantsContext';
import FormSelectField from '../elements/FormSelectField';
import FormTextField from '../elements/FormTextField';
import { BollardsContext, withBollardsContext } from '../controllers/BollardsContext';
import FormCheckbox from '../elements/FormCheckbox';
import MapModal from '../elements/MapModal';
import { customMarker } from '../../utils/maps';
import free from '../../assets/images/pin-picked.svg';
import picked from '../../assets/images/pin-free.svg';
import { isSameTime } from '../../utils/misc';

export enum BookingFormField {
    Business = 'businessId',
    Area = 'areaId',
    Days = 'days',
    Bollard = 'bollardId',
    FirstName = 'firstName',
    LastName = 'lastName',
    Email = 'email',
    PhoneNumber = 'phoneNumber',
    Code = 'phoneCountry',
    NumberOfPeople = 'numberOfPeople',
    Observations = 'observations',
    ReservationStatus = 'reservationStatus',
}

export enum BookingStateNames {
    ReservationsSelected = 'reservationsSelected',
    IsFetching = 'isFetching',
    ShowErrorDaysWithoutPeriodSelected = 'showErrorDaysWithoutPeriodSelected',
    RegisterUser = 'registerUser',
    Price = 'price',
    Errors = 'errors',
}

export interface BookingFormFields {
    [BookingFormField.Business]: string;
    [BookingFormField.Area]: string;
    [BookingFormField.Days]: Array<moment.Moment>;
    [BookingFormField.Bollard]: string;
    [BookingFormField.FirstName]: string;
    [BookingFormField.LastName]: string;
    [BookingFormField.Email]: string;
    [BookingFormField.PhoneNumber]: string;
    [BookingFormField.Code]: string;
    [BookingFormField.NumberOfPeople]: string;
    [BookingFormField.Observations]: string;
}

interface OwnProps extends TranslationContext, BusinessesContext, AreasContext, BookingsContext, RouteComponentProps, MerchantsContext, BollardsContext {
    bookingId?: string;
    fields: BookingFormFields;
    reservationsSelected: Array<BollardScheduleReservation>;
    isFetching: boolean;
    showErrorDaysWithoutPeriodSelected: boolean;
    errors: KeyedObject | null;
    registerUser: boolean;
    price: number;
    onFieldsChange(values: BookingFormFields): void;
    onStateChange(name: string, value: any): void;
}

interface OwnState {
    booking: Booking | null;
    businessOptions: SelectOption[];
    areaOptions: SelectOption[];
    businesses: Business[];
    areas: Area[];
    bollards: Bollard[];
    business: Business | null;
    area: Area | null;
    reservationPeriodsOptions: SelectOption[];
    bollardAvailabilityList: BollardAvailabilityList[];
    countries: SelectOption[];
    fetchingBooking: boolean;
    failBookingFetch: boolean;
    businessId: string;
    areaId: string;
    showDatePicker: boolean;
    bookingStartTime: string;
    bookingEndTime: string;
    scheduleSelectedDay: moment.Moment | null;
    periodIdSelected: number | null;
    bollardsTotal: number;
    bollardsParams: {
        _limit: number;
        _page: number;
        _order: OrderQuery;
        _status: BollardStatus;
    };
    isRequestingMore: boolean;
    reservationDetailsExpanded: boolean;
    reservationBollardsExpanded: boolean;
    reservationInfosExpanded: boolean;
    showMapModal: boolean;
    mapRef: google.maps.Map | null;
    areaPolygon: google.maps.Polygon | null;
    businessMarker: google.maps.Marker | null;
    bollardMarkers: google.maps.Marker[];
    schedules: BollardAvailabilityList[];
    params: BookingDaysParams;
    bollardDaysAvailabilityParams: BollardDaysAvailabilityParams;
}

const initialState: OwnState = {
    booking: null,
    businessOptions: [],
    areaOptions: [],
    businesses: [],
    areas: [],
    bollards: [],
    business: null,
    area: null,
    reservationPeriodsOptions: [],
    bollardAvailabilityList: [],
    countries: [{ label: 'PT +351', value: 'PT' }],
    fetchingBooking: false,
    failBookingFetch: false,
    businessId: '',
    areaId: '',
    showDatePicker: false,
    bookingStartTime: '',
    bookingEndTime: '',
    scheduleSelectedDay: null,
    periodIdSelected: null,
    bollardsTotal: 0,
    bollardsParams: {
        _limit: 20,
        _page: 0,
        _order: OrderQuery.Ascending,
        _status: BollardStatus.Active,
    },
    isRequestingMore: false,
    reservationDetailsExpanded: true,
    reservationBollardsExpanded: true,
    reservationInfosExpanded: true,
    showMapModal: false,
    mapRef: null,
    areaPolygon: null,
    businessMarker: null,
    bollardMarkers: [],
    schedules: [],
    params: {
        pageSize: 20,
    },
    bollardDaysAvailabilityParams: {
        reservationId: '',
    },
};

class BookingForm extends Component<OwnProps, OwnState> {
    constructor(props: OwnProps) {
        super(props);
        const { bookingId } = props;
        this.state = {
            ...initialState,
            fetchingBooking: bookingId !== undefined,
            bollardDaysAvailabilityParams: {
                reservationId: String(bookingId),
            },
        };
    }

    componentDidMount() {
        const { bookingId } = this.props;
        if (bookingId) {
            this.fetchBookingDays();
        } else {
            this.fetchCountries();
            this.fetchBusinesses();
        }
    }

    onSelectArea = (name: string, value: string): void => {
        const {
            areas,
        } = this.state;
        const { onFieldsChange, fields, onStateChange } = this.props;

        const area = areas.find(a => a.id === Number(value));

        if (area) {
            const valuesFields = {
                ...fields,
                [name]: value,
            };

            onFieldsChange(valuesFields);
            onStateChange(BookingStateNames.ReservationsSelected, []);
            this.fetchArea();
            this.refreshArea();
        }
    };

    onSelectDays = (dates: moment.Moment[]) => {
        const {
            area, bollardsParams,
        } = this.state;
        const {
            onFieldsChange, onStateChange, fields, bookingId,
        } = this.props;

        const valuesFields = {
            ...fields,
            [BookingFormField.Days]: [...dates],
            [BookingFormField.Bollard]: '',
        };

        onFieldsChange(valuesFields);
        if (!bookingId) {
            onStateChange(BookingStateNames.ReservationsSelected, []);
            onStateChange(BookingStateNames.Price, 0);
        }

        this.setState({
            scheduleSelectedDay: dates.length > 0 ? dates[0] : null,
            bollardsParams: {
                ...bollardsParams,
                _page: 0,
            },
        }, () => {
            this.fetchSchedules();

            if (dates.length > 0) this.fetchBollards(dates[0], true);

            if (area?.reservationPeriods) {
                this.setAvailableReservationPeriods(area.reservationPeriods);
            }
        });
    };

    onFieldChange = (name: string, value: string): void => {
        const { onFieldsChange, fields } = this.props;

        const valuesFields = { ...fields, [name]: value };

        onFieldsChange(valuesFields);
    };

    onSelectBusiness = (name: string, value: string): void => {
        const {
            businesses, businessMarker,
        } = this.state;

        const { fields, onFieldsChange, onStateChange } = this.props;

        const business = businesses.find(b => b.id === Number(value));
        if (business) {
            const valuesFields: BookingFormFields = {
                ...fields,
                [name]: value,
                [BookingFormField.Area]: '',
            };

            onFieldsChange(valuesFields);
            onStateChange(BookingStateNames.ReservationsSelected, []);

            this.setState({
                bollardAvailabilityList: [],
            },
            () => {
                if (businessMarker !== null) {
                    businessMarker.setMap(null);
                }
                this.fetchBusinessAreas();
            });
        }
    };

    onSelectScheduleFilterDay = (date: moment.Moment) => {
        const { bollardsParams } = this.state;

        this.setState({
            scheduleSelectedDay: date,
            bollardsParams: {
                ...bollardsParams,
                _page: 0,
            },
        }, () => this.fetchBollards(date, true));
    }

    setAvailableReservationPeriods = (areaReservationPeriods: Array<ReservationPeriod>, date: moment.Moment | undefined = undefined) => {
        const { fields } = this.props;

        const selectedDate = date || fields[BookingFormField.Days][0];

        if (!selectedDate) return;

        const specialPeriods = areaReservationPeriods.filter((period: ReservationPeriod) => period.dayOfYear && moment.utc(period.dayOfYear).format(DATE_FORMAT) === selectedDate.format(DATE_FORMAT));

        let reservationPeriods: Array<ReservationPeriod> = [];
        if (specialPeriods && specialPeriods.length > 0) {
            reservationPeriods = specialPeriods;
        } else {
            reservationPeriods = areaReservationPeriods.filter((period: ReservationPeriod) => period.dayOfWeek === Object.values(WeekDays)[selectedDate.day()]);
        }

        const availableReservationPeriods = reservationPeriods.filter(period => !this.isPeriodFinished(selectedDate.clone(), period));

        availableReservationPeriods.sort((a: ReservationPeriod, b: ReservationPeriod) => moment.utc(a.startTime).local().diff(moment.utc(b.startTime).local(), 'm'));
    }

    fetchBookingDays = async () => {
        const {
            bookingId, fields, getBookingDays, onStateChange, onFieldsChange, getBooking, getBollardAvailability,
        } = this.props;
        const { params, bollardDaysAvailabilityParams } = this.state;

        onStateChange(BookingStateNames.IsFetching, true);

        if (!bookingId) return;

        const bookingData = await getBooking(bookingId);
        const bookingDays = await getBookingDays(bookingId, params);

        if (bookingData?.status === BookingStatus.Reserved) onStateChange(BookingFormField.ReservationStatus, bookingData?.status);

        let price = 0;
        const valuesFields: BookingFormFields = {
            ...fields,
            [BookingFormField.Days]: [],
            [BookingFormField.Business]: String(bookingData?.businessArea.business.id),
            [BookingFormField.Area]: String(bookingData?.businessArea.id),
        };

        if (!bookingDays) return;
        const reservations = await Promise.all(bookingDays.map(async bookingDay => {
            valuesFields[BookingFormField.Days].push(moment(bookingDay.localDay));
            price += bookingDay.price;

            const bollardsAvailability = await getBollardAvailability(bookingDay.bollardId, bookingDay.localDay, bollardDaysAvailabilityParams);
            const bollardAvailability = bollardsAvailability?.filter(bollard => bollard.localStartTime === bookingDay.localStartTime
                && bollard.localEndTime === bookingDay.localEndTime
                && bollard.localDay === bookingDay.localDay
            );

            return {
                periodId: bollardAvailability ? bollardAvailability[0].reservationPeriodId : 0,
                bollard: {
                    designation: bookingDay.bollardDesignation,
                    id: bookingDay.bollardId,
                },
                days: [moment(bookingDay.localDay)],
                price: bookingDay.price,
            };
        }));

        onFieldsChange(valuesFields);
        onStateChange(BookingStateNames.ReservationsSelected, reservations ? [...reservations] : []);
        onStateChange(BookingStateNames.Price, price);

        this.setState({
            scheduleSelectedDay: valuesFields[BookingFormField.Days][0],
        }, () => this.fetchBollards(valuesFields[BookingFormField.Days][0]));
    }

    fetchBusinesses = () => {
        const { getBusinesses, bookingId, selectedMerchantId } = this.props;
        const {
            businessId,
        } = this.state;
        const { fields, onFieldsChange } = this.props;

        getBusinesses({
            _limit: 999, _sort: 'name', _order: 'Ascending', merchantId: selectedMerchantId,
        }).then(businessesData => {
            if (businessesData) {
                let business: Business | null = null;
                const { data: businesses } = businessesData;

                const businessWithAreas: Business[] = businesses.filter((b: Business) => b.numberOfAreas > 0);

                if (bookingId !== undefined) {
                    business = businesses.find((b: Business) => b.id === Number(businessId)) || null;
                } else if (businesses.length > 0) {
                    [business] = businessWithAreas;
                }

                const valuesField: BookingFormFields = {
                    ...fields,
                    [BookingFormField.Business]: business ? String(business.id) : '',
                };

                onFieldsChange(valuesField);

                this.setState({
                    businessOptions: businessWithAreas.map(b => ({ label: b.name, value: String(b.id) })),
                    businesses,
                }, () => {
                    if (business) this.fetchBusinessAreas();
                });
            }
        });
    };

    fetchCountries = async () => {
        const { getCountries } = this.props;
        const options: SelectOption[] = [];
        const countries = await getCountries();

        if (!countries) return;

        countries.forEach((country: Country) => {
            options.push({
                label: `${country.code} ${country.phoneCode}`,
                value: country.code,
            });
        });
        options.sort((a, b) => a.label.localeCompare(b.label));
        this.setState({ countries: options });
    };

    getPeriodLabel(period: Booking | RawScheduleEntry | ReservationPeriod) {
        const formatTime = (time: string) => moment.utc(time, 'HH:mm:ss').local().format('HH:mm');
        return `${formatTime(period.startTime)} - ${formatTime(period.endTime)}`;
    }

    fetchBusinessAreas = () => {
        const {
            getBusinessAreas, bookingId, fields, onFieldsChange,
        } = this.props;
        const { areaId } = this.state;

        if (fields[BookingFormField.Business] !== '') {
            getBusinessAreas(fields[BookingFormField.Business], {
                _limit: 999,
                _sort: 'name',
                _order: OrderQuery.Ascending,
            }).then(areasData => {
                if (areasData) {
                    const areaOptions: SelectOption[] = [];
                    const { data: areas } = areasData;
                    areas.forEach((area: Area) => {
                        areaOptions.push({ label: area.name, value: String(area.id) });
                    });
                    let a = '';
                    if (bookingId !== undefined) {
                        a = areaId;
                    } else if (areas.length > 0) {
                        a = String(areas[0].id);
                    }

                    const valuesField = {
                        ...fields,
                        [BookingFormField.Area]: a,
                    };

                    onFieldsChange(valuesField);

                    this.setState({
                        scheduleSelectedDay: fields[BookingFormField.Days][0] || null,
                        areaOptions,
                        areas,
                    }, () => {
                        if (String(a)) {
                            this.fetchArea();
                        }
                    });
                }
            });
        }
    };

    fetchArea = async () => {
        const { getArea, fields } = this.props;
        const {
            scheduleSelectedDay,
            bollardAvailabilityList,
        } = this.state;

        const areaData = await getArea(Number(fields[BookingFormField.Business]), Number(fields[BookingFormField.Area]));

        if (areaData) {
            this.setState(
                {
                    area: areaData,
                },
                () => {
                    if (scheduleSelectedDay) {
                        if (bollardAvailabilityList.length > 0) {
                            this.fetchBollards(scheduleSelectedDay, true);
                        } else {
                            this.fetchBollards(scheduleSelectedDay);
                        }
                    }
                    if (areaData.reservationPeriods) {
                        this.setAvailableReservationPeriods(areaData.reservationPeriods);
                    }
                },
            );
        }
    };

    fetchBollards = async (date: moment.Moment, refreshList = false) => {
        const {
            bollardsParams, bollardAvailabilityList, bollardDaysAvailabilityParams,
        } = this.state;
        const {
            getBollardsList, getBollardAvailability, fields, onStateChange, bookingId,
        } = this.props;

        onStateChange(BookingStateNames.IsFetching, true);

        const bollardsList = await getBollardsList(
            fields[BookingFormField.Business],
            fields[BookingFormField.Area],
            bollardsParams,
        );

        if (!bollardsList) return;

        const bollardAvailabilityByDay = await Promise.all(bollardsList.data.map(async bollard => {
            if (bollard.status === BollardStatus.Active) {
                let bollardAvailability: BollardAvailability[] | null = [];
                if (bookingId) {
                    bollardAvailability = await getBollardAvailability(bollard.id, date.format(DATE_FORMAT), bollardDaysAvailabilityParams);
                } else {
                    bollardAvailability = await getBollardAvailability(bollard.id, date.format(DATE_FORMAT));
                }

                return {
                    bollard,
                    availabilityList: bollardAvailability,
                };
            }
            return {
                bollard,
                availabilityList: null,
            };
        }));

        const newBollards = bollardAvailabilityByDay.filter(bollard => bollard?.availabilityList !== null);

        this.setState({
            bollardAvailabilityList: refreshList ? newBollards : [...bollardAvailabilityList.concat(newBollards)],
            bollardsTotal: bollardsList.total,
            isRequestingMore: false,
        });

        onStateChange(BookingStateNames.IsFetching, false);
    };

    requestMore = () => {
        const {
            bollardsParams, bollardsTotal, bollardAvailabilityList, isRequestingMore, scheduleSelectedDay,
        } = this.state;

        if (bollardAvailabilityList.length < bollardsTotal && !isRequestingMore && scheduleSelectedDay) {
            this.setState({
                isRequestingMore: true,
                bollardsParams: {
                    ...bollardsParams,
                    _page: bollardsParams._page + 1,
                },
            }, () => this.fetchBollards(scheduleSelectedDay));
        }
    };

    reserveSpot = (entry: BollardAvailability, bollard: Bollard) => {
        const {
            scheduleSelectedDay,
        } = this.state;

        const {
            fields, reservationsSelected, onFieldsChange, onStateChange,
        } = this.props;

        const newReservations = [...reservationsSelected];
        const entryPrice = entry.price !== null ? Number(entry.price) : 0;
        if (scheduleSelectedDay) {
            const foundIdx = newReservations.findIndex(r => r.days.find(d => d.format('DD/MM/YYYY') === scheduleSelectedDay.format('DD/MM/YYYY')));

            if (foundIdx > -1) {
                newReservations.splice(foundIdx, 1);
            }

            newReservations.push({
                periodId: entry.reservationPeriodId,
                bollard,
                days: [scheduleSelectedDay],
                price: entryPrice,
            });
        }

        const updatedPrice = newReservations.reduce((partialSum, a) => partialSum + a.price, 0);

        const valuesField = {
            ...fields,
            [BookingFormField.Bollard]: String(bollard.id),
        };

        if (!entry.isOccupied) {
            onStateChange(BookingStateNames.Price, updatedPrice);
        }

        onFieldsChange(valuesField);
        onStateChange(BookingStateNames.ReservationsSelected, [...newReservations]);

        this.refreshMarkers();
    };

    closeErrorDialog = () => {
        const { onStateChange } = this.props;
        onStateChange(BookingStateNames.ShowErrorDaysWithoutPeriodSelected, false);
    }

    popDateByIndex = (index: number) => {
        const {
            fields, onFieldsChange, onStateChange, reservationsSelected, price,
        } = this.props;
        const { scheduleSelectedDay } = this.state;

        const newSelectedDates = [...fields[BookingFormField.Days]];
        const dateRemoved = newSelectedDates.splice(index, 1);
        let newPrice = 0;

        const newReservations = reservationsSelected.filter(reservationSelected => {
            if (dateRemoved[0].isSame(reservationSelected.days[0])) {
                newPrice = price - reservationSelected.price;
            }
            return !dateRemoved[0].isSame(reservationSelected.days[0]);
        });

        const valuesField = {
            ...fields,
            [BookingFormField.Days]: newSelectedDates,
        };

        if (fields[BookingFormField.Days][index] === scheduleSelectedDay && newSelectedDates.length > 0) this.onSelectScheduleFilterDay(newSelectedDates[0]);
        onFieldsChange(valuesField);
        onStateChange(BookingStateNames.ReservationsSelected, newReservations);
        onStateChange(BookingStateNames.Price, newPrice);
    };

    refreshArea = () => {
        const { mapRef, areaPolygon, areas } = this.state;
        const { fields } = this.props;

        const area = areas.find(a => a.id === Number(fields[BookingFormField.Area]));

        if (!mapRef || !area) return;

        if (areaPolygon !== null) areaPolygon.setMap(null);

        const bounds = new google.maps.LatLngBounds(area.boundSW, area.boundNE);
        mapRef.fitBounds(bounds, 80);

        const polygon = new google.maps.Polygon({
            paths: area.vertices,
            fillColor: '#ff4500',
            strokeColor: '#000000',
            fillOpacity: 0.5,
            strokeWeight: 2,
            clickable: false,
            editable: false,
            draggable: false,
        });
        polygon.setMap(mapRef);

        this.setState({
            areaPolygon: polygon,
        }, this.refreshMarkers);
    };

    refreshMarkers = () => {
        const {
            mapRef, bollardMarkers, bollardAvailabilityList,
        } = this.state;

        const {
            reservationsSelected,
        } = this.props;

        if (!mapRef) return;

        bollardMarkers.forEach(marker => marker.setMap(null));
        const markers: google.maps.Marker[] = [];
        bollardAvailabilityList.forEach(schedule => {
            const isSelected = reservationsSelected.find(reservationSelected => schedule.bollard.id === reservationSelected.bollard.id);
            if (schedule.availabilityList && schedule.availabilityList.length > 0) {
                const marker = customMarker(
                    mapRef,
                    { lat: schedule.bollard.location.lat, lng: schedule.bollard.location.lng },
                    this.getMarkerIcon(schedule),
                    undefined,
                    schedule.bollard.designation,
                    !!isSelected,
                );
                markers.push(marker);
            }
        });

        reservationsSelected.forEach(reservationSelected => {
            const bollardFound = bollardAvailabilityList.find(bollardAvailability => bollardAvailability.bollard.id === reservationSelected.bollard.id);

            if (bollardFound && bollardFound.availabilityList && bollardFound.availabilityList?.length > 0) return;

            const marker = customMarker(
                mapRef,
                { lat: reservationSelected.bollard.location.lat, lng: reservationSelected.bollard.location.lng },
                this.getMarkerIcon(reservationSelected),
                undefined,
                reservationSelected.bollard.designation,
                true,
            );
            markers.push(marker);
        });

        this.setState({ bollardMarkers: markers });
    };

    getMarkerIcon(schedule: BollardAvailabilityList | BollardScheduleReservation): string {
        const {
            reservationsSelected,
        } = this.props;

        const isSelected = reservationsSelected.find(reservationSelected => schedule.bollard.id === reservationSelected.bollard.id);

        if (isSelected) {
            return picked;
        }

        return free;
    }

    isPeriodFinished = (selectedDate: moment.Moment, period: ReservationPeriod | BollardAvailability) => {
        const { area } = this.state;

        const localMomentWithTimeZoneApplied = area?.timeZone ? momentTz().tz(area.timeZone) : momentTz();

        const userDateSelectionToString = selectedDate.clone().format('YYYY-MM-DD');
        const localDateToString = localMomentWithTimeZoneApplied.clone().format('YYYY-MM-DD');

        const endTimeMomentTz = momentTz(period.localEndTime, 'HH:mm:ss').tz(area?.timeZone || '');

        return userDateSelectionToString === localDateToString && localMomentWithTimeZoneApplied.isAfter(endTimeMomentTz);
    }

    fetchSchedules = async (date?: moment.Moment) => {
        const { getSchedulesNew, fields } = this.props;

        const days = date ? [date] : fields[BookingFormField.Days];

        const allSchedules = await getSchedulesNew(
            fields[BookingFormField.Business],
            days.map(day => day.format(DATE_FORMAT)),
        );

        const commonBollardSchedules: BollardAvailabilityList[] = [...([...allSchedules].shift()?.schedules.map(
            s => ({ ...s, scheduleEntries: s.availabilityList && [...s.availabilityList] }),
        ) || [])];

        // makes sure all schedules have the same periods to merge them into only one table
        // by shrinking to all common periods between selected days
        for (let scheduleDayIndex = 1; scheduleDayIndex < allSchedules.length; scheduleDayIndex++) {
            const bollardSchedulesCurrent = allSchedules[scheduleDayIndex].schedules;

            if (scheduleDayIndex > 2 && commonBollardSchedules.length === 0) break;

            for (let bollardIndex = 0; bollardIndex < bollardSchedulesCurrent.length; bollardIndex++) {
                const scheduleEntriesPrevious = commonBollardSchedules[bollardIndex];
                const scheduleEntriesCurrent = bollardSchedulesCurrent[bollardIndex].availabilityList || null;

                const samePeriodFilter = (a: BollardAvailability, b: BollardAvailability) => (
                    isSameTime(a.localEndTime, b.localEndTime) && isSameTime(a.localStartTime, b.localStartTime)
                );

                if (scheduleEntriesCurrent && scheduleEntriesPrevious.availabilityList) {
                    const filterCommon = (a: BollardAvailability) => scheduleEntriesCurrent.some(b => samePeriodFilter(a, b));
                    const commonSchedules = scheduleEntriesPrevious.availabilityList.filter(filterCommon);

                    const newScheduleEntries: BollardAvailability[] = commonSchedules?.map(commonSchedule => {
                        const reserved = commonSchedule.isOccupied || scheduleEntriesCurrent.find(se => samePeriodFilter(se, commonSchedule))?.isOccupied;

                        return { ...commonSchedule, isOccupied: !!reserved };
                    });

                    commonBollardSchedules[bollardIndex] = {
                        ...commonBollardSchedules[bollardIndex],
                        availabilityList: newScheduleEntries,
                    };
                }
            }
        }

        if (!commonBollardSchedules.length) {
            this.setState({ schedules: [] }, this.refreshMarkers);
            return;
        }

        const sortScheduleEntriesBasedOnStartTime = (a: BollardAvailability, b: BollardAvailability) => a.localStartTime
            .localeCompare(b.localStartTime);

        // removes all periods past current time from today's day and sorts
        const schedulesWithDatesAndTimesSorted = commonBollardSchedules.map(
            s => ({
                ...s,
                availabilityList: s.availabilityList && s.availabilityList
                    .filter(schedule => !this.isPeriodFinished(days[0], schedule))
                    .sort(sortScheduleEntriesBasedOnStartTime),
            }),
        );

        let periodIdSelected = null;
        if (schedulesWithDatesAndTimesSorted[0]) {
            if (schedulesWithDatesAndTimesSorted[0].availabilityList && schedulesWithDatesAndTimesSorted[0].availabilityList[0]) {
                periodIdSelected = schedulesWithDatesAndTimesSorted[0].availabilityList[0].reservationPeriodId;
            }
        }

        this.setState({
            schedules: schedulesWithDatesAndTimesSorted,
            periodIdSelected,
        }, this.refreshMarkers);
    };

    setMapRef = (ref: any) => {
        this.setState({
            mapRef: ref,
        }, () => {
            this.refreshArea();
        });
    }

    renderInputsSection(): ReactElement {
        const {
            t, bookingId, fields, errors, isFetching,
        } = this.props;
        const {
            businessOptions, areaOptions, reservationDetailsExpanded,
        } = this.state;

        return (
            <Card>
                <div className="widest-form-new__header-collapse">
                    <CardHeader
                        title={(
                            <span className="widest-form-new__section-header">
                                {t('bookingForm.detailsSection')}
                            </span>
                        )}
                        action={(
                            <IconButton onClick={() => this.setState({ reservationDetailsExpanded: !reservationDetailsExpanded })}>
                                {reservationDetailsExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            </IconButton>
                        )}
                    />
                </div>
                <div>
                    <Collapse in={reservationDetailsExpanded} timeout="auto" unmountOnExit>
                        <CardContent>
                            <div className="widest-form-new__grid-container">
                                <FormSelectField
                                    name={BookingFormField.Business}
                                    onChange={this.onSelectBusiness}
                                    options={businessOptions}
                                    value={fields[BookingFormField.Business]}
                                    errors={errors}
                                    label={t('bookingForm.businessLabel')}
                                    disabled={isFetching || bookingId !== undefined}
                                />
                                <FormSelectField
                                    name={BookingFormField.Area}
                                    onChange={this.onSelectArea}
                                    options={areaOptions}
                                    value={fields[BookingFormField.Area]}
                                    errors={errors}
                                    label={t('bookingForm.areaLabel')}
                                    disabled={
                                        isFetching || fields[BookingFormField.Business] === '' || bookingId !== undefined
                                    }
                                />
                            </div>
                        </CardContent>
                    </Collapse>
                </div>
            </Card>
        );
    }

    renderTableDaysSelect = () => {
        const { scheduleSelectedDay } = this.state;
        const { fields } = this.props;

        const days = [...fields[BookingFormField.Days]];

        return (
            <div className="schedule-days-select">
                <div className="schedule-days-select__btn-picker">
                    <IconButton
                        data-testid="date-picker-icon"
                        onClick={() => this.setState({ showDatePicker: true })}
                        onMouseDown={() => this.setState({ showDatePicker: true })}
                    >
                        <EventIcon />
                    </IconButton>
                </div>
                {days.map((d, index) => {
                    const isSelected = d.format('DD MMMM') === scheduleSelectedDay?.format('DD MMMM');
                    return (
                        <div key={d.format()} className={`schedule-days-select__day ${isSelected ? 'selected' : ''}`}>
                            <button
                                key={d.format('x')}
                                type="button"
                                onClick={() => this.onSelectScheduleFilterDay(d)}
                            >
                                {d.format('MMMM, DD YYYY')}
                            </button>
                            <button type="button" onClick={() => this.popDateByIndex(index)}>
                                <ClearIcon color="error" />
                            </button>
                        </div>
                    );
                })}
            </div>
        );
    };

    renderTableSection(): ReactElement {
        const { t, bookingId } = this.props;
        const {
            reservationBollardsExpanded, showMapModal,
        } = this.state;

        if (bookingId) return this.renderTable();

        return (
            <>
                <Card>
                    <div className="widest-form-new__header-collapse">
                        <CardHeader
                            title={(
                                <span className="widest-form-new__section-header">
                                    {t('bookingForm.availabilitySection')}
                                </span>
                            )}
                            action={(
                                <IconButton onClick={() => this.setState({ reservationBollardsExpanded: !reservationBollardsExpanded })}>
                                    {reservationBollardsExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                </IconButton>
                            )}
                        />
                        <button type="button" onClick={() => this.setState({ showMapModal: true })}>
                            {t('bookingForm.seeMap')}
                        </button>
                    </div>
                    <div>
                        <Collapse in={reservationBollardsExpanded} timeout="auto" unmountOnExit>
                            <CardContent>
                                <div className="widest-form-new__grid-container--table-section">
                                    <div className="widest-form-new__grid-container--table-section__bollard-table">
                                        {this.renderTable()}
                                    </div>
                                </div>
                            </CardContent>
                        </Collapse>
                    </div>
                </Card>
                <MapModal show={showMapModal} onClose={() => this.setState({ showMapModal: false })} setMapRef={this.setMapRef} />
            </>
        );
    }

    renderTable(): ReactElement {
        const {
            bookingId, fields, reservationsSelected,
        } = this.props;
        const {
            bollardAvailabilityList, scheduleSelectedDay, bollardsTotal, bollardsParams,
        } = this.state;

        let bollardId = -1;
        let periodId = -1;
        if (reservationsSelected.length > 0) {
            if (scheduleSelectedDay) {
                const reservationItem = reservationsSelected.find(r => r.days.find(d => d.format('DD/MM/YYYY') === scheduleSelectedDay.format('DD/MM/YYYY')));
                if (reservationItem) {
                    bollardId = reservationItem.bollard.id;
                    ({ periodId } = reservationItem);
                }
            }
        }

        return (
            <>
                {this.renderTableDaysSelect()}
                <BollardTable
                    selectedDays={fields[BookingFormField.Days]}
                    bollardAvailabilityList={bollardAvailabilityList}
                    selectedBookingId={bookingId}
                    selectedBollardId={bollardId}
                    selectedReservationId={periodId}
                    selectedAreaId={fields[BookingFormField.Area]}
                    onReserveSpot={this.reserveSpot}
                    hasMore={bollardsParams._page !== Math.ceil(bollardsTotal / bollardsParams._limit) - 1}
                    requestMoreBollards={this.requestMore}
                />
            </>
        );
    }

    renderInfoSection(): ReactElement {
        const {
            t, bookingId, errors, fields, registerUser, isFetching, onStateChange,
        } = this.props;
        const {
            countries, reservationInfosExpanded,
        } = this.state;

        return (
            <Card>
                <div className="widest-form-new__header-collapse">
                    <CardHeader
                        title={(
                            <span className="widest-form-new__section-header">
                                {t('bookingForm.infoSection')}
                            </span>
                        )}
                        action={(
                            <IconButton onClick={() => this.setState({ reservationInfosExpanded: !reservationInfosExpanded })}>
                                {reservationInfosExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            </IconButton>
                        )}
                    />
                </div>
                <div className="widest-form-new__form-control last">
                    <Collapse in={reservationInfosExpanded} timeout="auto" unmountOnExit>
                        <CardContent>
                            <FormTextField
                                name={BookingFormField.FirstName}
                                value={fields[BookingFormField.FirstName]}
                                onChange={this.onFieldChange}
                                placeholder={t('profileForm.firstNameLabel')}
                                label={`${t('profileForm.firstNameLabel')}${registerUser ? '*' : ''}`}
                                errors={errors}
                                disabled={isFetching || bookingId !== undefined}
                            />
                            <FormTextField
                                name={BookingFormField.LastName}
                                value={fields[BookingFormField.LastName]}
                                onChange={this.onFieldChange}
                                placeholder={t('profileForm.lastNameLabel')}
                                label={`${t('profileForm.lastNameLabel')}${registerUser ? '*' : ''}`}
                                errors={errors}
                                disabled={isFetching || bookingId !== undefined}
                            />
                            <FormTextField
                                name={BookingFormField.Email}
                                value={fields[BookingFormField.Email]}
                                onChange={this.onFieldChange}
                                placeholder={t('profileForm.emailLabel')}
                                label={`${t('profileForm.emailLabel')}${registerUser ? '*' : ''}`}
                                errors={errors}
                                disabled={isFetching || bookingId !== undefined}
                            />
                            <div className="wide-form__grid-container__double-input">
                                <FormSelectField
                                    name={BookingFormField.Code}
                                    errors={errors}
                                    disabled={isFetching || bookingId !== undefined}
                                    onChange={this.onFieldChange}
                                    options={countries}
                                    value={fields[BookingFormField.Code]}
                                    testId="phone-code-select"
                                    label={t('profileForm.phoneCode')}
                                />
                                <FormTextField
                                    name={BookingFormField.PhoneNumber}
                                    value={fields[BookingFormField.PhoneNumber]}
                                    onChange={this.onFieldChange}
                                    placeholder={t('profileForm.phoneLabel')}
                                    label={t('profileForm.phoneLabel')}
                                    errors={errors}
                                    disabled={isFetching || bookingId !== undefined}
                                />
                            </div>
                            <FormTextField
                                name="numberOfPeople"
                                value={fields[BookingFormField.NumberOfPeople]}
                                placeholder={t('bookingForm.nPax')}
                                label={t('bookingForm.nPax')}
                                errors={errors}
                                onChange={this.onFieldChange}
                                disabled={
                                    isFetching || fields[BookingFormField.Area] === '' || bookingId !== undefined
                                }
                            />
                            <div className="input-container">
                                <TextField
                                    name={BookingFormField.Observations}
                                    value={fields[BookingFormField.Observations]}
                                    disabled={isFetching || bookingId !== undefined}
                                    onChange={e => this.onFieldChange(e.currentTarget.name, e.currentTarget.value)}
                                    multiline
                                    rows={3}
                                    maxRows={3}
                                    label={t('bookingForm.observationsLabel')}
                                    placeholder={t('bookingForm.observationsLabel')}
                                    variant="outlined"
                                    fullWidth
                                    inputProps={{ maxLength: 255 }}
                                    className="input-container"
                                />
                            </div>
                            <div />
                            <FormCheckbox
                                name="RegisterUser"
                                checked={registerUser}
                                onChange={() => onStateChange(BookingStateNames.RegisterUser, !registerUser)}
                                errors={errors}
                                disabled={isFetching || bookingId !== undefined}
                                label={t('bookingForm.createAccount')}
                            />
                        </CardContent>
                    </Collapse>
                </div>
            </Card>
        );
    }

    renderErrorDialog = () => {
        const { t, showErrorDaysWithoutPeriodSelected } = this.props;

        return (
            <Dialog
                open={showErrorDaysWithoutPeriodSelected}
                onClose={this.closeErrorDialog}
            >
                <DialogTitle>{t('bookingForm.errorDaysWithoutPeriodSelectedTitle')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t('bookingForm.errorDaysWithoutPeriodSelectedMessage')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.closeErrorDialog} color="primary" autoFocus>
                        {t('general.ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    render() {
        const {
            fields, errors, isFetching, bookingId,
        } = this.props;
        const {
            showDatePicker,
        } = this.state;

        return (
            <>
                {isFetching && (
                    <Backdrop open data-testid="loader">
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}
                <form className="widest-form-new widest-form--booking" data-testid="booking-form">
                    <Backdrop open={isFetching}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    {this.renderErrorDialog()}
                    {!bookingId && this.renderInputsSection()}
                    {this.renderTableSection()}
                    {!bookingId && this.renderInfoSection()}
                    <MultipleDatePicker
                        onClose={() => this.setState({ showDatePicker: false })}
                        onSubmit={sortedSelectedDays => this.onSelectDays(sortedSelectedDays)}
                        open={showDatePicker}
                        preSelectedDates={[...fields[BookingFormField.Days]]}
                        errors={errors}
                        name="days"
                    />
                </form>
            </>
        );
    }
}

export default withTranslationContext(
    withAreasContext(withBusinessesContext(withBookingsContext(withMerchantsContext(withBollardsContext(withRouter(BookingForm)))))),
);
