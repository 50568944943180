/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { mapKey } from '../../settings';
import { googleMap } from '../../utils/maps';
import disabled from '../../assets/images/pin-disable.svg';
import reactivate from '../../assets/images/pin-reactivate.svg';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';

interface OwnProps extends TranslationContext {
    zoom: number;
    center: google.maps.LatLngLiteral;
    height: string;
    onMapMounted: Function;
    onClick?: (evt: any) => void;
    togglable?: boolean
}

interface OwnState {
    mapEnabled: boolean;
}

const initialState: OwnState = {
    mapEnabled: false,
};

class Map extends Component<OwnProps, OwnState> {
    map: google.maps.Map | null = null;

    id = '';

    constructor(props: OwnProps) {
        super(props);
        this.state = {
            ...initialState,
        };
        this.id = Math.random().toString().substr(2, 8);
    }

    componentDidMount() {
        const { onMapMounted } = this.props;
        if (window.google === undefined) {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${mapKey}&libraries=drawing,places&v=3.exp`;
            script.async = true;
            script.defer = true;
            script.addEventListener('load', () => {
                onMapMounted();
            });
            document.body.appendChild(script);
        } else {
            this.componentDidUpdate();
        }
    }

    componentDidUpdate() {
        const {
            onMapMounted, center, zoom, onClick,
        } = this.props;
        if (this.map === null && window.google !== undefined) {
            this.map = googleMap(center, zoom, this.id);
            if (onClick !== undefined) {
                this.map.addListener('click', onClick);
            }
            onMapMounted(this.map);
        }
    }

    disableMap = (): void => {
        this.setState({ mapEnabled: false });
    };

    enableMap = (): void => {
        this.setState({ mapEnabled: true });
    };

    render() {
        const { height, togglable, t } = this.props;
        const { mapEnabled } = this.state;

        if (togglable) {
            return (
                <div className="mapDiv">
                    <button
                        className="mapDiv__button"
                        type="button"
                        onClick={this.disableMap}
                        data-testid="disable-map-btn"
                    >
                        <img src={disabled} alt="Disabled Pin" />
                    </button>
                    <div
                        className={`mapDiv__overlay ${mapEnabled ? 'mapDiv__overlay--hidden' : 'mapDiv__overlay--over-map'}`}
                    >
                        <button
                            className="mapDiv__button mapDiv__enableButton"
                            type="button"
                            onClick={this.enableMap}
                            data-testid="reactivate-map-btn"
                        >
                            <img src={reactivate} alt={t('map.reactivatePin')} /> <span>{t('map.reactivateMap')}</span>
                        </button>
                    </div>
                    <div id={this.id} style={{ height, width: '100%', flexShrink: 0 }} />
                </div>
            );
        }
        return <div id={this.id} style={{ height, width: '100%', flexShrink: 0 }} />;
    }
}

export default withTranslationContext(Map);
