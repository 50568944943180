/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

/* eslint-disable react-hooks/exhaustive-deps */

import React, {
    FormEvent, FunctionComponent, useEffect, useState,
} from 'react';
import { Close } from '@material-ui/icons';
import {
    Backdrop, Button, CircularProgress, Dialog, IconButton, Typography,
} from '@material-ui/core';

import { KeyedObject } from '../../types/general';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { InvitationPayload, InvitationType } from '../../types/invitations';
import FormTextField from './FormTextField';
import { displayNotification, NotificationType } from '../../utils/notifications';
import { ErrorCode } from '../../types/errors';
import { InvitationsContext, withInvitationsContext } from '../controllers/InvitationsContext';
import { AuthorizationContext, withAuthorizationContext } from '../controllers/AuthorizationContext';
import { Permission } from '../../types/authorization';
import { MerchantsContext, withMerchantsContext } from '../controllers/MerchantsContext';

interface OwnProps extends TranslationContext, InvitationsContext, AuthorizationContext, MerchantsContext {
    onClose(updateList: boolean): void;
    invitationFor: InvitationType;
}

enum InvitationModalField {
    Email = 'email',
    Name = 'name',
}

const NewInvitationModal: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        onClose, t, invitationFor, validateInvitation, sendMerchantInvitation, checkPermission,
        getMerchants, sendReservationManagerInvitation,
    } = props;

    const [invitationEmail, setInvitationEmail] = useState('');
    const [receiverName, setReceiverName] = useState('');
    const [errors, setErrors] = useState<KeyedObject>({});
    const [isFetching, setIsFetching] = useState(false);

    const getMerchantsOptions = async (search = '') => {
        const merchantsData = await getMerchants({
            _q: search,
            _limit: 15,
            _sort: 'name',
        });
        if (!merchantsData) return;
    };

    useEffect(() => {
        if (invitationFor === InvitationType.ReservationManager && checkPermission([Permission.MERCHANT_LIST_VIEW])) {
            getMerchantsOptions();
        }
    }, [invitationFor]);

    const renderModalTitle = () => {
        switch (invitationFor) {
            case InvitationType.Merchant:
                return t('newInvitationModal.merchantTitle');
            case InvitationType.ReservationManager:
                return t('newInvitationModal.reservationManagerTitle');
            default:
        }
    };

    const onFormSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        const payload: InvitationPayload = {
            [InvitationModalField.Email]: invitationEmail,
            [InvitationModalField.Name]: receiverName,
        };

        const validationErrors = validateInvitation(payload);

        if (validationErrors) {
            setErrors(validationErrors);
            return;
        }
        setIsFetching(true);

        if (invitationFor === InvitationType.Merchant) {
            sendMerchantInvitation(payload, onInvitationSuccess, onInvitationFailure);
            return;
        }

        sendReservationManagerInvitation(payload, onInvitationSuccess, onInvitationFailure);
    };

    const onInvitationSuccess = (): void => {
        displayNotification({
            message: t('newInvitationModal.invitationSuccess'),
            type: NotificationType.Success,
        });
        onClose(true);
    };

    const onInvitationFailure = (errorCode: number): void => {
        displayNotification({
            type: NotificationType.Danger,
            message: t(errorCode ? `errors.${ErrorCode[errorCode]}` : 'errors.general'),
        });
        setIsFetching(false);
    };

    return (
        <>
            <Dialog
                open
                fullWidth
                maxWidth="sm"
                className="new-invitation-modal"
                onClose={onClose}
            >
                {isFetching && (
                    <Backdrop open data-testid="loader">
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}
                <form onSubmit={onFormSubmit} data-testid="recover-form">
                    <div className="new-invitation-modal__header">
                        <Typography variant="h2">
                            {renderModalTitle()}
                        </Typography>
                        <IconButton
                            aria-label="close"
                            onClick={() => onClose(false)}
                            data-testid="confirm-dialog-close-btn"
                        >
                            <Close color="primary" />
                        </IconButton>
                    </div>
                    <div className="new-invitation-modal__content">
                        <div className="new-invitation-modal__content__input-container">
                            <FormTextField
                                name={InvitationModalField.Name}
                                value={receiverName}
                                onChange={(_: string, value: string) => setReceiverName(value)}
                                errors={errors}
                                testId="invitation-name-input"
                                placeholder={t('newInvitationModal.name')}
                            />
                        </div>
                        <div className="new-invitation-modal__content__input-container">
                            <FormTextField
                                name={InvitationModalField.Email}
                                value={invitationEmail}
                                onChange={(_: string, value: string) => setInvitationEmail(value)}
                                errors={errors}
                                testId="invitation-email-input"
                                placeholder={t('newInvitationModal.email')}
                            />
                        </div>
                    </div>
                    <div className="new-invitation-modal__footer">
                        <Button
                            data-testid="close-btn"
                            onClick={() => onClose(false)}
                        >
                            {t('general.cancel')}
                        </Button>
                        <Button data-testid="send-btn" type="submit">
                            {t('newInvitationModal.confirm')}
                        </Button>
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export default withInvitationsContext(withTranslationContext(withAuthorizationContext(withMerchantsContext(NewInvitationModal))));
