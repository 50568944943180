/*
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */

import moment from 'moment';
import React, { FunctionComponent, memo } from 'react';
import Lottie from 'react-lottie';
import { Bollard } from '../../../types/bollards';
import ScheduleRow from './BollardRow';
import NoDataLottie from '../../../assets/lottie/no-data.json';

import {
    BollardAvailability, BollardAvailabilityList,
} from '../../../types/bookings';
import { TIME_FORMAT } from '../../../utils/constants';
import { withTranslationContext, TranslationContext } from '../../controllers/TranslationContext';
import BollardInfiniteScroll from './BollardInfiniteScroll';

interface OwnProps {
    bollardAvailabilityList: BollardAvailabilityList[];
    selectedDays: moment.Moment[];
    onReserveSpot: (se: BollardAvailability, bollard: Bollard) => void;
    selectedReservationId?: number;
    selectedBollardId?: number;
    selectedBookingId?: string;
    selectedAreaId?: string;
    hasMore: boolean;
    requestMoreBollards(): void;
}

type Props = TranslationContext & OwnProps;

interface ScheduleHoursOwnProps {
    interval: moment.Moment[];
}

type ScheduleHoursProps = ScheduleHoursOwnProps;

const ScheduleHours = (props: ScheduleHoursProps) => {
    const { interval } = props;

    return (
        <div className="schedule-table__hours-container">
            <span>{interval[0].clone().subtract(1, 'hour').format('H')}h</span>
            {interval.map(time => (
                <span key={time.format('x')}>{time.format('H')}h</span>
            ))}
        </div>
    );
};

export const BollardTable: FunctionComponent<OwnProps> = memo(withTranslationContext((props: Props) => {
    const {
        bollardAvailabilityList, t, selectedDays, selectedBollardId, hasMore, requestMoreBollards,
        selectedBookingId, selectedReservationId, onReserveSpot,
    } = props;

    const allAvailabilityList = bollardAvailabilityList.flatMap(bollardAvailability => bollardAvailability.availabilityList);
    const lowestTime = moment.min(allAvailabilityList.flatMap(se => moment(se!.localStartTime, TIME_FORMAT))).startOf('hour');
    const highestTime = moment.max(allAvailabilityList.flatMap(se => moment(se!.localEndTime, TIME_FORMAT))).startOf('hour');

    const timeInterval = [lowestTime.startOf('hour')];
    while ([...timeInterval].pop()?.isBefore(highestTime)) {
        const lastHighestTime = [...timeInterval].pop() as moment.Moment;
        const newHighestTime = lastHighestTime.clone().add(1, 'hour');
        timeInterval.push(newHighestTime);
    }

    if (!selectedBookingId && (bollardAvailabilityList.length === 0 || selectedDays.length === 0)) {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: NoDataLottie,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
            },
        };

        return (
            <div className="placeholder-no-data">
                <Lottie
                    options={defaultOptions}
                    height={194}
                    width={194}
                />
                <p className="placeholder-no-data__title">{t('bookingForm.noDataTitle')}</p>
                <p className="placeholder-no-data__message">{t('bookingForm.noDataMessage')}</p>
            </div>
        );
    }

    return (
        <BollardInfiniteScroll
            hasMore={hasMore}
            requestMore={requestMoreBollards}
        >
            <React.Fragment>
                <div className="schedule-table">
                    <div className="schedule-table__bollard-container">
                        {t('bookingForm.bollard')}
                    </div>
                    <ScheduleHours interval={timeInterval} />
                    {
                        bollardAvailabilityList.map(bollardAvailability => (
                            <ScheduleRow
                                key={bollardAvailability.bollard.id}
                                bollard={bollardAvailability.bollard}
                                scheduleEntries={bollardAvailability.availabilityList}
                                selectedBollardId={selectedBollardId}
                                selectedBookingId={selectedBookingId}
                                selectedReservationId={selectedReservationId}
                                reserveSpot={onReserveSpot}
                                initialHour={timeInterval[0]}
                                finalHour={timeInterval[timeInterval.length - 1].clone().add(1, 'hour')}
                            />
                        ))
                }
                </div>
            </React.Fragment>
        </BollardInfiniteScroll>
    );
}));
