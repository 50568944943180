/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios, { AxiosError } from 'axios';
import {
    bollardAvailabilityURL,
    bollardDailyOccupationURL, bollardPostLocationUpdatedURL,
    bollardsAvailabilityURL,
    bollardsURL, bollardValidateLocationURL,
} from '../../services/bollards';
import { BollardsContextProvider } from './BollardsContext';
import {
    BollardOccupation,
    BollardDayOccupation,
    BollardOccupationReservationDetailsPayload,
    Bollard,
    PayloadLocation, PayloadLocationUpdated,
} from '../../types/bollards';
import { reservationBollardOccupationURL } from '../../services/bookings';
import { BollardAvailability, Booking } from '../../types/bookings';
import { KeyedObject } from '../../types/general';
import { COUNT_HEADER } from '../../utils/constants';
import { TranslationContext, withTranslationContext } from './TranslationContext';

interface OwnProps {
    children: React.ReactNode,
}

type Props = OwnProps & TranslationContext;

export class BollardsController extends Component<Props> {
    getBollardDailyOccupation = async (bollardId: number, day: string): Promise<BollardOccupation | null> => {
        try {
            const { data } = await axios.get(bollardDailyOccupationURL(bollardId, day));
            return data;
        } catch {
            return null;
        }
    };

    getMultipleBollardsDailyOccupation = async (bollardsIds: number[], day: string): Promise<Array<BollardOccupation>> => {
        const requests = bollardsIds.map(id => this.getBollardDailyOccupation(id, day));

        const bollardsOccupation: Array<BollardOccupation | null> = await Promise.all(requests);

        return bollardsOccupation.filter(this.isBollardOccupationNotNull);
    };

    getBollardOccupationReservation = async (payload: BollardOccupationReservationDetailsPayload): Promise<Booking | null> => {
        try {
            const { data } = await axios.post(reservationBollardOccupationURL(), payload);
            return data;
        } catch {
            return null;
        }
    };

    getBollardWeeklyAvailability = async (bollardId: number, startDate: string, endDate: string): Promise<BollardDayOccupation | null> => {
        try {
            const { data } = await axios.post(bollardAvailabilityURL(), {
                bollardId,
                endDate,
                startDate,
            });

            return data;
        } catch {
            return null;
        }
    };

    getMultipleBollardsWeeklyAvailability = async (bollardsIds: number[], startDate: string, endDate: string): Promise<Array<BollardDayOccupation>> => {
        const requests = bollardsIds.map(id => this.getBollardWeeklyAvailability(id, startDate, endDate));

        const bollardsOccupation: Array<BollardDayOccupation | null> = await Promise.all(requests);

        return bollardsOccupation.filter(this.isBollardOccupationNotNull);
    };

    isBollardOccupationNotNull = <T, >(occupation: T | null): occupation is T => {
        return occupation !== null;
    }

    getBollardsList = async (businessId: string, areaId: string, filters?: KeyedObject): Promise<{ data:Bollard[], total:number } | null> => {
        try {
            const { data, headers } = await axios.get(bollardsURL(businessId, areaId, filters));
            return { data, total: headers[COUNT_HEADER] ? Number(headers[COUNT_HEADER]) : 0 };
        } catch {
            return null;
        }
    };

    getBollardAvailability = async (bollardId: number, day: string, filters: KeyedObject): Promise<BollardAvailability[] | null> => {
        try {
            const { data } = await axios.get(bollardsAvailabilityURL(bollardId, day, filters));
            return data;
        } catch {
            return null;
        }
    };

    validateBollardLocation = async (qrCodeId: string, areaId: number, businessId: number, payload: PayloadLocation, onFailure: (message: string) => void): Promise<boolean> => {
        const { t } = this.props;

        try {
            await axios.post(bollardValidateLocationURL(qrCodeId, areaId, businessId), payload);
            return true;
        } catch (error) {
            if ((error as AxiosError)?.response?.data?.errors[0].errorCode === 27) {
                onFailure(t('areaForm.notAllowedLocation'));
            } else if ((error as AxiosError)?.response?.data?.errors[0].errorCode === 29) {
                onFailure(t('areaForm.conflictBollardLocation'));
            } else {
                onFailure((error as AxiosError)?.response?.data?.errors[0]?.message);
            }
            return false;
        }
    };

    postBollardLocationUpdated = async (qrCodeId: string, areaId: number, businessId: number, payload: PayloadLocationUpdated, onSuccess: () => void, onFailure: (message: string) => void): Promise<void> => {
        try {
            await axios.post(bollardPostLocationUpdatedURL(qrCodeId, areaId, businessId), payload);
            onSuccess();
        } catch (error) {
            onFailure((error as AxiosError)?.response?.data?.errors[0]?.message);
        }
    };

    render() {
        const {
            children,
        } = this.props;

        return (
            <BollardsContextProvider
                value={{
                    getBollardsList: this.getBollardsList,
                    getBollardAvailability: this.getBollardAvailability,
                    getBollardDailyOccupation: this.getBollardDailyOccupation,
                    getMultipleBollardsDailyOccupation: this.getMultipleBollardsDailyOccupation,
                    getBollardOccupationReservation: this.getBollardOccupationReservation,
                    getBollardWeeklyAvailability: this.getBollardWeeklyAvailability,
                    getMultipleBollardsWeeklyAvailability: this.getMultipleBollardsWeeklyAvailability,
                    validateBollardLocation: this.validateBollardLocation,
                    postBollardLocationUpdated: this.postBollardLocationUpdated,
                }}
            >
                {children}
            </BollardsContextProvider>
        );
    }
}

export const ConnectedBollardsController = connect()(
    withTranslationContext(BollardsController),
);
