/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import logo from '../../assets/images/logo.png';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Check from '@material-ui/icons/CheckCircleOutline';
import Loader from '../elements/Loader';
import axios from 'axios';
import { activateURL } from '../../services/auth';
import { displayNotification, NotificationType } from '../../utils/notifications';
import { hasFieldErrors } from '../../utils/validations';
import { ErrorCode } from '../../types/errors';
import { AppRoute } from '../../types/routes';

interface MatchParams {
    token?: string;
}

interface OwnProps extends TranslationContext, AuthenticationContext, RouteComponentProps<MatchParams> {}

interface OwnState {
    loading: boolean;
    success: boolean;
}

const initialState: OwnState = {
    loading: true,
    success: false,
};

class ActivateScreen extends Component<OwnProps, OwnState> {
    constructor(props: OwnProps) {
        super(props);
        this.state = initialState;
    }

    activateAccount = () => {
        const {
            match: {
                params: { token },
            },
            t,
            history,
        } = this.props;
        let success = false;
        axios
            .post(activateURL(token))
            .then(() => {
                success = true;
                setTimeout(() => history.push(AppRoute.Login), 3000);
            })
            .catch((err) => {
                const errors = err.response.data;
                if (errors && errors.errors && errors.errors.length > 0) {
                    errors.errors.forEach((error: any) => {
                        displayNotification({
                            message: t(`errors.${ErrorCode[error.errorCode]}`),
                            type: NotificationType.Danger,
                        });
                    });
                } else {
                    let message = t('errors.general');
                    if (hasFieldErrors(errors)) message = t('errors.fields');

                    displayNotification({
                        message,
                        type: NotificationType.Danger,
                    });
                }
            })
            .finally(() => {
                this.setState({ loading: false, success });
            });
    };

    componentDidMount() {
        this.activateAccount();
    }

    render() {
        const { t } = this.props;
        const { loading, success } = this.state;
        return (
            <div className="auth-screen">
                <img src={logo} alt="Boser" className="auth-screen__logo" />
                <div className="auth-screen__activation-container">
                    {loading ? (
                        <>
                            <h3>{t('activateScreen.activating')}</h3>
                            <Loader />
                        </>
                    ) : (
                        <>
                            {success ? (
                                <>
                                    <h3>{t('activateScreen.activated')}</h3>
                                    <Check className="auth-screen__activation-container__success-image" />
                                </>
                            ) : (
                                <h3>{t('activateScreen.fail')}</h3>
                            )}
                        </>
                    )}
                </div>
            </div>
        );
    }
}

export default withTranslationContext(withAuthenticationContext(withRouter(ActivateScreen)));
