/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import axios, { AxiosError } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

import { KeyedObject } from '../types/general';
import { MerchantsAction, MerchantsActionTypes } from './merchants_types';
import { merchantURL, createMerchantURL } from '../services/merchants';

// Create Merchant

export const newMerchantRequestActionCreator = (): MerchantsActionTypes => {
    return {
        type: MerchantsAction.CREATE_MERCHANT_REQUEST,
    };
};

export const newMerchantSuccessActionCreator = (): MerchantsActionTypes => {
    return {
        type: MerchantsAction.CREATE_MERCHANT_SUCCESS,
    };
};

export const newMerchantFailureActionCreator = (error: KeyedObject | null): MerchantsActionTypes => {
    return {
        type: MerchantsAction.CREATE_MERCHANT_FAILURE,
        payload: error,
    };
};

export const requestCreateMerchant = (payload: FormData, onSuccess?: Function, onFailure?: Function) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(newMerchantRequestActionCreator());
        try {
            const { status } = await axios.post(createMerchantURL(), payload);

            if (status === 200) {
                dispatch(newMerchantSuccessActionCreator());

                if (onSuccess) onSuccess();
            }
        } catch (error) {
            let formErrors = {};
            if (error && (error as AxiosError).response) {
                formErrors = (error as AxiosError).response?.data;
            }
            dispatch(newMerchantFailureActionCreator(formErrors));
            if (onFailure) onFailure();
        }
    };
};

// Update Merchant

export const editMerchantRequestActionCreator = (): MerchantsActionTypes => {
    return {
        type: MerchantsAction.UPDATE_MERCHANT_REQUEST,
    };
};

export const editMerchantSuccessActionCreator = (): MerchantsActionTypes => {
    return {
        type: MerchantsAction.UPDATE_MERCHANT_SUCCESS,
    };
};

export const editMerchantFailureActionCreator = (error: KeyedObject | null): MerchantsActionTypes => {
    return {
        type: MerchantsAction.UPDATE_MERCHANT_FAILURE,
        payload: error,
    };
};

export const requestUpdateMerchant = (
    merchantId: string,
    payload: FormData,
    onSuccess?: Function,
    onFailure?: Function
) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(editMerchantRequestActionCreator());
        try {
            const { status } = await axios.put(merchantURL(merchantId), payload);

            if (status === 200) {
                dispatch(editMerchantSuccessActionCreator());

                if (onSuccess) onSuccess();
            }
        } catch (error) {
            let formErrors = {};
            if (error && (error as AxiosError).response) {
                formErrors = (error as AxiosError).response?.data;
            }
            dispatch(editMerchantFailureActionCreator(formErrors));
            if (onFailure) onFailure();
        }
    };
};

export const setSelectedMerchantIdActionCreator = (payload: number | null): MerchantsActionTypes => {
    return {
        type: MerchantsAction.SET_MERCHANT_ID,
        payload
    };
};
