/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, {
    FunctionComponent, useEffect, useRef, useState,
} from 'react';
import moment from 'moment';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';

interface OwnProps extends TranslationContext {
    onSelectDay(day: moment.Moment): void;
    daySelected: moment.Moment;
    showBiWeekly: boolean;
}

const BookingsCalendarTop: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t, daySelected, onSelectDay, showBiWeekly,
    } = props;

    const itemsWrapperRef = useRef<HTMLDivElement>(null);
    const [days, setDays] = useState<moment.Moment[]>([]);

    const updateDays = (resetArray = false) => {
        const limit = showBiWeekly ? 40 : 100;
        if (days.length >= limit && !resetArray) return;

        let auxDate = showBiWeekly ? moment().subtract(moment().weekday(), 'day') : moment();
        if (!showBiWeekly && moment(daySelected).diff(moment(), 'days') < 0) {
            auxDate = moment(daySelected);
        }

        if (!resetArray) {
            const lastItem = days[days.length - 1].clone();
            auxDate = showBiWeekly ? lastItem.add(2, 'weeks') : lastItem.add(1, 'day');
        }

        const daysToAdd = showBiWeekly
            ? Array.from({ length: resetArray ? 15 : 10 }, (v, k) => k).map(i => auxDate.clone().add(i * 2, 'weeks'))
            : Array.from({ length: 20 }, (v, k) => k).map(i => auxDate.clone().add(i, 'day'));

        const newDays = resetArray ? [...daysToAdd] : [...days, ...daysToAdd];
        setDays([...newDays]);
    };

    useEffect(() => {
        if (itemsWrapperRef && itemsWrapperRef.current) {
            itemsWrapperRef.current.scrollLeft = 0;
        }

        updateDays(true);
        // eslint-disable-next-line
    }, [showBiWeekly]);

    const getItemWidth = () => {
        let width = 100;

        const firstChild = itemsWrapperRef?.current?.firstElementChild;
        if (firstChild) {
            width = firstChild.clientWidth;
        }

        return width;
    };

    const onScrollBack = () => {
        if (itemsWrapperRef && itemsWrapperRef.current) {
            itemsWrapperRef.current.scrollLeft -= getItemWidth();
        }
    };

    const onScrollForward = () => {
        if (itemsWrapperRef && itemsWrapperRef.current) {
            const { scrollWidth, scrollLeft, offsetWidth } = itemsWrapperRef.current;
            itemsWrapperRef.current.scrollLeft += getItemWidth();
            if (scrollWidth - scrollLeft <= offsetWidth + 200) {
                updateDays();
            }
        }
    };

    const getBiWeeklyLabel = (day: moment.Moment) => {
        const firstWeekDay = t(`weekDaysAbbreviatedMoment.${day.weekday()}`);
        const twoWeeksAfter = day.clone().add(13, 'days');
        const lastWeekDay = t(`weekDaysAbbreviatedMoment.${twoWeeksAfter.weekday()}`);
        return `${firstWeekDay} ${day.format('DD')} - ${lastWeekDay} ${twoWeeksAfter.format('DD')}`;
    };

    let previousMonth = days.length > 0 ? days[0].month() : moment().month();

    return (
        <div className="booking-calendar__top">
            <div
                className="booking-calendar__top__items"
                ref={itemsWrapperRef}
                data-testid="calendar-top-items-wrapper"
            >
                {days.map((day, idx) => {
                    const month = day.month();
                    const newMonth = month !== previousMonth;
                    if (newMonth) {
                        previousMonth = month;
                    }

                    const monthLabel = t(`monthsMoment.${day.month()}`);
                    return (
                        <div key={day.format('DD MMMM YYYY')} className="booking-calendar__top__items__item">
                            {(newMonth || idx === 0) ? (
                                <div data-testid="month-title">
                                    <h3>{monthLabel}</h3>
                                    <h4>{day.format('YYYY')}</h4>
                                </div>
                            ) : <div />}
                            <button
                                onClick={() => onSelectDay(day.clone())}
                                className={day.format('DD MMMM') === daySelected.format('DD MMMM') ? 'selected' : ''}
                                data-testid="date-item-btn"
                            >
                                {showBiWeekly ? getBiWeeklyLabel(day) : `${day.format('DD')} ${monthLabel}`}
                            </button>
                        </div>
                    );
                })}
            </div>
            <div className="booking-calendar__top__controls">
                <button type="button" onClick={onScrollBack} data-testid="scroll-back-btn">
                    <ArrowBack />
                </button>
                <button type="button" onClick={onScrollForward} data-testid="scroll-forward-btn">
                    <ArrowForward />
                </button>
            </div>
        </div>
    );
};

export default withTranslationContext(BookingsCalendarTop);
