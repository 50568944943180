/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import { AuthorizationContextProvider } from './AuthorizationContext';
import { Permission, PermissionOperator } from '../../types/authorization';
import { checkPermission } from '../../utils/authorization';
import { AppState } from '../../reducers/types';


interface StateProps {
    permissions: Array<Permission>;
}

interface OwnProps {
    children: ReactNode;
}

type Props = StateProps & OwnProps;

export class AuthorizationController extends Component<Props> {
    checkPermission = (actions: Array<Permission>, operator: PermissionOperator = PermissionOperator.OR): boolean => {
        const {
            permissions,
        } = this.props;

        return checkPermission(permissions, actions, operator);
    };

    render(): ReactNode {
        const {
            children,
            permissions,
        } = this.props;

        return (
            <AuthorizationContextProvider
                value={{
                    userPermissions: permissions,
                    checkPermission: this.checkPermission,
                }}
            >
                {children}
            </AuthorizationContextProvider>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => {
    return {
        permissions: state.authorization.permissions,
    };
};

export const ConnectedAuthorizationController = connect(mapStateToProps)(AuthorizationController);
