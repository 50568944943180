/**
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */

import React, {
    Component,
    ReactElement,
} from 'react';
import { Close } from '@material-ui/icons';

import {
    Dialog, DialogContent, IconButton,
} from '@material-ui/core';
import { GenericFunction } from '../../utils/misc';
import { mapCenter } from '../../utils/constants';
import Map from './MapNew';
import { withTranslationContext } from '../controllers/TranslationContext';

interface OwnProps {
    show: boolean;
    title?: string;
    children?: ReactElement;
    onClose: GenericFunction;
    setMapRef: GenericFunction;
}

type Props = OwnProps;

class MapModal extends Component<Props> {
    onMapMounted = (ref: any) => {
        const { setMapRef } = this.props;

        setMapRef(ref);
    };

    render() {
        const {
            show,
            title,
            onClose,
        } = this.props;

        return (
            <Dialog
                open={show}
                onClose={() => onClose()}
                className="map-modal"
                data-testid="map-modal"
            >
                <DialogContent className="map-modal__content">
                    <div className="map-modal__content__top">
                        <div className="map-modal__content__top__title">
                            {title}
                        </div>
                        <IconButton
                            aria-label="close"
                            onClick={onClose}
                            data-testid="map-modal-dialog-close-btn"
                        >
                            <Close color="primary" />
                        </IconButton>
                    </div>
                    <div className="map-modal__content__bottom">
                        <div className="map-modal__content__bottom__map">
                            <Map zoom={8} height="500px" center={mapCenter} onMapMounted={this.onMapMounted} togglable />
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

export default withTranslationContext(MapModal);
