/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { REHYDRATE } from 'redux-persist';

import { BusinessesState } from './types';
import { GeneralAction } from '../actions/general_types';
import { BusinessesAction } from '../actions/businesses_types';

export const businessesInitialState: BusinessesState = {
    createBusinessRequest: {
        isFetching: false,
        errors: null,
    },
    updateBusinessRequest: {
        isFetching: false,
        errors: null,
    },
};

export default function (state = businessesInitialState, action: any): BusinessesState {
    switch (action.type) {
        case REHYDRATE:
            if (action.payload && action.payload.businesses) {
                return {
                    ...action.payload.businesses,
                    createBusinessRequest: businessesInitialState.createBusinessRequest,
                    updateBusinessRequest: businessesInitialState.updateBusinessRequest,
                };
            }
            return state;
        case BusinessesAction.CREATE_BUSINESS_REQUEST:
            return {
                ...state,
                createBusinessRequest: {
                    ...state.createBusinessRequest,
                    isFetching: true,
                },
            };
        case BusinessesAction.CREATE_BUSINESS_SUCCESS:
            return {
                ...state,
                createBusinessRequest: {
                    isFetching: false,
                    errors: null,
                },
            };
        case BusinessesAction.CREATE_BUSINESS_FAILURE:
            return {
                ...state,
                createBusinessRequest: {
                    isFetching: false,
                    errors: action.payload,
                },
            };
        case BusinessesAction.UPDATE_BUSINESS_REQUEST:
            return {
                ...state,
                updateBusinessRequest: {
                    ...state.updateBusinessRequest,
                    isFetching: true,
                },
            };
        case BusinessesAction.UPDATE_BUSINESS_SUCCESS:
            return {
                ...state,
                updateBusinessRequest: {
                    isFetching: false,
                    errors: null,
                },
            };
        case BusinessesAction.UPDATE_BUSINESS_FAILURE:
            return {
                ...state,
                updateBusinessRequest: {
                    isFetching: false,
                    errors: action.payload,
                },
            };
        case GeneralAction.STORE_RESET:
            return businessesInitialState;
        default:
            return state;
    }
}
