/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { useState, memo, useEffect } from 'react';
import {
    ButtonBase, IconButton, List, ListItem, ListItemText, Typography, Dialog, Button, DialogActions,
} from '@material-ui/core';
import LeftIcon from '@material-ui/icons/ArrowLeft';
import ClearIcon from '@material-ui/icons/Clear';
import RightIcon from '@material-ui/icons/ArrowRight';
import moment from 'moment';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { KeyedObject } from '../../types/general';
import { DATE_FORMAT } from '../../utils/constants';

type CircleProps = {
    day: number;
    onClick: (day: number) => void;
    checked: boolean;
    disabled?: boolean;
    invalid?: boolean;
}

type RightSideOwnProps = {
    selectedDates: moment.Moment[];
    readOnly: boolean;
    removeDate: (index: number) => void;
    invalidDays: string[];
    isRanged: boolean;
}

type RightSideProps = RightSideOwnProps & TranslationContext;

type CalendarOwnProps = {
    onDayClick: (selectedDay: moment.Moment) => void;
    removeDate: (index: number) => void;
    selectedDates: moment.Moment[];
    onSubmit: () => void;
    close: () => void;
    readonly: boolean;
    invalidDays: string[];
    beforeToday: boolean | undefined;
    limit: number | undefined;
    isRanged: boolean | undefined;
}

type CalendarProps = CalendarOwnProps & TranslationContext;

type MultiDatePickerOwnProps = {
    open: boolean;
    onSubmit: (selectedDays: moment.Moment[]) => void;
    onClose: () => void;
    name: string;
    errors?: KeyedObject | null;
    preSelectedDates?: moment.Moment[];
    readonly?: boolean;
    beforeToday?: boolean;
    isRanged?: boolean;
    limit?: number;
}

type MultiDatePickerProps = MultiDatePickerOwnProps;

const getWeeksInMonth = (month: moment.Moment) => {
    const weeks: number[][] = [];
    const maxDaysOnMonth = month.daysInMonth();

    let newMonth = month.clone().startOf('month');
    let weekNum = 0;
    
    while (weekNum < Math.ceil(maxDaysOnMonth / 7) + 1) {
        const week: number[] = [];
        while (newMonth.weekday() < 6 && newMonth.date() < maxDaysOnMonth) {
            week.push(newMonth.date());
            newMonth = newMonth.add(1, 'day');
        }

        weekNum++;
        weeks.push([...week, newMonth.date()]);
        newMonth = newMonth.add(1, 'day');

        if (newMonth.date() === 1) {
            break;
        }
    }
    return weeks;
};

const Circle: React.FC<CircleProps> = ({
    day, onClick, checked, disabled = false, invalid,
}: CircleProps) => {
    return (
        <ButtonBase
            className={`calendar__wrapper__body__week__circle
             ${checked && 'calendar__wrapper__body__week__circle--checked'} 
             ${disabled && 'calendar__wrapper__body__week__circle--disabled'}
             ${invalid && 'calendar__wrapper__body__week__circle--invalid'}
            `}
            disabled={disabled}
            onClick={() => onClick(day)}
        >
            <Typography
                color="inherit"
                variant="body1"
                className={
                    `calendar__wrapper__body__week__circle__text 
                    ${checked && 'calendar__wrapper__body__week__circle__text--checked'}
                    ${disabled && 'calendar__wrapper__body__week__circle__text--disabled'}
                `}
            >
                {day}
            </Typography>
        </ButtonBase>
    );
};

const WeekHeader: React.FC = memo(() => {
    return (
        <div className="calendar__wrapper__body__week-header">
            {moment.weekdaysMin(true).map(name => (
                <Typography key={name} variant="overline">{name}</Typography>
            ))}
        </div>
    );
});

const RightSide: React.FC<RightSideOwnProps> = memo(
    withTranslationContext(({
        selectedDates, readOnly, removeDate, t, invalidDays, isRanged,
    }: RightSideProps) => {
        const sortDatesDescending = (a: moment.Moment, b: moment.Moment) => (a.isBefore(b) ? -1 : 1);
        const sortedDates = [...selectedDates].sort(sortDatesDescending);

        return (
            <div className="multiple-date-picker__right-side">
                <div className="multiple-date-picker__right-side__header">
                    <Typography variant="subtitle1">
                        {t('datePicker.selectedDays')}
                    </Typography>
                    <Typography variant="subtitle1">
                        {selectedDates.length}
                    </Typography>
                </div>
                <List dense className="multiple-date-picker__right-side__list">
                    {sortedDates.map(date => (
                        <ListItem
                            key={`${date.toString()}`}
                            button
                            disabled={readOnly}
                            className={`
                                ${invalidDays.includes(date.format(DATE_FORMAT)) && 'multiple-date-picker__right-side__list__item--disabled'}
                            `}
                        >
                            <ListItemText primary={date.format('ll')} />
                            {
                                !readOnly && !isRanged && (
                                    <IconButton onClick={() => removeDate(selectedDates.findIndex(d => d.isSame(date, 'date')))}>
                                        <ClearIcon color="error" />
                                    </IconButton>
                                )
                            }
                        </ListItem>
                    ))}
                </List>
            </div>
        );
    }),
);

const Calendar: React.FC<CalendarOwnProps> = withTranslationContext(({
    onDayClick, selectedDates, removeDate, onSubmit, close, t, readonly, invalidDays, beforeToday, limit, isRanged,
}: CalendarProps) => {
    const [monthAndYear, setMonthAndYear] = useState<moment.Moment>(moment().startOf('month'));

    const renderWeekLine = (week: number[], weekIndex: number) => {
        const onClick = (isChecked: boolean, dayOfMonth: moment.Moment, dayIndex: number) => {
            if (readonly) return;
            return !isChecked ? onDayClick(dayOfMonth) : removeDate(dayIndex);
        };

        return (
            <div className="calendar__wrapper__body__week" key={`${monthAndYear.toString()}${weekIndex}`}>
                {
                    week.map(day => {
                        let isDisabled = false;
                        const dayOfMonth = monthAndYear.clone().add(day - 1, 'day');
                        const dayIndex = [...selectedDates].findIndex(date => date.isSame(dayOfMonth, 'date'));
                        const isChecked = dayIndex >= 0;
                        const disableBeforeToday = dayOfMonth.isBefore(moment(), 'date');

                        if (limit) {
                            if (isRanged) {
                                const daysInRange = selectedDates[0].clone().add(limit - 1, 'days');
                                dayOfMonth.isBefore(daysInRange);
                                isDisabled = (!beforeToday && disableBeforeToday) || dayOfMonth.isAfter(daysInRange);
                            } else {
                                isDisabled = (!beforeToday && disableBeforeToday) || (selectedDates.length >= limit && !selectedDates.some(selectedDate => selectedDate.isSame(dayOfMonth)));
                            }
                        } else {
                            if (!isRanged && !beforeToday) {
                                isDisabled = disableBeforeToday;
                            }
                        }

                        return (
                            <Circle
                                key={dayOfMonth.format('L')}
                                day={day}
                                onClick={() => onClick(isChecked, dayOfMonth, dayIndex)}
                                checked={isChecked}
                                disabled={isDisabled}
                                invalid={invalidDays.includes(dayOfMonth.format(DATE_FORMAT))}
                            />
                        );
                    })
                }
            </div>
        );
    };
    
    const previousMonth = () => setMonthAndYear(monthAndYear.clone().subtract(1, 'month'));

    const nextMonth = () => setMonthAndYear(monthAndYear.clone().add(1, 'month'));

    return (
        <div className="calendar">
            <div className="calendar__toolbar">
                <IconButton onClick={previousMonth}>
                    <LeftIcon />
                </IconButton>
                <Typography variant="subtitle1">
                    {monthAndYear.format('MMMM, YYYY')}
                </Typography>
                <IconButton onClick={nextMonth}>
                    <RightIcon />
                </IconButton>
            </div>
            <div className="calendar__wrapper">
                <div className="calendar__wrapper__body">
                    <WeekHeader />
                    { getWeeksInMonth(monthAndYear).map(renderWeekLine) }
                </div>
                <DialogActions>
                    {
                        !readonly && (
                            <Button onClick={onSubmit} size="small">
                                {t('datePicker.submit')}
                            </Button>
                        )
                    }
                    <Button onClick={close} size="small">
                        {readonly ? t('datePicker.close') : t('datePicker.cancel')}
                    </Button>
                </DialogActions>
            </div>
        </div>
    );
});

export const MultipleDatePicker: React.FC<MultiDatePickerProps> = ({
    onSubmit, onClose, open, preSelectedDates = [], readonly, name, errors, beforeToday, isRanged, limit,
}: MultiDatePickerProps) => {
    const [selectedDates, setSelectedDates] = useState<moment.Moment[]>([...preSelectedDates]);

    useEffect(() => {
        setSelectedDates(preSelectedDates);
    }, [preSelectedDates]);

    const onDayClick = (selectedDay: moment.Moment) => {
        if (!isRanged) return setSelectedDates([...selectedDates, selectedDay]);

        let selectedDatesRange = [...selectedDates];
        selectedDatesRange = selectedDatesRange.sort((a, b) => a.valueOf() - b.valueOf());

        const firstDaySelected = selectedDatesRange[0];
        const lastDaySelected = selectedDatesRange[selectedDatesRange.length - 1];

        if (firstDaySelected.isSame(lastDaySelected)) {
            if (selectedDay.isBefore(firstDaySelected)) {
                selectedDatesRange = [selectedDay];
            } else {
                const selectedDaysArray = getSelectedDatesRange(selectedDay, lastDaySelected);
                selectedDatesRange = [...selectedDatesRange, ...selectedDaysArray];
            }
        } else {
            selectedDatesRange = [selectedDay];
        }

        setSelectedDates(selectedDatesRange);
    };

    const getSelectedDatesRange = (selectedDay: moment.Moment, lastDaySelected: moment.Moment): moment.Moment[] => {
        const selectedDaysArray: moment.Moment[] = [];

        for (let i = selectedDay.diff(lastDaySelected, 'days') - 1; i >= 0; i--) {
            selectedDaysArray.push(selectedDay.clone().subtract(i, 'days'));
        }

        return selectedDaysArray;
    };

    const popDateByIndex = (index: number) => {
        if (isRanged) {
            setSelectedDates(prevState => [prevState[index]]);
        } else {
            const newSelectedDates = [...selectedDates];
            newSelectedDates.splice(index, 1);
            setSelectedDates(newSelectedDates);
        }
    };

    const handleSubmit = () => {
        const sortDatesDescending = (a: moment.Moment, b: moment.Moment) => (a.isBefore(b) ? -1 : 1);
        const sortedDates = [...selectedDates].sort(sortDatesDescending);
        onSubmit(sortedDates);
        onClose();
    };

    const hasErrors = Object.keys(errors?.fields || {}).filter(key => key === name).length > 0;
    const invalidDays = hasErrors ? errors?.fields.days.invalidDays : [];
    
    return (
        <Dialog open={open} onClose={onClose}>
            <div className="multiple-date-picker" data-testid="multi-date-picker">
                <Calendar
                    onDayClick={onDayClick}
                    selectedDates={selectedDates}
                    removeDate={popDateByIndex}
                    onSubmit={handleSubmit}
                    close={onClose}
                    readonly={readonly || false}
                    invalidDays={invalidDays}
                    beforeToday={beforeToday}
                    limit={limit}
                    isRanged={isRanged}
                />
                <RightSide
                    readOnly={readonly || false}
                    removeDate={popDateByIndex}
                    selectedDates={selectedDates}
                    invalidDays={invalidDays}
                    isRanged={isRanged || false}
                />
            </div>
        </Dialog>
    );
};
