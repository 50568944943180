/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { REHYDRATE } from 'redux-persist';

import { BookingsState } from './types';
import { GeneralAction } from '../actions/general_types';
import { BookingsAction } from '../actions/bookings_types';

export const bookingsInitialState: BookingsState = {
    createBookingRequest: {
        isFetching: false,
        errors: null,
    },
    calendarBookingBusinessData: null,
    calendarBookingBollardsData: null,
};

export default function (state = bookingsInitialState, action: any): BookingsState {
    switch (action.type) {
        case REHYDRATE:
            if (action.payload && action.payload.bookings) {
                return {
                    ...action.payload.bookings,
                    createBookingRequest: bookingsInitialState.createBookingRequest,
                };
            }
            return state;
        case BookingsAction.CREATE_BOOKING_REQUEST:
            return {
                ...state,
                createBookingRequest: {
                    ...state.createBookingRequest,
                    isFetching: true,
                },
            };
        case BookingsAction.CREATE_BOOKING_SUCCESS:
            return {
                ...state,
                createBookingRequest: {
                    isFetching: false,
                    errors: null,
                },
            };
        case BookingsAction.CREATE_BOOKING_FAILURE:
            return {
                ...state,
                createBookingRequest: {
                    isFetching: false,
                    errors: action.payload,
                },
            };
        case BookingsAction.SET_BOOKING_CALENDAR_BUSINESS:
            return {
                ...state,
                calendarBookingBusinessData: action.payload,
            };
        case BookingsAction.SET_BOOKING_CALENDAR_BOLLARDS:
            return {
                ...state,
                calendarBookingBollardsData: action.payload,
            };
        case GeneralAction.STORE_RESET:
            return bookingsInitialState;
        default:
            return state;
    }
}
