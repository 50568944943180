/*
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

const IconArrowUp: FunctionComponent = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="31.39" height="31.39" viewBox="0 0 31.39 31.39">
            <g id="ic-arrow" transform="translate(1434.39 42.39) rotate(-180)">
                <path id="Path_934" data-name="Path 934" d="M0,0H31.39V31.39H0Z" transform="translate(1403 11)" fill="none" />
                <path id="Path_935" data-name="Path 935" d="M13.959,14.7l6.474-6.474,1.849,1.849-8.323,8.323L5.636,10.071,7.485,8.222Z" transform="translate(1404.734 13.531)" fill="#667085" />
            </g>
        </svg>
    );
};

export default IconArrowUp;
