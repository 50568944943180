/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, {
    FunctionComponent, useEffect, useState,
} from 'react';
import moment from 'moment';
import { Bollard, BollardOccupation } from '../../../types/bollards';
import { Area, ReservationPeriod } from '../../../types/areas';
import BookingCalendarIndividualRow from './BookingCalendarIndividualRow';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import CalendarInfiniteScroll from './CalendarInfiniteScroll';

interface OwnProps extends TranslationContext {
    area: Area;
    bollards: Bollard[];
    occupations: BollardOccupation[];
    hasMore: boolean;
    requestMoreBollards(): void;
}

const BookingsCalendarIndividualAvailability: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t, bollards, area, occupations, requestMoreBollards, hasMore,
    } = props;

    const [hoursItems, setHoursItems] = useState<moment.Moment[]>([]);

    useEffect(() => {
        let minHour = 6;
        let maxHour = 22;
        if (area.reservationPeriods && area.reservationPeriods.length > 0) {
            minHour = Math.min(...area.reservationPeriods.map((p: ReservationPeriod) => Number(p.startTime.split(':')[0])))- 1;
            maxHour = Math.max(...area.reservationPeriods.map((p: ReservationPeriod) => Number(p.endTime.split(':')[0]))) + 1;
        }
        const hours = Array.from({ length: maxHour - minHour }, (v, k) => k)
            .map(i => moment().set({ hour: minHour + i, minutes: 0, seconds: 0 }));
        setHoursItems(hours);
    }, [area]);

    return (
        <CalendarInfiniteScroll
            hasMore={hasMore}
            requestMore={requestMoreBollards}
        >
            <React.Fragment>
                <div className="booking-calendar__grid__description">
                    {t('bookingForm.bollard')}
                </div>
                <div className="booking-calendar__grid__row">
                    {hoursItems.map(hour => {
                        return (
                            <span key={hour.format('DD/MM HH:mm')} className="booking-calendar__grid__row__date" data-testid="hour-item">
                                {hour.format('HH:mm')}
                            </span>
                        );
                    })}
                </div>
            </React.Fragment>
            {bollards.map(bollard => {
                const bollardOccupation = occupations.find(b => b.bollardId === bollard.id);

                return (
                    <React.Fragment key={bollard.id}>
                        <div className="booking-calendar__grid__bollard-name">{bollard.designation}</div>
                        <BookingCalendarIndividualRow
                            hourItems={hoursItems}
                            occupation={bollardOccupation}
                        />
                    </React.Fragment>
                );
            })}
        </CalendarInfiniteScroll>
    );
};

export default withTranslationContext(BookingsCalendarIndividualAvailability);
