/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { AnyAction } from 'redux';
import { REHYDRATE } from 'redux-persist';


import { AuthorizationAction } from '../actions/authorization_types';
import { AuthorizationState } from './types';

export const authorizationInitialState: AuthorizationState = {
    permissions: [],
};

export default (state = authorizationInitialState, action: AnyAction): AuthorizationState => {
    switch (action.type) {
        case REHYDRATE:
            if (action.payload && action.payload.authorization) {
                return {
                    ...action.payload.authorization,
                };
            }
            return state;
        case AuthorizationAction.SetPermissions:
            return {
                ...state,
                permissions: action.payload,
            };
        default:
            return state;
    }
};
