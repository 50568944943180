/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

import { ClientsContextProvider } from './ClientsContext';
import { clientsURL } from '../../services/clients';
import { KeyedObject } from '../../types/general';
import { ListData } from '../../types/clients';

interface OwnProps {
    children: any;
}

interface OwnState {}

type Props = OwnProps;
type State = OwnState;

export class ClientsController extends Component<Props, State> {
    getClients = async (filters?: KeyedObject): Promise<ListData | null> => {
        try {
            const { data, headers } = await axios.get(clientsURL(filters));
            return { clients: data, total: headers['x-total-count'] };
        } catch {
            return null;
        }
    };

    render() {
        const { children } = this.props;

        return (
            <ClientsContextProvider
                value={{
                    getClients: this.getClients,
                }}
            >
                {children}
            </ClientsContextProvider>
        );
    }
}

export const ConnectedClientsController = connect()(ClientsController);
