/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';

import { ConnectedBookingsController } from './BookingsController';
import {
    Country, KeyedObject, ListResponse,
} from '../../types/general';
import { ApiError } from '../../types/errors';
import {
    BollardAvailabilityList,
    BollardSchedule,
    BollardScheduleReservation,
    Booking,
    BookingDays,
    BookingPaymentStatus,
    PayloadSubmitNewReservationMultipleDays,
    PayloadSubmitSetReservationMultipleDays,
    PayloadSubmitSetReservationObservation,
} from '../../types/bookings';
import { BookingCalendarBollardsProps, BookingCalendarBusinessProps } from '../../reducers/types';

export interface BookingsContext {
    createBookingFetching: boolean;
    createBookingErrors: ApiError | null;
    calendarBookingBollardsData: BookingCalendarBollardsProps | null;
    calendarBookingBusinessData: BookingCalendarBusinessProps | null;
    getCountries(): Promise<Country[] | null>;
    getBooking(areaId: string): Promise<Booking | null>;
    getBookingDays(bookingId: string, filters?: KeyedObject): Promise<BookingDays[] | null>;
    getBookings(filters?: KeyedObject): Promise<ListResponse<Booking> | null>;
    getSchedules(businessId: string, areaId: string, days: string[]): Promise<{ day: string, schedules: BollardSchedule[] }[]>;
    getSchedulesNew(businessId: string, days: string[]): Promise<{ day: string, schedules: BollardAvailabilityList[] }[]>;
    validateNewBooking(fields: any, registerUser: boolean): KeyedObject | null;
    submitNewBooking(payload: any, registerUser: boolean, onSuccess: Function, onFailure: Function): void;
    submitNewBookingsReservation(payload: any, reservations: BollardScheduleReservation[], registerUser: boolean, onSuccess: Function, onFailure: Function): void;
    submitNewBookingsReservationMultipleDays(payload: PayloadSubmitNewReservationMultipleDays, onSuccess: Function, onFailure: Function): void;
    cancelBooking(reservationId: React.Key, onSuccess: () => void, onFailure: (errorCode?: number) => void): Promise<void>;
    setBookingPaymentStatus(reservationId: React.Key, newStatus: BookingPaymentStatus, onSuccess: () => void, onFailure: (errorCode?: number) => void): Promise<void>;
    setBookingCalendarBollardsData(bookingCalendarBollardsProps: BookingCalendarBollardsProps | null): void;
    setBookingCalendarBusinessData(bookingCalendarBusinessProps: BookingCalendarBusinessProps | null): void;
    setBooking(bookingId: string, payload: PayloadSubmitSetReservationMultipleDays, onSuccess: Function, onFailure: Function): void;
    setBookingObservations(bookingId: string, payload: PayloadSubmitSetReservationObservation, onSuccess: Function, onFailure: Function): void;
}

export const bookingsContextDefaultValue = {
    createBookingFetching: false,
    createBookingErrors: null,
    calendarBookingBusinessData: null,
    calendarBookingBollardsData: null,
    getCountries: async () => null,
    getBooking: async () => null,
    getBookingDays: async () => null,
    getBookings: async () => null,
    getSchedules: async () => [],
    getSchedulesNew: async () => [],
    validateNewBooking: () => null,
    submitNewBooking: () => {},
    submitNewBookingsReservation: () => {},
    submitNewBookingsReservationMultipleDays: () => {},
    cancelBooking: async (): Promise<void> => {},
    setBookingPaymentStatus: async (): Promise<void> => {},
    setBookingCalendarBusinessData: (): void => {},
    setBookingCalendarBollardsData: (): void => {},
    setBooking: () => {},
    setBookingObservations: () => {},
};

const BookingsContextInstance = createContext<BookingsContext | null>(bookingsContextDefaultValue);

export const BookingsContextProvider = BookingsContextInstance.Provider;
export const BookingsContextConsumer = BookingsContextInstance.Consumer;

export const withBookingsContext = <P extends object>(
    Component: ComponentType<P>,
): FC<Omit<P, keyof BookingsContext>> => props => (
    <ConnectedBookingsController>
        <BookingsContextConsumer>{ctx => <Component {...(props as P)} {...ctx} />}</BookingsContextConsumer>
    </ConnectedBookingsController>
    );
