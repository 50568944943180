/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

export enum PermissionOperator {
    OR = 'OR',
    AND = 'AND',
}

export enum Permission {
    // Users
    MERCHANT_CREATE = 'MERCHANT_CREATE',
    MERCHANT_EDIT = 'MERCHANT_EDIT',
    MERCHANT_LIST_VIEW = 'MERCHANT_LIST_VIEW',
    ACTIVATE_MERCHANT = 'ACTIVATE_MERCHANT',
    // Businesses
    BUSINESS_CREATE = 'BUSINESS_CREATE',
    BUSINESS_EDIT = 'BUSINESS_EDIT',
    BUSINESS_VIEW_LIST = 'BUSINESS_VIEW_LIST',
    RESTRICT_BUSINESS_BY_DOMAIN = 'RESTRICT_BUSINESS_BY_DOMAIN',
    BUSINESS_ACTIVATE = 'BUSINESS_ACTIVATE',
    // Areas
    AREA_CREATE = 'AREA_CREATE',
    AREA_EDIT = 'AREA_EDIT',
    AREA_VIEW_LIST = 'AREA_VIEW_LIST',
    AREA_ACTIVATE = 'AREA_ACTIVATE',
    // Bollards
    BOLLARD_CREATE = 'BOLLARD_CREATE',
    BOLLARD_EDIT = 'BOLLARD_EDIT',
    BOLLARD_VIEW_ACTIVE = 'BOLLARD_VIEW_ACTIVE',
    BOLLARD_VIEW_INACTIVE = 'BOLLARD_VIEW_INACTIVE',
    ACTIVATE_BOLLARD = 'ACTIVATE_BOLLARD',
    LOCATE_BOLLARD = 'LOCATE_BOLLARD',
    // Bookings
    BOOKING_CREATE = 'BOOKING_CREATE',
    BOOKING_EDIT = 'BOOKING_EDIT',
    BOOKING_VIEW_LIST = 'BOOKING_VIEW_LIST',
    BOOKING_VIEW_CALENDAR = 'BOOKING_VIEW_CALENDAR',
    BOOKING_VIEW_DETAILS = 'BOOKING_VIEW_DETAILS',
    // Clients
    CLIENTS_VIEW_LIST = 'CLIENTS_VIEW_LIST',
    // Reservation Managers
    RESERVATION_MANAGERS_LIST_SCREEN = 'RESERVATION_MANAGERS_LIST_SCREEN',
    RESERVATION_MANAGERS_VIEW_DETAILS = 'RESERVATION_MANAGERS_VIEW_DETAILS',
    // Invitations
    INVITE_MERCHANT = 'INVITE_MERCHANT',
    MERCHANT_INVITATION_LIST_VIEW = 'MERCHANT_INVITATION_LIST_VIEW',
    DELETE_MERCHANT_PENDING_INVITATION = 'DELETE_MERCHANT_PENDING_INVITATION',
    RESERVATION_MANAGER_INVITATION_LIST_VIEW = 'RESERVATION_MANAGER_INVITATION_LIST_VIEW',
    INVITE_RESERVATION_MANAGER = 'INVITE_RESERVATION_MANAGER',
    DELETE_RESERVATION_MANAGER_PENDING_INVITATION = 'DELETE_RESERVATION_MANAGER_PENDING_INVITATION',
    ALL_RESERVATION_MANAGERS = 'ALL_RESERVATION_MANAGERS',
}
