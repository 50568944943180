/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';
import { KeyedObject } from '../types/general';
import { objectToParams } from '../utils/misc';

const api = 'invitations';

export const acceptInviteURL = (token: string): string => {
    return `${API_URL}/${api}/accept-invite/${token}`;
};

export const invitationsURL = (filters?: KeyedObject): string => {
    const params = objectToParams(filters);
    return `${API_URL}/${api}${params}`;
};

export const invitationURL = (id: number): string => {
    return `${API_URL}/${api}/${id}`;
};

export const invitationsMerchantURL = (filters?: KeyedObject): string => {
    const params = objectToParams(filters);
    return `${API_URL}/${api}/merchant${params}`;
};

export const invitationReservationManagerByAdminURL = (merchantId: number): string => {
    return `${API_URL}/${api}/${merchantId}/reservationManager`;
};

export const invitationReservationManagerByMerchantURL = (): string => {
    return `${API_URL}/${api}/reservationManager`;
};
