/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';
import { KeyedObject } from '../types/general';
import { objectToParams } from '../utils/misc';

const api = 'merchants';

export const merchantsURL = (filters?: KeyedObject): string => {
    const params = objectToParams(filters);
    return `${API_URL}/${api}${params}`;
};

export const registerURL = (): string => {
    return `${API_URL}/${api}`;
};

export const editProfileURL = (id: string): string => {
    return `${API_URL}/${api}/${id}`;
};

export const createMerchantURL = (): string => {
    return `${API_URL}/${api}/admin`;
};

export const merchantURL = (id: string | number, filters?: KeyedObject): string => {
    const params = objectToParams(filters);
    return `${API_URL}/${api}/${id}${params}`;
};
