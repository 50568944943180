/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { REHYDRATE } from 'redux-persist';

import { PreferencesState } from './types';
import { Language } from '../types/preferences';
import { GeneralAction } from '../actions/general_types';
import { PreferencesAction } from '../actions/preferences_types';

export const preferencesInitialState: PreferencesState = {
    translations: null,
    language: Language.EN,
    languages: [Language.PT, Language.EN],
    translationsRequest: {
        isFetching: false,
        errors: null,
    },
};

export default function (state = preferencesInitialState, action: any): PreferencesState {
    switch (action.type) {
        case REHYDRATE:
            if (action.payload && action.payload.preferences) {
                return {
                    ...action.payload.preferences,
                    translationsRequest: {
                        isFetching: false,
                        errors: null,
                    },
                };
            }
            return state;
        case PreferencesAction.LANGUAGE_SET:
            return {
                ...state,
                language: action.payload,
            };
        case GeneralAction.STORE_RESET:
            return preferencesInitialState;
        default:
            return state;
    }
}
