/**
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

interface OwnProps {
    children: React.ReactNode;
    hasMore: boolean;
    requestMore(): void;
}

const BollardInfiniteScroll: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const { children, requestMore, hasMore } = props;

    const handleScroll = (e: React.UIEvent) => {
        const { currentTarget: { scrollHeight, scrollTop, clientHeight } } = e;

        if (scrollTop + clientHeight >= scrollHeight - 100) {
            requestMore();
        }
    };

    return (
        <div className="bollard-table__grid" onScroll={hasMore ? handleScroll : undefined} data-testid="calendar-scroll-wrapper">
            {children}
        </div>
    );
};

export default BollardInfiniteScroll;
