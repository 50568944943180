/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useEffect, useState } from 'react';
import moment from 'moment';

import { Bollard, BollardDayOccupation } from '../../../types/bollards';
import BookingCalendarBiWeeklyRow from './BookingCalendarBiWeeklyRow';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import CalendarInfiniteScroll from './CalendarInfiniteScroll';

interface OwnProps extends TranslationContext {
    bollards: Bollard[];
    hasMore: boolean;
    dateSelected: moment.Moment;
    onDaySelected(day: moment.Moment): void;
    occupations: { [key: number]: BollardDayOccupation[] };
    requestMoreBollards(): void;
}

const BookingCalendarBiWeekly: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t, bollards, dateSelected, onDaySelected, occupations, hasMore, requestMoreBollards,
    } = props;

    const [daysItems, setDaysItems] = useState<moment.Moment[]>([]);

    useEffect(() => {
        const days: moment.Moment[] = Array.from({ length: 14 }, (v, k) => k).map(i => dateSelected.clone().add(i, 'days'));

        setDaysItems(days);
    }, [dateSelected]);

    return (
        <CalendarInfiniteScroll hasMore={hasMore} requestMore={requestMoreBollards}>
            <React.Fragment>
                <div className="booking-calendar__grid__description">
                    {t('bookingForm.bollard')}
                </div>
                <div className="booking-calendar__grid__row">
                    {daysItems.map(day => {
                        return (
                            <span key={day.format('DD/MM HH:mm')} className="booking-calendar__grid__row__date" data-testid="bi-weekly-day">
                                {`${t(`weekDaysAbbreviatedMoment.${day.weekday()}`)} ${day.format('DD')}`}
                            </span>
                        );
                    })}
                </div>
            </React.Fragment>
            {bollards.map(bollard => {
                const bollardOccupation = occupations[bollard.id];

                return (
                    <React.Fragment key={bollard.id}>
                        <div className="booking-calendar__grid__bollard-name">{bollard.designation}</div>
                        <BookingCalendarBiWeeklyRow
                            daysItems={daysItems}
                            onDaySelected={onDaySelected}
                            occupationDays={bollardOccupation || []}
                        />
                    </React.Fragment>
                );
            })}
        </CalendarInfiniteScroll>
    );
};

export default withTranslationContext(BookingCalendarBiWeekly);
