/*
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */

import React, {
    FunctionComponent, useState,
} from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import Back from '@material-ui/icons/ArrowBack';
import { Button } from '@material-ui/core';
import moment from 'moment/moment';
import BookingForm, { BookingFormField, BookingFormFields, BookingStateNames } from '../views/BookingForm';
import { AppRoute } from '../../types/routes';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { BollardsContext, withBollardsContext } from '../controllers/BollardsContext';
import { displayNotification, NotificationType } from '../../utils/notifications';
import {
    BollardScheduleReservation, BookingStatus,
    PayloadSubmitSetReservationMultipleDays,
} from '../../types/bookings';
import { DATE_FORMAT } from '../../utils/constants';
import { BookingsContext, withBookingsContext } from '../controllers/BookingsContext';
import { buildRoute } from '../../utils/misc';

interface MatchParams {
    bookingId?: string;
}

type OwnProps = TranslationContext & BollardsContext & BookingsContext & RouteComponentProps<MatchParams>;

interface UserReservation {
    registerUser: boolean;
    reservationsSelected: Array<BollardScheduleReservation>;
    showErrorDaysWithoutPeriodSelected: boolean;
    isFetching: boolean;
    price: number;
    reservationStatus: string;
}

export const AddBookingScreen: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const [fields, setFields] = useState<BookingFormFields>({
        [BookingFormField.Business]: '',
        [BookingFormField.Area]: '',
        [BookingFormField.Days]: [moment()],
        [BookingFormField.Bollard]: '',
        [BookingFormField.FirstName]: '',
        [BookingFormField.LastName]: '',
        [BookingFormField.Email]: '',
        [BookingFormField.PhoneNumber]: '',
        [BookingFormField.Code]: 'PT',
        [BookingFormField.NumberOfPeople]: '1',
        [BookingFormField.Observations]: '',
    });
    const [userReservation, setUserReservation] = useState<UserReservation>({
        registerUser: false,
        reservationsSelected: [],
        showErrorDaysWithoutPeriodSelected: false,
        isFetching: false,
        price: 0,
        reservationStatus: '',
    });

    const onFieldsChange = (values: BookingFormFields): void => {
        setFields(values);
    };

    const onUserReservationChange = (name: string, value: any): void => {
        setUserReservation(prevState => {
            return {
                ...prevState,
                [name]: value,
            };
        });
    };

    const onFormSubmit = (): void => {
        const {
            t,
            setBooking,
            match: {
                params: { bookingId },
            },
        } = props;

        if (userReservation.reservationsSelected.length === 0) {
            displayNotification({
                message: t('bookingForm.selectOnePeriod'),
                type: NotificationType.Danger,
            });
            return;
        }

        const days = fields[BookingFormField.Days];
        if (days.length > userReservation.reservationsSelected.length) {
            onUserReservationChange(BookingStateNames.ShowErrorDaysWithoutPeriodSelected, true);
            return;
        }

        onUserReservationChange(BookingStateNames.IsFetching, true);

        const payload: PayloadSubmitSetReservationMultipleDays = {
            [BookingFormField.Days]: userReservation.reservationsSelected.map(reservation => {
                return {
                    bollardId: reservation.bollard.id,
                    day: reservation.days[0].format(DATE_FORMAT),
                    reservationPeriodId: reservation.periodId,
                };
            }),
        };

        setBooking(String(bookingId), payload, onFormSubmitSuccess, onFormSubmitFailure);
    };

    const onFormSubmitSuccess = () => {
        const { t, history } = props;

        displayNotification({
            message: t('bookingForm.editSuccess'),
            type: NotificationType.Success,
        });

        history.push(AppRoute.Bookings);
    };

    const onFormSubmitFailure = (errors: string | null) => {
        const { t } = props;

        if (errors) {
            displayNotification({
                message: errors,
                type: NotificationType.Danger,
            });
        } else {
            displayNotification({
                message: t('errors.genericAPIError'),
                type: NotificationType.Danger,
            });
        }

        onUserReservationChange(BookingStateNames.IsFetching, false);
    };

    const {
        match: {
            params: { bookingId },
        },
        t,
    } = props;

    return (
        <div className="bookings-form-container-new" data-testid="edit-booking-screen">
            <div className="header">
                <div className="header__left">
                    <Link
                        to={buildRoute(AppRoute.BookingDetails, {
                            bookingId,
                        })}
                        className="return-link"
                    >
                        <Back />
                    </Link>
                    <h2 className="bookings-form-container--header-container__header">
                        <span className="bookings-form-container--header-container__header--title">{t('bookingDetailsScreen.title')}</span>
                        <span className="bookings-form-container--header-container__header--title"> / #{bookingId}</span>
                        <span> / {t('editBookingScreen.title')}</span>
                    </h2>
                </div>
                <div className="header__right">
                    <div className="header__right__price">
                        <h1>{t('bookingForm.total')}</h1>
                        <p>{userReservation.price} €</p>
                    </div>
                    <div className="header__right__btn-submit">
                        <Button
                            className="margin-button"
                            variant="contained"
                            color="primary"
                            onClick={onFormSubmit}
                            disabled={userReservation.reservationStatus !== BookingStatus.Reserved}
                        >
                            {t('bookingForm.submitBtn')}
                        </Button>
                    </div>
                </div>
            </div>
            <BookingForm
                onFieldsChange={onFieldsChange}
                fields={fields}
                reservationsSelected={userReservation.reservationsSelected}
                isFetching={userReservation.isFetching}
                showErrorDaysWithoutPeriodSelected={userReservation.showErrorDaysWithoutPeriodSelected}
                errors={null}
                registerUser={userReservation.registerUser}
                onStateChange={onUserReservationChange}
                price={userReservation.price}
                bookingId={bookingId}
            />
        </div>
    );
};

export default withTranslationContext(withBookingsContext(withBollardsContext(AddBookingScreen)));
