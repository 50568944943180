/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import moment from 'moment';
import { Bollard } from './bollards';
import { Area } from './areas';
import { GeoPoint, QueryParams } from './general';
import { User } from './users';

export interface UnregisteredReservationInfo {
    email: string | null;
    firstName: string | null;
    lastName: string | null;
    phoneNumber: string | null;
}

export enum BookingPaymentStatus {
    NotApplicable = 'NOT_APPLICABLE',
    NotPaid = 'NOT_PAID',
    Paid = 'PAID',
}

export interface Booking {
    id: number;
    status: BookingStatus;
    createdDate: Date;
    lastModifiedDate: Date;
    bollardId: string;
    bollardDesignation: string;
    businessArea: Area;
    customer: User | null;
    day: string;
    endTime: string;
    location: GeoPoint;
    merchant: User;
    startTime: string;
    observations: string;
    startDateTime: string;
    endDateTime: string;
    timeZone: string;
    price: number;
    unregisteredReservationInfo: UnregisteredReservationInfo | null;
    paymentStatus: BookingPaymentStatus;
    numberOfPeople: number;
}

export interface BookingParams extends QueryParams {
    _q: string;
    businessId: number | undefined;
    businessAreaId: number | undefined;
    day?: moment.Moment | null;
    days: string[] | null;
    status?: BookingStatus;
}

export enum BookingStatus {
    All = '',
    CheckedIn = 'CHECKED_IN',
    CheckedOut = 'CHECKED_OUT',
    Canceled = 'CANCELED',
    CanceledByMerchant = 'CANCELED_BY_MERCHANT',
    CanceledByCustomer = 'CANCELED_BY_CUSTOMER',
    Reserved = 'RESERVED',
    ATMPending = 'ATM_REFERENCE_CREATED',
    MBWayPending = 'MB_WAY_REQUEST_CREATED',
    CCPending = 'CREDIT_CARD_PAYMENT_CREATED',
    Absence = 'ABSENCE',
    MerchantReservationConcluded = 'MERCHANT_RESERVATION_CONCLUDED',
    NotPaid = 'NOT_PAID',
}

export interface RawScheduleEntry {
    startTime: string;
    endTime: string;
    reserved: boolean;
    reservationPeriodId: string;
    price: string | null;
}

export interface ScheduleEntry {
    startTime: string;
    endTime: string;
    reserved: boolean;
    reservationPeriodId: number;
    price: string | null;
}

export interface BollardAvailability {
    localStartTime: string;
    localEndTime: string;
    isOccupied: boolean;
    price: number;
    localDay: string;
    reservationPeriodId: number;
    occupied: boolean;
}

export interface BollardSchedule {
    bollard: Bollard;
    scheduleEntries: ScheduleEntry[];
}

export interface BollardAvailabilityList {
    bollard: Bollard;
    availabilityList: BollardAvailability[] | null;
}

export interface PayloadSubmitNewReservationMultipleDays {
    days: PayloadDays[];
    businessId: string;
    areaId: string;
    bollardId: string;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    phoneNumber: string | null;
    phoneCountry: string;
    numberOfPeople: string;
    observations: string;
    createAccount: boolean;
    country: string;
}

export interface PayloadSubmitSetReservationMultipleDays {
    days: PayloadDays[];
}

export interface PayloadSubmitSetReservationObservation {
    observations: string;
}

export interface PayloadDays {
    bollardId: number;
    day: string;
    reservationPeriodId: number;
}

export interface RawBollardSchedule {
    bollard: Bollard;
    scheduleEntries: RawScheduleEntry[];
}

export interface BollardScheduleReservation {
    bollard: Bollard;
    periodId: number;
    days: moment.Moment[];
    price: number;
}

export interface BookingDaysParams {
    pageSize: number;
}

export interface BollardDaysAvailabilityParams {
    reservationId: string;
}

export interface BookingDays {
    id: number;
    reservationId: number;
    bollardId: number;
    price: number;
    localDay: string;
    localStartTime: string;
    localEndTime: string;
    startDateTime: string;
    endDateTime: string;
    bollardDesignation: string;
    bollardUserId: string;
}

export interface BookingRequestPayload {}

export interface BookingResponsePayload {}
