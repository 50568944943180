/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, ReactElement } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { withTranslationContext, TranslationContext } from '../controllers/TranslationContext';
import { withClientsContext, ClientsContext } from '../controllers/ClientsContext';
import { ClientParams, ClientStatus } from '../../types/clients';
import Loader from '../elements/Loader';

interface OwnProps extends TranslationContext, ClientsContext {}

interface OwnState {
    clients: any;
    totalClients: number;
    params: ClientParams;
    isFetching: boolean;
}

const initialState: OwnState = {
    clients: [],
    totalClients: 0,
    params: {
        pageSize: 20,
        pageIndex: 0,
    },
    isFetching: true,
};

class ClientsScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount() {
        this.fetchClients();
    }

    fetchClients = async () => {
        const { getClients } = this.props;
        const { params } = this.state;

        const data = await getClients(params);
        if (data !== null) {
            this.setState({
                clients: data.clients,
                totalClients: Number(data.total),
            });
        }
        this.setState({ isFetching: false });
    };

    onChangePage = (evt: any, page: number) => {
        this.setState(
            {
                params: { ...this.state.params, pageIndex: page },
            },
            this.fetchClients
        );
    };

    onChangeRowsPerPage = (evt: any) => {
        this.setState(
            {
                params: { ...this.state.params, pageSize: evt.target.value },
            },
            this.fetchClients
        );
    };

    renderTableHeadCell(name: string, label?: string): ReactElement {
        const { t } = this.props;
        return (
            <TableCell align="center">
                {t(`clientsScreen.${label || name}`)}
            </TableCell>
        );
    }

    renderTable(): ReactElement {
        const { t } = this.props;
        const { clients, params, totalClients } = this.state;
        return (
            <Paper>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {this.renderTableHeadCell('name')}
                                {this.renderTableHeadCell('email')}
                                {this.renderTableHeadCell('phoneNumber')}
                                {this.renderTableHeadCell('status')}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {clients.map((client: any) => {
                                return (
                                    <TableRow
                                        key={client.id}
                                        className={
                                            client.status === ClientStatus.Active
                                                ? 'table-row--active'
                                                : 'table-row--disabled'
                                        }
                                    >
                                        <TableCell align="center">{client.name}</TableCell>
                                        <TableCell align="center">{client.email}</TableCell>
                                        <TableCell align="center">{client.phoneNumber}</TableCell>
                                        <TableCell align="center">{t(`status.${client.status}`)}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20]}
                    component="div"
                    count={totalClients}
                    rowsPerPage={params.pageSize}
                    page={params.pageIndex}
                    onPageChange={this.onChangePage}
                    onRowsPerPageChange={this.onChangeRowsPerPage}
                />
            </Paper>
        );
    }

    render() {
        const { t } = this.props;
        const { isFetching } = this.state;
        if (isFetching) {
            return <Loader />;
        }

        return (
            <div className="clients-page">
                <div className="clients-page__header">
                    <h2>{t('clientsScreen.title')}</h2>
                </div>
                {this.renderTable()}
            </div>
        );
    }
}

export default withClientsContext(withTranslationContext(ClientsScreen));
