/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

export const VERSION = process.env.REACT_APP_COMMIT;
export const API_URL = process.env.REACT_APP_API_URL;

export const mapKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

export const GOOGLE_ANAlYTICS_TRACKING_ID = process.env.REACT_APP_GOOGLE_ANAlYTICS_TRACKING_ID;
