/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios, { AxiosError } from 'axios';

import { ThunkDispatch } from 'redux-thunk';
import { BookingsContextProvider } from './BookingsContext';
import { AppState, BookingCalendarBollardsProps, BookingCalendarBusinessProps } from '../../reducers/types';
import {
    bookingsURL,
    bookingURL,
    cancelReservationURL,
    createBookingUnregisteredURL,
    createBookingURL, bookingPaymentStatusURL, createBookingMultipleDaysURL, bookingDaysURL, bookingObservationsURL,
} from '../../services/bookings';
import {
    requestCreateBooking,
    setBookingCalendarBollardsActionCreator,
    setBookingCalendarBusinessActionCreator,
} from '../../actions/bookings';
import {
    Country, KeyedObject, ListResponse,
} from '../../types/general';
import { validateForm } from '../../utils/validations';
import { ApiError } from '../../types/errors';
import { validations } from '../../types/validations';
import { schedulesURL } from '../../services/businesses';
import {
    BollardAvailabilityList,
    BollardSchedule,
    BollardScheduleReservation,
    Booking,
    BookingDays,
    BookingPaymentStatus,
    PayloadSubmitNewReservationMultipleDays,
    PayloadSubmitSetReservationObservation,
} from '../../types/bookings';
import { COUNT_HEADER, DATE_FORMAT } from '../../utils/constants';
import { countriesURL } from '../../services/general';
import { bollardsAvailabilityURL } from '../../services/bollards';

interface StateProps {
    createBookingFetching: boolean;
    createBookingErrors: ApiError | null;
    calendarBookingBusinessData: BookingCalendarBusinessProps | null;
    calendarBookingBollardsData: BookingCalendarBollardsProps | null;
}

interface DispatchProps {
    dispatchRequestCreateBooking: Function;
    dispatchRequestBookingCalendarBusiness: (bookingCalendarBusinessProps: BookingCalendarBusinessProps | null) => void;
    dispatchRequestBookingCalendarBollards: (bookingCalendarBollardsProps: BookingCalendarBollardsProps | null) => void;
}

interface OwnProps {
    children: any;
}

type Props = OwnProps & StateProps & DispatchProps;

export class BookingsController extends Component<Props> {
    // Requests
    getCountries = async (): Promise<Country[] | null> => {
        try {
            const { data } = await axios.get(countriesURL());
            return data;
        } catch {
            return null;
        }
    };

    getBooking = async (bookingId: string): Promise<Booking | null> => {
        try {
            const { data } = await axios.get(bookingURL(bookingId));
            return data;
        } catch {
            return null;
        }
    };

    getBookingDays = async (bookingId: string, filters?: KeyedObject): Promise<BookingDays[] | null> => {
        try {
            const { data } = await axios.get(bookingDaysURL(bookingId, filters));
            return data;
        } catch {
            return null;
        }
    };

    getBookings = async (filters?: KeyedObject): Promise<ListResponse<Booking> | null> => {
        try {
            const { data, headers } = await axios.get(bookingsURL(filters));
            return { data, total: headers[COUNT_HEADER] ? Number(headers[COUNT_HEADER]) : 0 };
        } catch {
            return null;
        }
    };

    getSchedules = async (businessId: string, areaId: string, days: string[]): Promise<{ day: string, schedules: BollardSchedule[] }[]> => {
        try {
            const urls = days.map(day => axios.get(schedulesURL(businessId, areaId, day)));
            const responses = await Promise.all(urls);
            return responses.map((res, index) => ({
                schedules: res.data,
                day: days[index],
            }));
        } catch {
            return [];
        }
    };

    getSchedulesNew = async (businessId: string, days: string[]): Promise<{ day: string, schedules: BollardAvailabilityList[] }[]> => {
        try {
            const urls = days.map(day => axios.get(bollardsAvailabilityURL(Number(businessId), day)));
            const responses = await Promise.all(urls);
            return responses.map((res, index) => ({
                schedules: res.data,
                day: days[index],
            }));
        } catch {
            return [];
        }
    };

    // Create booking

    validateNewBooking = (fields: any, registerUser: boolean): KeyedObject | null => {
        const errors: KeyedObject | null = validateForm(fields, registerUser ? validations.bookingCreate : validations.bookingCreateUnregistered);

        if (!errors || Object.keys(errors).length === 0) return null;
        return { fields: errors };
    };

    submitNewBooking = (payload: any, registerUser: boolean, onSuccess: Function, onFailure: Function) => {
        const { dispatchRequestCreateBooking } = this.props;

        dispatchRequestCreateBooking(payload, registerUser, onSuccess, onFailure);
    };

    createNewBooking = async (payload: any, registerUser: boolean) => {
        try {
            await axios.post(registerUser ? createBookingURL() : createBookingUnregisteredURL(), payload);
        } catch {}
    }

    submitNewBookingsReservation = (payload: any, reservations: BollardScheduleReservation[], registerUser: boolean, onSuccess: Function, onFailure: Function) => {
        Promise.all(reservations.map(reservation => {
            const dayPayload = {
                ...payload,
                reservationPeriodId: reservation.periodId,
                bollardId: reservation.bollard.id,
                days: reservation.days.map(day => day.format(DATE_FORMAT)),
            };

            return axios.post(registerUser ? createBookingURL() : createBookingUnregisteredURL(), dayPayload)
                .catch(error => error);
        }))
            .then(response => {
                const errors: Array<AxiosError> = [];
                response.forEach(res => {
                    if (res.isAxiosError) {
                        errors.push((res as AxiosError)?.response?.data);
                    }
                });

                if (errors.length > 0) {
                    onFailure(errors);
                } else {
                    onSuccess();
                }
            });
    };

    submitNewBookingsReservationMultipleDays = async (payload: PayloadSubmitNewReservationMultipleDays, onSuccess: Function, onFailure: Function) => {
        try {
            await axios.post(createBookingMultipleDaysURL(), payload);
            onSuccess();
        } catch (error) {
            onFailure((error as AxiosError)?.response?.data?.errors[0]?.message);
        }
    };

    // Delete Booking
    cancelBooking = async (reservationId: React.Key, onSuccess: () => void, onFailure: (errorCode?: number) => void): Promise<void> => {
        try {
            await axios.put(cancelReservationURL(reservationId));
            onSuccess();
        } catch (error) {
            onFailure((error as AxiosError)?.response?.data?.errors[0]?.errorCode);
        }
    }

    setBookingPaymentStatus = async (id: React.Key, newStatus: BookingPaymentStatus, onSuccess: () => void, onFailure: (errorCode?: number) => void): Promise<void> => {
        try {
            await axios.put(bookingPaymentStatusURL(id, newStatus));
            onSuccess();
        } catch (error) {
            onFailure((error as AxiosError)?.response?.data?.errors[0]?.errorCode);
        }
    }

    setBookingCalendarBollardsData = (data: BookingCalendarBollardsProps | null) => {
        const { dispatchRequestBookingCalendarBollards } = this.props;
        dispatchRequestBookingCalendarBollards(data);
    }

    setBookingCalendarBusinessData = (data: BookingCalendarBusinessProps | null) => {
        const { dispatchRequestBookingCalendarBusiness } = this.props;
        dispatchRequestBookingCalendarBusiness(data);
    }

    setBooking = async (bookingId: string, payload: PayloadSubmitNewReservationMultipleDays, onSuccess: Function, onFailure: Function) => {
        try {
            await axios.put(bookingURL(bookingId), payload);
            onSuccess();
        } catch (error) {
            onFailure((error as AxiosError)?.response?.data?.errors[0]?.message);
        }
    };

    setBookingObservations = async (bookingId: string, payload: PayloadSubmitSetReservationObservation, onSuccess: Function, onFailure: Function) => {
        try {
            await axios.put(bookingObservationsURL(bookingId), payload);
            onSuccess();
        } catch (error) {
            onFailure((error as AxiosError)?.response?.data?.errors[0]?.message);
        }
    };

    render() {
        const {
            children,
            createBookingErrors,
            createBookingFetching,
            calendarBookingBollardsData,
            calendarBookingBusinessData,
        } = this.props;

        return (
            <BookingsContextProvider
                value={{
                    createBookingErrors,
                    createBookingFetching,
                    calendarBookingBollardsData,
                    calendarBookingBusinessData,
                    getCountries: this.getCountries,
                    getBooking: this.getBooking,
                    getBookingDays: this.getBookingDays,
                    getBookings: this.getBookings,
                    getSchedules: this.getSchedules,
                    getSchedulesNew: this.getSchedulesNew,
                    validateNewBooking: this.validateNewBooking,
                    submitNewBooking: this.submitNewBooking,
                    submitNewBookingsReservation: this.submitNewBookingsReservation,
                    submitNewBookingsReservationMultipleDays: this.submitNewBookingsReservationMultipleDays,
                    cancelBooking: this.cancelBooking,
                    setBookingPaymentStatus: this.setBookingPaymentStatus,
                    setBookingCalendarBollardsData: this.setBookingCalendarBollardsData,
                    setBookingCalendarBusinessData: this.setBookingCalendarBusinessData,
                    setBooking: this.setBooking,
                    setBookingObservations: this.setBookingObservations,
                }}
            >
                {children}
            </BookingsContextProvider>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => {
    return {
        createBookingFetching: state.bookings.createBookingRequest.isFetching,
        createBookingErrors: state.bookings.createBookingRequest.errors,
        calendarBookingBusinessData: state.bookings.calendarBookingBusinessData,
        calendarBookingBollardsData: state.bookings.calendarBookingBollardsData,
    };
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => ({
    dispatchRequestCreateBooking: (payload: FormData, registerUser: boolean, onSuccess: Function, onFailure: Function) => dispatch(requestCreateBooking(payload, registerUser, onSuccess, onFailure)),
    dispatchRequestBookingCalendarBusiness: (bookingCalendarBusinessProps: BookingCalendarBusinessProps | null) => dispatch(setBookingCalendarBusinessActionCreator(bookingCalendarBusinessProps)),
    dispatchRequestBookingCalendarBollards: (bookingCalendarProps: BookingCalendarBollardsProps | null) => dispatch(setBookingCalendarBollardsActionCreator(bookingCalendarProps)),
});

export const ConnectedBookingsController = connect(mapStateToProps, mapDispatchToProps)(BookingsController);
