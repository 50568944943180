/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './components/Router';
import { configureStore } from './store';
import { setupAPI } from './api';

const { persistor, store } = configureStore();

interface OwnProps {}
interface OwnState {
    isReady: boolean;
}

const initialState = {
    isReady: false,
};

class App extends Component<OwnProps, OwnState> {
    state = initialState;

    async componentDidMount() {
        await setupAPI(store);
        this.setState({ isReady: true });
    }

    render() {
        const { isReady } = this.state;

        if (!isReady) return null;

        return (
            <div className="app">
                <PersistGate persistor={persistor}>
                    <Provider store={store}>
                        <BrowserRouter>
                            <AppRouter />
                        </BrowserRouter>
                    </Provider>
                </PersistGate>
            </div>
        );
    }
}

export default App;
