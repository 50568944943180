/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { ChangeEvent, Component, ReactNode } from 'react';
import {
    InputLabel,
    FormControl,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    FormHelperText,
    InputAdornment,
    IconButton,
} from '@material-ui/core';
import IconAdd from '@material-ui/icons/Add';
import { KeyboardArrowDown } from '@material-ui/icons';
import { uniqueId, isNil, isEmpty } from 'lodash';

import ErrorMessage from './ErrorMessage';
import { KeyedObject } from '../../types/general';
import { FormValidatorError, getErrorsForField, hasAnyErrors } from '../../utils/validations';

interface OwnProps {
    id?: string;
    name: string;
    label?: string;
    placeholder?: string;
    value?: any;
    inputProps?: object;
    required?: boolean;
    disabled?: boolean;
    options: Array<KeyedObject>;
    multiple?: boolean;
    onChange?: Function;
    errors: KeyedObject | null;
    onAdd?: Function;
    testId?: string;
}

interface OwnState {}

const initialState: OwnState = {};

class FormSelectField extends Component<OwnProps, OwnState> {
    state = initialState;

    onClick = (): void => {
        const { onAdd } = this.props;
        if (onAdd) onAdd();
    };

    onChange = (event: ChangeEvent<{ value: unknown }>): void => {
        const { name, onChange, multiple } = this.props;

        if (onChange) {
            if (multiple) onChange(name, event.target.value);
            else onChange(name, event.target.value as string);
        }
    };

    getSelectedLabels = (selected: any): string => {
        const { options, placeholder } = this.props;

        const labels: Array<string> = [];
        if (selected) {
            selected.forEach((sel: any) => {
                if (options) {
                    const optInValue = options.find(o => o.value === sel);
                    if (optInValue) {
                        labels.push(optInValue.label);
                    }
                }
            });
        }
        return labels.length ? labels.join(', ') : placeholder || '';
    };

    getSelectedLabel = (selected: any): string => {
        const { options, placeholder } = this.props;

        let optInValue = null;

        if (Array.isArray(selected)) {
            optInValue = options.find(o => {
                if (Array.isArray(o.value)) {
                    return selected.reduce((match, current) => {
                        if (match) {
                            return o.value.includes(current);
                        }
                        return false;
                    });
                }
                return false;
            });
        } else {
            optInValue = options.find(o => o.value === selected);
        }

        if (!optInValue || selected === '') return placeholder || '';

        return optInValue.label || '';
    };

    renderEndAdornment(): ReactNode {
        const { onAdd } = this.props;

        if (onAdd) {
            return (
                <InputAdornment position="end">
                    <IconButton onClick={this.onClick}>
                        <IconAdd />
                    </IconButton>
                </InputAdornment>
            );
        }

        return null;
    }

    renderErrors(fieldErrors: FormValidatorError[]): ReactNode {
        const { name } = this.props;

        return <ErrorMessage errors={fieldErrors} field={name} />;
    }

    render() {
        const {
            id,
            name,
            value,
            errors,
            label,
            inputProps,
            required,
            disabled,
            options,
            multiple,
            onAdd,
            testId,
        } = this.props;
        
        const fieldId = id || uniqueId();
        const fieldErrors = getErrorsForField(name, errors);
        const hasErrors = hasAnyErrors(fieldErrors);
        const hasValue = !isNil(value) && !isEmpty(`${value}`);

        return (
            <FormControl
                fullWidth
                error={hasErrors}
                className={`input-container ${!hasValue ? 'is-empty' : ''} ${isNil(onAdd) ? '' : 'with-add-button'}`}
                required={required}
                disabled={disabled}
            >
                {label && <InputLabel htmlFor={fieldId}>{label}</InputLabel>}
                <Select
                    id={fieldId}
                    name={name}
                    value={value || ''}
                    onChange={this.onChange}
                    inputProps={{
                        ...inputProps,
                        id: fieldId,
                        name,
                        'data-testid': `${testId}-input`,
                    }}
                    renderValue={selected => {
                        if (multiple) {
                            return this.getSelectedLabels(selected);
                        }
                        return this.getSelectedLabel(selected);
                    }}
                    multiple={multiple}
                    displayEmpty
                    endAdornment={this.renderEndAdornment()}
                    data-testid={testId}
                    IconComponent={props => <KeyboardArrowDown {...props} />}
                >
                    {options
                        && options.map(({ value: optValue, label: optLabel }) => {
                            if (multiple) {
                                let isChecked = false;
                                if (value) {
                                    const optInValue = value.find((v: any) => v === optValue);
                                    isChecked = !!optInValue;
                                }
                                return (
                                    <MenuItem key={optValue} value={optValue}>
                                        <Checkbox checked={isChecked} color="primary" />
                                        <ListItemText primary={optLabel} />
                                    </MenuItem>
                                );
                            }
                            return (
                                <MenuItem key={optValue} value={optValue}>
                                    {optLabel}
                                </MenuItem>
                            );
                        })}
                </Select>
                <FormHelperText>{this.renderErrors(fieldErrors)}</FormHelperText>
            </FormControl>
        );
    }
}

export default FormSelectField;
