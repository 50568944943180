/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

export const mapCenter = { lat: 38.736946, lng: -9.142685 };

export const DATE_FORMAT = 'yyyy-MM-DD';
export const TIME_FORMAT = 'HH:mm:ss';

export const COUNT_HEADER = 'x-total-count';

export enum ReservationErrorCode {
    ALREADY_RESERVED = 38
}
