/*
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { SelectOption } from '../../types/general';
import FormSelectField from './FormSelectField';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { Language } from '../../types/preferences';
import { PreferencesContext, withPreferencesContext } from '../controllers/PreferencesContext';

interface OwnProps extends TranslationContext, PreferencesContext {}

interface OwnState {}

const initialState: OwnState = {};

class FormSelectFieldLanguage extends Component<OwnProps, OwnState> {
    state = initialState;

    onSelectLanguage = (_: string, value: string): void => {
        const { changeLanguage } = this.props;
        changeLanguage(value as Language);
    };

    render() {
        const {
            t,
            language,
        } = this.props;

        const languages: SelectOption[] = [
            { label: t(`drawer.${Language.EN}`), value: Language.EN },
            { label: t(`drawer.${Language.PT}`), value: Language.PT },
        ];

        return (
            <div className="language-overlay">
                <FormSelectField
                    name="language"
                    onChange={this.onSelectLanguage}
                    options={languages}
                    value={language}
                    errors={null}
                    label={t('drawer.languageLabel')}
                    testId="language-select"
                />
            </div>
        );
    }
}

export default withTranslationContext(withPreferencesContext(FormSelectFieldLanguage));
