/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */
import React, { useEffect } from 'react';
import {
    Redirect, Route, Switch, useHistory, useLocation,
} from 'react-router-dom';
import ReactNotification from 'react-notifications-component';

import ReactGA from 'react-ga4';

import LoginScreen from './screens/LoginScreen';
import RegisterScreen from './screens/RegisterScreen';
import RecoverScreen from './screens/RecoverScreen';
import MapScreen from './screens/MapScreen';
import ProfileScreen from './screens/ProfileScreen';
import ResetScreen from './screens/ResetScreen';
import AreasScreen from './screens/AreasScreen';
import AddAreaScreen from './screens/AddAreaScreen';
import EditAreaScreen from './screens/EditAreaScreen';
import BusinessesScreen from './screens/BusinessesScreen';
import AddBusinessScreen from './screens/AddBusinessScreen';
import EditBusinessScreen from './screens/EditBusinessScreen';
import BookingsScreen from './screens/BookingsScreen';
import AddBookingScreen from './screens/AddBookingScreen';
import BookingDetailsScreen from './screens/BookingDetailsScreen';
import ActivateScreen from './screens/ActivateScreen';
import { AppRoute } from '../types/routes';
import AuthenticatedRoute from './containers/AuthenticatedRoute';
import Drawer from './views/Drawer';
import NavBar from './views/NavBar';

import { AuthenticationContext, withAuthenticationContext } from './controllers/AuthenticationContext';
import BookingsCalendarScreen from './screens/BookingsCalendarScreen';
import ClientsScreen from './screens/ClientsScreen';
import MerchantsScreen from './screens/MerchantsScreen';
import { Permission } from '../types/authorization';
import EditMerchantScreen from './screens/EditMerchantScreen';
import ReservationManagersScreen from './screens/ReservationManagersScreen';
import ReservationManagerDetailsScreen from './screens/ReservationManagerDetailsScreen';
import FormSelectFieldLanguage from './elements/FormSelectFieldLanguage';
import { UsersRoles } from '../types/users';
import { GOOGLE_ANAlYTICS_TRACKING_ID } from '../settings';
import EditBookingScreen from './screens/EditBookingScreen';

type Props = AuthenticationContext;

export default withAuthenticationContext((props: Props) => {
    const { token, isAuthenticated, authenticatedUser } = props;
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (token) {
            switch (history.location.pathname) {
                case AppRoute.Login:
                case AppRoute.Register:
                case AppRoute.Activate:
                case AppRoute.Recover:
                case AppRoute.Reset:
                    if (authenticatedUser?.role === UsersRoles.ReservationManager) return history.push(AppRoute.Bookings);
                    history.push(AppRoute.Map);
                    break;
                default:
                    break;
            }
        }
    }, [token, history, authenticatedUser]);

    useEffect(() => {
        ReactGA.initialize(String(GOOGLE_ANAlYTICS_TRACKING_ID));
    }, []);

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: location.pathname, title: window.location.href + location.search });
    }, [location]);

    return (
        <div className="app-router">
            {isAuthenticated ? <Drawer /> : <FormSelectFieldLanguage />}
            <ReactNotification />
            <div className="app-container">
                <NavBar />
                <Switch>
                    <Route exact path={AppRoute.Login} component={LoginScreen} />
                    <Route exact path={[AppRoute.Register, AppRoute.RegisterInvite]} component={RegisterScreen} />
                    <Route exact path={AppRoute.Recover} component={RecoverScreen} />
                    <Route exact path={AppRoute.Reset} component={ResetScreen} />
                    <Route exact path={AppRoute.Activate} component={ActivateScreen} />
                    <Switch>
                        <AuthenticatedRoute
                            exact
                            path={AppRoute.Map}
                            component={MapScreen}
                            permissions={[Permission.BUSINESS_VIEW_LIST]}
                        />
                        <AuthenticatedRoute exact path={AppRoute.Profile} component={ProfileScreen} />
                        <AuthenticatedRoute
                            exact
                            path={AppRoute.Areas}
                            component={AreasScreen}
                            permissions={[Permission.AREA_VIEW_LIST]}
                        />
                        <AuthenticatedRoute
                            exact
                            path={AppRoute.Clients}
                            component={ClientsScreen}
                            permissions={[Permission.CLIENTS_VIEW_LIST]}
                        />
                        <AuthenticatedRoute
                            exact
                            path={AppRoute.Merchants}
                            component={MerchantsScreen}
                            permissions={[Permission.MERCHANT_LIST_VIEW]}
                        />
                        <AuthenticatedRoute
                            exact
                            path={AppRoute.EditMerchant}
                            component={EditMerchantScreen}
                            permissions={[Permission.MERCHANT_EDIT]}
                        />
                        <AuthenticatedRoute
                            exact
                            path={AppRoute.AddArea}
                            component={AddAreaScreen}
                            permissions={[Permission.AREA_CREATE]}
                        />
                        <AuthenticatedRoute
                            exact
                            path={AppRoute.EditArea}
                            component={EditAreaScreen}
                            permissions={[Permission.AREA_EDIT]}
                        />
                        <AuthenticatedRoute
                            exact
                            path={AppRoute.Bookings}
                            component={BookingsScreen}
                            permissions={[Permission.BOOKING_VIEW_LIST]}
                        />
                        <AuthenticatedRoute
                            exact
                            path={AppRoute.AddBooking}
                            component={AddBookingScreen}
                            permissions={[Permission.BOOKING_CREATE]}
                        />
                        <AuthenticatedRoute
                            exact
                            path={AppRoute.EditBooking}
                            component={EditBookingScreen}
                            permissions={[Permission.BOOKING_EDIT]}
                        />
                        <AuthenticatedRoute
                            exact
                            path={AppRoute.BookingDetails}
                            component={BookingDetailsScreen}
                            permissions={[Permission.BOOKING_VIEW_DETAILS]}
                        />
                        <AuthenticatedRoute
                            exact
                            path={AppRoute.Businesses}
                            component={BusinessesScreen}
                            permissions={[Permission.BUSINESS_VIEW_LIST]}
                        />
                        <AuthenticatedRoute
                            exact
                            path={AppRoute.AddBusiness}
                            component={AddBusinessScreen}
                            permissions={[Permission.BUSINESS_CREATE]}
                        />
                        <AuthenticatedRoute
                            exact
                            path={AppRoute.EditBusiness}
                            component={EditBusinessScreen}
                            permissions={[Permission.BUSINESS_EDIT]}
                        />
                        <AuthenticatedRoute
                            exact
                            path={AppRoute.BookingsCalendar}
                            component={BookingsCalendarScreen}
                            permissions={[Permission.BOOKING_VIEW_CALENDAR]}
                        />
                        <AuthenticatedRoute
                            exact
                            path={AppRoute.ReservationManagers}
                            component={ReservationManagersScreen}
                            permissions={[Permission.RESERVATION_MANAGERS_LIST_SCREEN]}
                        />
                        <AuthenticatedRoute
                            exact
                            path={AppRoute.ReservationManagerDetails}
                            component={ReservationManagerDetailsScreen}
                            permissions={[Permission.RESERVATION_MANAGERS_LIST_SCREEN]}
                        />
                        {authenticatedUser?.role === UsersRoles.ReservationManager
                            ? <Redirect to={AppRoute.Bookings} />
                            : <Redirect to={AppRoute.Map} />
                        }
                    </Switch>
                    <Redirect to={AppRoute.Login} />
                </Switch>
            </div>
        </div>
    );
});
