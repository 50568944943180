/**
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */

import React, {
    FunctionComponent, ReactElement,
} from 'react';
import { Close } from '@material-ui/icons';

import {
    Button, Dialog, DialogContent, IconButton,
} from '@material-ui/core';
import { GenericFunction } from '../../utils/misc';

interface OwnProps {
    show: boolean;
    title?: string;
    children?: ReactElement;
    onClose: GenericFunction;
    onConfirm: GenericFunction;
    cancelButton?: string;
    okButton?: string;
    okButtonClass?: string;
    isLarge?: boolean;
}

type Props = OwnProps;

const ConfirmModal: FunctionComponent<Props> = (props: Props) => {
    const {
        show,
        title,
        children,
        onClose,
        okButton,
        onConfirm,
        cancelButton,
        okButtonClass,
        isLarge,
    } = props;

    return (
        <Dialog
            open={show}
            onClose={() => onClose()}
            className="confirm"
            data-testid="confirm-dialog"
        >
            <DialogContent className={`confirm__content ${isLarge ? 'large' : ''}`}>
                <div className="confirm__content__top">
                    <div className="confirm__content__top__title">
                        {title}
                    </div>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        data-testid="confirm-dialog-close-btn"
                    >
                        <Close color="primary" />
                    </IconButton>
                </div>
                <div className="confirm__content__bottom">
                    <div className="confirm__content__bottom__message">
                        {children}
                    </div>
                    <div className="confirm__content__bottom__buttons">
                        {cancelButton && (
                            <Button
                                data-testid="confirm-cancel-button"
                                onClick={() => onClose()}
                            >
                                {cancelButton}
                            </Button>
                        )}
                        {okButton && (
                            <Button
                                data-testid="confirm-ok-button"
                                className={okButtonClass}
                                variant="contained"
                                autoFocus
                                onClick={() => onConfirm()}
                                disableElevation
                            >
                                {okButton}
                            </Button>
                        )}
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default ConfirmModal;
