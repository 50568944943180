/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';

import { ConnectedAreasController } from './AreasController';
import { GeoPoint, KeyedObject, ListResponse } from '../../types/general';
import { ApiError } from '../../types/errors';
import { Bollard } from '../../types/bollards';
import { Area, AreaPredictBollardsResponse } from '../../types/areas';

export interface AreasContext {
    createAreaFetching: boolean;
    createAreaErrors: ApiError | null;
    updateAreaFetching: boolean;
    updateAreaErrors: ApiError | null;
    getArea(businessId: number, areaId: number): Promise<Area | null>;
    getAreas(filters?: KeyedObject, businessId?: string): Promise<ListResponse<Area> | null>;
    getBusinessAreas(businessId: string, filters?: KeyedObject): Promise<ListResponse<Area> | null>;
    getBollards(businessId: string | number, areaId: string | number, filters?: KeyedObject): Promise<ListResponse<Bollard> | null>;
    getQrCodesPdf(businessId: string, areaId: string): Promise<Blob | null>;
    predictArea(
        businessId: number,
        areaId: string | undefined,
        forceUpdate: boolean,
        bollardRadius: number,
        vertices: GeoPoint[]
    ): Promise<AreaPredictBollardsResponse | null>;
    validatePredictArea(fields: any): KeyedObject | null;
    validateNewArea(fields: any): KeyedObject | null;
    validateEditArea(fields: any): KeyedObject | null;
    submitNewArea(businessId: number, payload: FormData, onSuccess: Function, onFailure: Function): void;
    submitEditArea(
        businessId: number,
        areaId: number,
        payload: FormData,
        onSuccess: Function,
        onFailure: Function
    ): void;
    updateBollard(businessId: string, areaId: string, bollard: Bollard, newBollard: Partial<Bollard>): Promise<any>;
}

export const areasContextDefaultValue = {
    createAreaFetching: false,
    createAreaErrors: null,
    updateAreaFetching: false,
    updateAreaErrors: null,
    getArea: async () => null,
    getAreas: async () => null,
    getBusinessAreas: async () => null,
    getBollards: async () => null,
    getQrCodesPdf: async () => null,
    predictArea: async () => null,
    validatePredictArea: () => null,
    validateNewArea: () => null,
    validateEditArea: () => null,
    submitNewArea: () => {},
    submitEditArea: () => {},
    updateBollard: async () => null,
};

const AreasContextInstance = createContext<AreasContext | null>(areasContextDefaultValue);

export const AreasContextProvider = AreasContextInstance.Provider;
export const AreasContextConsumer = AreasContextInstance.Consumer;

export const withAreasContext = <P extends object>(Component: ComponentType<P>): FC<Omit<P, keyof AreasContext>> => props => (
    <ConnectedAreasController>
        <AreasContextConsumer>{ctx => <Component {...(props as P)} {...ctx} />}</AreasContextConsumer>
    </ConnectedAreasController>
);
