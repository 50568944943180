/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import OptionsIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ActivateIcon from '@material-ui/icons/Done';
import BlockIcon from '@material-ui/icons/Block';
import EditIcon from '@material-ui/icons/Edit';
import { throttle } from 'lodash';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Select } from '@material-ui/core';

import FormTextField from '../elements/FormTextField';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { MerchantsContext, withMerchantsContext } from '../controllers/MerchantsContext';
import { Order, OrderQuery, QueryParams } from '../../types/general';
import { AppRoute } from '../../types/routes';
import { buildRoute } from '../../utils/misc';
import { displayNotification, NotificationType } from '../../utils/notifications';
import { handleFormSubmitFailure } from '../../utils/validations';
import { Merchant, UserStatus, MerchantToggleStatusParam } from '../../types/users';
import Loader from '../elements/Loader';
import NewInvitationModal from '../elements/NewInvitationModal';
import { InvitationType, ListViewOptions } from '../../types/invitations';
import { Permission } from '../../types/authorization';
import Can from '../containers/Can';
import InvitationsTable from '../elements/InvitationsTable';

interface OwnProps extends TranslationContext, MerchantsContext {}

interface OwnState {
    anchorElement: any;
    order: Order;
    merchants: Merchant[];
    totalMerchants: number;
    params: QueryParams;
    currentMerchant: Merchant | null;
    statusModal: boolean;
    isFetching: boolean;
    isInvitationModalOpen: boolean;
    viewSelected: ListViewOptions;
    updateList: boolean;
}

const initialState: OwnState = {
    anchorElement: null,
    order: Order.Ascending,
    merchants: [],
    totalMerchants: 0,
    params: {
        _limit: 20,
        _page: 0,
        _order: OrderQuery.Ascending,
        _q: '',
        _sort: 'name',
    },
    currentMerchant: null,
    statusModal: false,
    isFetching: true,
    viewSelected: ListViewOptions.ActualUsers,
    isInvitationModalOpen: false,
    updateList: false,
};

let throttledFetchMerchants = () => {};

class MerchantsScreen extends Component<OwnProps, OwnState> {
    constructor(props: OwnProps) {
        super(props);
        this.state = {
            ...initialState,
        };
        throttledFetchMerchants = throttle(this.fetchMerchants, 500);
    }

    fetchMerchants = () => {
        const { getMerchants } = this.props;
        const { params } = this.state;
        getMerchants(params).then((merchantsData) => {
            if (merchantsData) {
                this.setState({
                    merchants: merchantsData.data,
                    totalMerchants: merchantsData.total,
                });
            }
            this.setState({ isFetching: false });
        });
    };

    componentDidMount() {
        this.fetchMerchants();
    }

    onParamsChange = (name: string, value: string, throttle: boolean = false): void => {
        this.setState(
            {
                params: {
                    ...this.state.params,
                    _page: throttle ? 0 : this.state.params._page,
                    [name]: value,
                },
            },
            () => (throttle ? throttledFetchMerchants() : this.fetchMerchants())
        );
    };

    setToggleStatus = () => {
        this.setState({
            statusModal: true,
            anchorElement: null,
        });
    };

    toggleStatus = () => {
        const { toggleMerchantStatus } = this.props;
        const { currentMerchant } = this.state;

        if (!currentMerchant) return;

        const newStatusParam = currentMerchant.activationStatus === UserStatus.Active ? MerchantToggleStatusParam.Deactivate : MerchantToggleStatusParam.Activate;

        toggleMerchantStatus(
            currentMerchant.id,
            newStatusParam,
            () => this.handleToggleSuccess(newStatusParam),
            this.handleToggleFailure,
        );
    };

    handleToggleSuccess = (newStatus: MerchantToggleStatusParam) => {
        const { t } = this.props;

        this.fetchMerchants();
        displayNotification({
            message: newStatus === MerchantToggleStatusParam.Deactivate ? t('merchantsScreen.successDisable') : t('merchantsScreen.successEnable'),
            type: NotificationType.Success,
        });
    }

    handleToggleFailure = () => {
        const { t, createMerchantErrors, updateMerchantErrors } = this.props;

        handleFormSubmitFailure(t, createMerchantErrors, updateMerchantErrors);
    }

    activateMenu = (evt: React.MouseEvent<SVGSVGElement>, merchant: Merchant) => {
        evt.stopPropagation();
        this.setState({
            anchorElement: evt.currentTarget,
            currentMerchant: merchant,
        });
    };

    resetMenu = () => {
        this.setState({
            anchorElement: null,
            currentMerchant: null,
        });
    };

    onChangePage = (page: number) => {
        const { params } = this.state;

        this.setState({
            params: {
                ...params,
                _page: page,
            },
        }, this.fetchMerchants);
    };

    onChangeRowsPerPage = (evt: any) => {
        this.setState(
            {
                params: { ...this.state.params, _limit: evt.target.value },
            },
            this.fetchMerchants
        );
    };

    selectSort = (col: string) => {
        const { order, params } = this.state;

        const newOrder = params._sort !== col ? Order.Ascending : order === Order.Ascending ? Order.Descending : Order.Ascending;
        const newOrderParam = newOrder === Order.Ascending ? OrderQuery.Ascending : OrderQuery.Descending;

        this.setState({
            order: newOrder,
            params: {
                ...params,
                _sort: col,
                _order: newOrderParam,
            },
        }, this.fetchMerchants);
    };

    renderTableHeadCell(name: string, label?: string): ReactElement {
        const { order, params } = this.state;
        const { t } = this.props;

        return (
            <TableCell align="center">
                <TableSortLabel
                    active={params._sort === name}
                    direction={params._sort === name ? order : Order.Ascending}
                    onClick={() => this.selectSort(name)}
                >
                    {t(`merchantsScreen.${label ? label : name}`)}
                </TableSortLabel>
            </TableCell>
        );
    }

    renderTable(): ReactElement {
        const { t } = this.props;
        const {
            merchants, anchorElement, currentMerchant, params, totalMerchants,
        } = this.state;

        return (
            <Paper>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {this.renderTableHeadCell('name')}
                                {this.renderTableHeadCell('numberOfBusinesses', 'numberOfBusinesses')}
                                {this.renderTableHeadCell('email')}
                                {this.renderTableHeadCell('phoneNumber')}
                                {this.renderTableHeadCell('status')}
                                <TableCell align="center">{t('merchantsScreen.options')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {merchants.map((merchant) => {
                                return (
                                    <TableRow
                                        key={merchant.id}
                                        className={
                                            merchant.activationStatus === UserStatus.Active
                                                ? 'table-row--active'
                                                : 'table-row--disabled'
                                        }
                                    >
                                        <TableCell
                                            align="center">{`${merchant.firstName} ${merchant.lastName}`}</TableCell>
                                        <TableCell align="center">{merchant.numberOfBusinesses}</TableCell>
                                        <TableCell align="center">{merchant.email}</TableCell>
                                        <TableCell align="center">{merchant.phoneNumber}</TableCell>
                                        <TableCell align="center">{t(`status.${merchant.activationStatus}`)}</TableCell>
                                        <TableCell align="center">
                                            <OptionsIcon
                                                className="options-icon"
                                                data-testid="options-btn"
                                                onClick={(evt) => this.activateMenu(evt, merchant)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20]}
                    component="div"
                    count={totalMerchants}
                    rowsPerPage={params._limit}
                    page={params._page}
                    onPageChange={(_, page) => this.onChangePage(page)}
                    onRowsPerPageChange={this.onChangeRowsPerPage}
                />
                <Menu
                    className="business-menu-items"
                    anchorEl={anchorElement}
                    keepMounted
                    open={!!(anchorElement)}
                    onClose={this.resetMenu}
                >
                    {currentMerchant && currentMerchant.activationStatus !== UserStatus.NotValidated && (
                        <MenuItem onClick={() => this.setToggleStatus()}>
                            {currentMerchant.activationStatus === UserStatus.Active ? (
                                <>
                                    <BlockIcon />
                                    {t('merchantsScreen.deactivate')}
                                </>
                            ) : (
                                <>
                                    <ActivateIcon />
                                    {t('merchantsScreen.activate')}
                                </>
                            )}
                        </MenuItem>
                    )}
                    <Link
                        to={buildRoute(AppRoute.EditMerchant, { merchantId: currentMerchant?.id })}
                    >
                        <MenuItem>
                            <EditIcon />
                            {t('merchantsScreen.edit')}
                        </MenuItem>
                    </Link>
                </Menu>
            </Paper>
        );
    }

    renderStatusModal(): ReactElement {
        const { t } = this.props;
        const { statusModal, currentMerchant } = this.state;
        return (
            <Dialog
                open={statusModal}
                onClose={() => this.setState({ statusModal: false })}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {currentMerchant?.activationStatus === UserStatus.Active
                        ? t('merchantsScreen.disableTitle')
                        : t('merchantsScreen.enableTitle')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {currentMerchant?.activationStatus === UserStatus.Active
                            ? t('merchantsScreen.disableText')
                            : t('merchantsScreen.enableText')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.setState({ statusModal: false })} color="primary">
                        {t('general.no')}
                    </Button>
                    <Button
                        onClick={() => {
                            this.toggleStatus();
                            this.setState({ statusModal: false });
                        }}
                        color="primary"
                        autoFocus
                    >
                        {t('general.yes')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderViewSelector(): ReactElement {
        const { viewSelected } = this.state;
        const { t } = this.props;
        return (
            <Select
                MenuProps={{ classes: { paper: 'select' } }}
                value={viewSelected}
                onChange={(event) => this.setState({ viewSelected: event.target.value as ListViewOptions })}
                data-testid="view-select_input"
                className="merchants-page__filters__view-select"
            >
                <MenuItem value={ListViewOptions.ActualUsers}>{t('merchantsScreen.actualMerchants')}</MenuItem>
                <MenuItem value={ListViewOptions.Invitations}>{t('merchantsScreen.invitations')}</MenuItem>
            </Select>
        );
    }

    isMerchantsInvitationSelected(): boolean {
        const { viewSelected } = this.state;
        return viewSelected === ListViewOptions.Invitations;
    }

    onNewInvitationClick = () => {
        this.setState({ isInvitationModalOpen: true });
    }

    handleInvitationModalClose = () => {
        this.setState(prevState => ({
            isInvitationModalOpen: false,
            updateList: !prevState.updateList,
        }));
    };

    render() {
        const {
            params, isFetching, isInvitationModalOpen, updateList,
        } = this.state;
        const { t } = this.props;

        if (isFetching) {
            return <Loader />;
        }

        return (
            <div className="merchants-page">
                {isInvitationModalOpen && (
                    <NewInvitationModal
                        onClose={() => this.handleInvitationModalClose()}
                        invitationFor={InvitationType.Merchant}
                    />
                )}
                <div className="merchants-page__header">
                    <h2>{t('merchantsScreen.title')}</h2>
                    <div className="merchants-page__header__button-wrapper">
                        <Can
                            actions={[Permission.INVITE_MERCHANT]}
                            yes={() => (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    data-testid="new-invitation-button"
                                    onClick={this.onNewInvitationClick}
                                >
                                    {t('merchantsScreen.sendInvite')}
                                </Button>
                            )}
                        />
                    </div>
                </div>
                <div className="merchants-page__filters">
                    <Can
                        actions={[Permission.MERCHANT_INVITATION_LIST_VIEW]}
                        yes={() => this.renderViewSelector()}
                    />
                    <div />
                    {!this.isMerchantsInvitationSelected() && (
                        <FormTextField
                            name="_q"
                            value={params._q || ''}
                            onChange={(name: string, value: string) => this.onParamsChange(name, value, true)}
                            placeholder={t('merchantsScreen.search')}
                            label={t('merchantsScreen.search')}
                            errors={null}
                            icon={<SearchIcon />}
                        />
                    )}
                </div>
                {!this.isMerchantsInvitationSelected() && this.renderTable()}
                {this.renderStatusModal()}
                {this.isMerchantsInvitationSelected() &&
                    <InvitationsTable
                        updateList={updateList}
                        invitationsFor={InvitationType.Merchant}
                    />
                }
            </div>
        );
    }
}

export default withMerchantsContext(withTranslationContext(MerchantsScreen));
