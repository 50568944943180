/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import axios, { AxiosError } from 'axios';
import { AuthenticationAction, AuthenticationActionTypes } from './authentication_types';
import {
    LoginRequestPayload,
    LoginResponsePayload,
    RegisterResponsePayload,
    ForgotPassRequestPayload,
    ResetPassRequestPayload,
    ProfileResponsePayload,
} from '../types/authentication';
import {
    loginURL, forgotPassURL, resetPassURL, logoutURL,
} from '../services/auth';
import { registerURL, editProfileURL } from '../services/merchants';
import { KeyedObject } from '../types/general';
import { UsersRoles } from '../types/users';
import { Permission } from '../types/authorization';
import { groupedPermissions } from '../constants/permissions';
import { setPermissionsActionCreator } from './authorization';

// Login

export const loginRequestActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.LOGIN_REQUEST,
    };
};

export const loginSuccessActionCreator = (payload: LoginResponsePayload): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.LOGIN_SUCCESS,
        payload,
    };
};

export const loginFailureActionCreator = (error: KeyedObject | null): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.LOGIN_FAILURE,
        payload: error,
    };
};

export const requestLogin = (
    payload: LoginRequestPayload,
    onSuccess: () => void,
    onFailure: () => void,
) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(loginRequestActionCreator());
        try {
            const { data, headers } = await axios.post(loginURL(), payload);
            if (data.role === UsersRoles.User) {
                dispatch(loginFailureActionCreator({
                    errors: [{
                        errorCode: 7,
                    }],
                }));
                onFailure();
                return;
            }

            const authorizationToken = headers.authorization;

            dispatch(
                loginSuccessActionCreator({
                    user: data,
                    token: authorizationToken,
                }),
            );

            let permissions: Array<Permission> = [];

            switch (data.role) {
                case UsersRoles.Admin:
                    permissions = groupedPermissions.Admin;
                    break;
                case UsersRoles.Merchant:
                    permissions = groupedPermissions.Merchant;
                    break;
                case UsersRoles.ReservationManager:
                    permissions = groupedPermissions.ReservationManager;
                    break;
                default:
            }

            dispatch(setPermissionsActionCreator(permissions));

            onSuccess();
        } catch (error) {
            let formErrors = {};
            if (error) {
                formErrors = (error as AxiosError).response?.data;
            }
            dispatch(loginFailureActionCreator(formErrors));
            onFailure();
        }
    };
};

// Registration

export const registrationRequestActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.REGISTRATION_REQUEST,
    };
};

export const registrationSuccessActionCreator = (payload: RegisterResponsePayload): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.REGISTRATION_SUCCESS,
        payload,
    };
};

export const registrationFailureActionCreator = (error: KeyedObject | null): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.REGISTRATION_FAILURE,
        payload: error,
    };
};

export const requestRegistration = (payload: FormData, onSuccess: () => void, onFailure: () => void) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(registrationRequestActionCreator());
        try {
            const { status } = await axios.post(registerURL(), payload);

            if (status === 200) {
                dispatch(registrationSuccessActionCreator({}));
                onSuccess();
            }
        } catch (error) {
            let formErrors = {};
            if (error) {
                formErrors = (error as AxiosError).response?.data;
            }
            dispatch(registrationFailureActionCreator(formErrors));
            onFailure();
        }
    };
};

// Forgot Pass

export const forgotPassRequestActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.FORGOT_PASS_REQUEST,
    };
};

export const forgotPassSuccessActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.FORGOT_PASS_SUCCESS,
    };
};

export const forgotPassFailureActionCreator = (error: KeyedObject | null): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.FORGOT_PASS_FAILURE,
        payload: error,
    };
};

export const requestForgotPass = (
    payload: ForgotPassRequestPayload,
    onSuccess?: Function,
    onFailure?: Function,
) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(forgotPassRequestActionCreator());
        try {
            const { status } = await axios.post(forgotPassURL(), payload);

            if (status === 204) {
                dispatch(forgotPassSuccessActionCreator());
                if (onSuccess) onSuccess();
            }
        } catch (error) {
            let formErrors = {};
            if (error) {
                formErrors = (error as AxiosError).response?.data;
            }
            dispatch(forgotPassFailureActionCreator(formErrors));
            if (onFailure) onFailure();
        }
    };
};

// Reset Pass

export const resetPassRequestActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.RESET_PASS_REQUEST,
    };
};

export const resetPassSuccessActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.RESET_PASS_SUCCESS,
    };
};

export const resetPassFailureActionCreator = (error: KeyedObject | null): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.RESET_PASS_FAILURE,
        payload: error,
    };
};

export const requestResetPass = (
    payload: ResetPassRequestPayload,
    onSuccess?: Function,
    onFailure?: Function,
) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(resetPassRequestActionCreator());
        try {
            const { status } = await axios.post(resetPassURL(), payload);

            if (status === 200) {
                dispatch(resetPassSuccessActionCreator());
                if (onSuccess) onSuccess();
            }
        } catch (error) {
            let formErrors = {};
            if (error) {
                formErrors = (error as AxiosError).response?.data;
            }
            dispatch(resetPassFailureActionCreator(formErrors));
            if (onFailure) onFailure();
        }
    };
};

// Profile

export const profileChangeRequestActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.PROFILE_CHANGE_REQUEST,
    };
};

export const profileChangeSuccessActionCreator = (payload: ProfileResponsePayload): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.PROFILE_CHANGE_SUCCESS,
        payload,
    };
};

export const profileChangeFailureActionCreator = (error: KeyedObject | null): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.PROFILE_CHANGE_FAILURE,
        payload: error,
    };
};

export const requestProfileChange = (
    id: string,
    payload: FormData,
    onSuccess: () => void,
    onFailure: () => void,
) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(profileChangeRequestActionCreator());
        try {
            const { status, data } = await axios.put(editProfileURL(id), payload);

            if (status === 200) {
                dispatch(profileChangeSuccessActionCreator(data));
                onSuccess();
            }
        } catch (error) {
            let formErrors = {};
            if (error) {
                formErrors = (error as AxiosError).response?.data;
            }
            dispatch(profileChangeFailureActionCreator(formErrors));
            onFailure();
        }
    };
};

// Logout

export const logoutRequestActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.LOGOUT_REQUEST,
    };
};

export const requestLogout = (onSuccess?: Function) => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        try {
            axios.post(logoutURL()).finally(() => {
                dispatch(logoutRequestActionCreator());
                if (onSuccess) onSuccess();
            });
        } catch {}
    };
};
