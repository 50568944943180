/**
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */
/* eslint-disable react-hooks/exhaustive-deps */

import React, {
    FunctionComponent, useEffect, useRef, useState,
} from 'react';
import {
    Collapse,
    IconButton, Divider, Button, Menu, MenuItem,
} from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import IconArrowUp from '../assets/IconArrowUp';
import IconArrowDown from '../assets/IconArrowDown';
import { Business } from '../../types/businesses';
import { Area } from '../../types/areas';
import { ReservationManagersContext, withReservationManagersContext } from '../controllers/ReservationManagersContext';
import ConfirmModal from './ConfirmModal';
import { displayNotification, NotificationType } from '../../utils/notifications';

interface OwnProps extends TranslationContext, ReservationManagersContext {
    business: Business;
    reservationManagerId: number;
    isExpanded: boolean;
    handleExpand: () => void;
    update: () => void;
    removeBusiness: (id: number) => void;
}

const defaultAreasQueryParams = {
    _limit: 10,
    _page: 0,
};

enum confirmModalType {
    DELETE_AREA = 'DELETE_AREA',
    DELETE_BUSINESS = 'DELETE_BUSINESS',
}

const ReservationManagerRelatedAreasList: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        getReservationManagerAreasRelatedToABusiness,
        disassociateReservationManagerFromBusiness,
        disassociateReservationManagerFromAnArea,
        reservationManagerId,
        handleExpand,
        isExpanded,
        business,
        update,
        t,
    } = props;

    const [areasList, setAreasList] = React.useState<Area[]>([]);
    const [hasMoreAreasToFetch, setHasMoreAreasToFetch] = React.useState(true);
    const [businessIdSelected, setBusinessIdSelected] = React.useState<number | null>(null);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [selectedAreaId, setSelectedAreaId] = useState<number | null>(null);
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [confirmModalView, setConfirmModalView] = useState<confirmModalType>(confirmModalType.DELETE_AREA);
    const listFinalValue = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        if (!businessIdSelected || !hasMoreAreasToFetch) return;

        const intersectionObserver = new IntersectionObserver(entries => {
            if (entries.some(entry => entry.isIntersecting)) {
                setCurrentPage(prevPage => prevPage + 1);
            }
        });

        if (listFinalValue?.current) intersectionObserver.observe(listFinalValue.current);

        return () => intersectionObserver.disconnect();
    }, [isExpanded]);

    useEffect(() => {
        if (!businessIdSelected || !hasMoreAreasToFetch) return;

        const prepareAreas = async () => {
            const areasData = await getReservationManagerAreasRelatedToABusiness(reservationManagerId, businessIdSelected, {
                ...defaultAreasQueryParams,
                _page: currentPage,
            });
            if (!areasData || areasData?.data.length === 0) {
                setHasMoreAreasToFetch(false);
                return;
            }
            setAreasList(prevAreas => [...prevAreas, ...areasData.data]);
        };
        prepareAreas();
    }, [currentPage, businessIdSelected]);

    const handleExpandClick = () => {
        setBusinessIdSelected(business.id);
        handleExpand();
    };
    const handleDeleteAction = (type: confirmModalType, id: number) => {
        if (type === confirmModalType.DELETE_AREA) {
            setSelectedAreaId(id);
        } else {
            setBusinessIdSelected(id);
        }

        setConfirmModalView(type);
        setShowConfirm(true);
    };

    const filterAreasByRemovingDeletedOne = () => {
        const { removeBusiness } = props;

        const newAreasList = areasList.filter(area => area.id !== selectedAreaId);
        if (newAreasList.length === 0 && businessIdSelected) {
            removeBusiness(businessIdSelected);
            return;
        }
        setAreasList(newAreasList);
    };

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setMenuAnchorEl(null);
    };

    const handleConfirmActionModal = async () => {
        if (confirmModalView === confirmModalType.DELETE_AREA) {
            if (!selectedAreaId) return;
            await disassociateReservationManagerFromAnArea(reservationManagerId, selectedAreaId, filterAreasByRemovingDeletedOne);
            displayNotification({
                message: t('addAreaModal.removeSuccess'),
                type: NotificationType.Success,
            });
        } else {
            if (!businessIdSelected) return;
            await disassociateReservationManagerFromBusiness(reservationManagerId, businessIdSelected, update);
        }
        setShowConfirm(false);
        setMenuAnchorEl(null);
    };

    const handleConfirmModalMessage = () => {
        let message = t('reservationManagerDetailsScreen.deleteAreaConfirmationMessage');
        let location = String(areasList.find(area => area.id === selectedAreaId)?.name);
        if (confirmModalView === confirmModalType.DELETE_BUSINESS) {
            message = t('reservationManagerDetailsScreen.disassociateBusinessConfirmationMessage');
            location = String(business?.name);
        }
        return (
            <>
                <p>{message} <b>{location}</b></p>
                <p>{t('reservationManagerDetailsScreen.sureToContinue')}</p>
            </>
        );
    };

    const handleConfirmModalTitle = () => {
        if (confirmModalView === confirmModalType.DELETE_BUSINESS) return t('reservationManagerDetailsScreen.removeBusiness');
        return t('reservationManagerDetailsScreen.removeArea');
    };

    return (
        <>
            <ConfirmModal
                onClose={() => setShowConfirm(false)}
                title={handleConfirmModalTitle()}
                onConfirm={handleConfirmActionModal}
                show={showConfirm}
                cancelButton={t('reservationManagerDetailsScreen.cancel')}
                okButton={t('reservationManagerDetailsScreen.yesRemove')}
                okButtonClass="delete"
            >
                {handleConfirmModalMessage()}
            </ConfirmModal>
            <div className={`reservation-manager-detail__businesses${isExpanded ? '--expanded' : ''}`}>
                <h3>
                    {business.name}
                </h3>
                <div className="reservation-manager-detail__businesses__right">
                    <IconButton
                        onClick={handleOpenMenu}
                        data-testid={`ellipsis-btn-${business.id}`}
                    >
                        <MoreHoriz />
                    </IconButton>
                    <IconButton
                        aria-label="expand-row"
                        size="small"
                        onClick={handleExpandClick}
                        data-testid={`expand-btn-${business.id}`}
                    >
                        {isExpanded ? <IconArrowUp /> : <IconArrowDown />}
                    </IconButton>
                    <Menu
                        open={!!menuAnchorEl}
                        anchorEl={menuAnchorEl}
                        onClose={handleCloseMenu}
                        className="reservation-manager-menu"
                    >
                        <MenuItem
                            onClick={() => handleDeleteAction(confirmModalType.DELETE_BUSINESS, business.id)}
                            data-testid="remove-business-btn"
                        >{t('reservationManagerDetailsScreen.removeBusiness')}
                        </MenuItem>
                    </Menu>
                </div>
            </div>
            <div
                className={`reservation-manager-detail__businesses__collapsed-container${isExpanded ? '' : '--expanded'}`}
            >
                <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                    {areasList.map(area => (
                        <React.Fragment key={area.id}>
                            <div
                                className="reservation-manager-detail__businesses__collapsed-container__area-wrapper"
                                data-testid={`area-${area.id}`}
                            >
                                <p>{area.name}</p>
                                <Button
                                    onClick={() => handleDeleteAction(confirmModalType.DELETE_AREA, area.id)}
                                    data-testid={`delete-area-btn-${area.id}`}
                                >
                                    {t('reservationManagerDetailsScreen.remove')}
                                </Button>

                            </div>

                            <Divider variant="middle" />
                        </React.Fragment>
                    ))}
                    <span ref={listFinalValue} data-testid="observer-trigger" />
                </Collapse>
            </div>
        </>
    );
};

export default withTranslationContext(withReservationManagersContext(ReservationManagerRelatedAreasList));
