/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

export interface User {
    active: boolean;
    avatar: string;
    email: string;
    firstName: string;
    id: number;
    lastLoginDate: string;
    lastName: string;
    phoneNumber: number;
    phoneCountry: string;
    phoneNumberCode?: string;
    role: string;
    lastUpdate?: string;
}

export interface Merchant extends User {
    activationStatus: UserStatus;
    numberOfBusinesses: number;
}

export enum UsersRoles {
    Admin = 'Admin',
    Merchant = 'Merchant',
    User = 'User',
    ReservationManager = 'Reservation Manager',
}

export enum MerchantToggleStatusParam {
    Activate = 'ACTIVATE',
    Deactivate = 'DEACTIVATE',
}

export enum UserStatus {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
    NotValidated = 'NOT_VALIDATED',
}
