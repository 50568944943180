/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';

import { ConnectedMerchantsController } from './MerchantsController';
import { KeyedObject, ListResponse } from '../../types/general';
import { ApiError } from '../../types/errors';
import { Merchant, MerchantToggleStatusParam } from '../../types/users';

export interface MerchantsContext {
    createMerchantFetching: boolean;
    createMerchantErrors: ApiError | null;
    updateMerchantFetching: boolean;
    updateMerchantErrors: ApiError | null;
    selectedMerchantId: number | null;
    getMerchant(merchantId: string | number): Promise<Merchant | null>;
    getMerchants(filters?: KeyedObject): Promise<ListResponse<Merchant> | null>;
    toggleMerchantStatus(merchantId: number, newStatus: MerchantToggleStatusParam, onSuccess: () => void, onFailure: () => void): Promise<void>;
    validateNewMerchant(fields: any): KeyedObject | null;
    validateEditMerchant(fields: any): KeyedObject | null;
    submitNewMerchant(payload: any, onSuccess: Function, onFailure: Function): void;
    submitEditMerchant(merchantId: string, payload: FormData, onSuccess: Function, onFailure: Function): void;
    setSelectedMerchantId(merchantId: number): void;
}

export const merchantsContextDefaultValue = {
    createMerchantFetching: false,
    createMerchantErrors: null,
    updateMerchantFetching: false,
    updateMerchantErrors: null,
    selectedMerchantId: null,
    getMerchant: async () => null,
    getMerchants: async () => null,
    toggleMerchantStatus: async () => {},
    validateNewMerchant: () => null,
    validateEditMerchant: () => null,
    submitNewMerchant: () => {},
    submitEditMerchant: () => {},
    setSelectedMerchantId: () => {},
};

const MerchantsContextInstance = createContext<MerchantsContext | null>(merchantsContextDefaultValue);

export const MerchantsContextProvider = MerchantsContextInstance.Provider;
export const MerchantsContextConsumer = MerchantsContextInstance.Consumer;

export const withMerchantsContext = <P extends object>(
    Component: ComponentType<P>
): FC<Omit<P, keyof MerchantsContext>> => (props) => (
    <ConnectedMerchantsController>
        <MerchantsContextConsumer>{(ctx) => <Component {...(props as P)} {...ctx} />}</MerchantsContextConsumer>
    </ConnectedMerchantsController>
);
