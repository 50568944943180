/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';
import { ConnectedInvitationsController } from './InvitationsController';
import { AcceptInvitePayload, Invitation, InvitationPayload } from '../../types/invitations';
import { KeyedObject, ListResponse } from '../../types/general';
import { GenericFunction } from '../../utils/misc';

export interface InvitationsContext {
    acceptInvite(token: string, fields: AcceptInvitePayload, onSuccess: () => void, onFailure: () => void): Promise<void>;
    deleteInvitation(id: number, onSuccess: () => void, onFailure: () => void): Promise<void>;
    getInvitations(filters?: KeyedObject): Promise<ListResponse<Invitation> | null>;
    validateInvitation(fields: InvitationPayload): KeyedObject | null;
    sendMerchantInvitation(payload: InvitationPayload, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void>;
    sendReservationManagerInvitation(payload: InvitationPayload, onSuccess: GenericFunction, onFailure: GenericFunction, merchantId?: number): Promise<void>;
}

export const invitationsContextDefaultValue: InvitationsContext = {
    acceptInvite: async () => {},
    deleteInvitation: async () => {},
    getInvitations: async () => null,
    validateInvitation: () => null,
    sendMerchantInvitation: async (): Promise<void> => {},
    sendReservationManagerInvitation: async (): Promise<void> => {},
};

const InvitationsContext = createContext<InvitationsContext | null>(invitationsContextDefaultValue);

export const InvitationsContextProvider = InvitationsContext.Provider;
export const InvitationsContextConsumer = InvitationsContext.Consumer;

export const withInvitationsContext = <P extends object>(
    Component: ComponentType<P>,
): FC<Omit<P, keyof InvitationsContext>> => props => (
    <ConnectedInvitationsController>
        <InvitationsContextConsumer>
            {ctx => <Component {...(props as P)} {...ctx} />}
        </InvitationsContextConsumer>
    </ConnectedInvitationsController>
);
