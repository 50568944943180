/*
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import moment from 'moment';
import { TextField } from '@material-ui/core';
import { BookingsContext, withBookingsContext } from '../controllers/BookingsContext';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import {
    Booking,
    BookingDays,
    BookingDaysParams,
    BookingStatus,
    PayloadSubmitSetReservationObservation,
} from '../../types/bookings';
import { ReactComponent as IconBusiness } from '../../assets/images/icon_business.svg';
import { ReactComponent as IconArea } from '../../assets/images/icon_area.svg';
import { ReactComponent as IconCalendar } from '../../assets/images/icon_calendar.svg';
import { ReactComponent as IconCalendarBlack } from '../../assets/images/icon_calendar_black.svg';
import { ReactComponent as IconEdit } from '../../assets/images/icon_edit.svg';
import { ReactComponent as IconCustomer } from '../../assets/images/icon_customer.svg';
import { ReactComponent as IconGuests } from '../../assets/images/icon_guests.svg';
import { ReactComponent as IconMail } from '../../assets/images/icon_mail.svg';
import { ReactComponent as IconPhone } from '../../assets/images/icon_phone.svg';
import { ReactComponent as IconObservation } from '../../assets/images/icon_observation.svg';
import Loader from '../elements/Loader';
import ConfirmModal from '../elements/ConfirmModal';
import { BookingFormField } from './BookingForm';
import { displayNotification, NotificationType } from '../../utils/notifications';
import { buildRoute } from '../../utils/misc';
import { AppRoute } from '../../types/routes';

interface OwnProps extends TranslationContext, BookingsContext, RouteComponentProps {
    bookingId?: string;
}

interface OwnState {
    booking: Booking | null;
    bookingDays: BookingDays[] | null;
    isFetchingBooking: boolean;
    params: BookingDaysParams;
    showEditObservationModal: boolean;
    editObservationInput: string;
}

const initialState: OwnState = {
    booking: null,
    bookingDays: null,
    isFetchingBooking: false,
    params: {
        pageSize: 20,
    },
    showEditObservationModal: false,
    editObservationInput: '',
};

class BookingDetails extends Component<OwnProps, OwnState> {
    constructor(props: OwnProps) {
        super(props);
        const { bookingId } = props;
        this.state = {
            ...initialState,
            isFetchingBooking: bookingId !== undefined,
        };
    }

    componentDidMount() {
        this.fetchBooking();
    }

    fetchBooking = async () => {
        const { getBooking, getBookingDays, bookingId } = this.props;
        const { params } = this.state;

        if (!bookingId) return;

        this.setState({ isFetchingBooking: true });

        const booking = await getBooking(bookingId);
        const bookingDays = await getBookingDays(bookingId, params);

        if (booking) this.setState({ booking, editObservationInput: booking.observations });
        if (bookingDays) this.setState({ bookingDays });

        this.setState({ isFetchingBooking: false });
    };

    submitObservation = () => {
        const { setBookingObservations, bookingId } = this.props;
        const { editObservationInput } = this.state;

        const payload: PayloadSubmitSetReservationObservation = {
            observations: editObservationInput,
        };

        setBookingObservations(String(bookingId), payload, this.onFormSubmitSuccess, this.onFormSubmitFailure);
    }

    onFormSubmitSuccess = () => {
        const { t } = this.props;

        displayNotification({
            message: t('bookingDetailsScreen.editObservationsSuccessfully'),
            type: NotificationType.Success,
        });

        this.setState({ showEditObservationModal: false });
        this.fetchBooking();
    };

    onFormSubmitFailure = (errors: string | null) => {
        const { t } = this.props;

        if (errors) {
            displayNotification({
                message: errors,
                type: NotificationType.Danger,
            });
        } else {
            displayNotification({
                message: t('errors.genericAPIError'),
                type: NotificationType.Danger,
            });
        }

        this.setState({ showEditObservationModal: false });
    };

    render() {
        const { t, bookingId } = this.props;
        const {
            booking, isFetchingBooking, bookingDays, showEditObservationModal, editObservationInput,
        } = this.state;

        if (isFetchingBooking) return <Loader />;

        return (
            <div className="booking-details-container" data-testid="booking-details">
                <div className="booking-details-container__content">
                    <div className="booking-details-container__content__section">
                        <p className="booking-details-container__content__section__title">
                            {t('bookingDetailsScreen.reservationDetails')}
                        </p>
                        <div className="booking-details-container__content__section__row">
                            <div className="booking-details-container__content__section__row__data">
                                <div className="booking-details-container__content__section__row__data__icon">
                                    <IconBusiness />
                                </div>
                                <div className="booking-details-container__content__section__row__data__info">
                                    <p className="booking-details-container__content__section__row__data__info__title">
                                        {t('bookingForm.businessLabel')}
                                    </p>
                                    <p data-testid="booking-details-business">
                                        {booking?.businessArea.business.name}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="booking-details-container__content__section__row">
                            <div className="booking-details-container__content__section__row__data">
                                <div className="booking-details-container__content__section__row__data__icon">
                                    <IconArea />
                                </div>
                                <div className="booking-details-container__content__section__row__data__info">
                                    <p className="booking-details-container__content__section__row__data__info__title">
                                        {t('bookingForm.areaLabel')}
                                    </p>
                                    <p data-testid="booking-details-area">
                                        {booking?.businessArea.name}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="booking-details-container__content__section__row">
                            <div className="booking-details-container__content__section__row__data">
                                <div className="booking-details-container__content__section__row__data__icon">
                                    <IconCalendar />
                                </div>
                                <div className="booking-details-container__content__section__row__data__info">
                                    {bookingDays && bookingDays.map(bookindDay => (
                                        <div className="booking-details-container__content__section__row__data__info__date-row">
                                            <div className="booking-details-container__content__section__row__data__info__date-row__date" data-testid="booking-details-date">
                                                <p className="booking-details-container__content__section__row__data__info__title">
                                                    {t('bookingDetailsScreen.dateLabel')}
                                                </p>
                                                {moment(bookindDay.localDay).format('DD/MM/YYYY')}
                                            </div>
                                            <div className="booking-details-container__content__section__row__data__info__date-row__time" data-testid="booking-details-time">
                                                <p className="booking-details-container__content__section__row__data__info__title">
                                                    {t('bookingDetailsScreen.timeLabel')}
                                                </p>
                                                {bookindDay.localStartTime} - {bookindDay.localEndTime}
                                            </div>
                                            <div className="booking-details-container__content__section__row__data__info__date-row__bollard" data-testid="booking-details-bollard">
                                                <p className="booking-details-container__content__section__row__data__info__title">
                                                    {t('bookingForm.bollardLabel')}
                                                </p>
                                                {bookindDay.bollardDesignation}
                                            </div>
                                            <div className="booking-details-container__content__section__row__data__info__date-row__price" data-testid="booking-details-price">
                                                <p className="booking-details-container__content__section__row__data__info__title">
                                                    {t('bookingForm.priceLabel')}
                                                </p>
                                                {bookindDay.price}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="booking-details-container__content__section">
                        <p className="booking-details-container__content__section__title">
                            {t('bookingDetailsScreen.clientDetails')}
                        </p>
                        {booking?.customer || booking?.unregisteredReservationInfo ? (
                            <div className="booking-details-container__content__section__row">
                                <div className="booking-details-container__content__section__row__two-columns">
                                    <div className="booking-details-container__content__section__row__two-columns__column">
                                        <div className="booking-details-container__content__section__row__data__icon">
                                            <IconCustomer />
                                        </div>
                                        <div className="booking-details-container__content__section__row__data__info">
                                            <p className="booking-details-container__content__section__row__data__info__title">
                                                {t('profileForm.firstNameLabel')}
                                            </p>
                                            <p data-testid="booking-details-firstname">
                                                {booking?.unregisteredReservationInfo ? booking?.unregisteredReservationInfo.firstName : booking?.customer?.firstName}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="booking-details-container__content__section__row__two-columns__column">
                                        <div className="booking-details-container__content__section__row__data__icon">
                                            <IconCustomer />
                                        </div>
                                        <div className="booking-details-container__content__section__row__data__info">
                                            <p className="booking-details-container__content__section__row__data__info__title">
                                                {t('profileForm.lastNameLabel')}
                                            </p>
                                            <p data-testid="booking-details-lastname">
                                                {booking?.unregisteredReservationInfo ? booking?.unregisteredReservationInfo.lastName : booking?.customer?.lastName}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="booking-details-container__content__section__row__two-columns">
                                    <div className="booking-details-container__content__section__row__two-columns__column">
                                        <div className="booking-details-container__content__section__row__data__icon">
                                            <IconMail />
                                        </div>
                                        <div className="booking-details-container__content__section__row__data__info">
                                            <p className="booking-details-container__content__section__row__data__info__title">
                                                {t('profileForm.emailLabel')}
                                            </p>
                                            <p data-testid="booking-details-email">
                                                {booking?.unregisteredReservationInfo ? booking?.unregisteredReservationInfo.email : booking?.customer?.email}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="booking-details-container__content__section__row__two-columns__column">
                                        <div className="booking-details-container__content__section__row__data__icon">
                                            <IconPhone />
                                        </div>
                                        <div className="booking-details-container__content__section__row__data__info">
                                            <p className="booking-details-container__content__section__row__data__info__title">
                                                {t('merchantsScreen.phoneNumber')}
                                            </p>
                                            <p data-testid="booking-details-phonenumber">
                                                {booking?.unregisteredReservationInfo ? booking?.unregisteredReservationInfo.phoneNumber : `${booking?.customer?.phoneNumberCode} ${booking?.customer?.phoneNumber}`}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null }
                        <div className="booking-details-container__content__section__row">
                            <div className="booking-details-container__content__section__row__data">
                                <div className="booking-details-container__content__section__row__data__icon">
                                    <IconGuests />
                                </div>
                                <div className="booking-details-container__content__section__row__data__info">
                                    <p className="booking-details-container__content__section__row__data__info__title">
                                        {t('bookingDetailsScreen.numberOfGuests')}
                                    </p>
                                    <p data-testid="booking-details-people">
                                        {booking?.numberOfPeople}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {booking?.observations && (
                            <div className="booking-details-container__content__section__row">
                                <div className="booking-details-container__content__section__row__data">
                                    <div className="booking-details-container__content__section__row__data__icon">
                                        <IconObservation />
                                    </div>
                                    <div className="booking-details-container__content__section__row__data__info">
                                        <p className="booking-details-container__content__section__row__data__info__title">
                                            {t('bookingForm.observationsLabel')}
                                        </p>
                                        <p data-testid="booking-details-observations">
                                            {booking.observations}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="booking-details-container__sidebar">
                    {booking?.status === BookingStatus.Reserved && (
                        <Link
                            to={buildRoute(AppRoute.EditBooking, {
                                bookingId,
                            })}
                        >
                            <IconCalendarBlack />
                            <span>{t('bookingDetailsScreen.editReservation')}</span>
                        </Link>
                    )}
                    <button type="button" onClick={() => this.setState({ showEditObservationModal: true })}>
                        <IconEdit />
                        <span>{t('bookingDetailsScreen.editObservations')}</span>
                    </button>
                </div>
                <ConfirmModal
                    onClose={() => this.setState({ showEditObservationModal: false })}
                    title={t('bookingDetailsScreen.editObservations')}
                    onConfirm={this.submitObservation}
                    show={showEditObservationModal}
                    cancelButton={t('reservationManagerDetailsScreen.cancel')}
                    okButton={t('profileForm.submitButton')}
                    okButtonClass="edit"
                    isLarge
                >
                    <div className="input-container">
                        <TextField
                            name={BookingFormField.Observations}
                            value={editObservationInput}
                            onChange={e => this.setState({ editObservationInput: e.currentTarget.value })}
                            multiline
                            rows={3}
                            maxRows={3}
                            label={t('bookingForm.observationsLabel')}
                            placeholder={t('bookingForm.observationsLabel')}
                            variant="outlined"
                            fullWidth
                            inputProps={{ maxLength: 600 }}
                            className="input-container"
                        />
                    </div>
                </ConfirmModal>
            </div>
        );
    }
}

export default withTranslationContext(
    withBookingsContext(withRouter(BookingDetails)),
);
