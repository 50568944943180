/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';
import { KeyedObject } from '../types/general';
import { objectToParams } from '../utils/misc';

const api = 'bollards';

export const bollardDailyOccupationURL = (bollardId: number, day: string): string => {
    return `${API_URL}/${api}/${bollardId}/daily-occupation/${day}`;
};

export const bollardAvailabilityURL = (): string => {
    return `${API_URL}/${api}/availability`;
};

export const bollardsURL = (businessId: string, areaId: string, filters?: KeyedObject): string => {
    const params = objectToParams(filters);
    return `${API_URL}/businesses/${businessId}/areas/${areaId}/${api}${params}`;
};

export const bollardsAvailabilityURL = (bollardId: number, day: string, filters?: KeyedObject): string => {
    const params = objectToParams(filters);
    return `${API_URL}/${api}/${bollardId}/availability/${day}${params}`;
};

export const bollardValidateLocationURL = (qrCodeId: string, areaId: number, businessId: number): string => {
    return `${API_URL}/businesses/${businessId}/areas/${areaId}/${api}/${qrCodeId}/validate-location`;
};

export const bollardPostLocationUpdatedURL = (qrCodeId: string, areaId: number, businessId: number): string => {
    return `${API_URL}/businesses/${businessId}/areas/${areaId}/${api}/${qrCodeId}`;
};
