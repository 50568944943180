/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

// Login

import axios, { AxiosError } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

import { KeyedObject } from '../types/general';
import { BookingsAction, BookingsActionTypes } from './bookings_types';
import { createBookingUnregisteredURL, createBookingURL } from '../services/bookings';
import { BookingCalendarBollardsProps, BookingCalendarBusinessProps } from '../reducers/types';

// Create Booking

export const newBookingRequestActionCreator = (): BookingsActionTypes => {
    return {
        type: BookingsAction.CREATE_BOOKING_REQUEST,
    };
};

export const newBookingSuccessActionCreator = (): BookingsActionTypes => {
    return {
        type: BookingsAction.CREATE_BOOKING_SUCCESS,
    };
};

export const newBookingFailureActionCreator = (error: KeyedObject | null): BookingsActionTypes => {
    return {
        type: BookingsAction.CREATE_BOOKING_FAILURE,
        payload: error,
    };
};

export const setBookingCalendarBusinessActionCreator = (payload: BookingCalendarBusinessProps | null): BookingsActionTypes => {
    return {
        type: BookingsAction.SET_BOOKING_CALENDAR_BUSINESS,
        payload
    };
};

export const setBookingCalendarBollardsActionCreator = (payload: BookingCalendarBollardsProps | null): BookingsActionTypes => {
    return {
        type: BookingsAction.SET_BOOKING_CALENDAR_BOLLARDS,
        payload
    };
};

export const requestCreateBooking = (payload: FormData, registerUser: boolean, onSuccess?: Function, onFailure?: Function) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(newBookingRequestActionCreator());
        try {
            await axios.post(registerUser ? createBookingURL() : createBookingUnregisteredURL(), payload);

            dispatch(newBookingSuccessActionCreator());

            if (onSuccess) onSuccess();
        } catch (error) {
            let formErrors = {};
            if (error) {
                formErrors = (error as AxiosError).response?.data;
            }
            dispatch(newBookingFailureActionCreator(formErrors));
            if (onFailure) onFailure();
        }
    };
};
