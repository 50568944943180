/*
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { googleMap, mapURL } from '../../utils/maps';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';

interface OwnProps extends TranslationContext {
    zoom: number;
    center: google.maps.LatLngLiteral;
    height: string;
    onMapMounted: Function;
    onClick?: (evt: any) => void;
    togglable?: boolean
}

class Map extends Component<OwnProps> {
    map: google.maps.Map | null = null;

    id = '';

    constructor(props: OwnProps) {
        super(props);
        this.id = Math.random().toString().substr(2, 8);
    }

    componentDidMount() {
        const { onMapMounted } = this.props;
        if (window.google === undefined) {
            const script = document.createElement('script');
            script.src = mapURL();
            script.async = true;
            script.defer = true;
            script.addEventListener('load', () => {
                onMapMounted();
            });
            document.body.appendChild(script);
        } else {
            this.updateMap();
        }
    }

    componentDidUpdate() {
        this.updateMap();
    }

    updateMap = () => {
        const {
            onMapMounted, center, zoom, onClick,
        } = this.props;
        if (this.map === null && window.google !== undefined) {
            this.map = googleMap(center, zoom, this.id);
            if (onClick !== undefined) {
                this.map.addListener('click', onClick);
            }
            onMapMounted(this.map);
        }
    }

    render() {
        const { height, togglable } = this.props;

        if (togglable) {
            return (
                <div className="mapDiv">
                    <div id={this.id} style={{ height, width: '100%', flexShrink: 0 }} />
                </div>
            );
        }
        return <div id={this.id} style={{ height, width: '100%', flexShrink: 0 }} />;
    }
}

export default withTranslationContext(Map);
