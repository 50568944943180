/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AppRoute } from '../../types/routes';
import RegisterForm from '../views/RegisterForm';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/AuthenticationContext';
import PageHeader from '../elements/PageHeader';

interface OwnProps extends TranslationContext, AuthenticationContext, RouteComponentProps {}

interface OwnState {}

const initialState: OwnState = {};

class RegisterScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount() {
        const { isAuthenticated, history } = this.props;

        if (isAuthenticated) {
            history.push(AppRoute.Map);
        }
    }

    render() {
        const { t } = this.props;

        return (
            <div className="auth-screen">
                <div className="auth-screen__gradient-container">
                    <div className="auth-screen__gradient-container__padding-container">
                        <PageHeader
                            to={AppRoute.Login}
                            back={t('general.back')}
                            title={t('registerScreen.title')}
                            withLogo
                        />
                    </div>
                </div>
                <RegisterForm />
            </div>
        );
    }
}

export default withTranslationContext(withAuthenticationContext(withRouter(RegisterScreen)));
