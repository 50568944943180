/**
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';
import { KeyedObject } from '../types/general';
import { objectToParams } from '../utils/misc';

const api = 'reservation-managers';

export const reservationManagersURL = (isAdmin: boolean, filters?: KeyedObject): string => {
    const params = objectToParams(filters);
    return isAdmin ? `${API_URL}/${api}${params}` : `${API_URL}/${api}/merchant${params}`;
};

export const reservationManagerURL = (reservationManagerId: string): string => {
    return `${API_URL}/${api}/${reservationManagerId}`;
};

export const reservationManagerBusinessesURL = (reservationManagerId: string, filters?: KeyedObject): string => {
    const params = objectToParams(filters);
    return `${API_URL}/${api}/${reservationManagerId}/businesses${params}`;
};

export const reservationManagerAreasForABusinessURL = (reservationManagerId: number, businessId: number, filters?: KeyedObject): string => {
    const params = objectToParams(filters);
    return `${API_URL}/${api}/${reservationManagerId}/businesses/${businessId}/business-areas/${params}`;
};

export const reservationManagerAvailableAreasURL = (reservationManagerId: number, businessId: number): string => {
    return `${API_URL}/${api}/${reservationManagerId}/businesses/${businessId}/business-areas/available`;
};

export const associateReservationManagerToAreaURL = (reservationManagerId: number, areaId: number): string => {
    return `${API_URL}/${api}/${reservationManagerId}/business-areas/${areaId}/associate`;
};

export const disassociateReservationManagerFromAreaURL = (reservationManagerId: number, areaId: number): string => {
    return `${API_URL}/${api}/${reservationManagerId}/business-areas/${areaId}/disassociate`;
};

export const disassociateReservationManagerFromBusinessURL = (reservationManagerId: number, businessId: number): string => {
    return `${API_URL}/${api}/${reservationManagerId}/business/${businessId}/disassociate`;
};

export const removeReservationManagerURL = (reservationManagerId: number): string => {
    return `${API_URL}/${api}/${reservationManagerId}/remove`;
};
