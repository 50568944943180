/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';

import AreaForm from '../views/AreaForm';
import { AppRoute } from '../../types/routes';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import PageHeader from '../elements/PageHeader';

interface OwnProps extends TranslationContext {}

interface OwnState {}

const initialState: OwnState = {};

class AddAreaScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    render() {
        const { t } = this.props;
        return (
            <div className="areas-form-container">
                <PageHeader to={AppRoute.Areas} back={t('general.back')} title={t('addAreaScreen.title')} />
                <AreaForm />
            </div>
        );
    }
}

export default withTranslationContext(AddAreaScreen);
