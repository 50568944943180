/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';
import { KeyedObject, ListResponse } from '../../types/general';
import { ReservationManager } from '../../types/reservationManager';
import { ConnectedReservationManagersController } from './ReservationManagersController';
import { Business } from '../../types/businesses';
import { Area } from '../../types/areas';
import { GenericFunction } from '../../utils/misc';

export interface ReservationManagersContext {
    getReservationManagers(filters?: KeyedObject): Promise<ListResponse<ReservationManager>>;
    getReservationManager(reservationManagerId: string): Promise<ReservationManager | null>;
    getReservationManagerBusinesses(reservationManagerId: string, filters?: KeyedObject): Promise<ListResponse<Business>>;
    getReservationManagerAreasRelatedToABusiness(reservationManagerId: number, businessId: number, filters?: KeyedObject): Promise<ListResponse<Area>>;
    getReservationManagerAvailableAreasRelatedToABusiness(reservationManagerId: number, businessId: number): Promise<ListResponse<Area>>;
    associateReservationManagerToAnArea(reservationManagerId: number, areaId: number, onSuccess?: GenericFunction): Promise<void | null>;
    disassociateReservationManagerFromAnArea(reservationManagerId: number, areaId: number, onSuccess?: GenericFunction): Promise<void | null>;
    disassociateReservationManagerFromBusiness(reservationManagerId: number, businessId: number, onSuccess?: GenericFunction): Promise<void | null>;
    removeReservationManager(reservationManagerId: number, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void>;
}

export const reservationManagersContextDefaultValue: ReservationManagersContext = {
    getReservationManagers: async () => ({ data: [], total: 0 }),
    getReservationManager: async () => null,
    getReservationManagerBusinesses: async () => ({ data: [], total: 0 }),
    getReservationManagerAreasRelatedToABusiness: async () => ({ data: [], total: 0 }),
    getReservationManagerAvailableAreasRelatedToABusiness: async () => ({ data: [], total: 0 }),
    associateReservationManagerToAnArea: async () => null,
    disassociateReservationManagerFromAnArea: async () => null,
    disassociateReservationManagerFromBusiness: async () => null,
    removeReservationManager: async (): Promise<void> => {},
};

const ReservationManagersContext = createContext<ReservationManagersContext | null>(reservationManagersContextDefaultValue);

export const ReservationManagersContextProvider = ReservationManagersContext.Provider;
export const ReservationManagersContextConsumer = ReservationManagersContext.Consumer;

export const withReservationManagersContext = <P extends object>(
    Component: ComponentType<P>,
): FC<Omit<P, keyof ReservationManagersContext>> => props => (
    <ConnectedReservationManagersController>
        <ReservationManagersContextConsumer>
            {ctx => <Component {...(props as P)} {...ctx} />}
        </ReservationManagersContextConsumer>
    </ConnectedReservationManagersController>
    );
