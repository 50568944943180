/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { createStore, compose, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authorization from './reducers/authorization';
import authentication from './reducers/authentication';
import preferences from './reducers/preferences';
import areas from './reducers/areas';
import businesses from './reducers/businesses';
import bookings from './reducers/bookings';
import merchants from './reducers/merchants';

const isDevVersion: boolean = process.env.NODE_ENV === 'development';
const composeEnhancers = composeWithDevTools || compose;
const middlewares: any = [ReduxThunk];
const volatileReducers: any = [];

const config = {
    key: 'root',
    storage,
    debug: isDevVersion,
    blacklist: volatileReducers,
};

const reducers = persistCombineReducers(config, {
    authentication,
    authorization,
    preferences,
    areas,
    businesses,
    bookings,
    merchants,
});

export const configureStore = () => {
    const store = createStore(reducers, composeEnhancers(applyMiddleware(...middlewares)));

    const persistor = persistStore(store, null);

    return { persistor, store };
};
