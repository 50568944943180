/*
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */

import { Tooltip } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { Bollard } from '../../../types/bollards';
import { BollardAvailability } from '../../../types/bookings';
import { withTranslationContext, TranslationContext } from '../../controllers/TranslationContext';
import { TIME_FORMAT } from '../../../utils/constants';
import { formatHourTime } from '../../../utils/misc';

interface ScheduleRowOwnProps extends TranslationContext{
    bollard: Bollard;
    scheduleEntries: BollardAvailability[] | null;
    initialHour: moment.Moment;
    finalHour: moment.Moment;
    reserveSpot: (se: BollardAvailability, bollard: Bollard) => void;
    selectedReservationId?: number;
    selectedBollardId?: number;
    selectedBookingId?: string;
}

enum scheduleStatus {
    RESERVED = 'reserved',
}

const BollardRow: React.FC<ScheduleRowOwnProps> = (props: ScheduleRowOwnProps) => {
    const {
        bollard, scheduleEntries, selectedBollardId, selectedReservationId, finalHour, initialHour, reserveSpot, t,
    } = props;

    const getEntryClassNames = (
        reserved: boolean, selected: boolean,
    ) => {
        const classNames = [
            'schedule-table__bollard-container__schedule-container__schedule',
        ];

        if (selected) {
            classNames.push(
                'schedule-table__bollard-container__schedule-container__schedule--selected',
            );
        } else if (reserved) {
            classNames.push(
                'schedule-table__bollard-container__schedule-container__schedule--reserved',
            );
        } else {
            classNames.push(
                'schedule-table__bollard-container__schedule-container__schedule--available',
            );
        }
        return classNames;
    };

    const singlePeriodSpan = (singlePeriod?: BollardAvailability) => {
        if (!singlePeriod) return null;

        const classNames = getEntryClassNames(
            singlePeriod.isOccupied,
            singlePeriod.reservationPeriodId === selectedReservationId && bollard.id === selectedBollardId,
        );

        const startTimeMoment = moment(singlePeriod.localStartTime, TIME_FORMAT);
        const endTimeMoment = moment(singlePeriod.localEndTime, TIME_FORMAT);
        if (endTimeMoment.diff(startTimeMoment, 'minute') <= 0) {
            return null;
        }

        return (
            <div
                className="schedule-table__bollard-container__schedule-container__wrapper--has-padding"
                style={{ flex: endTimeMoment.diff(startTimeMoment, 'minute') }}
            >
                <Tooltip
                    className={classNames.join(' ')}
                    onClick={() => reserveSpot(singlePeriod, bollard)}
                    arrow
                    title={`${formatHourTime(singlePeriod.localStartTime)} - ${formatHourTime(singlePeriod.localEndTime)}`}
                >
                    <span>{classNames.join(' ').includes(scheduleStatus.RESERVED) ? t('bookingForm.reserved') : t('bookingForm.available')}</span>
                </Tooltip>
            </div>
        );
    };

    const multiPeriodContainer = (overlappedPeriods: BollardAvailability[]) => {
        const maxOverlappedPeriods = moment.max(overlappedPeriods.map(op => moment(op.localEndTime, TIME_FORMAT)));
        const minOverlappedPeriods = moment.min(overlappedPeriods.map(op => moment(op.localStartTime, TIME_FORMAT)));

        return (
            <div
                className="schedule-table__bollard-container__schedule-container__overlaps"
                style={{ flex: maxOverlappedPeriods.diff(minOverlappedPeriods, 'minute') }}
            >
                {
                    overlappedPeriods.map(entry => {
                        const classNames = getEntryClassNames(
                            entry.isOccupied,
                            entry.reservationPeriodId === selectedReservationId && bollard.id === selectedBollardId,
                        );

                        const startTimeMoment = moment(entry.localStartTime, TIME_FORMAT);
                        const endTimeMoment = moment(entry.localEndTime, TIME_FORMAT);

                        return (
                            <div className="schedule-table__bollard-container__schedule-container__overlaps__container" key={`${bollard.id}${entry.reservationPeriodId}`}>
                                <div
                                    className={startTimeMoment.diff(minOverlappedPeriods, 'minute') !== 0 ? 'schedule-table__bollard-container__schedule-container__overlaps__container__wrapper--has-padding' : 'schedule-table__bollard-container__schedule-container__overlaps__container__wrapper'}
                                    style={{ flex: startTimeMoment.diff(minOverlappedPeriods, 'minute') }}
                                >
                                    <span
                                        className="schedule-table__bollard-container__schedule-container__schedule schedule-table__bollard-container__schedule-container__schedule--unavailable"
                                    />
                                </div>
                                <div
                                    className="schedule-table__bollard-container__schedule-container__overlaps__container__wrapper--has-padding"
                                    style={{ flex: endTimeMoment.diff(startTimeMoment, 'minutes') }}
                                >
                                    <Tooltip
                                        className={classNames.join(' ')}
                                        onClick={() => reserveSpot(entry, bollard)}
                                        arrow
                                        title={`${formatHourTime(entry.localStartTime)} - ${formatHourTime(entry.localEndTime)}`}
                                    >
                                        <span>{classNames.join(' ').includes(scheduleStatus.RESERVED) ? t('bookingForm.reserved') : t('bookingForm.available')}</span>
                                    </Tooltip>
                                </div>
                                <div
                                    className={maxOverlappedPeriods.diff(endTimeMoment, 'minute') !== 0 ? 'schedule-table__bollard-container__schedule-container__overlaps__container__wrapper--has-padding' : 'schedule-table__bollard-container__schedule-container__overlaps__container__wrapper'}
                                    style={{ flex: maxOverlappedPeriods.diff(endTimeMoment, 'minute') }}
                                >
                                    <span
                                        className="schedule-table__bollard-container__schedule-container__schedule schedule-table__bollard-container__schedule-container__schedule--unavailable"
                                    />
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        );
    };

    const groupPeriods = (se: BollardAvailability[] | null) => {
        const overlappingPeriods: BollardAvailability[] = [];
        const nonOverlappingPeriods: BollardAvailability[] = [];

        if (!se) {
            return;
        }

        // if child is length of 1 is a single period if length > 1 then its a overlapped periods
        if (se.length === 1) {
            return se.map(e => [e]);
        }

        const addToOverlapArray = (scheduleEntry: BollardAvailability) => {
            // because A overlaps B but B cloud not overlap C, so check first if B already in nonOverlapped array
            if (nonOverlappingPeriods.findIndex(op => op.reservationPeriodId === scheduleEntry.reservationPeriodId) >= 0) {
                const index = nonOverlappingPeriods.findIndex(op => op.reservationPeriodId === scheduleEntry.reservationPeriodId);
                nonOverlappingPeriods.splice(index, 1);
            }

            if (overlappingPeriods.findIndex(op => op.reservationPeriodId === scheduleEntry.reservationPeriodId) < 0) {
                overlappingPeriods.push(scheduleEntry);
            }
        };

        const addToNoNonOverlapArray = (scheduleEntry: BollardAvailability) => {
            // because A overlaps B but B cloud not overlap C, so check first if B already in overlap array
            if (overlappingPeriods.findIndex(op => op.reservationPeriodId === scheduleEntry.reservationPeriodId) >= 0) return;

            if (nonOverlappingPeriods.findIndex(op => op.reservationPeriodId === scheduleEntry.reservationPeriodId) < 0) {
                nonOverlappingPeriods.push(scheduleEntry);
            }
        };

        for (let i = 0; i < se.length; i++) {
            const se1 = se[i];
            for (let j = i + 1; j < se.length; j++) {
                const se2 = se[j];

                const se1StartTimeMoment = moment(se1.localStartTime, TIME_FORMAT);
                const se2StartTimeMoment = moment(se2.localStartTime, TIME_FORMAT);
                const se1EndTimeMoment = moment(se1.localEndTime, TIME_FORMAT);
                const se2EndTimeMoment = moment(se2.localEndTime, TIME_FORMAT);

                if ((se1EndTimeMoment.isAfter(se2StartTimeMoment) && se1StartTimeMoment.isBefore(se2EndTimeMoment))) {
                    addToOverlapArray(se1);
                    addToOverlapArray(se2);
                    continue;
                }

                addToNoNonOverlapArray(se1);
                addToNoNonOverlapArray(se2);
            }
        }

        // no need to check overlaps because of none
        if (overlappingPeriods.length === 0) {
            return [...nonOverlappingPeriods.map(p => [p])];
        }

        const sortPeriodGroupAscending = (a: BollardAvailability[], b: BollardAvailability[]) => {
            const aStartTime = moment.min(a.map(op => moment(op.localStartTime, TIME_FORMAT)));
            const bStartTime = moment.min(b.map(op => moment(op.localStartTime, TIME_FORMAT)));

            return aStartTime.isBefore(bStartTime) ? -1 : 1;
        };

        const finalGroupedPeriods: BollardAvailability[][] = [overlappingPeriods, ...nonOverlappingPeriods.map(p => [p])];
        return finalGroupedPeriods.sort(sortPeriodGroupAscending);
    };

    const emptyPeriodSpan = (spanNumber: number) => {
        return (
            <div
                className="schedule-table__bollard-container__schedule-container__wrapper"
                style={{ flex: spanNumber }}
            >
                <div className="schedule-table__bollard-container__schedule-container__wrapper--has-padding">
                    <span
                        className="schedule-table__bollard-container__schedule-container__schedule schedule-table__bollard-container__schedule-container__schedule--unavailable"
                    />
                </div>
            </div>
        );
    };

    const groupedPeriods = groupPeriods(scheduleEntries);
    const row: React.ReactNode[] = [];

    // mounts all periods into a list
    if (groupedPeriods) {
        groupedPeriods.forEach((currentGroupPeriod, index) => {
            if (currentGroupPeriod.length === 0) return null;

            const getEmptyTimeSpanBetweenPeriods = () => {
                const previousPeriod = groupedPeriods[index - 1];
                const currentPeriodStartTime = moment.min(currentGroupPeriod.map(period => moment(period.localStartTime, TIME_FORMAT)));

                if (!previousPeriod) {
                    return currentPeriodStartTime.diff(initialHour, 'minute');
                }

                const previousPeriodEndTime = moment.max(previousPeriod.map(period => moment(period.localEndTime, TIME_FORMAT)));
                return currentPeriodStartTime?.diff(previousPeriodEndTime, 'minute') || 0;
            };

            if (currentGroupPeriod.length === 1) {
            // single period
                const currentPeriod = [...currentGroupPeriod].pop();
                if (!currentPeriod) return null;

                const emptySpan = getEmptyTimeSpanBetweenPeriods();
                if (emptySpan) {
                    row.push(emptyPeriodSpan(emptySpan));
                }
                row.push(singlePeriodSpan(currentPeriod));
            } else {
            // multiple period
                const emptySpan = getEmptyTimeSpanBetweenPeriods();
                if (emptySpan) {
                    row.push(emptyPeriodSpan(emptySpan));
                }
                row.push(multiPeriodContainer(currentGroupPeriod));
            }
        });
    }

    // adds remaining empty span to complete a full hour (ex: last period ends at 20:15, the remaining 45 minutes aer added to complete to 21:00)
    if (groupedPeriods && groupedPeriods?.length >= 1) {
        const lastPeriod = groupedPeriods[groupedPeriods.length - 1];
        const lastPeriodEndTime = moment.max(lastPeriod.map(period => moment(period.localEndTime, TIME_FORMAT)));
        row.push(emptyPeriodSpan(finalHour.diff(lastPeriodEndTime, 'minute')));
    }

    return (
        <React.Fragment key={bollard.id}>
            <div className="schedule-table__bollard-container">
                {bollard.designation}
            </div>
            <div className="schedule-table__bollard-container__schedule-container">
                <div className="schedule-table__bollard-container__schedule-container__wrapper">
                    <div className="schedule-table__bollard-container__schedule-container__wrapper--has-padding">
                        <span
                            className="schedule-table__bollard-container__schedule-container__schedule schedule-table__bollard-container__schedule-container__schedule--unavailable"
                        />
                    </div>
                </div>
                {row}
            </div>
        </React.Fragment>
    );
};

export default withTranslationContext(BollardRow);
