/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';

import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { KeyedObject } from '../../types/general';
import { ErrorCode } from '../../types/errors';
import { FormValidatorError } from '../../utils/validations';

enum ErrorMessageType {
    Field = 'field',
    Logic = 'error',
}

interface OwnProps extends TranslationContext {
    field?: string;
    errors: FormValidatorError[] | null | undefined;
    type?: string;
    extraClass?: string;
    showAll?: boolean;
}

interface OwnState {}

const initialState: OwnState = {};

class ErrorMessage extends Component<OwnProps, OwnState> {
    state = initialState;

    getGeneralErrorMessage(error: KeyedObject): string {
        const { t } = this.props;
        const { errorCode } = error;

        let message = '';
        if (errorCode !== null) {
            message = t(`errors.${ErrorCode[errorCode]}`);
        }

        return message;
    }

    getFieldErrorMessage(error: KeyedObject): string {
        const { t } = this.props;
        const {
            min, max, size, typeOfViolation, decimalPoints,
        } = error;

        let message = '';
        if (typeOfViolation !== null) {
            switch (typeOfViolation) {
                case 'Size':
                    if (min && max) {
                        message = t('errors.Size', { min, max });
                    } else if (min) {
                        message = t('errors.SizeMin', { min });
                    } else if (max) {
                        message = t('errors.SizeMax', { max });
                    }
                    break;
                case 'SizeExact':
                    if (size) {
                        message = t('errors.SizeExact', { size });
                    }
                    break;
                case 'Max':
                    if (max) {
                        message = t('errors.Max', { max });
                    }
                    break;
                case 'Min':
                    if (min) {
                        message = t('errors.Min', { min });
                    }
                    break;
                case 'NotNull':
                    message = t('errors.NotNull');
                    break;
                case 'SizeMustBeGreater':
                    if (size) {
                        message = t('errors.SizeMustBeGreater', { size });
                    }
                    break;
                case 'DecimalPoints':
                    if (decimalPoints) {
                        message = t('errors.DecimalPoints', { decimalPoints });
                    }
                    break;
                case 'FiscalCodeByCountry':
                    message = t('errors.FiscalCodeByCountry');
                    break;
                case 'NotDecimal':
                    message = t('errors.notDecimal');
                    break;
                default:
                    message = t(`errors.${typeOfViolation}`);
                    break;
            }
        }
        return message;
    }

    getErrorMessage(error: KeyedObject): string {
        const { type } = this.props;

        let message = '';
        const errorType = type || ErrorMessageType.Field;

        switch (errorType) {
            case ErrorMessageType.Field:
                message = this.getFieldErrorMessage(error);
                break;
            case ErrorMessageType.Logic:
                message = this.getGeneralErrorMessage(error);
                break;
            default:
                break;
        }

        return message;
    }

    render() {
        const {
            field, errors, showAll, extraClass,
        } = this.props;

        if (!errors || errors.length < 1) return null;
        if (!showAll) {
            const [error] = errors;

            return (
                <span data-field={field} className={`field-error ${extraClass || ''}`}>
                    {this.getErrorMessage(error)}
                </span>
            );
        }

        return errors.map((error: KeyedObject, index: number) => {
            return (
                <span key={index} data-field={field} className={`field-error ${extraClass || ''}`}>
                    {this.getErrorMessage(error)}
                </span>
            );
        });
    }
}

export default withTranslationContext(ErrorMessage);
