/**
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */

import React, {
    FunctionComponent,
} from 'react';
import Pagination from '@material-ui/lab/Pagination';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { GenericFunction } from '../../utils/misc';

interface OwnProps extends TranslationContext {
    numberOfPages: number;
    onPageChange: GenericFunction;
    currentPage: number;
}

type Props = OwnProps;

const ConfirmModal: FunctionComponent<Props> = (props: Props) => {
    const {
        numberOfPages,
        onPageChange,
        currentPage,
        t,
    } = props;

    return (
        <div className="pagination">
            <button
                type="button"
                onClick={() => onPageChange(null, currentPage - 1)}
                disabled={numberOfPages === 0 || currentPage === 1}
            >
                <KeyboardArrowLeftIcon />
                {t('pagination.previous')}
            </button>
            <Pagination
                count={numberOfPages}
                shape="rounded"
                onChange={onPageChange}
                page={currentPage}
                hidePrevButton
                hideNextButton
            />
            <button
                type="button"
                onClick={() => onPageChange(null, currentPage + 1)}
                disabled={numberOfPages === 0 || currentPage === numberOfPages}
            >
                {t('pagination.next')}
                <KeyboardArrowRight />
            </button>
        </div>
    );
};

export default withTranslationContext(ConfirmModal);
