/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { GeoPoint } from './general';

export interface Bollard {
    businessId: number;
    businessAreaId: number;
    createdDate: Date;
    id: number;
    lastModifiedDate: Date;
    location: GeoPoint;
    day: string;
    qrCode: string;
    qrCodeId: string;
    status: BollardStatus;
    designation: string;
    fastMPStickerId: string;
}

export enum BollardStatus {
    Active = 'ACTIVE',
    Disabled = 'DISABLED',
    Unlocalized = 'UNLOCALIZED',
}

export interface BollardOccupiedPeriod {
    startTime: string;
    endTime: string;
}

export interface BollardOccupation {
    day: string;
    bollardId: number;
    occupiedPeriods: Array<BollardOccupiedPeriod>;
}

export interface BollardOccupationReservationDetailsPayload {
    day: string;
    bollardId: number;
    startTime: string;
    endTime: string;
}

export interface PayloadLocation {
    lat: number;
    lng: number;
}

export interface PayloadLocationUpdated {
    designation: string;
    location: PayloadLocation;
    status: string;
    fastMPStickerId: string;
    userId: number;
}

export enum BollardOccupationDayStatus {
    Full = 'FULL',
    Free = 'FREE',
    Partial = 'PARTIAL',
    Unavailable = 'UNAVAILABLE',
}

export interface BollardDayOccupation {
    bollardId: number;
    day: string;
    designations: string[];
    status: BollardOccupationDayStatus;
}
