/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, {
    Component, FormEvent, ReactElement, ReactNode,
} from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
    Backdrop, Button, CircularProgress, FormHelperText, TextField,
} from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/WorkOutline';
import AddressIcon from '@material-ui/icons/PinDrop';
import GoogleAutocomplete from 'react-google-autocomplete';
import axios from 'axios';

import Autocomplete from '@material-ui/lab/Autocomplete';
import MonetizationIcon from '@material-ui/icons/MonetizationOnOutlined';
import Map from '../elements/Map';
import { mapCenter } from '../../utils/constants';
import ErrorMessage from '../elements/ErrorMessage';
import FormCheckbox from '../elements/FormCheckbox';
import { AppRoute } from '../../types/routes';
import Loader from '../elements/Loader';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import FormTextField from '../elements/FormTextField';
import FormSelectField from '../elements/FormSelectField';
import { Country, KeyedObject, SelectOption } from '../../types/general';
import { displayNotification, NotificationType } from '../../utils/notifications';
import { FormValidatorError, getErrorsForField, handleFormSubmitFailure } from '../../utils/validations';
import { countriesURL } from '../../services/general';
import { customMarker } from '../../utils/maps';
import { BusinessesContext, withBusinessesContext } from '../controllers/BusinessesContext';
import FormPasswordField from '../elements/FormPasswordField';
import { MerchantsContext, withMerchantsContext } from '../controllers/MerchantsContext';
import { AuthorizationContext, withAuthorizationContext } from '../controllers/AuthorizationContext';
import { Permission } from '../../types/authorization';
import { User } from '../../types/users';

enum BusinessBaseFormField {
    Merchant = 'merchantId',
    Name = 'name',
    Email = 'email',
    CountryCode = 'phoneCountry',
    PhoneNumber = 'phoneNumber',
    NIF = 'fiscalCode',
    IBAN = 'bankAccount',
    SWIFT = 'bicSwift',
    Code = 'permanentCertificateAccessCode',
}

enum BusinessAddressFormField {
    Country = 'country',
    Address = 'street',
    ZipCode = 'postalCode',
    City = 'city',
    Latitude = 'lat',
    Longitude = 'lng',
}

enum BusinessEuPagoFormField {
    Key = 'chave',
    ClientId = 'client_id',
    ClientSecret = 'client_secret',
    GrantType = 'grant_type',
    Password = 'password',
    Username = 'username',
}

export interface BusinessBaseFormFields {
    [BusinessBaseFormField.Merchant]: string;
    [BusinessBaseFormField.Name]: string;
    [BusinessBaseFormField.Email]: string;
    [BusinessBaseFormField.CountryCode]: string;
    [BusinessBaseFormField.PhoneNumber]: string;
    [BusinessBaseFormField.NIF]: string;
    [BusinessBaseFormField.IBAN]: string;
    [BusinessBaseFormField.SWIFT]?: string;
    [BusinessBaseFormField.Code]: string;
}

export interface BusinessAddressFormFields {
    [BusinessAddressFormField.Country]: SelectOption | null | undefined;
    [BusinessAddressFormField.Address]: string;
    [BusinessAddressFormField.ZipCode]: string;
    [BusinessAddressFormField.City]: string;
    [BusinessAddressFormField.Latitude]: number | string;
    [BusinessAddressFormField.Longitude]: number | string;
}

export interface BusinessEuPagoFormFields {
    [BusinessEuPagoFormField.Key]: string;
    [BusinessEuPagoFormField.ClientId]: string;
    [BusinessEuPagoFormField.ClientSecret]: string;
    [BusinessEuPagoFormField.GrantType]: string;
    [BusinessEuPagoFormField.Password]: string;
    [BusinessEuPagoFormField.Username]: string;
}

interface OwnProps extends TranslationContext, BusinessesContext, RouteComponentProps, MerchantsContext, AuthorizationContext {
    businessId?: string;
}

interface OwnState {
    isReady: boolean;
    validated: boolean | null;
    fields: BusinessBaseFormFields;
    address: BusinessAddressFormFields;
    euPago: BusinessEuPagoFormFields;
    errors: KeyedObject | null;
    countries: SelectOption[];
    phoneCountries: SelectOption[];
    merchants: SelectOption[];
    mapRef: google.maps.Map | null;
    hasEuPagoIntegrated: boolean;
    showEuPagoForm: boolean;
    changeEuPagoFields: boolean;
    fetchingBusiness: boolean;
    failBusinessFetch: boolean;
    marker: google.maps.Marker | null;
}

const initialState: OwnState = {
    isReady: false,
    validated: null,
    fields: {
        [BusinessBaseFormField.Merchant]: '',
        [BusinessBaseFormField.Name]: '',
        [BusinessBaseFormField.Email]: '',
        [BusinessBaseFormField.CountryCode]: 'PT',
        [BusinessBaseFormField.PhoneNumber]: '',
        [BusinessBaseFormField.NIF]: '',
        [BusinessBaseFormField.IBAN]: '',
        [BusinessBaseFormField.SWIFT]: '',
        [BusinessBaseFormField.Code]: '',
    },
    address: {
        [BusinessAddressFormField.Country]: null,
        [BusinessAddressFormField.Address]: '',
        [BusinessAddressFormField.ZipCode]: '',
        [BusinessAddressFormField.City]: '',
        [BusinessAddressFormField.Latitude]: '',
        [BusinessAddressFormField.Longitude]: '',
    },
    euPago: {
        [BusinessEuPagoFormField.Key]: '',
        [BusinessEuPagoFormField.ClientId]: '',
        [BusinessEuPagoFormField.ClientSecret]: '',
        [BusinessEuPagoFormField.GrantType]: 'password',
        [BusinessEuPagoFormField.Password]: '',
        [BusinessEuPagoFormField.Username]: '',
    },
    phoneCountries: [{ label: 'PT +351', value: 'PT' }],
    countries: [{ label: 'Portugal', value: 'PT' }],
    merchants: [],
    errors: null,
    mapRef: null,
    hasEuPagoIntegrated: false,
    showEuPagoForm: false,
    changeEuPagoFields: true,
    fetchingBusiness: false,
    failBusinessFetch: false,
    marker: null,
};

class BusinessForm extends Component<OwnProps, OwnState> {
    constructor(props: OwnProps) {
        super(props);
        const { businessId } = props;
        this.state = {
            ...initialState,
            fetchingBusiness: businessId !== undefined,
        };
    }

    componentDidMount() {
        this.prepare();
    }

    onMapMounted = (ref: google.maps.Map) => {
        const { businessId } = this.props;
        const { address } = this.state;
        if (ref && businessId !== undefined) {
            setTimeout(() => {
                ref.panTo({
                    lat: Number(address[BusinessAddressFormField.Latitude]),
                    lng: Number(address[BusinessAddressFormField.Longitude]),
                });
                ref.setZoom(19);
            }, 50);
        }
        this.setState({ mapRef: ref }, this.refreshMarker);
    };

    onMapClick = (evt: any) => {
        this.setState(
            {
                address: {
                    ...this.state.address,
                    lat: evt.latLng.lat(),
                    lng: evt.latLng.lng(),
                },
            },
            this.refreshMarker,
        );
    };

    onGoogleAutocompleteChange = (evt: FormEvent<HTMLInputElement>) => {
        this.setState({
            address: {
                ...this.state.address,
                [evt.currentTarget.name]: evt.currentTarget.value,
            },
        });
    };

    onAutocompleteChange = (event: any, newValue: SelectOption | null) => {
        this.setState({
            address: {
                ...this.state.address,
                [BusinessAddressFormField.Country]: newValue,
            },
        });
    };

    onFieldsChange = (name: string, value: string): void => {
        const { fields } = this.state;

        this.setState(
            {
                fields: {
                    ...fields,
                    [name]: value,
                },
            },
            () => {
                if (name === BusinessBaseFormField.Name) {
                    this.refreshMarker();
                }
            },
        );
    };

    onAddressChange = (name: string, value: string): void => {
        const { address } = this.state;

        this.setState({
            address: {
                ...address,
                [name]: value,
            },
        });
    };

    onEuPagoChange = (name: string, value: string): void => {
        const { euPago } = this.state;

        this.setState({
            euPago: {
                ...euPago,
                [name]: value,
            },
        });
    };

    onFormSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        const {
            submitNewBusiness,
            submitEditBusiness,
            validateNewBusiness,
            validateEditBusiness,
            businessId,
        } = this.props;
        const {
            fields, address, euPago, showEuPagoForm, changeEuPagoFields,
        } = this.state;

        let hasEuPagoBody = false;
        if (businessId !== undefined) {
            if (changeEuPagoFields && showEuPagoForm) hasEuPagoBody = true;
        } else {
            if (showEuPagoForm) hasEuPagoBody = true;
        }

        const errors = businessId === undefined
            ? validateNewBusiness({ ...fields, ...address, ...euPago }, hasEuPagoBody)
            : validateEditBusiness({ ...fields, ...address, ...euPago }, hasEuPagoBody);

        this.setState(
            { errors },
            () => {
                if (!errors) {
                    const { bicSwift } = fields;

                    const payload = {
                        ...fields,
                        [BusinessBaseFormField.Merchant]: fields[BusinessBaseFormField.Merchant] ? Number(fields[BusinessBaseFormField.Merchant]) : '',
                        [BusinessBaseFormField.SWIFT]: bicSwift !== '' ? bicSwift : undefined,
                        [BusinessBaseFormField.IBAN]: fields[BusinessBaseFormField.IBAN].split(' ').join(''),
                        address: {
                            ...address,
                            country: address.country?.value,
                        },
                        deleteEuPagoApiKey: (businessId !== undefined && !showEuPagoForm) ? true : undefined,
                        euPagoValidateAccountRequest: hasEuPagoBody ? euPago : undefined,
                    };

                    businessId === undefined
                        ? submitNewBusiness(payload, this.onFormSubmitSuccess, this.onFormSubmitFailure)
                        : submitEditBusiness(businessId, payload, this.onFormSubmitSuccess, this.onFormSubmitFailure);
                }
            },
        );
    };

    onFormSubmitSuccess = () => {
        const { t, history, businessId } = this.props;

        displayNotification({
            message: businessId === undefined ? t('businessForm.success') : t('businessForm.successEdit'),
            type: NotificationType.Success,
        });

        history.push(AppRoute.Businesses);
    };

    onFormSubmitFailure = () => {
        const { t, createBusinessErrors, updateBusinessErrors } = this.props;

        this.setState({ errors: handleFormSubmitFailure(t, createBusinessErrors, updateBusinessErrors) });
    };

    prepare = async () => {
        const { t, getMerchants, checkPermission } = this.props;
        const countriesOpts: SelectOption[] = [];
        const phoneCountriesOpts: SelectOption[] = [];
        const merchantsOpts: SelectOption[] = [];

        try {
            const { data: countries } = await axios.get(countriesURL());

            if (checkPermission([Permission.MERCHANT_LIST_VIEW])) {
                const merchantsResponse = await getMerchants({ _limit: 999 });

                if (merchantsResponse) {
                    merchantsResponse.data.forEach((merchant: User) => {
                        merchantsOpts.push({
                            label: `${merchant.firstName} ${merchant.lastName}`,
                            value: merchant.id,
                        });
                    });
                }
            }

            countries.forEach((country: Country) => {
                phoneCountriesOpts.push({
                    label: `${country.code} ${country.phoneCode}`,
                    value: country.code,
                });
                countriesOpts.push({
                    label: t(`countries.${country.code}`),
                    value: country.code,
                });
            });
            countriesOpts.sort((a, b) => a.label.localeCompare(b.label));

            this.setState({
                countries: countriesOpts,
                phoneCountries: phoneCountriesOpts,
                merchants: merchantsOpts,
            });
            this.fetchBusiness();
        } catch {
            this.setState({
                countries: [],
                phoneCountries: [],
                merchants: [],
            });
            this.fetchBusiness();
        }
    };

    fetchBusiness = () => {
        const { getBusiness, businessId } = this.props;
        const {
            Key,
        } = BusinessEuPagoFormField;
        const { euPago } = this.state;

        if (businessId !== undefined) {
            getBusiness(businessId).then(business => {
                if (!business) {
                    this.setState({ fetchingBusiness: false, failBusinessFetch: true });
                } else {
                    const fields = {
                        [BusinessBaseFormField.Merchant]: String(business.merchant.id),
                        [BusinessBaseFormField.Name]: business[BusinessBaseFormField.Name],
                        [BusinessBaseFormField.Email]: business[BusinessBaseFormField.Email],
                        [BusinessBaseFormField.CountryCode]: business[BusinessBaseFormField.CountryCode],
                        [BusinessBaseFormField.PhoneNumber]: String(business[BusinessBaseFormField.PhoneNumber]),
                        [BusinessBaseFormField.NIF]: business[BusinessBaseFormField.NIF] || '',
                        [BusinessBaseFormField.IBAN]: business[BusinessBaseFormField.IBAN] || '',
                        [BusinessBaseFormField.SWIFT]: business[BusinessBaseFormField.SWIFT] || '',
                        [BusinessBaseFormField.Code]: business[BusinessBaseFormField.Code] || '',
                    };
                    const address = {
                        [BusinessAddressFormField.Country]: this.getCountryOption(
                            business.address[BusinessAddressFormField.Country],
                        ),
                        [BusinessAddressFormField.Address]: business.address[BusinessAddressFormField.Address],
                        [BusinessAddressFormField.ZipCode]: business.address[BusinessAddressFormField.ZipCode],
                        [BusinessAddressFormField.City]: business.address[BusinessAddressFormField.City],
                        [BusinessAddressFormField.Latitude]: business.address[BusinessAddressFormField.Latitude],
                        [BusinessAddressFormField.Longitude]: business.address[BusinessAddressFormField.Longitude],
                    };

                    this.setState({
                        fields,
                        address,
                        fetchingBusiness: false,
                        euPago: { ...euPago, [Key]: business.euPagoApiKey },
                        showEuPagoForm: business.euPagoIntegrated || false,
                        hasEuPagoIntegrated: business.euPagoIntegrated,
                        changeEuPagoFields: !business.euPagoApiKey,
                    });
                }
            });
        }
    };

    refreshMarker = () => {
        const {
            mapRef, address, marker, fields,
        } = this.state;
        if (mapRef) {
            if (marker) {
                marker.setMap(null);
            }
            const mark = customMarker(
                mapRef,
                { lat: Number(address.lat), lng: Number(address.lng) },
                undefined,
                fields[BusinessBaseFormField.Name],
            );
            this.setState({ marker: mark });
        }
    };

    getCountryOption(countryCode: string) {
        const { countries } = this.state;
        return countries.find(country => country.value === countryCode);
    }

    shouldAutocomplete = (evt: any) => {
        const { countries } = this.state;
        const found = countries.find(country => country.label === evt.target.value);
        if (found) {
            this.setState({
                address: {
                    ...this.state.address,
                    [BusinessAddressFormField.Country]: found,
                },
            });
        }
    };

    handlePlaceSelected = (place: any) => {
        const { mapRef, address } = this.state;
        const { t } = this.props;
        if (!mapRef) {
            displayNotification({
                message: t('errors.wrong'),
                type: NotificationType.Danger,
            });
            return;
        }
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        const street = place.address_components.find((component: any) => component.types.includes('route'));
        const city = place.address_components.find((component: any) => component.types.includes('political'));
        const postalCode = place.address_components.find((component: any) => component.types.includes('postal_code'));
        mapRef.panTo({ lat, lng });
        mapRef.setZoom(19);
        this.setState(
            {
                address: {
                    ...this.state.address,
                    lat,
                    lng,
                    [BusinessAddressFormField.Address]: street
                        ? address[BusinessAddressFormField.Address].length > street.long_name.length
                            ? address[BusinessAddressFormField.Address]
                            : street.long_name
                        : '',
                    [BusinessAddressFormField.ZipCode]: postalCode ? postalCode.long_name : '',
                    [BusinessAddressFormField.City]: city ? city.long_name : '',
                },
            },
            this.refreshMarker,
        );
    };

    renderErrors(name: string, fieldErrors: FormValidatorError[]): ReactNode {
        return <ErrorMessage errors={fieldErrors} field={name} />;
    }

    renderBusinessSection(): ReactElement {
        const {
            t, createBusinessFetching, updateBusinessFetching, businessId,
            checkPermission,
        } = this.props;
        const {
            fields, address, errors, countries, phoneCountries, merchants,
        } = this.state;

        return (
            <>
                <span className="section-header">
                    <BusinessIcon /> {t('businessForm.businessSection')}
                </span>
                {checkPermission([Permission.MERCHANT_LIST_VIEW]) && (
                    <div className="wide-form__grid-container__double-space">
                        <FormSelectField
                            name={BusinessBaseFormField.Merchant}
                            errors={errors}
                            disabled={createBusinessFetching || updateBusinessFetching || businessId !== undefined}
                            onChange={this.onFieldsChange}
                            options={merchants}
                            value={fields[BusinessBaseFormField.Merchant]}
                            testId="merchant-select"
                            label={t('businessForm.merchantLabel')}
                        />
                    </div>
                )}
                <div className="wide-form__grid-container__double-space">
                    <FormTextField
                        name={BusinessBaseFormField.Name}
                        value={fields[BusinessBaseFormField.Name]}
                        onChange={this.onFieldsChange}
                        placeholder={t('businessForm.nameLabel')}
                        label={`${t('businessForm.nameLabel')}*`}
                        errors={errors}
                        disabled={createBusinessFetching || updateBusinessFetching}
                        testId="name-input"
                    />
                </div>
                <FormTextField
                    name={BusinessBaseFormField.Email}
                    value={fields[BusinessBaseFormField.Email]}
                    onChange={this.onFieldsChange}
                    placeholder={t('businessForm.emailLabel')}
                    label={`${t('businessForm.emailLabel')}*`}
                    errors={errors}
                    disabled={createBusinessFetching || updateBusinessFetching}
                    testId="email-input"
                />
                <div className="wide-form__grid-container__double-input">
                    <FormSelectField
                        name={BusinessBaseFormField.CountryCode}
                        errors={errors}
                        disabled={createBusinessFetching || updateBusinessFetching}
                        onChange={this.onFieldsChange}
                        options={phoneCountries}
                        value={fields[BusinessBaseFormField.CountryCode]}
                        testId="phone-code-select"
                    />
                    <FormTextField
                        name={BusinessBaseFormField.PhoneNumber}
                        value={fields[BusinessBaseFormField.PhoneNumber]}
                        onChange={this.onFieldsChange}
                        placeholder={t('businessForm.phoneLabel')}
                        label={`${t('businessForm.phoneLabel')}*`}
                        errors={errors}
                        disabled={createBusinessFetching || updateBusinessFetching}
                        testId="phone-number-input"
                    />
                </div>
                <FormTextField
                    name={BusinessBaseFormField.NIF}
                    value={fields[BusinessBaseFormField.NIF]}
                    onChange={this.onFieldsChange}
                    placeholder={t('businessForm.nifLabel')}
                    label={`${t('businessForm.nifLabel')}*`}
                    errors={errors}
                    disabled={createBusinessFetching || updateBusinessFetching}
                    testId="nif-input"
                />
                <FormTextField
                    name={BusinessBaseFormField.IBAN}
                    value={fields[BusinessBaseFormField.IBAN]}
                    onChange={this.onFieldsChange}
                    placeholder={t('businessForm.ibanLabel')}
                    label={`${t('businessForm.ibanLabel')}*`}
                    errors={errors}
                    disabled={createBusinessFetching || updateBusinessFetching}
                    testId="iban-input"
                />
                <FormTextField
                    name={BusinessBaseFormField.SWIFT}
                    value={
                        fields[BusinessBaseFormField.SWIFT] === undefined
                            ? ''
                            : String(fields[BusinessBaseFormField.SWIFT])
                    }
                    onChange={this.onFieldsChange}
                    placeholder={t('businessForm.swiftLabel')}
                    label={`${t('businessForm.swiftLabel')}`}
                    errors={errors}
                    disabled={createBusinessFetching || updateBusinessFetching}
                    testId="swift-input"
                />
                <Autocomplete
                    className="country-autocomplete"
                    options={countries}
                    getOptionLabel={(option: SelectOption) => option.label}
                    value={address[BusinessAddressFormField.Country]}
                    onChange={this.onAutocompleteChange}
                    data-testid="country-autocomplete"
                    ListboxProps={{
                        'data-testid': 'country-autocomplete-options-wrapper',
                    }}
                    renderInput={(params: any) => (
                        <TextField
                            {...params}
                            label={`${t('businessForm.countryLabel')}*`}
                            placeholder={t('businessForm.countryLabel')}
                            error={getErrorsForField(BusinessAddressFormField.Country, errors).length > 0}
                            helperText={this.renderErrors(
                                BusinessAddressFormField.Country,
                                getErrorsForField(BusinessAddressFormField.Country, errors),
                            )}
                            onChange={this.shouldAutocomplete}
                            margin="normal"
                            data-testid="country-autocomplete-input"
                        />
                    )}
                />
                <div className="wide-form__grid-container__double-space">
                    <FormTextField
                        name={BusinessBaseFormField.Code}
                        value={fields[BusinessBaseFormField.Code]}
                        onChange={this.onFieldsChange}
                        placeholder={t('businessForm.codeLabel')}
                        label={t('businessForm.codeLabel')}
                        errors={errors}
                        disabled={createBusinessFetching || updateBusinessFetching}
                        testId="code-input"
                    />
                </div>
            </>
        );
    }

    renderAddressSection(): ReactElement {
        const { t, createBusinessFetching, updateBusinessFetching } = this.props;
        const { address, errors } = this.state;
        return (
            <>
                <span className="section-header">
                    <AddressIcon /> {t('businessForm.addressSection')}
                </span>
                <span className="wide-form__grid-container__section-tooltip">{t('businessForm.addressTooltip')}</span>
                <div className="wide-form__grid-container__double-space">
                    <div
                        className={`custom-material-input ${
                            getErrorsForField(BusinessAddressFormField.Address, errors).length > 0
                                ? 'custom-material-input--errors'
                                : ''
                        }`}
                    >
                        {window.google !== undefined && (
                            <GoogleAutocomplete
                                className="google-autocomplete"
                                onPlaceSelected={this.handlePlaceSelected}
                                onChange={this.onGoogleAutocompleteChange}
                                name={BusinessAddressFormField.Address}
                                value={address[BusinessAddressFormField.Address]}
                                types={['address']}
                                placeholder={t('businessForm.addressLabel')}
                            />
                        )}
                        <span className="bar" />
                        <label htmlFor={BusinessAddressFormField.Address}>{t('businessForm.addressLabel')}*</label>
                        <FormHelperText>
                            <ErrorMessage
                                errors={getErrorsForField(BusinessAddressFormField.Address, errors)}
                                field={BusinessAddressFormField.Address}
                            />
                        </FormHelperText>
                    </div>
                </div>
                <FormTextField
                    name={BusinessAddressFormField.ZipCode}
                    value={address[BusinessAddressFormField.ZipCode]}
                    onChange={this.onAddressChange}
                    placeholder={t('businessForm.zipCodeLabel')}
                    label={`${t('businessForm.zipCodeLabel')}*`}
                    errors={errors}
                    disabled={createBusinessFetching || updateBusinessFetching}
                    testId="zip-code-input"
                />
                <FormTextField
                    name={BusinessAddressFormField.City}
                    value={address[BusinessAddressFormField.City]}
                    onChange={this.onAddressChange}
                    placeholder={t('businessForm.cityLabel')}
                    label={`${t('businessForm.cityLabel')}*`}
                    errors={errors}
                    disabled={createBusinessFetching || updateBusinessFetching}
                    testId="city-input"
                />
                <FormTextField
                    name={BusinessAddressFormField.Longitude}
                    value={String(address[BusinessAddressFormField.Longitude])}
                    onChange={this.onAddressChange}
                    placeholder={t('businessForm.longitudeLabel')}
                    label={t('businessForm.longitudeLabel')}
                    errors={errors}
                    disabled
                    testId="longitude-input"
                />
                <FormTextField
                    name={BusinessAddressFormField.Latitude}
                    value={String(address[BusinessAddressFormField.Latitude])}
                    onChange={this.onAddressChange}
                    placeholder={t('businessForm.latitudeLabel')}
                    label={t('businessForm.latitudeLabel')}
                    errors={errors}
                    disabled
                    testId="latitude-input"
                />
            </>
        );
    }

    renderEuPagoSection = (): React.ReactNode => {
        const {
            t, createBusinessFetching, updateBusinessFetching, businessId,
        } = this.props;
        const {
            euPago, errors, showEuPagoForm, changeEuPagoFields, hasEuPagoIntegrated,
        } = this.state;
        const {
            Username, Password, ClientId, ClientSecret, Key,
        } = BusinessEuPagoFormField;

        const header = (
            <React.Fragment>
                <FormCheckbox
                    name="showEuPagoForm"
                    checked={showEuPagoForm}
                    onChange={() => this.setState({ showEuPagoForm: !showEuPagoForm })}
                    errors={errors}
                    disabled={createBusinessFetching || updateBusinessFetching}
                    label={t('businessForm.euPagoSection.label')}
                />
                {
                    hasEuPagoIntegrated && (
                        <FormCheckbox
                            name="changeEuPagoFields"
                            checked={changeEuPagoFields}
                            onChange={() => this.setState({ changeEuPagoFields: !changeEuPagoFields })}
                            errors={errors}
                            disabled={createBusinessFetching || updateBusinessFetching}
                            label={t('businessForm.euPagoSection.editLabel')}
                        />
                    )
                }
            </React.Fragment>
        );

        if (!showEuPagoForm) return header;

        return (
            <React.Fragment>
                {header}
                <span className="section-header">
                    <MonetizationIcon /> {t('businessForm.euPagoSection.header')}
                </span>
                {
                    changeEuPagoFields && (
                        <React.Fragment>
                            <FormTextField
                                name={Username}
                                value={euPago[Username]}
                                onChange={this.onEuPagoChange}
                                placeholder={t('businessForm.euPagoSection.username')}
                                label={`${t('businessForm.euPagoSection.username')}*`}
                                errors={errors}
                                disabled={createBusinessFetching || updateBusinessFetching}
                                testId="eupago-username-input"
                            />
                            <FormPasswordField
                                name={Password}
                                value={euPago[Password]}
                                onChange={this.onEuPagoChange}
                                placeholder={t('businessForm.euPagoSection.password')}
                                label={`${t('businessForm.euPagoSection.password')}*`}
                                errors={errors}
                                disabled={createBusinessFetching || updateBusinessFetching}
                                testId="eupago-password-input"
                            />
                            <FormTextField
                                name={ClientId}
                                value={euPago[ClientId]}
                                onChange={this.onEuPagoChange}
                                placeholder={t('businessForm.euPagoSection.clientId')}
                                label={`${t('businessForm.euPagoSection.clientId')}*`}
                                errors={errors}
                                disabled={createBusinessFetching || updateBusinessFetching}
                                testId="eupago-client-id-input"
                            />
                            <FormTextField
                                name={ClientSecret}
                                value={euPago[ClientSecret]}
                                onChange={this.onEuPagoChange}
                                placeholder={t('businessForm.euPagoSection.clientSecret')}
                                label={`${t('businessForm.euPagoSection.clientSecret')}*`}
                                errors={errors}
                                disabled={createBusinessFetching || updateBusinessFetching}
                                testId="eupago-client-secret-input"
                            />
                        </React.Fragment>
                    )
                }

                <FormTextField
                    name={Key}
                    value={euPago[Key]}
                    onChange={this.onEuPagoChange}
                    placeholder={t('businessForm.euPagoSection.key')}
                    label={`${t('businessForm.euPagoSection.key')}*`}
                    errors={errors}
                    disabled={createBusinessFetching || updateBusinessFetching || (!changeEuPagoFields && businessId !== undefined)}
                    testId="eupago-key-input"
                />
            </React.Fragment>
        );
    }

    render(): ReactElement {
        const {
            t, createBusinessFetching, updateBusinessFetching, businessId,
        } = this.props;
        const { fetchingBusiness, failBusinessFetch } = this.state;
        if (fetchingBusiness) {
            return <Loader />;
        }
        if (failBusinessFetch) {
            return <h3>{t('businessForm.invalidBusiness')}</h3>;
        }
        return (
            <form onSubmit={this.onFormSubmit} className="wide-form" data-testid="business-form">
                <Backdrop open={createBusinessFetching}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className="wide-form__grid-container">
                    {this.renderBusinessSection()}
                    {this.renderAddressSection()}
                </div>

                <Map
                    zoom={8}
                    height="500px"
                    center={mapCenter}
                    onMapMounted={this.onMapMounted}
                    onClick={this.onMapClick}
                    togglable
                />
                <div className="wide-form__grid-container">
                    {this.renderEuPagoSection()}
                </div>
                <Button
                    className="margin-button"
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={createBusinessFetching || updateBusinessFetching}
                >
                    {businessId === undefined ? t('businessForm.submitButton') : t('businessForm.editButton')}
                </Button>
            </form>
        );
    }
}

export default withTranslationContext(
    withBusinessesContext(
        withRouter(
            withMerchantsContext(
                withAuthorizationContext(
                    BusinessForm,
                ),
            ),
        ),
    ),
);
