/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { KeyedObject } from '../types/general';

export enum AreasAction {
    CREATE_AREA_REQUEST = 'CREATE_AREA_REQUEST',
    CREATE_AREA_SUCCESS = 'CREATE_AREA_SUCCESS',
    CREATE_AREA_FAILURE = 'CREATE_AREA_FAILURE',
    UPDATE_AREA_REQUEST = 'UPDATE_AREA_REQUEST',
    UPDATE_AREA_SUCCESS = 'UPDATE_AREA_SUCCESS',
    UPDATE_AREA_FAILURE = 'UPDATE_AREA_FAILURE',
}

// Create Area

export interface NewAreaRequestActionType {
    type: typeof AreasAction.CREATE_AREA_REQUEST;
}

export interface NewAreaSuccessActionType {
    type: typeof AreasAction.CREATE_AREA_SUCCESS;
}

export interface NewAreaFailureActionType {
    type: typeof AreasAction.CREATE_AREA_FAILURE;
    payload: KeyedObject | null;
}

// Update Area

export interface EditAreaRequestActionType {
    type: typeof AreasAction.UPDATE_AREA_REQUEST;
}

export interface EditAreaSuccessActionType {
    type: typeof AreasAction.UPDATE_AREA_SUCCESS;
}

export interface EditAreaFailureActionType {
    type: typeof AreasAction.UPDATE_AREA_FAILURE;
    payload: KeyedObject | null;
}

export type AreasActionTypes =
    | NewAreaRequestActionType
    | NewAreaSuccessActionType
    | NewAreaFailureActionType
    | EditAreaRequestActionType
    | EditAreaSuccessActionType
    | EditAreaFailureActionType;
