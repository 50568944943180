/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

// Login

import axios, { AxiosError } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

import { KeyedObject } from '../types/general';
import { BusinessesAction, BusinessesActionTypes } from './businesses_types';
import { BusinessRequestPayload } from '../types/businesses';
import { businessURL, businessesURL } from '../services/businesses';

// Create Business

export const newBusinessRequestActionCreator = (): BusinessesActionTypes => {
    return {
        type: BusinessesAction.CREATE_BUSINESS_REQUEST,
    };
};

export const newBusinessSuccessActionCreator = (): BusinessesActionTypes => {
    return {
        type: BusinessesAction.CREATE_BUSINESS_SUCCESS,
    };
};

export const newBusinessFailureActionCreator = (error: KeyedObject | null): BusinessesActionTypes => {
    return {
        type: BusinessesAction.CREATE_BUSINESS_FAILURE,
        payload: error,
    };
};

export const requestCreateBusiness = (payload: BusinessRequestPayload, onSuccess?: Function, onFailure?: Function) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(newBusinessRequestActionCreator());
        try {
            const { status } = await axios.post(businessesURL(), payload);

            if (status === 200) {
                dispatch(newBusinessSuccessActionCreator());

                if (onSuccess) onSuccess();
            }
        } catch (error) {
            let formErrors = {};
            if (error) {
                formErrors = (error as AxiosError).response?.data;
            }
            dispatch(newBusinessFailureActionCreator(formErrors));
            if (onFailure) onFailure();
        }
    };
};

// Update Business

export const editBusinessRequestActionCreator = (): BusinessesActionTypes => {
    return {
        type: BusinessesAction.UPDATE_BUSINESS_REQUEST,
    };
};

export const editBusinessSuccessActionCreator = (): BusinessesActionTypes => {
    return {
        type: BusinessesAction.UPDATE_BUSINESS_SUCCESS,
    };
};

export const editBusinessFailureActionCreator = (error: KeyedObject | null): BusinessesActionTypes => {
    return {
        type: BusinessesAction.UPDATE_BUSINESS_FAILURE,
        payload: error,
    };
};

export const requestUpdateBusiness = (
    businessId: string,
    payload: BusinessRequestPayload,
    onSuccess?: Function,
    onFailure?: Function,
) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(editBusinessRequestActionCreator());
        try {
            const { status } = await axios.put(businessURL(businessId), payload);

            if (status === 200) {
                dispatch(editBusinessSuccessActionCreator());

                if (onSuccess) onSuccess();
            }
        } catch (error) {
            let formErrors = {};
            if (error) {
                formErrors = (error as AxiosError).response?.data;
            }
            dispatch(editBusinessFailureActionCreator(formErrors));
            if (onFailure) onFailure();
        }
    };
};
