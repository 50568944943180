/*
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useState } from 'react';
import {
    Box,
    Button,
    Dialog, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';
import QrReader from 'react-qr-reader';
import FormTextField from './FormTextField';
import { withTranslationContext, TranslationContext } from '../controllers/TranslationContext';
import { displayNotification, NotificationType } from '../../utils/notifications';

interface OwnProps {
    defaultStickerId?: string;
    onClose: () => void;
    onSave: (fastStickerId: string) => void;
}

type Props = TranslationContext & OwnProps;

export const FastMPModal: FunctionComponent<OwnProps> = withTranslationContext((props: Props) => {
    const {
        defaultStickerId, onSave, onClose, t,
    } = props;

    const [stickerId, setStickerId] = useState(defaultStickerId || '');
    const [showQRCodeReader, setShowQRCodeReader] = useState(false);

    const displayInvalidQRCode = () => {
        displayNotification({
            message: t('errors.fastMPInvalid'),
            type: NotificationType.Danger,
        });
    };

    const getStickerId = (data: string | null) => {
        try {
            if (data) {
                const url = new URL(data);
                const urlStickerId = url.searchParams.get('i');
                if (urlStickerId) {
                    setStickerId(urlStickerId);
                    setShowQRCodeReader(false);
                    return;
                }
            }
            displayInvalidQRCode();
        } catch (error) {
            displayInvalidQRCode();
            setShowQRCodeReader(false);
        }
    };

    return (
        <React.Fragment>
            <Dialog
                open
                onClose={onClose}
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">{t('areaForm.fastMP.title')}</DialogTitle>
                <DialogContent>
                    {
                        !showQRCodeReader && (
                            <React.Fragment>
                                <FormTextField
                                    name="fastMPStickerId"
                                    value={stickerId}
                                    onChange={(_: string, value: string) => setStickerId(value)}
                                    placeholder={t('areaForm.fastMP.stickerId')}
                                    label={`${t('areaForm.fastMP.stickerId')}`}
                                    errors={null}
                                />
                                <Box display="flex" justifyContent="center" my={2}>
                                    <Button onClick={() => setShowQRCodeReader(true)}>
                                        {t('areaForm.fastMP.openQRCodeReader')}
                                    </Button>
                                </Box>
                            </React.Fragment>
                        )
                    }
                    
                    {
                        showQRCodeReader && (
                            <QrReader
                                delay={300}
                                onError={displayInvalidQRCode}
                                onScan={getStickerId}
                            />
                        )
                    }
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        {t('general.cancel')}
                    </Button>
                    <Button
                        onClick={() => {
                            onSave(stickerId);
                            onClose();
                        }}
                        color="primary"
                        autoFocus
                    >
                        {t('general.save')}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
});
