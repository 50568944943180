/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';

const api = 'authentication';

export const loginURL = (): string => {
    return `${API_URL}/${api}/login`;
};

export const activateURL = (token: string | undefined): string => {
    return `${API_URL}/${api}/activate/${token}`;
};

export const forgotPassURL = (): string => {
    return `${API_URL}/${api}/recover-password`;
};

export const resetPassURL = (): string => {
    return `${API_URL}/${api}/reset-password`;
};

export const logoutURL = (): string => {
    return `${API_URL}/${api}/logout`;
};
