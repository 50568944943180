/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';
import { KeyedObject } from '../types/general';
import { generateId, objectToParams } from '../utils/misc';
import { UpdateBusinessStatus } from '../types/businesses';

const api = 'businesses';

export const businessesURL = (filters?: KeyedObject): string => {
    const params = objectToParams(filters);
    return `${API_URL}/${api}${params}`;
};

export const businessesListURL = (filters?: KeyedObject): string => {
    const params = objectToParams(filters);
    return `${API_URL}/${api}/roles${params}`;
};

export const businessURL = (id: string): string => {
    return `${API_URL}/${api}/${id}`;
};

export const businessAreasURL = (id: string | number, filters?: KeyedObject): string => {
    const params = objectToParams(filters);
    return `${API_URL}/${api}/${id}/areas${params}`;
};

export const areasURL = (filters?: KeyedObject, businessId?: string): string => {
    return businessAreasURL(businessId || '0', filters);
};

export const areaURL = (businessId: number, areaId: number): string => {
    return `${API_URL}/${api}/${businessId}/areas/${areaId}`;
};

export const qrCodesPdfURL = (businessId: string, areaId: string, cached = false): string => {
    return `${API_URL}/${api}/${businessId}/areas/${areaId}/qrCodes/pdf?totalAmount=${!cached ? generateId() : ''}`;
};

export const predictAreaURL = (id: string | number, filters?: KeyedObject): string => {
    const params = objectToParams(filters);
    return `${API_URL}/${api}/${id}/areas/predict-bollards${params}`;
};

export const bollardsURL = (businessId: string | number, areaId: string | number, filters?: KeyedObject): string => {
    const params = objectToParams(filters);
    return `${API_URL}/${api}/${businessId}/areas/${areaId}/bollards${params}`;
};

export const updateBollardURL = (businessId: string, areaId: string, bollardQRCode: string): string => {
    return `${API_URL}/${api}/${businessId}/areas/${areaId}/bollards/${bollardQRCode}`;
};

export const schedulesURL = (businessId: string, areaId: string, day: string): string => {
    return `${API_URL}/${api}/${businessId}/areas/${areaId}/schedule/${day}`;
};

export const updateBusinessStatusURL = (id: number, status: UpdateBusinessStatus): string => {
    return `${API_URL}/${api}/${id}/status?status=${status}`;
};
