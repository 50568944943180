/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { AppRoute } from '../../types/routes';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import PageHeader from '../elements/PageHeader';
import MerchantForm from '../views/MerchantForm';

interface MatchParams {
    merchantId?: string;
}

interface OwnProps extends TranslationContext, RouteComponentProps<MatchParams> {}

const EditMerchantScreen: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        match: {
            params: { merchantId },
        },
        t,
    } = props;

    return (
        <div className="merchants-form-container">
            <PageHeader to={AppRoute.Merchants} back={t('general.back')} title={t('editMerchantScreen.title')} />
            <MerchantForm merchantId={merchantId} />
        </div>
    );
}

export default withTranslationContext(withRouter(EditMerchantScreen));
