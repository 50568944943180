/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, FormEvent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import FormTextField from '../elements/FormTextField';
import { KeyedObject } from '../../types/general';
import { displayNotification, NotificationType } from '../../utils/notifications';
import { AppRoute } from '../../types/routes';
import { handleFormSubmitFailure } from '../../utils/validations';
import loginImage from '../../assets/images/login.svg';

enum RecoverFormField {
    Email = 'email',
}

export interface RecoverFormFields {
    [RecoverFormField.Email]: string;
}

interface OwnProps extends TranslationContext, AuthenticationContext, RouteComponentProps {}

interface OwnState {
    fields: RecoverFormFields;
    errors: KeyedObject | null;
}

const initialState: OwnState = {
    fields: {
        [RecoverFormField.Email]: '',
    },
    errors: null,
};

class RecoverForm extends Component<OwnProps, OwnState> {
    state = initialState;

    onInputChange = (name: string, value: string): void => {
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                [name]: value,
            },
        });
    };

    onFormSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        const { submitForgotPass, validateForgotPass } = this.props;
        const { fields } = this.state;

        const errors = validateForgotPass(fields);

        this.setState(
            {
                ...this.state,
                errors,
            },
            () => {
                if (!errors) {
                    submitForgotPass(fields, this.onFormSubmitSuccess, this.onFormSubmitFailure);
                }
            },
        );
    };

    onFormSubmitSuccess = () => {
        const { history, t } = this.props;
        displayNotification({
            message: t('recoverScreen.success'),
            type: NotificationType.Success,
        });
        history.push(AppRoute.Login);
    };

    onFormSubmitFailure = () => {
        const { t, forgotPassErrors } = this.props;

        this.setState({ errors: handleFormSubmitFailure(t, forgotPassErrors) });
    };

    render() {
        const { t } = this.props;
        const { fields, errors } = this.state;
        return (
            <form className="narrow-form" onSubmit={this.onFormSubmit} data-testid="recover-form">
                <img className="login-image" src={loginImage} alt="login" />
                <p>{t('recoverScreen.description')}</p>
                <FormTextField
                    name={RecoverFormField.Email}
                    value={fields[RecoverFormField.Email]}
                    onChange={this.onInputChange}
                    placeholder={t('recoverScreen.emailLabel')}
                    errors={errors}
                />
                <Button className="white-button" variant="contained" color="primary" type="submit">
                    {t('recoverScreen.send')}
                </Button>
            </form>
        );
    }
}

export default withTranslationContext(withAuthenticationContext(withRouter(RecoverForm)));
