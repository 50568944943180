/*
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */

import React, {
    FunctionComponent, useState,
} from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import Back from '@material-ui/icons/ArrowBack';
import { Button } from '@material-ui/core';
import moment from 'moment/moment';
import BookingForm, { BookingFormField, BookingFormFields, BookingStateNames } from '../views/BookingForm';
import { AppRoute } from '../../types/routes';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { BollardsContext, withBollardsContext } from '../controllers/BollardsContext';
import { displayNotification, NotificationType } from '../../utils/notifications';
import { BollardScheduleReservation, PayloadSubmitNewReservationMultipleDays } from '../../types/bookings';
import { DATE_FORMAT } from '../../utils/constants';
import { BookingsContext, withBookingsContext } from '../controllers/BookingsContext';
import { KeyedObject } from '../../types/general';

type OwnProps = TranslationContext & BollardsContext & BookingsContext & RouteComponentProps;

interface OwnState {
    registerUser: boolean;
    reservationsSelected: Array<BollardScheduleReservation>;
    errors: KeyedObject | null;
    showErrorDaysWithoutPeriodSelected: boolean;
    isFetching: boolean;
    price: number;
}

export const AddBookingScreen: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const [fields, setFields] = useState<BookingFormFields>({
        [BookingFormField.Business]: '',
        [BookingFormField.Area]: '',
        [BookingFormField.Days]: [moment()],
        [BookingFormField.Bollard]: '',
        [BookingFormField.FirstName]: '',
        [BookingFormField.LastName]: '',
        [BookingFormField.Email]: '',
        [BookingFormField.PhoneNumber]: '',
        [BookingFormField.Code]: 'PT',
        [BookingFormField.NumberOfPeople]: '1',
        [BookingFormField.Observations]: '',
    });
    const [states, setStates] = useState<OwnState>({
        errors: null,
        registerUser: false,
        reservationsSelected: [],
        showErrorDaysWithoutPeriodSelected: false,
        isFetching: false,
        price: 0,
    });

    const onFieldsChange = (values: BookingFormFields): void => {
        setFields(values);
    };

    const onStateChange = (name: string, value: any): void => {
        setStates(prevState => {
            return {
                ...prevState,
                [name]: value,
            };
        });
    };

    const onFormSubmit = (): void => {
        const { t, validateNewBooking, submitNewBookingsReservationMultipleDays } = props;

        const errors = validateNewBooking({ ...fields }, states.registerUser);

        onStateChange(BookingStateNames.Errors, errors);

        if (errors) return;

        if (states.reservationsSelected.length === 0) {
            displayNotification({
                message: t('bookingForm.selectOnePeriod'),
                type: NotificationType.Danger,
            });
            return;
        }

        const days = fields[BookingFormField.Days];
        if (days.length > states.reservationsSelected.length) {
            onStateChange(BookingStateNames.ShowErrorDaysWithoutPeriodSelected, true);
            return;
        }

        onStateChange(BookingStateNames.IsFetching, true);

        const payload: PayloadSubmitNewReservationMultipleDays = {
            ...fields,
            [BookingFormField.Days]: states.reservationsSelected.map(reservation => {
                return {
                    bollardId: reservation.bollard.id,
                    day: reservation.days[0].format(DATE_FORMAT),
                    reservationPeriodId: reservation.periodId,
                };
            }),
            [BookingFormField.FirstName]: fields[BookingFormField.FirstName] || null,
            [BookingFormField.LastName]: fields[BookingFormField.LastName] || null,
            [BookingFormField.PhoneNumber]: fields[BookingFormField.PhoneNumber] || null,
            [BookingFormField.Email]: fields[BookingFormField.Email] || null,
            [BookingFormField.NumberOfPeople]: fields[BookingFormField.NumberOfPeople],
            phoneCountry: fields[BookingFormField.Code],
            createAccount: states.registerUser,
            country: fields[BookingFormField.Code],
        };

        submitNewBookingsReservationMultipleDays(payload, onFormSubmitSuccess, onFormSubmitFailure);
    };

    const onFormSubmitSuccess = () => {
        const { t, history } = props;

        displayNotification({
            message: t('bookingForm.success'),
            type: NotificationType.Success,
        });

        history.push(AppRoute.Bookings);
    };

    const onFormSubmitFailure = (errors: string | null) => {
        const { t } = props;

        if (errors) {
            displayNotification({
                message: errors,
                type: NotificationType.Danger,
            });
        } else {
            displayNotification({
                message: t('errors.genericAPIError'),
                type: NotificationType.Danger,
            });
        }

        onStateChange(BookingStateNames.IsFetching, false);
    };

    const { t } = props;

    return (
        <div className="bookings-form-container-new">
            <div className="header">
                <div className="header__left">
                    <Link to={AppRoute.Bookings} className="return-link">
                        <Back />
                    </Link>
                    <h2 className="header__left__header">{t('addBookingScreen.title')}</h2>
                </div>
                <div className="header__right">
                    <div className="header__right__price">
                        <h1>{t('bookingForm.total')}</h1>
                        <p>{states.price} €</p>
                    </div>
                    <div className="header__right__btn-submit">
                        <Button
                            className="margin-button"
                            variant="contained"
                            color="primary"
                            onClick={onFormSubmit}
                        >
                            {t('bookingForm.reserve')}
                        </Button>
                    </div>
                </div>
            </div>
            <BookingForm
                onFieldsChange={onFieldsChange}
                fields={fields}
                reservationsSelected={states.reservationsSelected}
                isFetching={states.isFetching}
                showErrorDaysWithoutPeriodSelected={states.showErrorDaysWithoutPeriodSelected}
                errors={states.errors}
                registerUser={states.registerUser}
                onStateChange={onStateChange}
                price={states.price}
            />
        </div>
    );
};

export default withTranslationContext(withBookingsContext(withBollardsContext(AddBookingScreen)));
