/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, ReactElement } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import moment from 'moment';
import { Backdrop, CircularProgress } from '@material-ui/core';

import { groupBy } from 'lodash';
import Lottie from 'react-lottie';
import {
    Bollard, BollardOccupation, BollardDayOccupation, BollardStatus,
} from '../../../types/bollards';
import { Area } from '../../../types/areas';
import BookingsCalendarTop from './BookingsCalendarTop';
import { BookingsContext, withBookingsContext } from '../../controllers/BookingsContext';
import { AreasContext, withAreasContext } from '../../controllers/AreasContext';
import BookingsCalendarIndividualAvailability from './BookingsCalendarIndividualAvailability';
import BookingCalendarBiWeekly from './BookingCalendarBiWeekly';
import { OrderQuery } from '../../../types/general';
import { Business } from '../../../types/businesses';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { BollardsContext, withBollardsContext } from '../../controllers/BollardsContext';
import NoDataLottie from '../../../assets/lottie/no-data.json';
import FormSelectField from '../FormSelectField';

interface OwnProps extends BookingsContext, AreasContext, TranslationContext, BollardsContext, RouteComponentProps {
    area: Area | null;
    areas: Area[] | null;
    business: Business | null;
    businesses: Business[] | null;
    onSelectBusiness: (value: string) => void;
    onSelectArea: (value: number) => void;
}

interface OwnState {
    isFetching: boolean;
    isRequestingMore: boolean;
    showCalendarBiWeekly: boolean;
    daySelected: moment.Moment;
    bollards: Bollard[];
    bollardsOccupation: BollardOccupation[];
    bollardsWeeklyOccupation: { [key: number]: BollardDayOccupation[] };
    bollardsTotal: number;
    bollardsParams: {
        limit: number;
        page: number;
        order: OrderQuery;
        status: BollardStatus;
    };
}

const initialState: OwnState = {
    isFetching: false,
    isRequestingMore: false,
    showCalendarBiWeekly: true,
    daySelected: moment().subtract(moment().weekday(), 'day'),
    bollards: [],
    bollardsOccupation: [],
    bollardsWeeklyOccupation: {},
    bollardsTotal: 0,
    bollardsParams: {
        limit: 20,
        page: 0,
        order: OrderQuery.Ascending,
        status: BollardStatus.Active,
    },
};

class BookingsCalendar extends Component<OwnProps, OwnState> {
    constructor(props: OwnProps) {
        super(props);
        const {
            calendarBookingBollardsData,
        } = this.props;

        this.state = initialState;

        if (calendarBookingBollardsData) {
            this.state = {
                ...initialState,
                showCalendarBiWeekly: false,
                daySelected: moment(calendarBookingBollardsData.daySelected),
            };
        }
    }

    componentDidMount() {
        this.getBollards();
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>) {
        const { area, business } = this.props;

        const changedBusiness = business && business.id !== prevProps.business?.id;
        const changedArea = area && area.id !== prevProps.area?.id;
        if ((changedBusiness && changedArea) || changedArea) {
            this.setState({
                bollardsParams: {
                    ...initialState.bollardsParams,
                    page: 0,
                },
            }, () => this.getBollards(true));
        }
    }

    onDaySelectedChange = (day: moment.Moment) => {
        const { bollardsParams } = this.state;
        const { setBookingCalendarBollardsData } = this.props;

        const daySelectedToString = day.format('YYYY-MM-DD');

        setBookingCalendarBollardsData({ bollard: null, daySelected: String(daySelectedToString) });

        this.setState({
            daySelected: day,
            bollards: [],
            bollardsTotal: 0,
            bollardsParams: {
                ...bollardsParams,
                page: 0,
            },
        }, () => this.getBollards(true));
    };

    onToggleCalendarType = () => {
        const { showCalendarBiWeekly, bollardsParams } = this.state;

        const newShowCalendarBiWeekly = !showCalendarBiWeekly;
        this.setState({
            showCalendarBiWeekly: newShowCalendarBiWeekly,
            daySelected: newShowCalendarBiWeekly ? initialState.daySelected : moment(),
            bollardsParams: {
                ...bollardsParams,
                page: 0,
            },
        }, () => this.getBollards(true));
    };

    onDaySelectedInBiWeekly = (day: moment.Moment) => {
        const { setBookingCalendarBollardsData } = this.props;

        const daySelectedToString = day.format('YYYY-MM-DD');

        setBookingCalendarBollardsData({ bollard: null, daySelected: String(daySelectedToString) });
        this.setState({
            showCalendarBiWeekly: false,
            daySelected: day,
        }, () => this.getBollardsOccupation());
    };

    getBollards = async (refreshList = false) => {
        const {
            getBollards, area, business,
        } = this.props;

        const { bollardsParams, bollards } = this.state;

        if (!business || !area) return;

        this.setState({
            isFetching: true,
        });

        const bollardsData = await getBollards(business.id, area.id, {
            status: bollardsParams.status,
            _order: bollardsParams.order,
            _limit: bollardsParams.limit,
            _page: bollardsParams.page,
        });

        const newBollards = bollardsData?.data || [];

        this.setState({
            isRequestingMore: false,
            bollards: refreshList ? newBollards : [...bollards.concat(newBollards)],
            bollardsTotal: bollardsData ? Number(bollardsData.total) : 0,
        }, this.getBollardsOccupation);
    };

    getBollardsOccupation = () => {
        const { showCalendarBiWeekly } = this.state;

        if (showCalendarBiWeekly) {
            this.getBollardWeeklyAvailability();
        } else {
            this.getBollardsDailyOccupation();
        }
    };

    getBollardWeeklyAvailability = async () => {
        const { getMultipleBollardsWeeklyAvailability } = this.props;
        const { daySelected, bollards } = this.state;

        const bollardsIds = bollards.map(b => b.id);
        const bollardsOccupation = await getMultipleBollardsWeeklyAvailability(bollardsIds, daySelected.format('YYYY-MM-DD'), moment(daySelected).add(14, 'days').format('YYYY-MM-DD'));
        const auxOccupation = bollardsOccupation.flatMap(b => b);
        const occupationGroupedByBollards: { [key: number]: BollardDayOccupation[] } = groupBy(auxOccupation, 'bollardId');

        this.setState({
            isFetching: false,
            bollardsWeeklyOccupation: occupationGroupedByBollards,
        });
    };

    getBollardsDailyOccupation = async () => {
        const {
            getMultipleBollardsDailyOccupation,
            calendarBookingBollardsData,
        } = this.props;
        const { bollards, daySelected } = this.state;

        let bollardsOccupation;

        if (calendarBookingBollardsData && calendarBookingBollardsData.daySelected) {
            bollardsOccupation = await getMultipleBollardsDailyOccupation(bollards.map(b => b.id), calendarBookingBollardsData.daySelected);
        } else {
            bollardsOccupation = await getMultipleBollardsDailyOccupation(bollards.map(b => b.id), daySelected.format('YYYY-MM-DD'));
        }

        this.setState({
            isFetching: false,
            bollardsOccupation,
        });
    };

    requestMore = () => {
        const {
            bollardsParams, bollardsTotal, bollards, isRequestingMore,
        } = this.state;

        if (bollards.length < bollardsTotal && !isRequestingMore) {
            this.setState({
                isRequestingMore: true,
                bollardsParams: {
                    ...bollardsParams,
                    page: bollardsParams.page + 1,
                },
            }, this.getBollards);
        }
    };

    renderNoDataPlaceholder(): ReactElement {
        const { t } = this.props;

        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: NoDataLottie,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
            },
        };

        return (
            <div className="placeholder-no-data">
                <Lottie
                    options={defaultOptions}
                    height={194}
                    width={194}
                />
                <p className="placeholder-no-data__title">{t('bookingCalendar.noDataTitle')}</p>
                <p className="placeholder-no-data__message">{t('bookingCalendar.noDataMessage')}</p>
            </div>
        );
    }

    render() {
        const {
            t, area, business, areas, businesses, onSelectBusiness, onSelectArea,
        } = this.props;
        const {
            bollards, daySelected, showCalendarBiWeekly, isFetching, bollardsOccupation,
            bollardsTotal, bollardsWeeklyOccupation,
        } = this.state;

        return (
            <React.Fragment>
                {isFetching && (
                    <Backdrop open data-testid="loader">
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}
                <div className="section-header section-header--margin section-header--spaced">
                    <div className="bookings-page__filters">
                        <div className="bookings-page__filters__input-group">
                            {businesses && (
                                <FormSelectField
                                    name="business"
                                    onChange={(name: string, value: string) => onSelectBusiness(value)}
                                    options={businesses.map(b => ({ label: b.name, value: String(b.id) }))}
                                    value={business ? String(business.id) : null}
                                    label={t('bookingCalendar.businessLabel')}
                                    disabled={isFetching}
                                    errors={null}
                                />
                            )}
                            {areas && (
                                <FormSelectField
                                    name="area"
                                    onChange={(name: string, value: string) => onSelectArea(Number(value))}
                                    options={areas && areas.map(a => ({ label: a.name, value: String(a.id) }))}
                                    value={area ? String(area.id) : null}
                                    label={t('bookingCalendar.areaLabel')}
                                    disabled={isFetching}
                                    errors={null}
                                />
                            )}
                        </div>
                    </div>
                    {business && area && (
                        <div className="schedule-table-buttons">
                            <button
                                type="button"
                                className={showCalendarBiWeekly ? 'selected' : ''}
                                onClick={this.onToggleCalendarType}
                            >
                                {t('bookingCalendar.biWeekly')}
                            </button>
                            <button
                                type="button"
                                className={showCalendarBiWeekly ? '' : 'selected'}
                                onClick={this.onToggleCalendarType}
                                data-testid="individual-availability-btn"
                            >
                                {t('bookingCalendar.individualAvailability')}
                            </button>
                        </div>
                    )}
                </div>
                {business && area && bollards.length > 0 ? (
                    <div className="booking-calendar">
                        <BookingsCalendarTop
                            onSelectDay={this.onDaySelectedChange}
                            daySelected={daySelected}
                            showBiWeekly={showCalendarBiWeekly}
                        />
                        {showCalendarBiWeekly ? (
                            <BookingCalendarBiWeekly
                                bollards={bollards}
                                dateSelected={daySelected}
                                onDaySelected={this.onDaySelectedInBiWeekly}
                                occupations={bollardsWeeklyOccupation}
                                hasMore={bollards.length < bollardsTotal}
                                requestMoreBollards={this.requestMore}
                            />
                        ) : (
                            <BookingsCalendarIndividualAvailability
                                area={area}
                                bollards={bollards}
                                occupations={bollardsOccupation}
                                hasMore={bollards.length < bollardsTotal}
                                requestMoreBollards={this.requestMore}
                            />
                        )}
                    </div>
                ) : this.renderNoDataPlaceholder()}
            </React.Fragment>
        );
    }
}

export default withRouter(withBookingsContext(withAreasContext(withTranslationContext(withBollardsContext(BookingsCalendar)))));
