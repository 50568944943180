/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Back from '@material-ui/icons/ArrowBack';
import { IconButton } from '@material-ui/core';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { AppRoute } from '../../types/routes';
import { BookingsContext, withBookingsContext } from '../controllers/BookingsContext';
import { KeyedObject } from '../../types/general';
import BookingDetails from '../views/BookingDetails';

interface MatchParams {
    bookingId?: string;
}

interface OwnProps extends TranslationContext, BookingsContext, RouteComponentProps<MatchParams> {}

interface OwnState {}

const initialState: OwnState = {};

class BookingDetailsScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    onBackClick = () => {
        const { history, location } = this.props;

        const locationState = location.state ? location.state as KeyedObject : null;
        let isPreviousLocationBookingsCalendar;

        if (locationState && locationState.from) {
            isPreviousLocationBookingsCalendar = locationState.from.includes('/bookings-calendar');
        }

        if (isPreviousLocationBookingsCalendar) {
            history.push({
                pathname: AppRoute.BookingsCalendar,
                state: { from: location.pathname },
            });
        } else {
            history.push(AppRoute.Bookings);
        }
    };

    render() {
        const {
            match: {
                params: { bookingId },
            },
            t,
        } = this.props;

        return (
            <div className="bookings-form-container">
                <div className="bookings-form-container--header-container">
                    <IconButton
                        className="return-link"
                        data-testid="back-button"
                        disableRipple
                        onClick={() => this.onBackClick()}
                    >
                        <Back />
                    </IconButton>
                    <h2 className="bookings-form-container--header-container__header">
                        <span className="bookings-form-container--header-container__header--title">{t('bookingDetailsScreen.title')}</span>
                        <span> / #{bookingId}</span>
                    </h2>
                </div>
                <BookingDetails bookingId={bookingId} />
            </div>
        );
    }
}

export default withBookingsContext(withTranslationContext(withRouter(BookingDetailsScreen)));
