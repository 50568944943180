/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { QueryParams } from './general';
import { User } from './users';

export interface Business {
    id: number;
    createdDate?: Date;
    lastModifiedDate?: Date;
    name: string;
    email: string;
    phoneCountry: string;
    phoneNumber: number;
    status: BusinessStatus;
    contractType?: Contract;
    address: Address;
    merchant: User;
    numberOfAreas: number;
    fiscalCode?: string;
    bankAccount?: string;
    bicSwift?: string;
    permanentCertificateAccessCode?: string;
    euPagoIntegrated: boolean;
    euPagoApiKey: string;
}

export interface Address {
    street: string;
    postalCode: string;
    locality: string;
    city: string;
    country: string;
    lat: number;
    lng: number;
}

export interface BusinessParams extends QueryParams {
    _q: string;
    merchantId?: number;
    status?: BusinessStatus;
}

export enum BusinessStatus {
    All = '',
    Active = 'ACTIVE',
    Disabled = 'DISABLED',
    Pending = 'PENDING',
}

export enum Contract {
    Exclusive = 'EXCLUSIVE',
    NonExclusive = 'NON_EXCLUSIVE',
}

export interface BusinessRequestPayload {}

export interface BusinessResponsePayload {}

export enum UpdateBusinessStatus {
    Active = 'ACTIVE',
    Disabled = 'DISABLED',
}