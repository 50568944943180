/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, {
    FunctionComponent, ReactElement, useCallback, useEffect, useState,
} from 'react';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TablePagination from '@material-ui/core/TablePagination';
import MenuItem from '@material-ui/core/MenuItem';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import OptionsIcon from '@material-ui/icons/MoreHoriz';
import { Link } from 'react-router-dom';
import DetailsIcon from '@material-ui/icons/Assignment';
import DeleteIcon from '@material-ui/icons/Delete';
import { Menu } from '@material-ui/core';
import Lottie from 'react-lottie';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { InvitationType, ListViewOptions } from '../../types/invitations';
import InvitationsTable from '../elements/InvitationsTable';
import Can from '../containers/Can';
import { Permission } from '../../types/authorization';
import NewInvitationModal from '../elements/NewInvitationModal';
import { ReservationManagersContext, withReservationManagersContext } from '../controllers/ReservationManagersContext';
import { OrderQuery, QueryParams } from '../../types/general';
import { ReservationManager } from '../../types/reservationManager';
import { AuthorizationContext, withAuthorizationContext } from '../controllers/AuthorizationContext';
import { MerchantsContext, withMerchantsContext } from '../controllers/MerchantsContext';
import { buildRoute } from '../../utils/misc';
import { AppRoute } from '../../types/routes';
import ConfirmModal from '../elements/ConfirmModal';
import { displayNotification, NotificationType } from '../../utils/notifications';
import NoDataLottie from '../../assets/lottie/no-data.json';
import Loader from '../elements/Loader';

type OwnProps = TranslationContext & ReservationManagersContext & AuthorizationContext & MerchantsContext;

const ReservationManagersScreen: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t, getReservationManagers, checkPermission, selectedMerchantId,
    } = props;

    const [viewSelected, setViewSelected] = useState(checkPermission([Permission.RESERVATION_MANAGERS_LIST_SCREEN]) ? ListViewOptions.ActualUsers : ListViewOptions.Invitations);
    const [isFetching, setIsFetching] = useState(false);
    const [showInviteModal, setShowInviteModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [updateInvitationsList, setUpdateInvitationsList] = useState(false);
    const [reservationManagers, setReservationManagersList] = useState<ReservationManager[]>([]);
    const [total, setTotal] = useState(0);
    const [anchorElement, setAnchorElement] = useState<null | SVGSVGElement>(null);
    const [reservationManagerId, setReservationManagerId] = useState(0);
    const [listParams, setListParams] = useState<QueryParams>({
        _limit: 20,
        _order: OrderQuery.Ascending,
        _page: 0,
        _sort: 'name',
    });

    const fetchReservationManagers = useCallback(async () => {
        setIsFetching(true);
        const responseData = await getReservationManagers(listParams);
        setTotal(responseData?.total || 0);
        setReservationManagersList(responseData?.data || []);
        setIsFetching(false);
    }, [getReservationManagers, listParams]);

    useEffect(() => {
        if (viewSelected === ListViewOptions.ActualUsers) fetchReservationManagers();
    }, [viewSelected, listParams, fetchReservationManagers, selectedMerchantId]);

    const handleInvitationModalClose = (shouldUpdateList: boolean) => {
        setShowInviteModal(false);
        if (viewSelected === ListViewOptions.Invitations) setUpdateInvitationsList(shouldUpdateList);
    };

    const selectSort = (col: string) => {
        let newOrder = listParams._sort !== col ? OrderQuery.Ascending : OrderQuery.Descending;
        let newSort = col;
        if (listParams._sort === col && listParams._order === OrderQuery.Descending) {
            newOrder = OrderQuery.Ascending;
            newSort = 'name';
        }

        setListParams({
            ...listParams,
            _order: newOrder,
            _sort: newSort,
        });
    };

    const onChangePage = (page: number) => {
        setListParams({ ...listParams, _page: page });
    };

    const onChangeRowsPerPage = (newLimit: number) => {
        setListParams({ ...listParams, _limit: newLimit });
    };

    const activateMenu = (evt: React.MouseEvent<SVGSVGElement>, reservationManagerId: number) => {
        evt.stopPropagation();

        setAnchorElement(evt.currentTarget);
        setReservationManagerId(reservationManagerId);
    };

    const resetMenu = () => {
        setAnchorElement(null);
        setReservationManagerId(-1);
    };

    const renderTableHeadCell = (name: string, sortable = true): ReactElement => {
        let orderDirection: 'asc' | 'desc' = 'asc';
        if (listParams._sort === name && listParams._order === OrderQuery.Descending) {
            orderDirection = 'desc';
        }

        if (sortable) {
            return (
                <TableCell align="center">
                    <TableSortLabel
                        data-testid="table-header"
                        active={listParams._sort === name}
                        direction={orderDirection}
                        onClick={() => selectSort(name)}
                    >
                        {t(`reservationManagersScreen.${name}`)}
                    </TableSortLabel>
                </TableCell>
            );
        }

        return (
            <TableCell align="center">
                {t(`reservationManagersScreen.${name}`)}
            </TableCell>
        );
    };

    const renderReservationManagersTable = () => {
        if (reservationManagers.length === 0) {
            const defaultOptions = {
                loop: true,
                autoplay: true,
                animationData: NoDataLottie,
                rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                },
            };

            return (
                <div className="placeholder-no-data">
                    <Lottie
                        options={defaultOptions}
                        height={194}
                        width={194}
                    />
                    <p className="placeholder-no-data__title"> {t('reservationManagersScreen.noDataTitle')}</p>
                    <p className="placeholder-no-data__message">{t('reservationManagersScreen.noDataMessage')}</p>
                </div>
            );
        }

        return (
            <Paper>
                <TableContainer component={Paper}>
                    <Table>
                        <TableRow>
                            {renderTableHeadCell('name')}
                            {renderTableHeadCell('email')}
                            {renderTableHeadCell('status', false)}
                            {renderTableHeadCell('options', false)}
                        </TableRow>
                        <TableBody>
                            {reservationManagers.map(reservationManager => (
                                <TableRow key={reservationManager.email}>
                                    <TableCell
                                        align="center"
                                    >
                                        {`${reservationManager.name}`}
                                    </TableCell>
                                    <TableCell align="center">{reservationManager.email}</TableCell>
                                    <TableCell align="center">{t(`status.${reservationManager.status}`)}</TableCell>
                                    <TableCell align="center">
                                        <OptionsIcon
                                            className="options-icon"
                                            onClick={evt => activateMenu(evt, reservationManager.id)}
                                            data-testid="options-btn"
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Menu
                    className="area-menu-items"
                    anchorEl={anchorElement}
                    keepMounted
                    open={!!anchorElement}
                    onClose={resetMenu}
                >
                    <Link
                        to={buildRoute(AppRoute.ReservationManagerDetails, {
                            reservationManagerId,
                        })}
                    >
                        <MenuItem data-testid="reservation-details-btn">
                            <DetailsIcon />
                            {t('bookingsScreen.details')}
                        </MenuItem>
                    </Link>
                    <MenuItem data-testid="reservation-manager-remove-btn" onClick={() => setShowConfirmModal(true)}>
                        <DeleteIcon />
                        {t('reservationManagerDetailsScreen.remove')}
                    </MenuItem>
                </Menu>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20]}
                    component="div"
                    count={total}
                    rowsPerPage={listParams._limit}
                    page={listParams._page}
                    onPageChange={(_, page) => onChangePage(page)}
                    onRowsPerPageChange={e => onChangeRowsPerPage(Number(e.target.value))}
                />
            </Paper>
        );
    };
    const handleConfirmActionModal = () => {
        const { removeReservationManager } = props;

        removeReservationManager(reservationManagerId, removeOnSucces, removeOnFailure);

        const removedReservationManager = reservationManagers.filter(reservationManager => reservationManager.id !== reservationManagerId);
        setReservationManagersList(removedReservationManager);

        setShowConfirmModal(false);
        resetMenu();
    };

    const removeOnSucces = () => {
        displayNotification({
            message: t('reservationManagersScreen.removeSuccess'),
            type: NotificationType.Success,
        });
    };

    const removeOnFailure = () => {
        displayNotification({
            message: t('reservationManagersScreen.removeFailure'),
            type: NotificationType.Danger,
        });
    };

    const onCloseConfirmModal = () => {
        setShowConfirmModal(false);
        resetMenu();
    };

    if (isFetching) {
        return <Loader />;
    }

    return (
        <div className="reservation-managers-page">
            {showInviteModal && (
                <NewInvitationModal
                    onClose={handleInvitationModalClose}
                    invitationFor={InvitationType.ReservationManager}
                />
            )}
            <div className="reservation-managers-page__header">
                <h2>{t('reservationManagersScreen.title')}</h2>
                <div className="reservation-managers-page__header__button-wrapper">
                    <Can
                        actions={[Permission.INVITE_RESERVATION_MANAGER]}
                        yes={() => (
                            <Button
                                variant="contained"
                                color="primary"
                                data-testid="new-invitation-button"
                                onClick={() => setShowInviteModal(true)}
                            >
                                {t('reservationManagersScreen.sendInvite')}
                            </Button>
                        )}
                    />
                </div>
            </div>
            <div className="reservation-managers-page__filters">
                <div className="reservation-managers-page__filters__table-buttons">
                    <button
                        type="button"
                        className={viewSelected === ListViewOptions.ActualUsers ? 'selected' : ''}
                        onClick={() => setViewSelected(ListViewOptions.ActualUsers)}
                    >
                        {t('reservationManagersScreen.actualReservationManager')}
                    </button>
                    <button
                        type="button"
                        className={viewSelected === ListViewOptions.Invitations ? 'selected' : ''}
                        data-testid="individual-availability-btn"
                        onClick={() => setViewSelected(ListViewOptions.Invitations)}
                    >
                        {t('reservationManagersScreen.invitations')}
                    </button>
                </div>
            </div>
            {viewSelected === ListViewOptions.ActualUsers ? renderReservationManagersTable() : (
                <InvitationsTable
                    updateList={updateInvitationsList}
                    invitationsFor={InvitationType.ReservationManager}
                />
            )}
            <ConfirmModal
                onClose={onCloseConfirmModal}
                title={t('reservationManagersScreen.removeReservationManager')}
                onConfirm={handleConfirmActionModal}
                show={showConfirmModal}
                cancelButton={t('reservationManagerDetailsScreen.cancel')}
                okButton={t('reservationManagerDetailsScreen.yesRemove')}
                okButtonClass="delete"
            >
                <p>{t('reservationManagersScreen.sureToContinue')}</p>
            </ConfirmModal>
        </div>
    );
};

export default withTranslationContext(withMerchantsContext(withReservationManagersContext(withAuthorizationContext(ReservationManagersScreen))));
