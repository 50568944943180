/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';
import { KeyedObject } from '../types/general';
import { objectToParams } from '../utils/misc';

const api = 'customers';

export const clientsURL = (filters?: KeyedObject): string => {
    const params = objectToParams(filters);
    return `${API_URL}/${api}/list${params}`;
};