/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import axios, { AxiosError } from 'axios';

import { AreasContextProvider } from './AreasContext';
import { AppState } from '../../reducers/types';
import {
    areasURL,
    areaURL,
    predictAreaURL,
    bollardsURL,
    businessAreasURL,
    updateBollardURL,
    qrCodesPdfURL,
} from '../../services/businesses';
import { GeoPoint, KeyedObject, ListResponse } from '../../types/general';
import { validateForm } from '../../utils/validations';
import { ApiError } from '../../types/errors';
import { requestCreateArea, requestUpdateArea } from '../../actions/areas';
import { validations } from '../../types/validations';
import { Bollard } from '../../types/bollards';
import { COUNT_HEADER } from '../../utils/constants';
import { Area, AreaPredictBollardsResponse } from '../../types/areas';

interface StateProps {
    createAreaFetching: boolean;
    createAreaErrors: ApiError | null;
    updateAreaFetching: boolean;
    updateAreaErrors: ApiError | null;
}

interface OwnProps {
    children: any;
}

interface OwnState {}

type Props = OwnProps & StateProps & ReturnType<typeof mapDispatchToProps>;
type State = OwnState;

export class AreasController extends Component<Props, State> {
    // Requests

    getArea = async (businessId: number, areaId: number): Promise<Area | null> => {
        try {
            const { data } = await axios.get(areaURL(businessId, areaId));
            return data;
        } catch {
            return null;
        }
    };

    getAreas = async (filters?: KeyedObject, businessId?: string): Promise<ListResponse<Area> | null> => {
        try {
            const { data, headers } = await axios.get(areasURL(filters, businessId));
            return { data, total: headers[COUNT_HEADER] ? Number(headers[COUNT_HEADER]) : 0 };
        } catch {
            return null;
        }
    };

    getBusinessAreas = async (businessId: string, filters?: KeyedObject): Promise<ListResponse<Area> | null> => {
        try {
            const { data, headers } = await axios.get(businessAreasURL(businessId, filters));
            return { data, total: headers[COUNT_HEADER] ? Number(headers[COUNT_HEADER]) : 0 };
        } catch {
            return null;
        }
    };

    getBollards = async (businessId: string | number, areaId: string | number, filters?: KeyedObject): Promise<ListResponse<Bollard> | null> => {
        try {
            const { data, headers } = await axios.get(bollardsURL(businessId, areaId, filters));
            return { data, total: headers[COUNT_HEADER] ? Number(headers[COUNT_HEADER]) : 0 };
        } catch {
            return null;
        }
    };

    getQrCodesPdf = async (businessId: string, areaId: string): Promise<Blob | null> => {
        try {
            const { data } = await axios.get(qrCodesPdfURL(businessId, areaId), { responseType: 'blob' });
            return data;
        } catch {
            return null;
        }
    };

    // Predict Area

    validatePredictArea = (fields: any): KeyedObject | null => {
        const errors: KeyedObject | null = validateForm(fields, validations.predictArea);

        if (!errors || Object.keys(errors).length === 0) return null;
        return { fields: errors };
    };

    predictArea = async (
        businessId: number,
        areaId: string | undefined,
        forceUpdate: boolean,
        bollardRadius: number,
        vertices: GeoPoint[],
    ): Promise<AreaPredictBollardsResponse | null> => {
        try {
            const { data } = await axios.post(predictAreaURL(businessId, { areaId }), {
                bollardRadius,
                forceUpdate,
                vertices,
            });
            return data;
        } catch (error) {
            return (error as AxiosError).response?.data;
        }
    };

    // Create area

    validateNewArea = (fields: any): KeyedObject | null => {
        const errors: KeyedObject | null = validateForm(fields, validations.areaCreate);

        if (!errors || Object.keys(errors).length === 0) return null;
        return { fields: errors };
    };

    submitNewArea = (businessId: number, payload: FormData, onSuccess: Function, onFailure: Function) => {
        const { dispatchRequestCreateArea } = this.props;

        dispatchRequestCreateArea(businessId, payload, onSuccess, onFailure);
    };

    // Update area

    validateEditArea = (fields: any): KeyedObject | null => {
        const errors: KeyedObject | null = validateForm(fields, validations.areaUpdate);

        if (!errors || Object.keys(errors).length === 0) return null;
        return { fields: errors };
    };

    submitEditArea = (
        businessId: number,
        areaId: number,
        payload: FormData,
        onSuccess: Function,
        onFailure: Function,
    ) => {
        const { dispatchRequestUpdateArea } = this.props;

        dispatchRequestUpdateArea(businessId, areaId, payload, onSuccess, onFailure);
    };

    // Update Bollard

    updateBollard = async (businessId: string, areaId: string, bollard: Bollard, newBollard: Partial<Bollard>) => {
        try {
            await axios.post(updateBollardURL(businessId, areaId, bollard.qrCodeId), {
                ...bollard,
                ...newBollard,
            });
            return true;
        } catch (error) {
            return (error as AxiosError).response?.data;
        }
    };

    render() {
        const {
            children, createAreaErrors, createAreaFetching, updateAreaErrors, updateAreaFetching,
        } = this.props;

        return (
            <AreasContextProvider
                value={{
                    createAreaErrors,
                    createAreaFetching,
                    updateAreaErrors,
                    updateAreaFetching,
                    getArea: this.getArea,
                    getAreas: this.getAreas,
                    getBusinessAreas: this.getBusinessAreas,
                    getBollards: this.getBollards,
                    getQrCodesPdf: this.getQrCodesPdf,
                    predictArea: this.predictArea,
                    validatePredictArea: this.validatePredictArea,
                    validateNewArea: this.validateNewArea,
                    validateEditArea: this.validateEditArea,
                    submitNewArea: this.submitNewArea,
                    submitEditArea: this.submitEditArea,
                    updateBollard: this.updateBollard,
                }}
            >
                {children}
            </AreasContextProvider>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => {
    return {
        createAreaFetching: state.areas.createAreaRequest.isFetching,
        createAreaErrors: state.areas.createAreaRequest.errors,
        updateAreaFetching: state.areas.updateAreaRequest.isFetching,
        updateAreaErrors: state.areas.updateAreaRequest.errors,
    };
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
    dispatchRequestCreateArea: (businessId: number, payload: FormData, onSuccess: Function, onFailure: Function) => dispatch(requestCreateArea(businessId, payload, onSuccess, onFailure)),
    dispatchRequestUpdateArea: (
        businessId: number,
        areaId: number,
        payload: FormData,
        onSuccess: Function,
        onFailure: Function,
    ) => dispatch(requestUpdateArea(businessId, areaId, payload, onSuccess, onFailure)),
});

export const ConnectedAreasController = connect(mapStateToProps, mapDispatchToProps)(AreasController);
