/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

export enum AppRoute {
    Login = '/login',
    Register = '/register',
    RegisterInvite = '/register/:token',
    Recover = '/recover',
    Profile = '/profile',
    Map = '/map',
    Areas = '/areas',
    Clients = '/clients',
    Merchants = '/merchants',
    EditMerchant = '/merchants/edit/:merchantId',
    AddArea = '/areas/add',
    EditArea = '/areas/edit/:businessId/:areaId',
    Dashboard = '/',
    Bookings = '/bookings',
    AddBooking = '/bookings/add',
    BookingDetails = '/bookings/details/:bookingId',
    BookingDetailsNew = '/bookings/details_new/:bookingId',
    EditBooking = '/bookings/edit/:bookingId',
    Businesses = '/businesses',
    AddBusiness = '/businesses/add',
    EditBusiness = '/businesses/edit/:businessId',
    Activate = '/confirm-account/:token',
    Reset = '/reset-password/:token',
    BookingsCalendar = '/bookings-calendar',
    ReservationManagers = '/reservation-managers',
    ReservationManagerDetails = '/reservation-managers/details/:reservationManagerId',
}
