/*
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useState } from 'react';
import {
    Button,
    Dialog, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';
import FormTextField from './FormTextField';
import { withTranslationContext, TranslationContext } from '../controllers/TranslationContext';

interface OwnProps {
    defaultUserId?: string;
    onClose: () => void;
    onSave: (userId: string) => void;
}

type Props = TranslationContext & OwnProps;

export const EditUserIdModal: FunctionComponent<OwnProps> = withTranslationContext((props: Props) => {
    const {
        defaultUserId, onSave, onClose, t,
    } = props;

    const [userId, setUserId] = useState(defaultUserId || '');
    
    return (
        <React.Fragment>
            <Dialog
                open
                onClose={onClose}
                fullWidth
            >
                <DialogTitle>{t('areaForm.designation')}</DialogTitle>
                <DialogContent>
                    <FormTextField
                        name="userIdField"
                        value={userId}
                        onChange={(_: string, value: string) => setUserId(value)}
                        placeholder={t('areaForm.designation')}
                        label={t('areaForm.designation')}
                        errors={null}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        {t('general.cancel')}
                    </Button>
                    <Button
                        onClick={() => {
                            onSave(userId);
                            onClose();
                        }}
                        color="primary"
                        autoFocus
                    >
                        {t('general.save')}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
});
