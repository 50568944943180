/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';

import { ConnectedBusinessesController } from './BusinessesController';
import { KeyedObject, ListResponse } from '../../types/general';
import { Business, BusinessRequestPayload, UpdateBusinessStatus } from '../../types/businesses';
import { ApiError } from '../../types/errors';
import { GenericFunction } from '../../utils/misc';

export interface BusinessesContext {
    createBusinessFetching: boolean;
    createBusinessErrors: ApiError | null;
    updateBusinessFetching: boolean;
    updateBusinessErrors: ApiError | null;
    getBusiness(id: string): Promise<Business | null>;
    getBusinesses(filters?: KeyedObject): Promise<ListResponse<Business> | null>;
    validateNewBusiness(fields: any, hasEuPago: boolean): KeyedObject | null;
    validateEditBusiness(fields: any, hasEuPago: boolean): KeyedObject | null;
    submitNewBusiness(payload: BusinessRequestPayload, onSuccess: Function, onFailure: Function): void;
    submitEditBusiness(
        businessId: string,
        payload: BusinessRequestPayload,
        onSuccess: Function,
        onFailure: Function
    ): void;
    updateBusinessStatus(id: number, status: UpdateBusinessStatus, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void>;
}

export const businessesContextDefaultValue = {
    createBusinessFetching: false,
    createBusinessErrors: null,
    updateBusinessFetching: false,
    updateBusinessErrors: null,
    getBusiness: async () => null,
    getBusinesses: async () => null,
    validateNewBusiness: () => null,
    validateEditBusiness: () => null,
    submitNewBusiness: () => {},
    submitEditBusiness: () => {},
    updateBusinessStatus: async (): Promise<void> => {},
};

const BusinessesContextInstance = createContext<BusinessesContext | null>(businessesContextDefaultValue);

export const BusinessesContextProvider = BusinessesContextInstance.Provider;
export const BusinessesContextConsumer = BusinessesContextInstance.Consumer;

export const withBusinessesContext = <P extends object>(
    Component: ComponentType<P>,
): FC<Omit<P, keyof BusinessesContext>> => props => (
    <ConnectedBusinessesController>
        <BusinessesContextConsumer>{ctx => <Component {...(props as P)} {...ctx} />}</BusinessesContextConsumer>
    </ConnectedBusinessesController>
);
