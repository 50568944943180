/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import MarkerClusterer from '@googlemaps/markerclustererplus';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import Map from '../elements/Map';
import { mapCenter } from '../../utils/constants';
import { withBusinessesContext, BusinessesContext } from '../controllers/BusinessesContext';
import { Business } from '../../types/businesses';
import { customMarker } from '../../utils/maps';
import { OrderQuery } from '../../types/general';
import { AppRoute } from '../../types/routes';

type OwnProps = BusinessesContext & RouteComponentProps

interface OwnState {
    businesses: Business[];
    mapRef: google.maps.Map | null;
    markerCluster: MarkerClusterer | null;
}

const initialState: OwnState = {
    businesses: [],
    mapRef: null,
    markerCluster: null,
};

class MapScreen extends Component<OwnProps, OwnState> {
    constructor(props: OwnProps) {
        super(props);
        this.state = {
            ...initialState,
        };
    }

    onMapMounted = (ref: google.maps.Map) => {
        const cluster = new MarkerClusterer(ref, [], {
            styles: [
                {
                    url: '/images/cluster.png',
                    height: 60,
                    width: 60,
                    textColor: '#000000',
                    textSize: 13,
                },
            ],
        });
        this.setState({ mapRef: ref, markerCluster: cluster }, this.fetchBusinesses);
    };

    onMarkerClick = (business: Business) => {
        const { history } = this.props;
        history.push(AppRoute.Bookings, { businessId: business.id });
    }

    fetchBusinesses = () => {
        const { getBusinesses } = this.props;
        getBusinesses({ _limit: 999, _sort: 'name', _order: OrderQuery.Ascending }).then(businessesData => {
            if (businessesData) {
                this.setState(
                    {
                        businesses: businessesData.data.filter((business: Business) => business.numberOfAreas > 0),
                    },
                    this.loadMarkers,
                );
            }
        });
    };

    loadMarkers = () => {
        const { mapRef, businesses, markerCluster } = this.state;
        if (mapRef !== null && businesses.length > 0 && markerCluster) {
            const lat = { min: 999, max: -999 };
            const lng = { min: 999, max: -999 };
            
            businesses.forEach(business => {
                if (business.address.lat > lat.max) {
                    lat.max = business.address.lat;
                }
                if (business.address.lat < lat.min) {
                    lat.min = business.address.lat;
                }
                if (business.address.lng > lng.max) {
                    lng.max = business.address.lng;
                }
                if (business.address.lng < lng.min) {
                    lng.min = business.address.lng;
                }
                const marker = customMarker(
                    mapRef,
                    { lat: business.address.lat, lng: business.address.lng },
                    undefined,
                    business.name,
                    undefined,
                    true,
                );
                marker.addListener('click', () => this.onMarkerClick(business));
                markerCluster.addMarker(marker);
            });
            mapRef.fitBounds(
                new google.maps.LatLngBounds({ lat: lat.min, lng: lng.min }, { lat: lat.max, lng: lng.max }),
            );
        }
    };

    render() {
        return (
            <div className="map-container">
                <Map zoom={8} height="100%" center={mapCenter} onMapMounted={this.onMapMounted} />
            </div>
        );
    }
}

export default withBusinessesContext(withRouter(MapScreen));
