/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import moment from 'moment';
import { KeyedObject, SelectOption, Time } from '../types/general';
import { AppRoute } from '../types/routes';
import { DATE_FORMAT, TIME_FORMAT } from './constants';

export const generateId = (prefix = ''): string => {
    return `${prefix}${Math.random().toString(36).slice(2)}`;
};

export const getOptionLabelByValue = (value: string | number, options: SelectOption[]) => options.find(option => option.value === value)?.label;

export const arrayToParams = (paramName: string, arrayValues: any[], useQ = true): string => {
    let params = '';

    Array.isArray(arrayValues)
    && arrayValues.forEach(value => {
        const includeAnd = params.length > 0 ? '&' : '';
        params += `${includeAnd}${paramName}=${value}`;
    });

    if (useQ) {
        if (params && params.length > 0) {
            params = `?${params}`;
        }
    }

    return params;
};

export const objectToParams = (obj?: KeyedObject, useQ = true): string => {
    let params = '';

    if (obj === undefined) return params;

    Object.keys(obj).forEach(key => {
        if (obj[key] !== undefined && obj[key] !== null) {
            const val = Array.isArray(obj[key]) ? obj[key] : `${obj[key]}`;
            if (val && val.length > 0) {
                if (params.length > 0) {
                    if (Array.isArray(val)) {
                        params = `${params}&${arrayToParams(key, val, false)}`;
                    } else {
                        params = `${params}&${key}=${val}`;
                    }
                } else {
                    if (Array.isArray(val)) {
                        params = `${arrayToParams(key, val, false)}`;
                    } else {
                        params = `${key}=${obj[key]}`;
                    }
                }
            }
        }
    });

    if (useQ) {
        if (params && params.length > 0) {
            params = `?${params}`;
        }
    }

    return params;
};

export const buildRoute = (route: AppRoute, params: KeyedObject): string => {
    let finalRoute: string = route;

    Object.keys(params).forEach(key => {
        finalRoute = finalRoute.replace(`:${key}`, `${params[key]}`);
    });

    return finalRoute;
};

export const mod97 = (string: string) => {
    let checksum = string.slice(0, 2);
    for (let offset = 2; offset < string.length; offset += 7) {
        const fragment = String(checksum) + string.substring(offset, offset + 7);
        checksum = (parseInt(fragment) % 97).toString();
    }
    return checksum;
};

export const validateIBAN = (input: string) => {
    const iban = String(input)
        .toUpperCase()
        .replace(/[^A-Z0-9]/g, '');
    const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);

    if (!code || iban.length !== ibanCodeLengths[code[1]]) {
        return false;
    }

    const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, letter => {
        return (letter.charCodeAt(0) - 55).toString();
    });

    return mod97(digits) === '1';
};

export const validatePtNif = (nif: string) => {
    let checkDigit: number;
    const characterReg = '[0-9]{9}';
    if (nif.match(characterReg)) {
        checkDigit = Number(nif.charAt(0)) * 9;
        for (let i = 2; i <= 8; i++) {
            checkDigit += Number(nif.charAt(i - 1)) * (10 - i);
        }
        checkDigit = 11 - (checkDigit % 11);
        if (checkDigit >= 10) {
            checkDigit = 0;
        }

        return String(checkDigit) === String(nif.charAt(8));
    }

    return false;
};

export const stringToDecimal = (stringNumber: string): number => {
    return String(stringNumber).includes(',') ? Number(String(stringNumber).replace(',', '.')) : Number(stringNumber);
};

const ibanCodeLengths: { [key: string]: number } = {
    AD: 24,
    AE: 23,
    AT: 20,
    AZ: 28,
    BA: 20,
    BE: 16,
    BG: 22,
    BH: 22,
    BR: 29,
    CH: 21,
    CR: 21,
    CY: 28,
    CZ: 24,
    DE: 22,
    DK: 18,
    DO: 28,
    EE: 20,
    ES: 24,
    FI: 18,
    FO: 18,
    FR: 27,
    GB: 22,
    GI: 23,
    GL: 18,
    GR: 27,
    GT: 28,
    HR: 21,
    HU: 28,
    IE: 22,
    IL: 23,
    IS: 26,
    IT: 27,
    JO: 30,
    KW: 30,
    KZ: 20,
    LB: 28,
    LI: 21,
    LT: 20,
    LU: 20,
    LV: 21,
    MC: 27,
    MD: 24,
    ME: 22,
    MK: 19,
    MR: 27,
    MT: 31,
    MU: 30,
    NL: 18,
    NO: 15,
    PK: 24,
    PL: 28,
    PS: 29,
    PT: 25,
    QA: 29,
    RO: 24,
    RS: 22,
    SA: 24,
    SE: 24,
    SI: 19,
    SK: 24,
    SM: 27,
    TN: 24,
    TR: 26,
    AL: 28,
    BY: 28,
    EG: 29,
    GE: 22,
    IQ: 23,
    LC: 32,
    SC: 31,
    ST: 25,
    SV: 28,
    TL: 23,
    UA: 29,
    VA: 22,
    VG: 24,
    XK: 20,
};

export const isSameTime = (
    timeA?: string | moment.Moment, timeB?: string | moment.Moment, timeAInUTC = false, timeBInUTC = false,
): boolean => {
    if (!timeA || !timeB) return false;

    let momentTimeA = timeA;
    let momentTimeB = timeB;
    if (typeof timeA === 'string') {
        momentTimeA = timeAInUTC ? moment.utc(timeA, TIME_FORMAT) : moment(timeA, TIME_FORMAT);
    }
    if (typeof timeB === 'string') {
        momentTimeB = timeBInUTC ? moment.utc(timeB, TIME_FORMAT) : moment(timeB, TIME_FORMAT);
    }

    return (momentTimeA as moment.Moment).isSame(momentTimeB);
};

export const isSameDate = (
    dateA: string | moment.Moment, dateB: string | moment.Moment, dateAInUTC = false, dateBInUTC = false,
): boolean => {
    let momentDateA = dateA;
    let momentDateB = dateB;
    if (typeof dateA === 'string') {
        momentDateA = dateAInUTC ? moment.utc(dateA, DATE_FORMAT) : moment(dateA, DATE_FORMAT);
    }
    if (typeof dateB === 'string') {
        momentDateB = dateBInUTC ? moment.utc(dateB, DATE_FORMAT) : moment(dateB, DATE_FORMAT);
    }

    return (momentDateA as moment.Moment).isSame(momentDateB);
};

export const formatHourTime = (hourString: string): string => {
    const time = hourString.includes('T') ? getTimeObjectFromDateTimeString(hourString) : getTimeObjectFromHourString(hourString);

    const numberFormatter = (n: number) => n.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
    });

    return `${numberFormatter(time.hour)}:${numberFormatter(time.minutes)}`;
};

export const getTimeObjectFromHourString = (hourString: string): Time => {
    const parts: Array<string> = hourString.split(':');

    return {
        hour: parts[0] ? Number(parts[0]) : 0,
        minutes: parts[1] ? Number(parts[1]) : 0,
    };
};

export const getTimeObjectFromDateTimeString = (dateTime: string): Time => {
    const dateTimeSplits = dateTime.split('T');

    if (dateTimeSplits[1]) {
        return getTimeObjectFromHourString(dateTimeSplits[1]);
    }
    return {
        hour: 0,
        minutes: 0,
    };
};

export type GenericFunction = (...args: any[]) => void;
