/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { AuthenticationContext, withAuthenticationContext } from '../controllers/AuthenticationContext';
import { AppRoute } from '../../types/routes';
import { AuthorizationContext, withAuthorizationContext } from '../controllers/AuthorizationContext';
import { checkPermission } from '../../utils/authorization';
import { Permission } from '../../types/authorization';
import { UsersRoles } from '../../types/users';

interface OwnProps extends RouteProps, AuthenticationContext, AuthorizationContext {
    permissions?: Permission[];
}

type Props = OwnProps;

const AuthenticatedRoute: React.FC<Props> = props => {
    if (!props.isAuthenticated) {
        const renderComponent = () => (
            <Redirect
                to={{
                    pathname: AppRoute.Login,
                    state: {
                        from: props.location,
                    },
                }}
            />
        );

        return <Route {...props} component={renderComponent} render={undefined} />;
    }

    if (props.permissions && props.permissions.length > 0 && !checkPermission(props.userPermissions, props.permissions)) {
        return (
            <Route {...props}>
                {props.authenticatedUser?.role === UsersRoles.ReservationManager ? (
                    <Redirect
                        to={{
                            pathname: AppRoute.Bookings,
                        }}
                    />
                    ) : (
                        <Redirect
                            to={{
                                pathname: AppRoute.Map,
                            }}
                        />
                )}
            </Route>
        );
    }

    return <Route {...props} />;
};

export default withAuthenticationContext(withAuthorizationContext(AuthenticatedRoute));
