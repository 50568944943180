/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import LoginForm from '../views/LoginForm';
import logo from '../../assets/images/logo.svg';

import { AuthenticationContext, withAuthenticationContext } from '../controllers/AuthenticationContext';
import { AppRoute } from '../../types/routes';
import { UsersRoles } from '../../types/users';

interface OwnProps extends TranslationContext, AuthenticationContext, RouteComponentProps {}

interface OwnState {}

const initialState: OwnState = {};

class LoginScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount() {
        const { isAuthenticated, history, authenticatedUser } = this.props;

        if (isAuthenticated) {
            if (authenticatedUser?.role === UsersRoles.ReservationManager) return history.push(AppRoute.Bookings);
            history.push(AppRoute.Map);
        }
    }

    render() {
        const { t } = this.props;
        
        return (
            <div className="auth-screen">
                <div className="auth-screen__gradient-container">
                    <img src={logo} alt="Boser" className="auth-screen__logo" />
                    <br />
                    <span className="auth-screen__title">{t('loginForm.title')}</span>
                    <br />
                    <span className="auth-screen__title">{t('loginForm.title2')}</span>
                </div>
                <LoginForm />
            </div>
        );
    }
}

export default withTranslationContext(withAuthenticationContext(withRouter(LoginScreen)));
