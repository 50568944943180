/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { ComponentType, createContext, FC } from 'react';

import { Permission, PermissionOperator } from '../../types/authorization';
import { ConnectedAuthorizationController } from './AuthorizationController';

export interface AuthorizationContext {
    userPermissions: Array<Permission>;
    checkPermission(actions: Array<Permission>, operator?: PermissionOperator): boolean;
}

export const authorizationContextDefaultValue = {
    userPermissions: [],
    checkPermission: (): boolean => false,
};

const AuthorizationContextCreated = createContext<AuthorizationContext | null>(authorizationContextDefaultValue);

export const AuthorizationContextProvider = AuthorizationContextCreated.Provider;
export const AuthorizationContextConsumer = AuthorizationContextCreated.Consumer;

export const withAuthorizationContext = <P extends unknown>(Component: ComponentType<P>): FC<Omit<P, keyof AuthorizationContext>> => (props) => (
    <ConnectedAuthorizationController>
        <AuthorizationContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </AuthorizationContextConsumer>
    </ConnectedAuthorizationController>
);
