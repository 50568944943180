/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { Permission } from '../types/authorization';

export const groupedPermissions: { [key: string]: Array<Permission> } = {
    Admin: [
        ...Object.values(Permission),
    ],
    Merchant: [
        // Businesses
        Permission.BUSINESS_CREATE,
        Permission.BUSINESS_EDIT,
        Permission.BUSINESS_VIEW_LIST,
        Permission.RESTRICT_BUSINESS_BY_DOMAIN,
        Permission.BUSINESS_ACTIVATE,
        // Areas
        Permission.AREA_CREATE,
        Permission.AREA_EDIT,
        Permission.AREA_VIEW_LIST,
        Permission.AREA_ACTIVATE,
        // Bollards
        Permission.BOLLARD_CREATE,
        Permission.BOLLARD_EDIT,
        Permission.BOLLARD_VIEW_ACTIVE,
        Permission.BOLLARD_VIEW_INACTIVE,
        Permission.ACTIVATE_BOLLARD,
        Permission.LOCATE_BOLLARD,
        // Bookings
        Permission.BOOKING_CREATE,
        Permission.BOOKING_EDIT,
        Permission.BOOKING_VIEW_LIST,
        Permission.BOOKING_VIEW_CALENDAR,
        Permission.BOOKING_VIEW_DETAILS,
        // Reservation Managers
        Permission.RESERVATION_MANAGERS_LIST_SCREEN,
        Permission.RESERVATION_MANAGER_INVITATION_LIST_VIEW,
        Permission.INVITE_RESERVATION_MANAGER,
        Permission.DELETE_RESERVATION_MANAGER_PENDING_INVITATION,
    ],
    ReservationManager: [
        // Bookings
        Permission.BOOKING_CREATE,
        Permission.BOOKING_VIEW_LIST,
        Permission.BOOKING_VIEW_DETAILS,
        Permission.BOOKING_EDIT,
        Permission.BOOKING_VIEW_CALENDAR,
        Permission.RESERVATION_MANAGERS_LIST_SCREEN,
    ],
};
