/**
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */
/* eslint-disable react-hooks/exhaustive-deps */

import React, {
    FunctionComponent, useCallback, useEffect, useState,
} from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';

import { ArrowBackIos } from '@material-ui/icons';
import { Button, IconButton } from '@material-ui/core';
import Lottie from 'react-lottie';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { ReservationManagersContext, withReservationManagersContext } from '../controllers/ReservationManagersContext';
import { AuthorizationContext, withAuthorizationContext } from '../controllers/AuthorizationContext';
import { ListViewDetail, ReservationManager } from '../../types/reservationManager';
import { OrderQuery, QueryParams } from '../../types/general';
import { Business } from '../../types/businesses';
import Loader from '../elements/Loader';
import ReservationManagerRelatedAreasList from '../elements/ReservationManagerBusinessRelatedList';
import { AppRoute } from '../../types/routes';
import AddAreaModal from '../elements/AddAreaModal';
import NoDataLottie from '../../assets/lottie/no-data.json';

interface MatchParams {
    reservationManagerId: string;
}

type OwnProps =
    TranslationContext
    & ReservationManagersContext
    & AuthorizationContext
    & RouteComponentProps<MatchParams>;

const ReservationManagerDetailsScreen: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const [viewSelected, setViewSelected] = useState(ListViewDetail.AreasManagement);
    const [reservationManager, setReservationManager] = useState<ReservationManager | null>(null);
    const [businesses, setBusinesses] = useState<Business[]>([]);
    const [isFetching, setIsFetching] = useState(true);
    const [total, setTotal] = useState(0);
    const [expandedBusinessId, setExpandedBusinessId] = React.useState<number | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [listParams, setListParams] = useState<QueryParams>({
        _limit: 20,
        _order: OrderQuery.Ascending,
        _page: 0,
        _sort: 'name',
    });

    const {
        match: {
            params: { reservationManagerId },
        },
        t,
        getReservationManager,
        getReservationManagerBusinesses,
    } = props;

    const history = useHistory();

    const getReservationManagerById = useCallback(async () => {
        const data = await getReservationManager(reservationManagerId);

        setReservationManager(data);
    }, [getReservationManager, reservationManagerId]);

    const getReservationManagerBusinessesData = useCallback(async () => {
        const { data, total } = await getReservationManagerBusinesses(reservationManagerId, listParams);

        setBusinesses(data);
        setTotal(total);
    }, [getReservationManagerBusinesses, reservationManagerId, listParams]);

    useEffect(() => {
        fetchData();
    }, [getReservationManagerById, getReservationManagerBusinesses, listParams]);

    const removeBusiness = (id: number) => {
        const newBusinesses = businesses.filter(business => business.id !== id);
        setBusinesses(newBusinesses);
    };

    const fetchData = async () => {
        Promise.all([getReservationManagerById(), getReservationManagerBusinessesData()]).then(() => {
            setIsFetching(false);
        });
    };

    const onChangePage = (page: number) => {
        setListParams({ ...listParams, _page: page });
    };

    const onChangeRowsPerPage = (newLimit: number) => {
        setListParams({ ...listParams, _limit: newLimit });
    };

    const handleExpandClick = (id: number) => {
        setExpandedBusinessId(expandedBusinessId === id ? null : id);
    };

    const onBackButtonClick = () => {
        history.push(AppRoute.ReservationManagers);
    };

    const handleOpenModal = () => {
        setIsModalOpen(prevState => !prevState);
    };

    const handleCloseModal = async () => {
        setIsModalOpen(false);
        setIsFetching(true);
        await fetchData();
    };

    const filterBusinessById = (businessId: number) => {
        setBusinesses(businesses.filter(business => business.id !== businessId));
        setTotal(prevTotal => prevTotal - 1);
    };

    const renderNoDataPlaceholder = () => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: NoDataLottie,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
            },
        };

        return (
            <div className="placeholder-no-data" data-testid="placeholder-no-data">
                <Lottie
                    options={defaultOptions}
                    height={194}
                    width={194}
                />
                <p className="placeholder-no-data__title">{t('reservationManagerDetailsScreen.noDataTitle')}</p>
                <p className="placeholder-no-data__message">{t('reservationManagerDetailsScreen.noDataMessage')}</p>
            </div>
        );
    };

    if (isFetching) return <Loader />;

    return (
        <div className="reservation-manager-detail" data-testid="reservation-manager-detail">
            {isModalOpen && reservationManager && (
                <AddAreaModal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    reservationManagerId={reservationManager.id}
                />
            )}
            <div className="reservation-manager-detail__header">
                <IconButton size="small" disableRipple onClick={onBackButtonClick} data-testid="back-button">
                    <ArrowBackIos />
                </IconButton>
                <h2>
                    <span className="reservation-manager-detail__header--title">
                        {t('drawer.reservationManagers')}
                    </span> / {reservationManager?.name}
                </h2>
            </div>
            <div className="reservation-manager-detail__tab-list">
                <div className="reservation-managers-page__filters__table-button">
                    <button
                        type="button"
                        className={viewSelected === ListViewDetail.AreasManagement ? 'selected' : ''}
                        onClick={() => setViewSelected(ListViewDetail.AreasManagement)}
                    >
                        {t('reservationManagerDetailsScreen.areasManagement')}
                    </button>
                </div>
            </div>
            <div className="reservation-manager-detail__tab-panel">
                {viewSelected === ListViewDetail.AreasManagement && (
                    <>
                        <div className="reservation-manager-detail__add-area-section">
                            <h3>{t('bookingsScreen.businessName')}</h3>
                            <Button
                                onClick={handleOpenModal}
                                data-testid="add-areas-button"
                            >
                                {t('reservationManagerDetailsScreen.addAreas')}
                            </Button>
                        </div>
                        { businesses.length === 0
                            ? renderNoDataPlaceholder()
                            : (
                                <Paper>
                                    <div>
                                        {businesses.map(business => (
                                            <ReservationManagerRelatedAreasList
                                                reservationManagerId={Number(reservationManagerId)}
                                                business={business}
                                                isExpanded={expandedBusinessId === business.id}
                                                handleExpand={() => handleExpandClick(business.id)}
                                                update={() => filterBusinessById(business.id)}
                                                removeBusiness={removeBusiness}
                                            />
                                        ))}
                                    </div>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 20]}
                                        component="div"
                                        count={total}
                                        rowsPerPage={listParams._limit}
                                        page={listParams._page}
                                        onPageChange={(_, page) => onChangePage(page)}
                                        onRowsPerPageChange={e => onChangeRowsPerPage(Number(e.target.value))}
                                    />
                                </Paper>
                            )
                        }
                    </>
                )}
            </div>
        </div>
    );
};

export default withTranslationContext(withReservationManagersContext(withAuthorizationContext(ReservationManagerDetailsScreen)));
