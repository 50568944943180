/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, ReactNode } from 'react';
import { store } from 'react-notifications-component';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import DoneIcon from '@material-ui/icons/Done';

import { NotificationData, NotificationType } from '../../utils/notifications';

interface OwnProps {
    children?: any;
    id: string;
    data: NotificationData;
    onClose?: Function;
}

type Props = OwnProps;

const Notification: FunctionComponent<Props> = ({ children, id, data, onClose }: Props) => {
    const { message, type } = data;

    const getIcon = (): ReactNode => {
        switch (type) {
            case NotificationType.Info:
                return <InfoIcon />;
            case NotificationType.Warning:
                return <WarningIcon />;
            case NotificationType.Danger:
                return <ErrorIcon />;
            case NotificationType.Success:
                return <DoneIcon />;
            default:
                return null;
        }
    };

    const onCloseClick = (e: React.MouseEvent) => {
        if (onClose) onClose(id);
        else store.removeNotification(id);
    };

    return (
        <div className={`notification-content ${type}`}>
            <div className="notification-content-left">{getIcon()}</div>
            <button
                type="button"
                className="notification-content-close"
                data-testid="notification-close-button"
                onClick={onCloseClick}
            >
                <CloseIcon />
            </button>
            <div className="notification-content-right">
                {children ? (
                    children
                ) : (
                    <>
                        <p>{message}</p>
                    </>
                )}
            </div>
        </div>
    );
};

export default Notification;
