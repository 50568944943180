/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, ReactElement } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
    Backdrop, Button, CircularProgress, IconButton, Menu, MenuItem, TextField,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Place';
import AreaIcon from '@material-ui/icons/Map';
import PictureIcon from '@material-ui/icons/CameraAlt';
import ScheduleIcon from '@material-ui/icons/CalendarToday';
import DeleteIcon from '@material-ui/icons/Delete';
import BollardIcon from '@material-ui/icons/Flag';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { throttle, isNil } from 'lodash';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import UploadIcon from '@material-ui/icons/CloudUpload';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import Map from '../elements/Map';
import { AppRoute } from '../../types/routes';
import boser from '../../assets/images/logo.png';
import green from '../../assets/images/pin-green.svg';
import red from '../../assets/images/pin-red.svg';
import yellow from '../../assets/images/pin-yellow.svg';
import picked from '../../assets/images/pin-selected.svg';
import Loader from '../elements/Loader';
import { AreasContext, withAreasContext } from '../controllers/AreasContext';
import { BusinessesContext, withBusinessesContext } from '../controllers/BusinessesContext';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import FormTextField from '../elements/FormTextField';
import FormSelectField from '../elements/FormSelectField';
import {
    KeyedObject, SelectOption, GeoPoint, WeekDays, Order, OrderQuery, QueryParams,
} from '../../types/general';
import { Business } from '../../types/businesses';
import { displayNotification, NotificationType } from '../../utils/notifications';
import { handleFormSubmitFailure, Regex } from '../../utils/validations';
import {
    ReservationPeriodFormEntry,
    ReservationPeriod,
    FormattedReservationPeriod, Area, AreaStatus,
} from '../../types/areas';
import { Bollard, BollardStatus } from '../../types/bollards';
import { mapCenter, TIME_FORMAT } from '../../utils/constants';
import { getOptionLabelByValue, stringToDecimal } from '../../utils/misc';
import { customMarker, drawingManager } from '../../utils/maps';
import { FastMPModal } from '../elements/FastMPModal';
import { EditUserIdModal } from '../elements/EditUserIdModal';
import { timezones } from '../../types/timezones.json';
import { MerchantsContext, withMerchantsContext } from '../controllers/MerchantsContext';
import { BollardsContext, withBollardsContext } from '../controllers/BollardsContext';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/AuthenticationContext';

enum AreaFormField {
    Name = 'name',
    Radius = 'bollardRadius',
    Polygon = 'vertices',
    RequestedBollards = 'requestedBollards',
    Schedule = 'reservationPeriods',
    Business = 'businessId',
    Force = 'forceUpdate',
    ScheduleDelete = 'reservationPeriodsToDelete',
    TimeZone = 'timeZone',
    Description = 'description',
}

export interface AreaFormFields {
    [AreaFormField.Name]: string;
    [AreaFormField.Radius]: string;
    [AreaFormField.Polygon]: GeoPoint[];
    [AreaFormField.RequestedBollards]: string;
    [AreaFormField.Schedule]: ReservationPeriodFormEntry[];
    [AreaFormField.Business]: number;
    [AreaFormField.Force]: boolean;
    [AreaFormField.ScheduleDelete]: number[];
    [AreaFormField.TimeZone]: string;
    [AreaFormField.Description]: string;
}

interface OwnProps extends TranslationContext, AuthenticationContext, BusinessesContext, AreasContext, MerchantsContext, BollardsContext, RouteComponentProps {
    businessId?: string;
    areaId?: string;
}

interface OwnState {
    isReady: boolean;
    validated: boolean | null;
    fields: AreaFormFields;
    errors: KeyedObject | null;
    mapRef: google.maps.Map | null;
    image: File | null;
    imageUrl: string;
    businesses: Business[];
    businessOptions: SelectOption[];
    area: string;
    businessPosition: GeoPoint;
    predictedMarkers: { position: GeoPoint; status: BollardStatus; id: number }[];
    possibleMarkers: number;
    currentPolygon: google.maps.Polygon | null;
    fetchingArea: boolean;
    failAreaFetch: boolean;
    sw: GeoPoint | null;
    ne: GeoPoint | null;
    order: Order;
    params: QueryParams;
    totalBollards: number;
    lastInput: Date;
    bollards: Bollard[];
    updateModal: boolean;
    polygonModal: boolean;
    radiusModal: boolean;
    markersWithStatus: MarkersStatusMap;
    businessMarker: google.maps.Marker | null;
    bollardMarkers: google.maps.Marker[];
    specialDay: boolean;
    anchorBollardOptions: Element | null;
    selectedBollardId: number;
    showFastSticker: boolean;
    showEditId: boolean;
    timezonesOpts: Array<SelectOption>;
    bollardSelected: number;
}

interface MarkersStatusMap {
    active: GeoPoint[];
    disabled: GeoPoint[];
}

const DEFAULT_EARLY_DATE = '08:00:00';
const DEFAULT_LATE_DATE = '20:00:00';
const DIALOG_NO = 'areaForm.dialogNo';
const DIALOG_YES = 'areaForm.dialogYes';

const weekDaysValues = [
    WeekDays.Sunday,
    WeekDays.Monday,
    WeekDays.Tuesday,
    WeekDays.Wednesday,
    WeekDays.Thursday,
    WeekDays.Friday,
    WeekDays.Saturday,
];

const initialState: OwnState = {
    isReady: false,
    validated: null,
    fields: {
        [AreaFormField.Business]: -1,
        [AreaFormField.Name]: '',
        [AreaFormField.Radius]: '1',
        [AreaFormField.Polygon]: [],
        [AreaFormField.RequestedBollards]: '',
        [AreaFormField.Schedule]: [
            {
                dayOfWeek: WeekDays.Monday,
                startTime: DEFAULT_EARLY_DATE,
                endTime: DEFAULT_LATE_DATE,
                price: '',
                edited: true,
                designation: '',
            },
        ],
        [AreaFormField.Force]: false,
        [AreaFormField.ScheduleDelete]: [],
        [AreaFormField.TimeZone]: 'Europe/Lisbon',
        [AreaFormField.Description]: '',
    },
    errors: null,
    mapRef: null,
    image: null,
    imageUrl: '',
    businesses: [],
    area: '',
    businessOptions: [],
    businessPosition: { lat: 100, lng: 100 },
    predictedMarkers: [],
    possibleMarkers: 0,
    currentPolygon: null,
    fetchingArea: true,
    failAreaFetch: false,
    sw: null,
    ne: null,
    order: Order.Ascending,
    params: {
        _limit: 20,
        _page: 0,
        _order: OrderQuery.Ascending,
        _sort: 'id',
    },
    totalBollards: 0,
    lastInput: new Date(),
    bollards: [],
    updateModal: false,
    radiusModal: false,
    polygonModal: false,
    markersWithStatus: { active: [], disabled: [] },
    businessMarker: null,
    bollardMarkers: [],
    specialDay: false,
    anchorBollardOptions: null,
    selectedBollardId: -1,
    showFastSticker: false,
    showEditId: false,
    timezonesOpts: [],
    bollardSelected: 0,
};

let throttledValidatePolygon = () => {};
let validatePolygonTimeout: any = null;

class AreaForm extends Component<OwnProps, OwnState> {
    constructor(props: OwnProps) {
        super(props);
        const { areaId } = props;
        this.state = {
            ...initialState,
            fetchingArea: areaId !== undefined,
            fields: {
                ...initialState.fields,
                [AreaFormField.Schedule]: initialState.fields[AreaFormField.Schedule].map(schedule => ({
                    ...schedule,
                })),
                [AreaFormField.ScheduleDelete]: [],
            },
        };
        throttledValidatePolygon = throttle(this.validatePolygon, 250);
    }

    componentDidMount() {
        const { t } = this.props;

        this.setState({
            timezonesOpts: timezones.map(zone => ({
                value: zone.value,
                label: t(`timezones.${zone.key}`),
            })),
        });

        this.fetchBusinesses();
        this.fetchArea();
        this.fetchBollards();
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>) {
        const { selectedMerchantId } = this.props;
        if (selectedMerchantId !== prevProps.selectedMerchantId) this.fetchBusinesses();
    }

    fetchBusinesses = () => {
        const { getBusinesses, areaId, selectedMerchantId } = this.props;
        if (areaId === undefined) {
            getBusinesses({
                _limit: 999, _sort: 'name', _order: 'Ascending', merchantId: selectedMerchantId,
            }).then(businessesData => {
                if (businessesData) {
                    const businessOptions: SelectOption[] = [];
                    businessesData.data.forEach((business: Business) => {
                        businessOptions.push({ label: business.name, value: business.id });
                    });
                    this.setState({ businessOptions, businesses: businessesData.data });
                }
            });
        }
    };

    calculateMarkersWithStatus(bollards: Bollard[]) {
        const markersWithStatus: MarkersStatusMap = {
            active: [],
            disabled: [],
        };
        return {
            predictedMarkers: bollards.map((bollard: any) => {
                if (bollard.status === BollardStatus.Active) {
                    markersWithStatus.active.push(bollard.location);
                } else if (bollard.status === BollardStatus.Disabled) {
                    markersWithStatus.disabled.push(bollard.location);
                }
                return {
                    position: bollard.location,
                    status: bollard.status,
                    id: bollard.id,
                };
            }),
            markersWithStatus,
        };
    }

    fetchArea = async () => {
        const { getArea, businessId, areaId } = this.props;
        const { fields } = this.state;

        if (!areaId || !businessId) return;

        const area = await getArea(Number(businessId), Number(areaId));
        if (!area) {
            this.setState({
                fetchingArea: false,
                failAreaFetch: true,
            });
            return;
        }

        const result = this.calculateMarkersWithStatus(area.bollards);
        const { predictedMarkers, markersWithStatus } = result;

        this.setState({
            fields: {
                ...fields,
                [AreaFormField.Business]: area.business.id,
                [AreaFormField.Name]: area.name,
                [AreaFormField.Description]: area.description,
                [AreaFormField.Radius]: String(area.bollardRadius),
                [AreaFormField.Polygon]: area.vertices || [],
                [AreaFormField.RequestedBollards]: String(area.requestedBollards),
                [AreaFormField.TimeZone]: area.timeZone,
                [AreaFormField.Schedule]: area.reservationPeriods?.map((reservation: ReservationPeriod) => ({
                    ...reservation,
                    designation: reservation.designation || '',
                    price: reservation.price === null ? '0' : String(reservation.price),
                })) || [],
            },
            fetchingArea: false,
            sw: area.boundSW,
            ne: area.boundNE,
            area: String(area.area),
            imageUrl: area.photo,
            predictedMarkers,
            markersWithStatus,
            businessPosition: { lat: area.business.address.lat, lng: area.business.address.lng },
            possibleMarkers: area.maxBollards,
            businessOptions: [{ label: area.business.name, value: area.business.id }],
        }, this.refreshMarkers);
    };

    fetchBollards = () => {
        const { businessId, areaId, getBollards } = this.props;
        const { params } = this.state;

        if (businessId && areaId) {
            getBollards(businessId, areaId, params).then(bollardsData => {
                if (bollardsData) {
                    this.setState({
                        totalBollards: bollardsData.total,
                        bollards: bollardsData.data,
                    });
                }
            });
        }
    };

    onMapMounted = (ref: google.maps.Map) => {
        const { areaId } = this.props;
        const {
            sw, ne, fields, businessPosition, businessOptions,
        } = this.state;
        if (ref && areaId !== undefined && sw !== null && ne !== null) {
            const polygon = new google.maps.Polygon({
                paths: fields[AreaFormField.Polygon],
                fillColor: '#ff4500',
                strokeColor: '#000000',
                fillOpacity: 0.5,
                strokeWeight: 2,
                clickable: true,
                editable: true,
                draggable: true,
            });
            google.maps.event.addListener(polygon.getPath(), 'set_at', throttledValidatePolygon);
            google.maps.event.addListener(polygon.getPath(), 'insert_at', throttledValidatePolygon);
            google.maps.event.addListener(polygon.getPath(), 'remove_at', throttledValidatePolygon);
            polygon.setMap(ref);
            ref.fitBounds(new google.maps.LatLngBounds(sw, ne));
            this.setState(
                {
                    currentPolygon: polygon,
                    businessMarker: customMarker(
                        ref,
                        businessPosition,
                        undefined,
                        getOptionLabelByValue(fields[AreaFormField.Business], businessOptions),
                    ),
                },
                this.refreshMarkers,
            );
        }
        const manager = drawingManager(ref);
        if (areaId !== undefined) {
            manager.setDrawingMode(null);
        }
        manager.addListener('polygoncomplete', this.onPolygonComplete);
        this.setState({ mapRef: ref });
    };

    refreshMarkers = () => {
        const {
            mapRef, bollardMarkers, predictedMarkers, bollardSelected,
        } = this.state;
        if (mapRef !== null) {
            bollardMarkers.forEach(marker => marker.setMap(null));
            const markers: google.maps.Marker[] = [];
            predictedMarkers.forEach(prediction => {
                const isSelected = prediction.id === bollardSelected;

                const marker = customMarker(
                    mapRef,
                    { lat: prediction.position.lat, lng: prediction.position.lng },
                    this.getMarkerIcon(prediction.status, isSelected),
                    undefined,
                    undefined,
                    isSelected,
                );

                marker.addListener('click', () => {
                    if (prediction.id === bollardSelected) {
                        this.setState({ bollardSelected: 0 });
                    } else {
                        this.setState({ bollardSelected: prediction.id });
                    }

                    this.refreshMarkers();
                });
                marker.addListener('dragend', () => this.onMarkerDrag({ lat: prediction.position.lat, lng: prediction.position.lng }, marker));

                markers.push(marker);
            });
            this.setState({ bollardMarkers: markers });
        }
    };

    onMarkerDrag = async (initialPosition: GeoPoint, marker: google.maps.Marker) => {
        const { predictedMarkers, bollardSelected, bollards } = this.state;
        const {
            validateBollardLocation, postBollardLocationUpdated, authenticatedUser,
        } = this.props;

        const position: google.maps.LatLng|null|undefined = marker.getPosition();

        if (!position) return;

        const newPredictedMarkersRequest = predictedMarkers.map(async prediction => {
            if (prediction.id !== bollardSelected) return prediction;

            const bollardDragged = bollards.find(bollard => bollard.id === bollardSelected);
            if (!bollardDragged) return prediction;

            const positionPayload = {
                lat: position.lat(),
                lng: position.lng(),
            };

            const isAllowedLocation = await validateBollardLocation(
                bollardDragged.qrCodeId,
                bollardDragged.businessAreaId,
                bollardDragged.businessId,
                positionPayload,
                this.onFailureNewBollardLocation,
            );

            if (isAllowedLocation) {
                const payloadLocationUpdated = {
                    designation: bollardDragged.designation,
                    location: positionPayload,
                    status: bollardDragged.status,
                    fastMPStickerId: bollardDragged.fastMPStickerId,
                    userId: authenticatedUser ? authenticatedUser.id : 0,
                };

                postBollardLocationUpdated(
                    bollardDragged.qrCodeId,
                    bollardDragged.businessAreaId,
                    bollardDragged.businessId,
                    payloadLocationUpdated,
                    this.onSuccessNewBollardLocation,
                    this.onFailureNewBollardLocation,
                );

                return {
                    ...prediction,
                    position: positionPayload,
                };
            }

            return {
                ...prediction,
                position: {
                    lat: initialPosition.lat,
                    lng: initialPosition.lng,
                },
            };
        });

        const newPredictedMarkers = await Promise.all(newPredictedMarkersRequest);

        this.setState({
            predictedMarkers: newPredictedMarkers,
            bollardSelected: 0,
        }, this.refreshMarkers);
    }

    onSuccessNewBollardLocation = () => {
        const { t } = this.props;

        displayNotification({
            message: t('areaForm.bollardLocationUpdatedSuccess'),
            type: NotificationType.Success,
        });
    }

    onFailureNewBollardLocation = (message: string) => {
        displayNotification({
            message,
            type: NotificationType.Danger,
        });
    }

    validatePolygon = (forceUpdate = false, created = false) => {
        const { currentPolygon, fields } = this.state;
        const { validatePredictArea, t } = this.props;

        if (currentPolygon) {
            const errors = validatePredictArea(fields);

            if (errors !== null && created) {
                displayNotification({
                    message:
                        errors.fields.businessId !== undefined
                            ? t('areaForm.errorAreaBusiness')
                            : t('areaForm.errorAreaRadius'),
                    type: NotificationType.Danger,
                });
            }

            const vertices = currentPolygon
                .getPath()
                .getArray()
                .map((position: any) => ({ lat: position.lat(), lng: position.lng() }));

            this.setState(
                {
                    errors,
                    fields: {
                        ...this.state.fields,
                        [AreaFormField.Polygon]: vertices,
                    },
                },
                () => {
                    this.proceedValidatingPolygon(forceUpdate, vertices);
                },
            );
        }
    };

    handlePredictArea = (data: any) => {
        const { t } = this.props;
        if (data && data.errors && data.errors.length > 0) {
            if (data.errors.find((error: any) => error.errorCode === 31) !== undefined) {
                this.setState({ radiusModal: true });
            } else if (data.errors.find((error: any) => error.errorCode === 32) !== undefined) {
                this.setState({ polygonModal: true });
            } else {
                displayNotification({
                    message: t('areaForm.errorPredict'),
                    type: NotificationType.Danger,
                });
            }
        } else {
            this.finishValidatingPolygon(data);
        }
    };

    proceedValidatingPolygon = (forceUpdate: boolean, vertices: GeoPoint[]) => {
        const { currentPolygon, fields, errors } = this.state;
        const { predictArea, areaId } = this.props;

        if (!errors) {
            predictArea(
                fields[AreaFormField.Business],
                areaId,
                forceUpdate,
                stringToDecimal(fields[AreaFormField.Radius]),
                vertices,
            ).then(data => {
                this.handlePredictArea(data);
            });
        } else {
            if (currentPolygon) {
                currentPolygon.setMap(null);
            }
        }
    };

    finishValidatingPolygon = (data: any) => {
        const { markersWithStatus } = this.state;
        const { area, bollards } = data;
        bollards.forEach((bollard: any) => {
            if (
                markersWithStatus.active.some(
                    marker => bollard.geoCenter.lat === marker.lat && bollard.geoCenter.lng === marker.lng,
                )
            ) {
                bollard.status = BollardStatus.Active;
            } else if (
                markersWithStatus.disabled.some(
                    marker => bollard.geoCenter.lat === marker.lat && bollard.geoCenter.lng === marker.lng,
                )
            ) {
                bollard.status = BollardStatus.Disabled;
            }
        });
        this.setState(
            {
                area,
                predictedMarkers: bollards.map((bollard: any) => ({
                    position: bollard.geoCenter,
                    status: bollard.status === undefined ? BollardStatus.Unlocalized : bollard.status,
                    id: bollard.id,
                })),
                possibleMarkers: bollards.length,
            },
            this.refreshMarkers,
        );
    };

    onPolygonComplete = (polygon: google.maps.Polygon) => {
        const { currentPolygon } = this.state;
        google.maps.event.addListener(polygon.getPath(), 'set_at', throttledValidatePolygon);
        google.maps.event.addListener(polygon.getPath(), 'insert_at', throttledValidatePolygon);
        google.maps.event.addListener(polygon.getPath(), 'remove_at', throttledValidatePolygon);

        if (currentPolygon !== null) {
            currentPolygon.setMap(null);
        }

        this.setState({ currentPolygon: polygon });

        this.validatePolygon(false, true);
    };

    onFileChange = (evt: any) => {
        this.setState({
            image: evt.target.files[0],
            imageUrl: URL.createObjectURL(evt.target.files[0]),
        });
    };

    onSelectBusiness = (name: string, value: string): void => {
        const {
            businesses, mapRef, businessMarker, businessOptions,
        } = this.state;
        const business = businesses.find(business => business.id === Number(value));
        if (business && mapRef !== null) {
            this.setState(
                {
                    businessPosition: { lat: business?.address.lat, lng: business?.address.lng },
                    fields: {
                        ...this.state.fields,
                        [name]: value,
                    },
                },
                () => {
                    mapRef.panTo({ lat: business?.address.lat, lng: business?.address.lng });
                    mapRef.setZoom(19);
                    if (businessMarker !== null) {
                        businessMarker.setMap(null);
                    }
                    this.setState({
                        businessMarker: customMarker(
                            mapRef,
                            { lat: business?.address.lat, lng: business?.address.lng },
                            undefined,
                            getOptionLabelByValue(value, businessOptions),
                        ),
                    });
                },
            );
        }
    };

    updateBollard = async (bollardId: number, newBollardValues: Partial<Bollard>) => {
        const {
            updateBollard, businessId, areaId, t,
        } = this.props;
        const { bollards } = this.state;
        const bollard = bollards.find(boll => boll.id === bollardId);
        if (businessId !== undefined && areaId !== undefined && bollard) {
            updateBollard(
                businessId,
                areaId,
                bollard,
                newBollardValues,
            ).then(data => {
                if (data === true) {
                    this.fetchBollards();
                    this.fetchArea();
                } else {
                    this.setState({ errors: handleFormSubmitFailure(t, data) });
                }
            });
        }
    };

    onScheduleDayChange = (date: any, day: ReservationPeriodFormEntry) => {
        const { fields } = this.state;
        const index = fields[AreaFormField.Schedule].indexOf(day);
        const newScheduleEntries = [...fields[AreaFormField.Schedule]];

        newScheduleEntries[index] = {
            ...fields[AreaFormField.Schedule][index],
            dayOfYear: date,
        };
        this.setState({
            fields: {
                ...fields,
                [AreaFormField.Schedule]: newScheduleEntries,
            },
        });
    };

    onScheduleTimeChange = (date: MaterialUiPickersDate, day: ReservationPeriodFormEntry, start = true) => {
        if (date) {
            const { fields } = this.state;
            const index = fields[AreaFormField.Schedule].indexOf(day);

            const newScheduleEntries = [...fields[AreaFormField.Schedule]];
            const newScheduleToDelete = [...fields[AreaFormField.ScheduleDelete]];

            if (day.id !== undefined && !fields[AreaFormField.ScheduleDelete].includes(day.id)) {
                newScheduleToDelete.push(day.id);
            }

            newScheduleEntries[index] = {
                ...fields[AreaFormField.Schedule][index],
                edited: true,
            };
            if (start) {
                newScheduleEntries[index].startTime = date.format('HH:mm:00');
            } else {
                newScheduleEntries[index].endTime = date.format('HH:mm:00');
            }
            this.setState({
                fields: {
                    ...fields,
                    [AreaFormField.Schedule]: newScheduleEntries,
                    [AreaFormField.ScheduleDelete]: newScheduleToDelete,
                },
            });
        }
    };

    deleteScheduleDay = (day: ReservationPeriodFormEntry) => {
        const { fields } = this.state;

        const newScheduleToDelete = [...fields[AreaFormField.ScheduleDelete]];
        const newScheduleEntries = [...fields[AreaFormField.Schedule]];

        newScheduleEntries.splice(fields[AreaFormField.Schedule].indexOf(day), 1);
        if (day.id !== undefined) {
            newScheduleToDelete.push(day.id);
        }
        this.setState({
            fields: {
                ...fields,
                [AreaFormField.Schedule]: newScheduleEntries,
                [AreaFormField.ScheduleDelete]: newScheduleToDelete,
            },
        });
    };

    addScheduleDay = () => {
        const { fields, specialDay } = this.state;
        const newScheduleEntries = [...fields[AreaFormField.Schedule]];

        if (specialDay) {
            newScheduleEntries.push({
                dayOfYear: specialDay ? new Date() : undefined,
                startTime: DEFAULT_EARLY_DATE,
                endTime: DEFAULT_LATE_DATE,
                price: '',
                edited: true,
                designation: '',
            });
        } else {
            newScheduleEntries.push({
                dayOfWeek: specialDay ? undefined : WeekDays.Monday,
                startTime: DEFAULT_EARLY_DATE,
                endTime: DEFAULT_LATE_DATE,
                price: '',
                edited: true,
                designation: '',
            });
        }

        this.setState({
            fields: {
                ...fields,
                [AreaFormField.Schedule]: newScheduleEntries,
            },
        });
    };

    onScheduleChange = (name: string, value: string, day: ReservationPeriodFormEntry): void => {
        const { fields } = this.state;
        const index = fields[AreaFormField.Schedule].indexOf(day);

        const newScheduleEntries = [...fields[AreaFormField.Schedule]];
        const newScheduleToDelete = [...fields[AreaFormField.ScheduleDelete]];

        if (day.id !== undefined && !fields[AreaFormField.ScheduleDelete].includes(day.id)) {
            newScheduleToDelete.push(day.id);
        }

        newScheduleEntries[index] = {
            ...fields[AreaFormField.Schedule][index],
            [name]: value,
            edited: true,
        };

        this.setState({
            fields: {
                ...fields,
                [AreaFormField.Schedule]: newScheduleEntries,
                [AreaFormField.ScheduleDelete]: newScheduleToDelete,
            },
        });
    };

    onFieldsChange = (name: string, value: string): void => {
        this.setState(
            {
                fields: {
                    ...this.state.fields,
                    [name]: value,
                },
            },
            () => {
                if (name === AreaFormField.Radius) {
                    this.setState({ lastInput: new Date() }, this.shouldValidatePolygon);
                }
            },
        );
    };

    onStateChange = (name: string, value: string): void => {
        this.setState({
            ...this.state,
            [name]: value,
        });
    };

    shouldValidatePolygon = () => {
        const { lastInput } = this.state;

        const timeStamp = new Date();
        if (lastInput.getTime() + 500 < timeStamp.getTime()) {
            throttledValidatePolygon();
        } else {
            this.setState({ lastInput: timeStamp });
            clearTimeout(validatePolygonTimeout);
            validatePolygonTimeout = setTimeout(this.shouldValidatePolygon, 500);
        }
    };

    onCheckChange = (name: string, checked: boolean): void => {
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                [name]: checked,
            },
        });
    };

    validateAreaForm = (): KeyedObject | null => {
        const {
            validateNewArea, validateEditArea, t, areaId,
        } = this.props;
        const { fields, image } = this.state;
        if (fields[AreaFormField.Polygon].length < 3) {
            displayNotification({
                message: t('areaForm.errorPolygon'),
                type: NotificationType.Danger,
            });
            return null;
        }

        if (image === null && areaId === undefined) {
            displayNotification({
                message: t('areaForm.errorPhoto'),
                type: NotificationType.Danger,
            });
            return null;
        }

        return areaId === undefined ? validateNewArea(fields) : validateEditArea(fields);
    };

    calculateReservationPeriods = (): FormattedReservationPeriod[] | null => {
        const { t } = this.props;
        const { fields } = this.state;

        let validPrices = true;
        const reservationPeriods = fields[AreaFormField.Schedule]
            .filter(entry => entry.edited)
            .map(entry => {
                if (!Regex.Decimal.test(entry.price)) {
                    validPrices = false;
                }

                return {
                    ...entry,
                    price: stringToDecimal(entry.price),
                    startTime: entry.startTime,
                    endTime: entry.endTime,
                };
            });

        if (!validPrices) {
            displayNotification({
                message: t('areaForm.priceError'),
                type: NotificationType.Danger,
            });
            return null;
        }

        return reservationPeriods;
    };

    onFormSubmit = (e: any): void => {
        e.preventDefault();

        const {
            submitNewArea, areaId, submitEditArea, businessId,
        } = this.props;
        const { fields, image } = this.state;

        const errors = this.validateAreaForm();

        this.setState({
            errors,
        });

        if (errors) return;

        const formData = new FormData();
        const reservationPeriods = this.calculateReservationPeriods();

        if (!reservationPeriods) {
            return;
        }

        formData.append(
            'businessArea',
            new Blob(
                [
                    JSON.stringify({
                        ...fields,
                        reservationPeriods,
                        [AreaFormField.Description]: fields[AreaFormField.Description] ? fields[AreaFormField.Description].trim() : null,
                        [AreaFormField.Radius]: stringToDecimal(fields[AreaFormField.Radius]),
                    }),
                ],
                {
                    type: 'application/json',
                },
            ),
        );

        if (image) {
            formData.append('photo', image);
        }

        // edit area
        if (areaId && businessId) {
            submitEditArea(
                Number(businessId),
                Number(areaId),
                formData,
                this.onFormSubmitSuccess,
                this.onFormSubmitFailure,
            );

            return;
        }

        submitNewArea(
            fields[AreaFormField.Business],
            formData,
            this.onFormSubmitSuccess,
            this.onFormSubmitFailure,
        );
    };

    onFormSubmitSuccess = (data: Area) => {
        const { t, history, areaId } = this.props;

        let message = t('areaForm.success', { name: data.name });

        if (data.status === AreaStatus.Disabled) {
            message = t('areaForm.createdSuccessfullyDisabledBollards', { name: data.name });
        } else if (areaId !== undefined) {
            message = t('areaForm.successEdit');
        }

        displayNotification({
            message,
            type: NotificationType.Success,
        });

        history.push(AppRoute.Areas);
    };

    onFormSubmitFailure = () => {
        const { t, createAreaErrors, updateAreaErrors } = this.props;
        if (
            updateAreaErrors?.errors.length > 0
            && updateAreaErrors?.errors.find((error: any) => error.errorCode === 31 || error.errorCode === 32)
            !== undefined
        ) {
            this.setState({ updateModal: true });
        } else {
            this.setState({ errors: handleFormSubmitFailure(t, createAreaErrors, updateAreaErrors) });
        }
    };

    selectSort = (col: string) => {
        const { order } = this.state;
        const sort = this.state.params._sort;
        const newOrder = sort !== col ? Order.Ascending : order === Order.Ascending ? Order.Descending : Order.Ascending;
        const newOrderParam = newOrder === Order.Ascending ? OrderQuery.Ascending : OrderQuery.Descending;
        this.setState(
            {
                order: newOrder,
                params: {
                    ...this.state.params,
                    _sort: col,
                    _order: newOrderParam,
                },
            },
            this.fetchBollards,
        );
    };

    onPageChange = (evt: any, page: number) => {
        this.setState(
            {
                params: { ...this.state.params, _page: page },
            },
            this.fetchBollards,
        );
    };

    onRowsPerPageChange = (evt: any) => {
        this.setState(
            {
                params: { ...this.state.params, _limit: evt.target.value },
            },
            this.fetchBollards,
        );
    };

    downloadQrcodesPdf = () => {
        const {
            businessId, areaId, getQrCodesPdf, t,
        } = this.props;
        const { fields } = this.state;

        if (businessId !== undefined && areaId !== undefined) {
            getQrCodesPdf(businessId, areaId).then(r => {
                const url = URL.createObjectURL(r);
                const link = document.createElement('a');
                if (link) {
                    link.href = url;
                    link.setAttribute(
                        'download',
                        t('areaForm.pdfFileName', { area: fields[AreaFormField.Name].replaceAll(' ', '_') }),
                    );
                    document.body.appendChild(link);
                    link.click();
                    if (link.parentNode) {
                        link.parentNode.removeChild(link);
                    }
                    URL.revokeObjectURL(url);
                }
            });
        }
    }

    getMarkerIcon(status: BollardStatus, isSelected: boolean): string {
        if (isSelected) return picked;

        switch (status) {
            case BollardStatus.Active:
                return green;
            case BollardStatus.Disabled:
                return red;
            case BollardStatus.Unlocalized:
            default:
                return yellow;
        }
    }

    renderInfoSection(): ReactElement {
        const {
            businessOptions, fields, errors, timezonesOpts,
        } = this.state;
        const {
            t, createAreaFetching, updateAreaFetching, areaId,
        } = this.props;

        return (
            <div className="wide-form__grid-container">
                <span className="section-header">
                    <InfoIcon /> {t('areaForm.infoSection')}
                </span>
                <div className="wide-form__grid-container__double-space">
                    <FormSelectField
                        name={AreaFormField.Business}
                        onChange={this.onSelectBusiness}
                        options={businessOptions}
                        value={fields[AreaFormField.Business]}
                        errors={errors}
                        label={t('areaForm.businessLabel')}
                        disabled={createAreaFetching || updateAreaFetching || areaId !== undefined}
                        testId="business-select"
                    />
                </div>
                <div className="wide-form__grid-container__double-space">
                    <FormTextField
                        name={AreaFormField.Name}
                        value={fields[AreaFormField.Name]}
                        onChange={this.onFieldsChange}
                        placeholder={t('areaForm.nameLabel')}
                        label={`${t('areaForm.nameLabel')}*`}
                        errors={errors}
                        disabled={createAreaFetching || updateAreaFetching}
                    />
                </div>
                <FormSelectField
                    name={AreaFormField.TimeZone}
                    onChange={this.onFieldsChange}
                    options={timezonesOpts}
                    value={fields[AreaFormField.TimeZone]}
                    errors={errors}
                    label={t('areaForm.timezoneLabel')}
                    disabled={createAreaFetching || updateAreaFetching || areaId !== undefined}
                    testId="timezone-select"
                />
                <div className="wide-form__grid-container__double-space--details">
                    <span className="form-section-title">
                        {t('areaForm.detailsTitle')}
                    </span>
                    <TextField
                        name={AreaFormField.Description}
                        value={fields[AreaFormField.Description]}
                        onChange={e => this.onFieldsChange(e.currentTarget.name, e.currentTarget.value)}
                        multiline
                        rows={4}
                        maxRows={4}
                        label={t('areaForm.description')}
                        placeholder={t('areaForm.description')}
                        variant="outlined"
                        fullWidth
                        inputProps={{ maxLength: 1200 }}
                    />
                </div>
            </div>
        );
    }

    renderPictureSection(): ReactElement {
        const { imageUrl } = this.state;
        const { t, createAreaFetching, updateAreaFetching } = this.props;
        return (
            <>
                <span className="section-header section-header--margin">
                    <PictureIcon /> {t('areaForm.pictureSection')}
                </span>
                <div className="wide-form__area-picture-container">
                    <img src={imageUrl !== '' ? imageUrl : boser} alt="avatar" />
                    <div className="file-input-wrapper">
                        <label className="file-input-wrapper__button" htmlFor="file">
                            <UploadIcon />
                            {t('areaForm.changeButton')}
                        </label>
                        <input
                            className="file-input-wrapper__input"
                            accept=".jpg,.png"
                            type="file"
                            id="file"
                            onChange={this.onFileChange}
                            data-testid="file-input"
                            disabled={createAreaFetching || updateAreaFetching}
                        />
                        <p>
                            {t('general.format')} <br /> {t('general.image')}
                        </p>
                    </div>
                </div>
            </>
        );
    }

    renderAreaSection(): ReactElement {
        const {
            fields, errors, area, possibleMarkers,
        } = this.state;
        const { t, createAreaFetching, updateAreaFetching } = this.props;
        return (
            <>
                <span className="section-header">
                    <AreaIcon /> {t('areaForm.areaSection')}
                </span>
                <span className="form-section-title">{t('areaForm.areaTooltip')}</span>
                <Map zoom={8} height="500px" center={mapCenter} onMapMounted={this.onMapMounted} togglable />
                <div className="wide-form__grid-container wide-form__grid-container--margin-top">
                    <FormTextField
                        name="area"
                        value={area}
                        placeholder={t('areaForm.areaLabel')}
                        label={`${t('areaForm.areaLabel')}`}
                        errors={errors}
                        disabled
                        onChange={this.onStateChange}
                        hint=" "
                    />
                    <FormTextField
                        name={AreaFormField.Radius}
                        value={fields[AreaFormField.Radius]}
                        onChange={this.onFieldsChange}
                        placeholder={t('areaForm.radiusLabel')}
                        label={`${t('areaForm.radiusLabel')}*`}
                        errors={errors}
                        disabled={createAreaFetching || updateAreaFetching}
                        hint={`${t('areaForm.distanceLabel')} ${
                            isNaN(Number(fields[AreaFormField.Radius])) ? ' ' : Number(fields[AreaFormField.Radius]) * 2
                        }m`}
                    />
                    <FormTextField
                        name="possibleMarkers"
                        value={String(possibleMarkers)}
                        placeholder={t('areaForm.possibleLabel')}
                        label={`${t('areaForm.possibleLabel')}*`}
                        errors={errors}
                        onChange={this.onStateChange}
                        disabled
                    />
                    <FormTextField
                        name={AreaFormField.RequestedBollards}
                        value={fields[AreaFormField.RequestedBollards]}
                        onChange={this.onFieldsChange}
                        placeholder={t('areaForm.bollardsLabel')}
                        label={`${t('areaForm.bollardsLabel')}*`}
                        errors={errors}
                        disabled={createAreaFetching || updateAreaFetching}
                    />
                </div>
            </>
        );
    }

    renderTableHeadCell(name: string, label?: string): ReactElement {
        const { order, params: { _sort } } = this.state;
        const { t } = this.props;

        return (
            <TableCell align="center">
                <TableSortLabel
                    active={_sort === name}
                    direction={_sort === name ? order : Order.Ascending}
                    onClick={() => this.selectSort(name)}
                >
                    {t(`areaForm.${label || name}`)}
                </TableSortLabel>
            </TableCell>
        );
    }

    renderBollardsSection(): ReactElement {
        const { areaId, t } = this.props;
        const {
            params, totalBollards, bollards, anchorBollardOptions, selectedBollardId,
        } = this.state;

        if (areaId === undefined) {
            return <React.Fragment />;
        }

        return (
            <>
                <span className="section-header">
                    <BollardIcon /> {t('areaForm.bollardsSection')}
                </span>
                <div className="form-space-between">
                    <span className="form-section-title">
                        {t('areaForm.bollardNumber')} {totalBollards}
                    </span>
                    <Button
                        variant="contained"
                        color="primary"
                        type="button"
                        onClick={this.downloadQrcodesPdf}
                    >
                        {t('areaForm.downloadPdf')}
                    </Button>
                </div>
                <Paper className="table-container">
                    <TableContainer component={Paper} style={{ maxHeight: '480px' }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {this.renderTableHeadCell('id')}
                                    {this.renderTableHeadCell('designation')}
                                    {this.renderTableHeadCell('location.lng', 'longitude')}
                                    {this.renderTableHeadCell('location.lat', 'latitude')}
                                    {this.renderTableHeadCell('status')}
                                    <TableCell align="center">{t('areaForm.actions')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    anchorBollardOptions && (
                                        <Menu
                                            open
                                            keepMounted
                                            anchorEl={anchorBollardOptions}
                                            onClose={() => this.setState({ anchorBollardOptions: null })}
                                        >
                                            <MenuItem
                                                data-testid="menu-edit-fast"
                                                onClick={() => this.setState({
                                                    anchorBollardOptions: null,
                                                    showFastSticker: true,
                                                })}
                                            >
                                                {t('areaForm.editFastSticker')}
                                            </MenuItem>
                                            <MenuItem
                                                data-testid="menu-edit-userid"
                                                onClick={() => this.setState({
                                                    anchorBollardOptions: null,
                                                    showEditId: true,
                                                })}
                                            >
                                                {t('areaForm.editUserId')}
                                            </MenuItem>
                                            <MenuItem
                                                data-testid="menu-download-qr"
                                                onClick={() => {
                                                    const bollard = bollards.find(bol => bol.id === selectedBollardId);
                                                    if (bollard) window.open(bollard.qrCode);
                                                }}
                                            >
                                                {t('areaForm.downloadQRCode')}
                                            </MenuItem>
                                        </Menu>
                                    )
                                }
                                {bollards.map(bollard => {
                                    return (
                                        <TableRow key={bollard.id}>
                                            <TableCell align="center">{bollard.id}</TableCell>
                                            <TableCell align="center">{bollard.designation}</TableCell>
                                            <TableCell align="center">{bollard.location.lng}</TableCell>
                                            <TableCell align="center">{bollard.location.lat}</TableCell>
                                            <TableCell>
                                                <Switch
                                                    checked={bollard.status === BollardStatus.Active}
                                                    onChange={() => this.updateBollard(
                                                        bollard.id,
                                                        {
                                                            status: bollard.status === BollardStatus.Active
                                                                ? BollardStatus.Disabled : BollardStatus.Active,
                                                        },
                                                    )}
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                    color="primary"
                                                />
                                                {t(`status.${bollard.status}`)}
                                            </TableCell>
                                            <TableCell align="center">
                                                <IconButton
                                                    data-testid="menu-options"
                                                    onClick={e => this.setState({
                                                        anchorBollardOptions: e.currentTarget,
                                                        selectedBollardId: bollard.id,
                                                    })}
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 20]}
                        component="div"
                        count={totalBollards}
                        rowsPerPage={params._limit}
                        page={params._page}
                        onPageChange={this.onPageChange}
                        onRowsPerPageChange={this.onRowsPerPageChange}
                    />
                </Paper>
            </>
        );
    }

    renderScheduleDays(special: boolean) {
        const { t, createAreaFetching, updateAreaFetching } = this.props;
        const { fields, errors } = this.state;

        const schedule = fields[AreaFormField.Schedule].filter(
            day => (special && !isNil(day.dayOfYear)) || (!special && !isNil(day.dayOfWeek)),
        );
        const weekDays = weekDaysValues.map(value => ({ label: t(`days.${value.toLowerCase()}`), value }));

        return (
            <>
                {schedule.map((day, index) => (
                    <React.Fragment key={index}>
                        {special ? (
                            <KeyboardDatePicker
                                disableToolbar
                                disabled={createAreaFetching || updateAreaFetching}
                                variant="inline"
                                format="DD-MM-yyyy"
                                id={`date-picker-inline${index}`}
                                label={t('areaForm.specialLabel')}
                                value={day.dayOfYear}
                                onChange={(date: any) => this.onScheduleDayChange(date, day)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        ) : (
                            <FormSelectField
                                name="dayOfWeek"
                                value={day.dayOfWeek}
                                onChange={(name: string, value: string) => this.onScheduleChange(name, value, day)}
                                options={weekDays}
                                errors={errors}
                                label={t('areaForm.dayLabel')}
                                disabled={createAreaFetching || updateAreaFetching}
                                testId="day-select"
                            />
                        )}
                        <KeyboardTimePicker
                            margin="normal"
                            id={`start-time-picker${index}`}
                            ampm={false}
                            minutesStep={5}
                            label={t('areaForm.startTimeLabel')}
                            value={moment(day.startTime, TIME_FORMAT)}
                            onChange={(date: MaterialUiPickersDate) => this.onScheduleTimeChange(date, day)}
                            disabled={createAreaFetching || updateAreaFetching}
                        />
                        <KeyboardTimePicker
                            margin="normal"
                            id={`end-time-picker${index}`}
                            ampm={false}
                            minutesStep={5}
                            label={t('areaForm.endTimeLabel')}
                            value={moment(day.endTime, TIME_FORMAT)}
                            onChange={(date: MaterialUiPickersDate) => this.onScheduleTimeChange(date, day, false)}
                            disabled={createAreaFetching || updateAreaFetching}
                        />
                        <FormTextField
                            name="designation"
                            value={day.designation}
                            onChange={(name: string, value: string) => this.onScheduleChange(name, value, day)}
                            placeholder={t('areaForm.designationLabel')}
                            label={t('areaForm.designationLabel')}
                            errors={errors}
                            maxLength={4}
                            disabled={createAreaFetching || updateAreaFetching}
                        />
                        <FormTextField
                            name="price"
                            value={day.price}
                            onChange={(name: string, value: string) => this.onScheduleChange(name, value, day)}
                            placeholder={t('areaForm.priceLabel')}
                            label={t('areaForm.priceLabel')}
                            errors={errors}
                            disabled={createAreaFetching || updateAreaFetching}
                        />
                        <DeleteIcon onClick={() => this.deleteScheduleDay(day)} data-testid="delete-schedule" />
                    </React.Fragment>
                ))}
            </>
        );
    }

    renderScheduleSection(): ReactElement {
        const { t, createAreaFetching, updateAreaFetching } = this.props;
        const { specialDay } = this.state;

        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <span className="section-header">
                    <ScheduleIcon /> {t('areaForm.scheduleSection')}
                </span>
                <div className="wide-form__wider-grid-container">
                    {this.renderScheduleDays(false)}
                    <div className="wide-form__wider-grid-container__separator" />
                    {this.renderScheduleDays(true)}
                </div>
                <div className="add-schedule-container">
                    {t('areaForm.dayLabel')}
                    <Switch
                        checked={specialDay}
                        onChange={() => this.setState({ specialDay: !specialDay })}
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        color="primary"
                    />
                    {t('areaForm.specialLabel')}
                    <br />
                    <Button
                        className="add-button"
                        variant="contained"
                        color="primary"
                        type="button"
                        onClick={this.addScheduleDay}
                        disabled={createAreaFetching || updateAreaFetching}
                    >
                        {t('areaForm.addButton')}
                    </Button>
                </div>
            </MuiPickersUtilsProvider>
        );
    }

    renderDialog = (title: string, content: string | React.ReactNode, stateKey: keyof OwnState, open: boolean, yesCallback: (() => void)) => {
        const { t } = this.props;

        return (
            <Dialog
                open={open}
                onClose={
                    () => this.setState(prev => ({
                        ...prev,
                        [stateKey]: false,
                    }))
                }
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={
                            () => this.setState(prev => ({
                                ...prev,
                                [stateKey]: false,
                            }))
                        }
                        color="primary"
                    >
                        {t(DIALOG_NO)}
                    </Button>
                    <Button
                        onClick={yesCallback}
                        color="primary"
                        autoFocus
                    >
                        {t(DIALOG_YES)}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderForceModal(): ReactElement {
        const { t } = this.props;
        const { updateModal, fields } = this.state;
        const yesCallback = () => {
            this.setState(
                { updateModal: false, fields: { ...fields, [AreaFormField.Force]: true } },
                () => this.onFormSubmit({ preventDefault: () => {} }),
            );
        };

        return this.renderDialog(t('areaForm.forceTitle'), t('areaForm.forceText'), 'updateModal', updateModal, yesCallback);
    }

    renderRadiusModal(): ReactElement {
        const { t } = this.props;
        const { radiusModal } = this.state;
        const yesCallback = () => {
            this.validatePolygon(true);
            this.setState({ radiusModal: false });
        };

        return this.renderDialog(t('areaForm.radiusTitle'), t('areaForm.radiusText'), 'radiusModal', radiusModal, yesCallback);
    }

    renderPolygonModal(): ReactElement {
        const { t } = this.props;
        const { polygonModal } = this.state;
        const yesCallback = () => {
            this.validatePolygon(true);
            this.setState({ polygonModal: false });
        };

        return this.renderDialog(t('areaForm.forceTitle'), t('areaForm.forceText'), 'polygonModal', polygonModal, yesCallback);
    }

    renderShowEditId = () => {
        const { showEditId, selectedBollardId, bollards } = this.state;
        const onSave = (userId: string) => {
            this.updateBollard(selectedBollardId, { designation: userId });
            this.setState({ showEditId: false });
        };

        const currentBollard = bollards.find(bol => bol.id === selectedBollardId);
        if (!showEditId || !currentBollard) return null;

        return (
            <EditUserIdModal
                onClose={() => this.setState({ showEditId: false })}
                onSave={onSave}
                defaultUserId={currentBollard.designation}
            />
        );
    }

    render(): ReactElement {
        const {
            t, createAreaFetching, updateAreaFetching, areaId,
        } = this.props;
        const {
            fetchingArea, failAreaFetch, selectedBollardId, bollards, showFastSticker,
        } = this.state;

        if (fetchingArea) {
            return <Loader />;
        }
        if (failAreaFetch) {
            return <h3>{t('areaForm.invalidArea')}</h3>;
        }

        return (
            <form onSubmit={this.onFormSubmit} className="wide-form" data-testid="area-form">
                {(createAreaFetching || updateAreaFetching) && (
                    <Backdrop open data-testid="loader">
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}

                {this.renderInfoSection()}
                {this.renderPictureSection()}
                {this.renderAreaSection()}
                {this.renderBollardsSection()}
                {this.renderScheduleSection()}
                {this.renderRadiusModal()}
                {this.renderPolygonModal()}
                {this.renderForceModal()}
                {this.renderShowEditId()}
                {
                    showFastSticker && (
                        <FastMPModal
                            onSave={(id: string) => this.updateBollard(selectedBollardId, { fastMPStickerId: id })}
                            onClose={() => this.setState({ selectedBollardId: -1, showFastSticker: false })}
                            defaultStickerId={bollards.find(boll => boll.id === selectedBollardId)?.fastMPStickerId || ''}
                        />
                    )
                }

                <Button
                    className="margin-button"
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={createAreaFetching || updateAreaFetching}
                >
                    {areaId === undefined ? t('areaForm.submitButton') : t('areaForm.editButton')}
                </Button>
            </form>
        );
    }
}

export default withTranslationContext(withAuthenticationContext(withBusinessesContext(withAreasContext(withMerchantsContext(withBollardsContext(withRouter(AreaForm)))))));
