/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { BusinessesContext, withBusinessesContext } from '../controllers/BusinessesContext';
import { AreasContext, withAreasContext } from '../controllers/AreasContext';
import { Business } from '../../types/businesses';
import { Area } from '../../types/areas';
import { BookingsContext, withBookingsContext } from '../controllers/BookingsContext';
import BookingsCalendar from '../elements/calendar/BookingsCalendar';
import { KeyedObject } from '../../types/general';
import { MerchantsContext, withMerchantsContext } from '../controllers/MerchantsContext';

interface OwnProps extends TranslationContext, BusinessesContext, AreasContext, BookingsContext, RouteComponentProps, MerchantsContext {}

interface OwnState {
    isFetching: boolean;
    businesses: Business[];
    areas: Area[];
    businessSelected: Business | null;
    areaSelected: Area | null;
    previousSelectedBusinessId: number | null;
    previousSelectedAreaId: number | null;
}

const initialState: OwnState = {
    isFetching: false,
    businesses: [],
    areas: [],
    businessSelected: null,
    areaSelected: null,
    previousSelectedBusinessId: null,
    previousSelectedAreaId: null,
};

class BookingsCalendarScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    constructor(props: OwnProps) {
        super(props);
        const {
            calendarBookingBusinessData,
            location,
            setBookingCalendarBusinessData,
            setBookingCalendarBollardsData,
        } = this.props;

        const locationState = location.state ? location.state as KeyedObject : null;
        let isPreviousLocationBookingsDetails = false;

        if (locationState && locationState.from) {
            isPreviousLocationBookingsDetails = locationState.from.includes('/bookings/details');
        }

        if (!isPreviousLocationBookingsDetails) {
            setBookingCalendarBusinessData(null);
            setBookingCalendarBollardsData(null);
            return;
        }

        if (calendarBookingBusinessData) {
            this.state.previousSelectedBusinessId = calendarBookingBusinessData.businessId;
            this.state.previousSelectedAreaId = calendarBookingBusinessData.areaId;
        }
    }

    componentDidMount() {
        this.getBusinesses();
    }

    onSelectBusiness = (value: string): void => {
        const {
            businesses,
        } = this.state;

        const business = businesses.find(b => b.id === Number(value));

        this.setState({
            businessSelected: business || null,
        }, this.getBusinessAreas);
    };

    onSelectArea = async (value: number): Promise<void> => {
        const { getArea } = this.props;
        const { businessSelected } = this.state;

        if (!businessSelected) return;

        const area = await getArea(businessSelected.id, value);

        this.setState({
            areaSelected: area,
        });
    };

    getBusinesses = async () => {
        const { getBusinesses, selectedMerchantId } = this.props;
        const { previousSelectedBusinessId } = this.state;

        this.setState({
            isFetching: true,
        });

        const businessesData = await getBusinesses({ _limit: 999, merchantId: selectedMerchantId });
        const businesses = businessesData && businessesData.data ? businessesData.data.filter((b: Business) => b.numberOfAreas > 0) : [];
        let business: Business | null = businesses[0] || null;

        if (previousSelectedBusinessId) {
            business = businesses.find((b: Business) => b.id === previousSelectedBusinessId) || null;
        }

        this.setState({
            isFetching: !!(business),
            businesses,
            businessSelected: business,
        }, this.getBusinessAreas);
    }

    getBusinessAreas = async () => {
        const { getBusinessAreas } = this.props;
        const { businessSelected, previousSelectedAreaId } = this.state;

        if (businessSelected) {
            const areasData = await getBusinessAreas(String(businessSelected.id), { _limit: 999 });
            let area = null;
            if (areasData && areasData.data) {
                area = areasData.data[0];
                if (previousSelectedAreaId) {
                    area = areasData.data.find((a: Area) => a.id === previousSelectedAreaId);
                }
            }

            if (area) this.onSelectArea(area.id);

            this.setState({
                isFetching: false,
                areas: areasData?.data || [],
            });
        } else {
            this.setState({
                areas: [],
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>) {
        const { selectedMerchantId } = this.props;
        if (selectedMerchantId !== prevProps.selectedMerchantId) this.getBusinesses();
    }

    render() {
        const { t } = this.props;
        const {
            isFetching, businessSelected, businesses, areas, areaSelected,
        } = this.state;
        return (
            <div className="bookings-page">
                {isFetching && (
                    <Backdrop open data-testid="loader">
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}
                <div className="bookings-page__header">
                    <h2 className="header-container__header">{t('bookingCalendar.calendar')}</h2>
                </div>
                <div className="widest-form widest-form--calendar">
                    <BookingsCalendar
                        area={areaSelected}
                        business={businessSelected}
                        areas={areas}
                        businesses={businesses}
                        onSelectBusiness={this.onSelectBusiness}
                        onSelectArea={this.onSelectArea}
                    />
                </div>
            </div>
        );
    }
}

export default withRouter(withTranslationContext(withBusinessesContext(withAreasContext(withBookingsContext(withMerchantsContext(BookingsCalendarScreen))))));
