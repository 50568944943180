/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { REHYDRATE } from 'redux-persist';

import { MerchantsState } from './types';
import { GeneralAction } from '../actions/general_types';
import { MerchantsAction } from '../actions/merchants_types';
import { AuthenticationAction } from '../actions/authentication_types';

export const merchantsInitialState: MerchantsState = {
    createMerchantRequest: {
        isFetching: false,
        errors: null,
    },
    updateMerchantRequest: {
        isFetching: false,
        errors: null,
    },
    selectedMerchantId: null,
};

export default function (state = merchantsInitialState, action: any): MerchantsState {
    switch (action.type) {
        case REHYDRATE:
            if (action.payload && action.payload.merchants) {
                return {
                    ...action.payload.merchants,
                    createMerchantRequest: merchantsInitialState.createMerchantRequest,
                    updateMerchantRequest: merchantsInitialState.updateMerchantRequest,
                };
            }
            return state;
        case MerchantsAction.CREATE_MERCHANT_REQUEST:
            return {
                ...state,
                createMerchantRequest: {
                    ...state.createMerchantRequest,
                    isFetching: true,
                },
            };
        case MerchantsAction.CREATE_MERCHANT_SUCCESS:
            return {
                ...state,
                createMerchantRequest: {
                    isFetching: false,
                    errors: null,
                },
            };
        case MerchantsAction.CREATE_MERCHANT_FAILURE:
            return {
                ...state,
                createMerchantRequest: {
                    isFetching: false,
                    errors: action.payload,
                },
            };
        case MerchantsAction.UPDATE_MERCHANT_REQUEST:
            return {
                ...state,
                updateMerchantRequest: {
                    ...state.updateMerchantRequest,
                    isFetching: true,
                },
            };
        case MerchantsAction.UPDATE_MERCHANT_SUCCESS:
            return {
                ...state,
                updateMerchantRequest: {
                    isFetching: false,
                    errors: null,
                },
            };
        case MerchantsAction.UPDATE_MERCHANT_FAILURE:
            return {
                ...state,
                updateMerchantRequest: {
                    isFetching: false,
                    errors: action.payload,
                },
            };
        case MerchantsAction.SET_MERCHANT_ID:
            return {
                ...state,
                selectedMerchantId: action.payload,
            };
        case AuthenticationAction.LOGOUT_REQUEST:
        case GeneralAction.STORE_RESET:
            return { ...merchantsInitialState };
        default:
            return state;
    }
}
