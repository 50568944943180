/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import OptionsIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ActivateIcon from '@material-ui/icons/Done';
import BlockIcon from '@material-ui/icons/Block';
import EditIcon from '@material-ui/icons/Edit';
import { throttle } from 'lodash';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Lottie from 'react-lottie';
import FormTextField from '../elements/FormTextField';
import FormSelectField from '../elements/FormSelectField';
import { withTranslationContext, TranslationContext } from '../controllers/TranslationContext';
import { withBusinessesContext, BusinessesContext } from '../controllers/BusinessesContext';
import { SelectOption, Order, OrderQuery } from '../../types/general';
import {
    Business, BusinessParams, BusinessStatus, UpdateBusinessStatus,
} from '../../types/businesses';
import { AppRoute } from '../../types/routes';
import { buildRoute } from '../../utils/misc';
import { displayNotification, NotificationType } from '../../utils/notifications';
import { ErrorCode } from '../../types/errors';
import Can from '../containers/Can';
import { Permission } from '../../types/authorization';
import Loader from '../elements/Loader';
import { MerchantsContext, withMerchantsContext } from '../controllers/MerchantsContext';
import NoDataLottie from '../../assets/lottie/no-data.json';

interface OwnProps extends TranslationContext, BusinessesContext, MerchantsContext {}

interface OwnState {
    anchorElement: any;
    order: Order;
    businesses: Business[];
    totalBusinesses: number;
    params: BusinessParams;
    currentBusiness: Business | null;
    statusModal: boolean;
    isFetching: boolean;
}

const initialState: OwnState = {
    anchorElement: null,
    order: Order.Ascending,
    businesses: [],
    totalBusinesses: 0,
    params: {
        _limit: 20,
        _page: 0,
        _order: OrderQuery.Ascending,
        _q: '',
        _sort: 'name',
        status: BusinessStatus.All,
    },
    currentBusiness: null,
    statusModal: false,
    isFetching: true,
};

let throttledFetchBusinesses = () => {};

class BusinessesScreen extends Component<OwnProps, OwnState> {
    constructor(props: OwnProps) {
        super(props);
        this.state = {
            ...initialState,
        };
        throttledFetchBusinesses = throttle(this.fetchBusinesses, 500);
    }

    fetchBusinesses = () => {
        const { getBusinesses, selectedMerchantId } = this.props;
        const { params } = this.state;
        getBusinesses({ ...params, merchantId: selectedMerchantId }).then(businessesData => {
            if (businessesData) {
                this.setState({
                    businesses: businessesData.data,
                    totalBusinesses: businessesData.total,
                });
            } else {
                this.setState({
                    businesses: [],
                    totalBusinesses: 0,
                });
            }
            this.setState({ isFetching: false });
        });
    };

    componentDidMount() {
        this.fetchBusinesses();
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>) {
        const { selectedMerchantId } = this.props;
        if (selectedMerchantId !== prevProps.selectedMerchantId) this.fetchBusinesses();
    }

    onParamsChange = (name: string, value: string, throttle = false): void => {
        this.setState(
            {
                params: {
                    ...this.state.params,
                    _page: throttle ? 0 : this.state.params._page,
                    [name]: value,
                },
            },
            () => (throttle ? throttledFetchBusinesses() : this.fetchBusinesses()),
        );
    };

    setToggleStatus = () => {
        this.setState({
            statusModal: true,
            anchorElement: null,
        });
    };

    toggleStatus = () => {
        const { updateBusinessStatus, t } = this.props;
        const { currentBusiness } = this.state;

        if (!currentBusiness) return;

        const onUpdateSuccess = () => {
            this.fetchBusinesses();
            displayNotification({
                message: currentBusiness.status === BusinessStatus.Active
                    ? t('businessesScreen.successDisable')
                    : t('businessesScreen.successEnable'),
                type: NotificationType.Success,
            });
        };

        const { id, status } = currentBusiness;

        const newStatus = status === BusinessStatus.Active ? UpdateBusinessStatus.Disabled : UpdateBusinessStatus.Active;

        updateBusinessStatus(id,
            newStatus,
            onUpdateSuccess,
            errorCode => displayNotification({
                type: NotificationType.Danger,
                message: t(errorCode ? `errors.${ErrorCode[errorCode]}` : 'errors.general'),
            }));
    };

    activateMenu = (evt: React.MouseEvent<SVGSVGElement>, business: Business) => {
        evt.stopPropagation();
        this.setState({
            currentBusiness: business,
            anchorElement: evt.currentTarget,
        });
    };

    resetMenu = () => {
        this.setState({
            anchorElement: null,
        });
    };

    onPageChange = (evt: any, page: number) => {
        this.setState(
            {
                params: { ...this.state.params, _page: page },
            },
            this.fetchBusinesses,
        );
    };

    onRowsPerPageChange = (evt: any) => {
        this.setState(
            {
                params: { ...this.state.params, _limit: evt.target.value },
            },
            this.fetchBusinesses,
        );
    };

    selectSort = (col: string) => {
        const { order } = this.state;
        const sort = this.state.params._sort;
        const newOrder = sort !== col ? Order.Ascending : order === Order.Ascending ? Order.Descending : Order.Ascending;
        const newOrderParam = newOrder === Order.Ascending ? OrderQuery.Ascending : OrderQuery.Descending;
        this.setState(
            {
                order: newOrder,
                params: {
                    ...this.state.params,
                    _sort: col,
                    _order: newOrderParam,
                },
            },
            this.fetchBusinesses,
        );
    };

    renderTableHeadCell(name: string, label?: string): ReactElement {
        const { order } = this.state;
        const sort = this.state.params._sort;
        const { t } = this.props;
        return (
            <TableCell align="center">
                <TableSortLabel
                    active={sort === name}
                    direction={sort === name ? order : Order.Ascending}
                    onClick={() => this.selectSort(name)}
                >
                    {t(`businessesScreen.${label || name}`)}
                </TableSortLabel>
            </TableCell>
        );
    }

    renderTable(): ReactElement {
        const { t } = this.props;
        const {
            businesses, anchorElement, params, totalBusinesses, currentBusiness,
        } = this.state;

        const { _limit } = params;

        if (businesses.length === 0) {
            const defaultOptions = {
                loop: true,
                autoplay: true,
                animationData: NoDataLottie,
                rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                },
            };

            return (
                <div className="placeholder-no-data">
                    <Lottie
                        options={defaultOptions}
                        height={194}
                        width={194}
                    />
                    <p className="placeholder-no-data__title">{t('businessesScreen.noDataTitle')}</p>
                    <p className="placeholder-no-data__message">{t('businessesScreen.noDataMessage')}</p>
                </div>
            );
        }

        return (
            <Paper>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {this.renderTableHeadCell('name')}
                                {this.renderTableHeadCell('address.city', 'city')}
                                {this.renderTableHeadCell('numberOfAreas')}
                                {this.renderTableHeadCell('status')}
                                <TableCell align="center">{t('businessesScreen.options')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {businesses.map(business => {
                                return (
                                    <TableRow
                                        key={business.id}
                                        className={
                                            business.status === BusinessStatus.Active
                                                ? 'table-row--active'
                                                : 'table-row--disabled'
                                        }
                                    >
                                        <TableCell align="center">{business.name}</TableCell>
                                        <TableCell align="center">{business.address.city}</TableCell>
                                        <TableCell align="center">{business.numberOfAreas}</TableCell>
                                        <TableCell align="center">{t(`status.${business.status}`)}</TableCell>
                                        <TableCell align="center">
                                            <OptionsIcon
                                                className="options-icon"
                                                onClick={evt => this.activateMenu(evt, business)}
                                                data-testid="options-btn"
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                    <Menu
                        className="business-menu-items"
                        anchorEl={anchorElement}
                        keepMounted
                        open={!!anchorElement}
                        onClose={this.resetMenu}
                    >
                        <Can
                            actions={[Permission.BUSINESS_EDIT]}
                            data-testid="business-edit-button"
                            yes={() => (
                                <Link
                                    to={buildRoute(AppRoute.EditBusiness, { businessId: currentBusiness?.id })}
                                >
                                    <MenuItem>
                                        <EditIcon />
                                        {t('businessesScreen.edit')}
                                    </MenuItem>
                                </Link>
                            )}
                        />
                        {currentBusiness?.status !== BusinessStatus.Pending && (
                            <Can
                                actions={[Permission.BUSINESS_ACTIVATE]}
                                yes={() => (
                                    <MenuItem
                                        onClick={this.setToggleStatus}
                                        data-testid="toggle-business-status-btn"
                                    >
                                        {currentBusiness?.status === BusinessStatus.Active ? (
                                            <>
                                                <BlockIcon />
                                                {t('businessesScreen.deactivate')}
                                            </>
                                        ) : (
                                            <>
                                                <ActivateIcon />
                                                {t('businessesScreen.activate')}
                                            </>
                                        )}
                                    </MenuItem>
                                )}
                            />

                        )}
                    </Menu>
                </TableContainer>
                <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 20]}
                    count={totalBusinesses}
                    rowsPerPage={_limit}
                    page={params._page}
                    onPageChange={this.onPageChange}
                    onRowsPerPageChange={this.onRowsPerPageChange}
                />
            </Paper>
        );
    }

    renderStatusModal(): ReactElement {
        const { t } = this.props;
        const { statusModal, currentBusiness } = this.state;
        return (
            <Dialog
                open={statusModal}
                onClose={() => this.setState({ statusModal: false })}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {currentBusiness?.status === BusinessStatus.Active
                        ? t('businessesScreen.disableTitle')
                        : t('businessesScreen.enableTitle')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {currentBusiness?.status === BusinessStatus.Active
                            ? t('businessesScreen.disableText')
                            : t('businessesScreen.enableText')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.setState({ statusModal: false })} color="primary">
                        {t('general.no')}
                    </Button>
                    <Button
                        onClick={() => {
                            this.toggleStatus();
                            this.setState({ statusModal: false });
                        }}
                        color="primary"
                        autoFocus
                    >
                        {t('general.yes')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    render() {
        const { isFetching } = this.state;
        const { status, _q } = this.state.params;
        const { t } = this.props;
        if (isFetching) {
            return <Loader />;
        }

        const statuses: SelectOption[] = [
            { label: t('statuses.all'), value: BusinessStatus.All },
            { label: t('statuses.active'), value: BusinessStatus.Active },
            { label: t('statuses.disabled'), value: BusinessStatus.Disabled },
            { label: t('statuses.pending'), value: BusinessStatus.Pending },
        ];

        return (
            <div className="businesses-page">
                <div className="businesses-page__header">
                    <h2>{t('businessesScreen.title')}</h2>
                    <Can
                        actions={[Permission.BUSINESS_CREATE]}
                        yes={() => (
                            <div>
                                <Link to={AppRoute.AddBusiness}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        data-testid="business-create-button"
                                    >
                                        {t('businessesScreen.addButton')}
                                    </Button>
                                </Link>
                            </div>
                        )}
                    />
                </div>
                <div className="businesses-page__filters">
                    <div className="businesses-page__filters__input-group">
                        <FormSelectField
                            name="status"
                            onChange={(name: string, value: string) => this.onParamsChange(name, value, true)}
                            options={statuses}
                            value={status}
                            errors={null}
                            label={t('businessesScreen.statusFilter')}
                            testId="businesses-status-filter"
                        />
                    </div>
                    <FormTextField
                        name="_q"
                        value={_q}
                        onChange={(name: string, value: string) => this.onParamsChange(name, value, true)}
                        placeholder={t('businessesScreen.search')}
                        label={t('businessesScreen.search')}
                        errors={null}
                        icon={<SearchIcon />}
                    />
                </div>
                {this.renderTable()}
                {this.renderStatusModal()}
            </div>
        );
    }
}

export default withBusinessesContext(withMerchantsContext(withTranslationContext(BusinessesScreen)));
