/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import BusinessForm from '../views/BusinessForm';
import { AppRoute } from '../../types/routes';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import PageHeader from '../elements/PageHeader';

interface MatchParams {
    businessId?: string;
}

interface OwnProps extends TranslationContext, RouteComponentProps<MatchParams> {}

interface OwnState {}

const initialState: OwnState = {};

class EditBusinessScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    render() {
        const {
            match: {
                params: { businessId },
            },
            t,
        } = this.props;
        return (
            <div className="businesses-form-container">
                <PageHeader to={AppRoute.Businesses} back={t('general.back')} title={t('editBusinessScreen.title')} />
                <BusinessForm businessId={businessId} />
            </div>
        );
    }
}

export default withTranslationContext(withRouter(EditBusinessScreen));
