/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';
import { ConnectedBollardsController } from './BollardsController';
import {
    BollardOccupation,
    BollardDayOccupation,
    BollardOccupationReservationDetailsPayload, Bollard,
    PayloadLocation, PayloadLocationUpdated,
} from '../../types/bollards';
import { BollardAvailability, Booking } from '../../types/bookings';

import { KeyedObject } from '../../types/general';

export interface BollardsContext {
    getBollardsList(businessId: string, areaId: string, filters?: KeyedObject): Promise<{ data:Bollard[], total:number } | null>;
    getBollardAvailability(bollardId: number, day: string, params?: KeyedObject): Promise<BollardAvailability[] | null>;
    getBollardDailyOccupation(bollardId: number, day: string): Promise<BollardOccupation | null>;
    getMultipleBollardsDailyOccupation(bollardsIds: number[], day: string): Promise<Array<BollardOccupation>>;
    getBollardWeeklyAvailability(bollardId: number, startDate: string, endDate: string): Promise<BollardDayOccupation | null>;
    getMultipleBollardsWeeklyAvailability(bollardsIds: number[], startDate: string, endDate: string): Promise<Array<BollardDayOccupation>>;
    getBollardOccupationReservation(payload: BollardOccupationReservationDetailsPayload): Promise<Booking | null>;
    validateBollardLocation(qrCode: string, areaId: number, businessId: number, payload: PayloadLocation, onFailure: (message: string) => void): Promise<boolean>;
    postBollardLocationUpdated(qrCode: string, areaId: number, businessId: number, payload: PayloadLocationUpdated, onSuccess: () => void, onFailure: (message: string) => void): Promise<void>;
}

export const bollardsContextDefaultValue = {
    getBollardsList: async () => null,
    getBollardAvailability: async () => null,
    getBollardDailyOccupation: async (): Promise<null> => null,
    getMultipleBollardsDailyOccupation: async (): Promise<Array<BollardOccupation>> => [],
    getBollardWeeklyAvailability: async (): Promise<null> => null,
    getMultipleBollardsWeeklyAvailability: async (): Promise<Array<BollardDayOccupation>> => [],
    getBollardOccupationReservation: async (): Promise<null> => null,
    validateBollardLocation: async (): Promise<boolean> => false,
    postBollardLocationUpdated: async (): Promise<void> => {},
};

const BollardsContextInstance = createContext<BollardsContext | null>(bollardsContextDefaultValue);

export const BollardsContextProvider = BollardsContextInstance.Provider;
export const BollardsContextConsumer = BollardsContextInstance.Consumer;

export const withBollardsContext = <P extends object>(Component: ComponentType<P>): FC<Omit<P, keyof BollardsContext>> => props => (
    <ConnectedBollardsController>
        <BollardsContextConsumer>{ctx => <Component {...(props as P)} {...ctx} />}</BollardsContextConsumer>
    </ConnectedBollardsController>
);
