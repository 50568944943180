/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import AreaForm from '../views/AreaForm';
import { AppRoute } from '../../types/routes';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import PageHeader from '../elements/PageHeader';

interface MatchParams {
    areaId?: string;
    businessId?: string;
}

interface OwnProps extends TranslationContext, RouteComponentProps<MatchParams> {}

interface OwnState {}

const initialState: OwnState = {};

class EditAreaScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    render() {
        const {
            match: {
                params: { areaId, businessId },
            },
            t,
        } = this.props;
        return (
            <div className="areas-form-container">
                <PageHeader to={AppRoute.Areas} back={t('general.back')} title={t('editAreaScreen.title')} />
                <AreaForm areaId={areaId} businessId={businessId} />
            </div>
        );
    }
}

export default withTranslationContext(withRouter(EditAreaScreen));
