/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, FormEvent } from 'react';
import {
    Backdrop, Button, CircularProgress, FormGroup,
} from '@material-ui/core';
import PhotoIcon from '@material-ui/icons/InsertPhoto';
import PersonIcon from '@material-ui/icons/Person';
import SecurityIcon from '@material-ui/icons/Security';
import UploadIcon from '@material-ui/icons/CloudUpload';
import axios from 'axios';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import FormTextField from '../elements/FormTextField';
import FormPasswordField from '../elements/FormPasswordField';
import { KeyedObject, SelectOption, Country } from '../../types/general';
import { displayNotification, NotificationType } from '../../utils/notifications';
import {
    validateForm, FormValidatorErrorType, handleFormSubmitFailure,
} from '../../utils/validations';
import { validations } from '../../types/validations';
import FormSelectField from '../elements/FormSelectField';
import { countriesURL } from '../../services/general';
import { ProfileRequestPayload } from '../../types/authentication';

enum ProfileFormField {
    FirstName = 'firstName',
    LastName = 'lastName',
    Email = 'email',
    PhoneNumber = 'phoneNumber',
    Code = 'code',
    OldPassword = 'oldPassword',
    Password = 'newPassword',
    RepeatPassword = 'repeatPassword',
}

export interface ProfileFormFields {
    [ProfileFormField.FirstName]: string;
    [ProfileFormField.LastName]: string;
    [ProfileFormField.Email]: string;
    [ProfileFormField.PhoneNumber]: string;
    [ProfileFormField.Code]: string;
    [ProfileFormField.OldPassword]: string;
    [ProfileFormField.Password]: string;
    [ProfileFormField.RepeatPassword]: string;
}

interface OwnProps extends TranslationContext, AuthenticationContext {}

interface OwnState {
    isReady: boolean;
    validated: boolean | null;
    fields: ProfileFormFields;
    errors: KeyedObject | null;
    countries: SelectOption[];
    avatar: File | null;
    avatarUrl: string;
}

const initialState: OwnState = {
    isReady: false,
    validated: null,
    fields: {
        [ProfileFormField.FirstName]: '',
        [ProfileFormField.LastName]: '',
        [ProfileFormField.Email]: '',
        [ProfileFormField.PhoneNumber]: '',
        [ProfileFormField.Code]: 'PT',
        [ProfileFormField.OldPassword]: '',
        [ProfileFormField.Password]: '',
        [ProfileFormField.RepeatPassword]: '',
    },
    countries: [{ label: 'PT +351', value: 'PT' }],
    errors: null,
    avatar: null,
    avatarUrl: '',
};

class ProfileForm extends Component<OwnProps, OwnState> {
    constructor(props: OwnProps) {
        super(props);
        const { authenticatedUser } = props;
        initialState.fields = {
            ...initialState.fields,
            [ProfileFormField.FirstName]: authenticatedUser?.firstName || '',
            [ProfileFormField.LastName]: authenticatedUser?.lastName || '',
            [ProfileFormField.Email]: authenticatedUser?.email || '',
            [ProfileFormField.PhoneNumber]: String(authenticatedUser?.phoneNumber) || '',
            [ProfileFormField.Code]: authenticatedUser?.phoneCountry || 'PT',
        };
        this.state = {
            ...initialState,
        };
    }

    componentDidMount() {
        this.fetchCountries();
    }

    onInputChange = (name: string, value: string): void => {
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                [name]: value,
            },
        });
    };

    onFileChange = (evt: any) => {
        this.setState({
            avatar: evt.target.files[0],
            avatarUrl: URL.createObjectURL(evt.target.files[0]),
        });
    };

    onFormSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        const { fields, avatar } = this.state;
        const { authenticatedUser, submitProfileChange } = this.props;

        const errors = this.validateProfile(fields);

        this.setState({
            errors,
        });

        if (errors) return;

        const payload: ProfileRequestPayload = {
            firstName: fields[ProfileFormField.FirstName],
            lastName: fields[ProfileFormField.LastName],
        };
        if (fields[ProfileFormField.OldPassword].length > 0) {
            payload.oldPassword = fields[ProfileFormField.OldPassword];
            payload.newPassword = fields[ProfileFormField.Password];
        }

        const formData = new FormData();

        formData.append(
            'merchant',
            new Blob([JSON.stringify(payload)], {
                type: 'application/json',
            }),
        );

        if (avatar) {
            formData.append('avatar', avatar);
        }

        submitProfileChange(
            String(authenticatedUser?.id),
            formData,
            this.onFormSubmitSuccess,
            this.onFormSubmitFailure,
        );
    };

    onFormSubmitSuccess = () => {
        const { t } = this.props;
        displayNotification({
            message: t('profileForm.profileChanged'),
            type: NotificationType.Success,
        });
    };

    onFormSubmitFailure = () => {
        const { t, profileChangeErrors } = this.props;

        this.setState({ errors: handleFormSubmitFailure(t, profileChangeErrors) });
    };

    fetchCountries = () => {
        const options: SelectOption[] = [];
        axios.get(countriesURL()).then(({ data: countries }) => {
            countries.forEach((country: Country) => {
                options.push({
                    label: `${country.code} ${country.phoneCode}`,
                    value: country.code,
                });
            });
            options.sort((a, b) => a.label.localeCompare(b.label));
            this.setState({ countries: options });
        });
    };

    validateProfile = (fields: ProfileFormFields): KeyedObject | null => {
        let errors: KeyedObject | null;

        errors = validateForm(fields, validations.profile);

        if (fields[ProfileFormField.Password] !== fields[ProfileFormField.RepeatPassword]) {
            if (errors === null) errors = {};
            if (errors.repeatPassword === null || errors.repeatPassword === undefined) {
                errors.repeatPassword = [
                    {
                        typeOfViolation: FormValidatorErrorType.PasswordsDontMatch,
                    },
                ];
            }
        }

        if (!errors || Object.keys(errors).length === 0) return null;
        return { fields: errors };
    };
    
    render() {
        const { t, authenticatedUser, profileChangeFetching } = this.props;
        const {
            fields, errors, countries, avatarUrl,
        } = this.state;
        return (
            <form onSubmit={this.onFormSubmit} className="wide-form" data-testid="profile-form">
                <Backdrop open={profileChangeFetching}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <span className="section-header">
                    <PhotoIcon /> {t('profileForm.photoSection')}
                </span>
                <div className="wide-form__picture-container">
                    <img
                        src={
                            avatarUrl !== ''
                                ? avatarUrl
                                : `${authenticatedUser?.avatar}?${authenticatedUser?.lastUpdate}`
                        }
                        alt="avatar"
                    />
                    <div className="file-input-wrapper">
                        <label className="file-input-wrapper__button" htmlFor="file">
                            <UploadIcon />
                            {t('areaForm.changeButton')}
                        </label>
                        <input
                            className="file-input-wrapper__input"
                            accept=".jpg,.png"
                            type="file"
                            id="file"
                            onChange={this.onFileChange}
                            data-testid="file-input"
                            disabled={profileChangeFetching}
                        />
                        <p>
                            {t('general.format')} <br /> {t('general.image')}
                        </p>
                    </div>
                </div>
                <div className="wide-form__grid-container">
                    <span className="section-header">
                        <PersonIcon /> {t('profileForm.personalSection')}
                    </span>
                    <FormTextField
                        name={ProfileFormField.FirstName}
                        value={fields[ProfileFormField.FirstName]}
                        onChange={this.onInputChange}
                        placeholder={t('profileForm.firstNameLabel')}
                        label={`${t('profileForm.firstNameLabel')}*`}
                        errors={errors}
                        disabled={profileChangeFetching}
                    />
                    <FormTextField
                        name={ProfileFormField.LastName}
                        value={fields[ProfileFormField.LastName]}
                        onChange={this.onInputChange}
                        placeholder={t('profileForm.lastNameLabel')}
                        label={`${t('profileForm.lastNameLabel')}*`}
                        errors={errors}
                        disabled={profileChangeFetching}
                    />
                    <FormTextField
                        name={ProfileFormField.Email}
                        value={fields[ProfileFormField.Email]}
                        onChange={this.onInputChange}
                        placeholder={t('profileForm.emailLabel')}
                        label={`${t('profileForm.emailLabel')}*`}
                        errors={errors}
                        disabled
                    />
                    <div className="wide-form__grid-container__double-input">
                        <FormSelectField
                            name={ProfileFormField.Code}
                            errors={errors}
                            disabled
                            onChange={this.onInputChange}
                            options={countries}
                            value={fields[ProfileFormField.Code]}
                            testId="phone-code-select"
                        />
                        <FormTextField
                            name={ProfileFormField.PhoneNumber}
                            value={fields[ProfileFormField.PhoneNumber]}
                            onChange={this.onInputChange}
                            placeholder={t('profileForm.phoneLabel')}
                            label={`${t('profileForm.phoneLabel')}*`}
                            errors={errors}
                            disabled
                        />
                    </div>
                    <span className="section-header">
                        <SecurityIcon /> {t('profileForm.passwordSection')}
                    </span>
                    <FormPasswordField
                        name={ProfileFormField.OldPassword}
                        value={fields[ProfileFormField.OldPassword]}
                        onChange={this.onInputChange}
                        placeholder={t('profileForm.oldPasswordLabel')}
                        label={`${t('profileForm.oldPasswordLabel')}*`}
                        errors={errors}
                        disabled={profileChangeFetching}
                    />
                    <br />
                    <FormPasswordField
                        name={ProfileFormField.Password}
                        value={fields[ProfileFormField.Password]}
                        onChange={this.onInputChange}
                        placeholder={t('profileForm.passwordLabel')}
                        label={`${t('profileForm.passwordLabel')}*`}
                        errors={errors}
                        disabled={profileChangeFetching}
                    />
                    <FormPasswordField
                        name={ProfileFormField.RepeatPassword}
                        value={fields[ProfileFormField.RepeatPassword]}
                        onChange={this.onInputChange}
                        placeholder={t('profileForm.repeatPasswordLabel')}
                        label={`${t('profileForm.repeatPasswordLabel')}*`}
                        errors={errors}
                        disabled={profileChangeFetching}
                    />
                </div>
                <FormGroup row className="register-bottom">
                    <Button variant="contained" color="primary" type="submit" disabled={profileChangeFetching}>
                        {t('profileForm.submitButton')}
                    </Button>
                </FormGroup>
            </form>
        );
    }
}

export default withTranslationContext(withAuthenticationContext(ProfileForm));
