/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { KeyedObject } from '../types/general';

export enum BusinessesAction {
    CREATE_BUSINESS_REQUEST = 'CREATE_BUSINESS_REQUEST',
    CREATE_BUSINESS_SUCCESS = 'CREATE_BUSINESS_SUCCESS',
    CREATE_BUSINESS_FAILURE = 'CREATE_BUSINESS_FAILURE',
    UPDATE_BUSINESS_REQUEST = 'UPDATE_BUSINESS_REQUEST',
    UPDATE_BUSINESS_SUCCESS = 'UPDATE_BUSINESS_SUCCESS',
    UPDATE_BUSINESS_FAILURE = 'UPDATE_BUSINESS_FAILURE',
}

// Create Business

export interface NewBusinessRequestActionType {
    type: typeof BusinessesAction.CREATE_BUSINESS_REQUEST;
}

export interface NewBusinessSuccessActionType {
    type: typeof BusinessesAction.CREATE_BUSINESS_SUCCESS;
}

export interface NewBusinessFailureActionType {
    type: typeof BusinessesAction.CREATE_BUSINESS_FAILURE;
    payload: KeyedObject | null;
}

// Update Business

export interface EditBusinessRequestActionType {
    type: typeof BusinessesAction.UPDATE_BUSINESS_REQUEST;
}

export interface EditBusinessSuccessActionType {
    type: typeof BusinessesAction.UPDATE_BUSINESS_SUCCESS;
}

export interface EditBusinessFailureActionType {
    type: typeof BusinessesAction.UPDATE_BUSINESS_FAILURE;
    payload: KeyedObject | null;
}

export type BusinessesActionTypes =
    | NewBusinessRequestActionType
    | NewBusinessSuccessActionType
    | NewBusinessFailureActionType
    | EditBusinessRequestActionType
    | EditBusinessSuccessActionType
    | EditBusinessFailureActionType;
