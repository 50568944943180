/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */
import React from 'react';
import { KeyedObject } from '../types/general';
import { Booking } from '../types/bookings';

export const interpolateString = (text: string, params?: KeyedObject): any => {
    if (!params) {
        return text;
    }

    const children = text.split(/({[^}]+})/g).map(child => {
        const match = /{(.+)}/g.exec(child);
        if (match) {
            const param = params[match[1]];
            return param || String(param);
        }
        return child;
    });

    return children.some(child => child && typeof child === 'object')
        ? React.createElement('span', null, ...children)
        : children.join('');
};

export const renderCustomerFirstNameLastName = (bookingData: Booking) => {
    if (bookingData.customer) {
        const { firstName, lastName } = bookingData.customer;
        if (lastName) {
            return `${firstName} ${lastName}`;
        }
        return firstName;
    }
    if (bookingData.unregisteredReservationInfo) {
        const { firstName, lastName } = bookingData.unregisteredReservationInfo;
        if (firstName && lastName) {
            return `${firstName} ${lastName}`;
        }
        return firstName;
    }
}

export const renderReservationDateAndTime = (reservation: Booking) => {
    const fromTime = reservation.startTime.split(':', 2).join(':');
    const toTime = reservation.endTime.split(':', 2).join(':');
    const [year, month, day] = reservation.day.split('-');
    const resultDate = [day, month, year].join('-');
    return `${resultDate} ${fromTime}-${toTime}`;
}