/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { KeyedObject } from '../types/general';

export enum MerchantsAction {
    CREATE_MERCHANT_REQUEST = 'CREATE_MERCHANT_REQUEST',
    CREATE_MERCHANT_SUCCESS = 'CREATE_MERCHANT_SUCCESS',
    CREATE_MERCHANT_FAILURE = 'CREATE_MERCHANT_FAILURE',
    UPDATE_MERCHANT_REQUEST = 'UPDATE_MERCHANT_REQUEST',
    UPDATE_MERCHANT_SUCCESS = 'UPDATE_MERCHANT_SUCCESS',
    UPDATE_MERCHANT_FAILURE = 'UPDATE_MERCHANT_FAILURE',
    SET_MERCHANT_ID = 'SET_MERCHANT_ID',
}

// Create Merchant

export interface NewMerchantRequestActionType {
    type: typeof MerchantsAction.CREATE_MERCHANT_REQUEST;
}

export interface NewMerchantSuccessActionType {
    type: typeof MerchantsAction.CREATE_MERCHANT_SUCCESS;
}

export interface NewMerchantFailureActionType {
    type: typeof MerchantsAction.CREATE_MERCHANT_FAILURE;
    payload: KeyedObject | null;
}

// Update Merchant

export interface EditMerchantRequestActionType {
    type: typeof MerchantsAction.UPDATE_MERCHANT_REQUEST;
}

export interface EditMerchantSuccessActionType {
    type: typeof MerchantsAction.UPDATE_MERCHANT_SUCCESS;
}

export interface EditMerchantFailureActionType {
    type: typeof MerchantsAction.UPDATE_MERCHANT_FAILURE;
    payload: KeyedObject | null;
}

export interface SetSelectedMerchantIdActionType {
    type: typeof MerchantsAction.SET_MERCHANT_ID;
    payload: number | null;
}

export type MerchantsActionTypes =
    | NewMerchantRequestActionType
    | NewMerchantSuccessActionType
    | NewMerchantFailureActionType
    | EditMerchantRequestActionType
    | EditMerchantSuccessActionType
    | EditMerchantFailureActionType
    | SetSelectedMerchantIdActionType;
