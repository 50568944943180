/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */
import { QueryParams } from './general';

export enum InvitationStatus {
    PENDING = 'PENDING',
    EXPIRED = 'EXPIRED'
}

export interface Invitation {
    id: number;
    name: string;
    role: InvitationType;
    email: string;
    status: InvitationStatus;
}

export interface InvitationsQueryParams extends QueryParams {
    role?: string;
    merchantId?: number;
}

export enum InvitationType {
    Merchant = 'MERCHANT',
    ReservationManager = 'RESERVATION_MANAGER',
}

export interface InvitationPayload {
    email: string;
    name: string;
}

export interface AcceptInvitePayload {
    password: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    phoneCountry: string;
}

export enum ListViewOptions {
    Invitations = 'Invitations',
    ActualUsers = 'ActualUsers',
}
