/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import { Link } from 'react-router-dom';
import Back from '@material-ui/icons/ArrowBack';
import logo from '../../assets/images/logo.svg';

interface OwnProps {
    to: string;
    back: string;
    title: string;
    withLogo?: boolean;
}

export default function PageHeader(props: OwnProps) {
    const { to, back, title, withLogo } = props;
    return (
        <div className="header-container">
            <Link to={to} className="return-link" data-testid="back-link">
                <Back />
                {back}
            </Link>
            {withLogo && <img src={logo} alt="Boser" data-testid="header-logo" />}
            <h2 className="header-container__header">{title}</h2>
        </div>
    );
}
