/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { KeyedObject } from '../types/general';
import { BookingCalendarBollardsProps, BookingCalendarBusinessProps } from '../reducers/types';

export enum BookingsAction {
    CREATE_BOOKING_REQUEST = 'CREATE_BOOKING_REQUEST',
    CREATE_BOOKING_SUCCESS = 'CREATE_BOOKING_SUCCESS',
    CREATE_BOOKING_FAILURE = 'CREATE_BOOKING_FAILURE',
    SET_BOOKING_CALENDAR_BUSINESS = 'SET_BOOKING_CALENDAR_BUSINESS',
    SET_BOOKING_CALENDAR_BOLLARDS = 'SET_BOOKING_CALENDAR_BOLLARDS',
}

// Create Booking

export interface NewBookingRequestActionType {
    type: typeof BookingsAction.CREATE_BOOKING_REQUEST;
}

export interface NewBookingSuccessActionType {
    type: typeof BookingsAction.CREATE_BOOKING_SUCCESS;
}

export interface NewBookingFailureActionType {
    type: typeof BookingsAction.CREATE_BOOKING_FAILURE;
    payload: KeyedObject | null;
}

export interface SetBookingCalendarBusinessActionType {
    type: typeof BookingsAction.SET_BOOKING_CALENDAR_BUSINESS;
    payload: BookingCalendarBusinessProps | null;
}

export interface SetBookingCalendarBollardsActionType {
    type: typeof BookingsAction.SET_BOOKING_CALENDAR_BOLLARDS;
    payload: BookingCalendarBollardsProps | null;
}

export type BookingsActionTypes =
    | NewBookingRequestActionType
    | NewBookingSuccessActionType
    | NewBookingFailureActionType
    | SetBookingCalendarBusinessActionType
    | SetBookingCalendarBollardsActionType;
