/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';
import moment from 'moment';
import { Tooltip } from '@material-ui/core';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { buildRoute, formatHourTime } from '../../../utils/misc';
import {
    BollardOccupation,
    BollardOccupationReservationDetailsPayload,
    BollardOccupiedPeriod,
} from '../../../types/bollards';
import { BollardsContext, withBollardsContext } from '../../controllers/BollardsContext';
import { TIME_FORMAT } from '../../../utils/constants';
import { AppRoute } from '../../../types/routes';

interface OwnProps extends BollardsContext, RouteComponentProps {
    hourItems: moment.Moment[];
    occupation?: BollardOccupation;
}

const BookingCalendarIndividualRow: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        hourItems, occupation,
    } = props;

    const occupationElements: React.ReactNode[] = [];

    if (occupation && hourItems.length > 0) {
        const occupiedPeriods = [...occupation.occupiedPeriods]
            .sort((a, b) => moment(a.startTime, TIME_FORMAT).diff(moment(b.startTime, TIME_FORMAT)));

        let previousEndTime = hourItems[0].clone();
        for (let i = 0; i < occupiedPeriods.length; i++) {
            const period = occupiedPeriods[i];
            const startTime = moment(period.startTime, TIME_FORMAT).utc();
            const endTime = moment(period.endTime, TIME_FORMAT).utc();

            const startDiff = Math.round(startTime.diff(previousEndTime.utc(), 'minutes', true));
            if (startDiff > 0) {
                occupationElements.push(<span
                    key={i}
                    data-testid="empty-cell"
                    className="transparent"
                    style={{ flex: startDiff }}
                />);
            }

            occupationElements.push(
                <Tooltip
                    key={`row-${formatHourTime(period.startTime)}`}
                    arrow
                    title={`${formatHourTime(period.startTime)} - ${formatHourTime(period.endTime)}`}
                    style={{ flex: endTime.diff(startTime, 'minutes', true) }}
                >
                    <span
                        data-testid="occupation-cell"
                        className="red-cell"
                        onClick={() => onNavigateToBookingsDetails(occupation, period)}
                    />
                </Tooltip>,
            );

            previousEndTime = moment(endTime);
        }

        const lastHourEl = hourItems[hourItems.length - 1];
        const endDiff = lastHourEl ? lastHourEl
            .clone()
            .utc()
            .diff(previousEndTime.clone().subtract(1, 'hours').utc(), 'minutes', true)
            : 0;
        if (endDiff > 0) {
            occupationElements.push(<span
                key={endDiff}
                data-testid="empty-cell"
                className="transparent"
                style={{ flex: endDiff }}
            />);
        }
    }

    const onNavigateToBookingsDetails = async (occupation: BollardOccupation, period: BollardOccupiedPeriod) => {
        const { getBollardOccupationReservation, history, location } = props;

        const payload: BollardOccupationReservationDetailsPayload = {
            bollardId: occupation.bollardId,
            day: occupation.day,
            startTime: period.startTime,
            endTime: period.endTime,
        };
        const reservation = await getBollardOccupationReservation(payload);

        if (reservation) {
            history.push(
                {
                    pathname: buildRoute(AppRoute.BookingDetails, { bookingId: reservation.id }),
                    state: { from: location.pathname },
                },
            );
        }
    };

    return (
        <>
            <div className="booking-calendar__grid__row booking-calendar__grid__row--individual-availability">
                {Array.from({ length: hourItems.length }, (_, i) => (
                    <span
                        key={`space-${i}`}
                        style={{ flex: 60 }}
                    />
                ))}
                <div className="booking-calendar__grid__row__occupation-wrapper">
                    {occupationElements}
                </div>
            </div>
        </>
    );
};

export default withRouter(withBollardsContext(BookingCalendarIndividualRow));
