/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { FunctionComponent, useEffect, useState } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import {
    Backdrop, CircularProgress, Dialog, DialogContent,
} from '@material-ui/core';
import OptionsIcon from '@material-ui/icons/MoreHoriz';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import Lottie from 'react-lottie';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { Order, OrderQuery, QueryParams } from '../../types/general';
import {
    Invitation,
    InvitationPayload,
    InvitationsQueryParams,
    InvitationStatus,
    InvitationType,
} from '../../types/invitations';
import { InvitationsContext, withInvitationsContext } from '../controllers/InvitationsContext';
import { displayNotification, NotificationType } from '../../utils/notifications';
import { ErrorCode } from '../../types/errors';
import { AuthorizationContext, withAuthorizationContext } from '../controllers/AuthorizationContext';
import { Permission } from '../../types/authorization';
import { MerchantsContext, withMerchantsContext } from '../controllers/MerchantsContext';
import Can from '../containers/Can';
import NoDataLottie from '../../assets/lottie/no-data.json';

interface OwnProps extends TranslationContext, InvitationsContext, AuthorizationContext, MerchantsContext {
    invitationsFor: InvitationType;
    updateList: boolean;
}

const InvitationsTable: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t, getInvitations, invitationsFor, sendMerchantInvitation, sendReservationManagerInvitation, updateList,
        checkPermission, deleteInvitation, selectedMerchantId,
    } = props;

    const queryInitialState: InvitationsQueryParams = {
        _limit: 20,
        _order: OrderQuery.Ascending,
        _page: 0,
        _sort: 'name',
        _q: '',
        role: checkPermission([Permission.ALL_RESERVATION_MANAGERS]) ? invitationsFor : undefined,
    };

    const [isFetching, setIsFetching] = useState(true);
    const [listQueryParams, setListQueryParams] = useState<QueryParams>(queryInitialState);
    const [tableOrder, setTableOrder] = useState<Order>(Order.Ascending);
    const [invitations, setInvitations] = useState<Invitation[]>([]);
    const [totalInvitations, setTotalInvitations] = useState(0);
    const [anchorElement, setAnchorElement] = useState<null | SVGSVGElement>(null);
    const [currentInvitation, setCurrentInvitation] = useState<Invitation | null>(null);
    const [invitationIdToDelete, setInvitationIdToDelete] = useState<number | null>(null);

    const fetchInvitations = async () => {
        setIsFetching(true);

        const invitationsData = await getInvitations(listQueryParams);

        if (invitationsData) {
            setInvitations(invitationsData.data);
            setTotalInvitations(invitationsData.total);
        }

        setIsFetching(false);
    };

    const newTableOrder = (column: string, sort: string, order: Order): Order => {
        if (sort !== column) return Order.Ascending;
        if (order === Order.Ascending) return Order.Descending;
        return Order.Ascending;
    };

    const onSortSelect = (col: string) => {
        let newOrder = Order.Ascending;
        const sort = listQueryParams._sort;
        if (sort) newOrder = newTableOrder(col, sort, tableOrder);
        const newOrderParam = newOrder === Order.Ascending ? OrderQuery.Ascending : OrderQuery.Descending;
        setTableOrder(newOrder);
        setListQueryParams(
            {
                ...listQueryParams,
                _sort: col,
                _order: newOrderParam,
            },
        );
    };

    const renderTableHeadCell = (name: string, clickable = true, label?: string) => {
        if (clickable) {
            return (
                <TableCell align="center">
                    <TableSortLabel
                        data-testid="table-header"
                        active={listQueryParams._sort === name}
                        direction={listQueryParams._sort === name ? tableOrder : Order.Ascending}
                        onClick={() => onSortSelect(name)}
                    >
                        {t(`invitationsTable.${label || name}`)}
                    </TableSortLabel>
                </TableCell>
            );
        }
        return (
            <TableCell align="center">
                {t(`invitationsTable.${label || name}`)}
            </TableCell>
        );
    };

    const onChangePage = (page: number) => {
        setListQueryParams({
            ...listQueryParams,
            _page: page,
        });
    };

    const onChangeRowsPerPage = (evt: any) => {
        setListQueryParams({
            ...listQueryParams,
            _limit: evt.target.value,
        });
    };

    const renderInvitationCurrentStatus = (status: InvitationStatus) => {
        switch (status) {
            case InvitationStatus.PENDING:
                return t('invitationsTable.pending');
            case InvitationStatus.EXPIRED:
                return t('invitationsTable.expired');
            default:
        }
    };

    const activateMenu = (evt: React.MouseEvent<SVGSVGElement>, clickedInvitation: Invitation) => {
        evt.stopPropagation();
        setAnchorElement(evt.currentTarget);
        setCurrentInvitation(clickedInvitation);
    };

    const closeMenu = () => {
        setAnchorElement(null);
        setCurrentInvitation(null);
    };

    const onInvitationSuccess = (): void => {
        displayNotification({
            message: t('invitationsTable.invitationSuccess'),
            type: NotificationType.Success,
        });
    };

    const onInvitationFailure = (errorCode: number): void => {
        displayNotification({
            type: NotificationType.Danger,
            message: t(errorCode ? `errors.${ErrorCode[errorCode]}` : 'errors.general'),
        });
    };

    const onResendInvitationClick = (recipientEmail: string, recipientName: string) => {
        closeMenu();
        const payload: InvitationPayload = {
            email: recipientEmail,
            name: recipientName,
        };

        if (invitationsFor === InvitationType.Merchant) {
            sendMerchantInvitation(payload, onInvitationSuccess, onInvitationFailure);
            return;
        }

        sendReservationManagerInvitation(payload, onInvitationSuccess, onInvitationFailure);
    };

    const onDeleteSuccess = () => {
        setInvitationIdToDelete(null);

        displayNotification({
            type: NotificationType.Success,
            message: t('invitationsTable.delete.successMsg'),
        });

        fetchInvitations();
    };

    const onDeleteFailure = () => {
        setInvitationIdToDelete(null);

        displayNotification({
            type: NotificationType.Danger,
            message: t('invitationsTable.delete.failureMsg'),
        });
    };

    const onDeleteInvitation = () => {
        if (!invitationIdToDelete) return;

        deleteInvitation(invitationIdToDelete, onDeleteSuccess, onDeleteFailure);
    };

    const onDeleteInvitationClick = (id: number) => {
        setInvitationIdToDelete(id);
        closeMenu();
    };

    useEffect(() => {
        fetchInvitations();
    }, [
        listQueryParams._page,
        listQueryParams._sort,
        listQueryParams._order,
        listQueryParams._limit,
    ]);

    useEffect(() => {
        fetchInvitations();
    }, [updateList, selectedMerchantId]);

    const renderConfirmationDialog = () => {
        return (
            <Dialog
                open
                onClose={() => setInvitationIdToDelete(null)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('invitationsTable.delete.title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('invitationsTable.delete.msg')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setInvitationIdToDelete(null)} color="primary">
                        {t('general.no')}
                    </Button>
                    <Button
                        onClick={onDeleteInvitation}
                        color="primary"
                        autoFocus
                    >
                        {t('general.yes')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const renderNoDataPlaceholder = () => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: NoDataLottie,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
            },
        };

        return (
            <div className="placeholder-no-data">
                <Lottie
                    options={defaultOptions}
                    height={194}
                    width={194}
                />
                <p className="placeholder-no-data__title"> {t('reservationManagersScreen.noDataTitleInvitations')}</p>
                <p className="placeholder-no-data__message">{t('reservationManagersScreen.noDataMessageInvitations')}</p>
            </div>
        );
    };

    const canSeeOptionsColumn = invitationsFor === InvitationType.Merchant || (invitationsFor === InvitationType.ReservationManager && checkPermission([Permission.RESERVATION_MANAGERS_LIST_SCREEN]));

    return (
        <>
            {isFetching && (
                <Backdrop open data-testid="loader">
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
            {invitationIdToDelete && renderConfirmationDialog()}
            {invitations.length === 0 ? renderNoDataPlaceholder() : (
                <Paper>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {renderTableHeadCell('name')}
                                    {renderTableHeadCell('email')}
                                    {renderTableHeadCell('status', false)}
                                    {canSeeOptionsColumn && renderTableHeadCell('options', false)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {invitations.map(invitation => {
                                    return (
                                        <TableRow
                                            key={invitation.id}
                                            className="table-row--active"
                                        >
                                            <TableCell align="center">{`${invitation.name}`}</TableCell>
                                            <TableCell align="center">{invitation.email}</TableCell>
                                            <TableCell align="center">
                                                {renderInvitationCurrentStatus(invitation.status)}
                                            </TableCell>
                                            {canSeeOptionsColumn && (
                                            <TableCell align="center">
                                                {invitation.status === InvitationStatus.PENDING && (
                                                    <OptionsIcon
                                                        className="options-icon"
                                                        data-testid="options-btn"
                                                        onClick={evt => activateMenu(evt, invitation)}
                                                    />
                                                )}
                                            </TableCell>
                                            )}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 20]}
                        component="div"
                        count={totalInvitations}
                        rowsPerPage={listQueryParams._limit}
                        page={listQueryParams._page}
                        onPageChange={(_, page) => onChangePage(page)}
                        onRowsPerPageChange={onChangeRowsPerPage}
                    />
                    {currentInvitation && (
                    <Menu
                        className="invitations-table-items"
                        anchorEl={anchorElement}
                        keepMounted
                        open
                        onClose={closeMenu}
                    >
                        <MenuItem
                            onClick={() => onResendInvitationClick(currentInvitation?.email, currentInvitation?.name)}
                        >
                            {t('invitationsTable.resendInvitation')}
                        </MenuItem>
                        <Can
                            actions={[Permission.DELETE_MERCHANT_PENDING_INVITATION, Permission.DELETE_RESERVATION_MANAGER_PENDING_INVITATION]}
                            data-testid="area-create-button"
                            yes={() => (
                                <MenuItem onClick={() => onDeleteInvitationClick(currentInvitation.id)}>
                                    {t('invitationsTable.deleteInvite')}
                                </MenuItem>
                            )}
                        />
                    </Menu>
                    )}
                </Paper>
            )}
        </>
    );
};

export default withInvitationsContext(withMerchantsContext(withTranslationContext(withAuthorizationContext(InvitationsTable))));
