/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { ChangeEvent, Component } from 'react';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import {
    CircularProgress,
    Divider,
    IconButton,
    Input,
    List,
    ListItem,
    ListItemText,
    Popper,
    Select, Tooltip,
    ClickAwayListener,
    MenuItem,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { throttle } from 'lodash';
import {
    ExpandLess, ExpandMore, UnfoldMore,
} from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';

import BoserLogo from '../../assets/images/boser_logo.svg';
import PersonIcon from '../../assets/images/person_icon.svg';
import BriefcaseIcon from '../../assets/images/briefcase_icon.svg';
import CalendarIcon from '../../assets/images/calendar_icon.svg';
import NotebookIcon from '../../assets/images/notebook_icon.svg';
import PinIcon from '../../assets/images/pin_icon.svg';
import StoreIcon from '../../assets/images/store_icon.svg';
import UsersIcon from '../../assets/images/users_icon.svg';
import LogoutIcon from '../../assets/images/logout_icon.svg';
import LanguageIcon from '../../assets/images/language_icon.svg';
import { AppRoute } from '../../types/routes';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { PreferencesContext, withPreferencesContext } from '../controllers/PreferencesContext';
import { AuthorizationContext, withAuthorizationContext } from '../controllers/AuthorizationContext';
import { OrderQuery, SelectOption } from '../../types/general';
import { Language } from '../../types/preferences';
import { Permission } from '../../types/authorization';
import Can from '../containers/Can';
import { Merchant, UsersRoles } from '../../types/users';
import { MerchantsContext, withMerchantsContext } from '../controllers/MerchantsContext';

interface OwnProps extends AuthenticationContext,
    TranslationContext,
    PreferencesContext,
    RouteComponentProps,
    MerchantsContext,
    AuthorizationContext {}

interface OwnState {
    mobileExpanded: boolean;
    merchantSelected: Merchant | null;
    popperAnchorEl: HTMLElement | null;
    isMerchantSearchOpen: boolean;
    merchantSearchInputValue: string;
    merchants: Merchant[];
    isFetchingMerchants: boolean;
    isLanguageSelectOpen: boolean;
}

const initialState: OwnState = {
    mobileExpanded: false,
    merchantSelected: null,
    popperAnchorEl: null,
    isMerchantSearchOpen: false,
    merchantSearchInputValue: '',
    merchants: [],
    isFetchingMerchants: false,
    isLanguageSelectOpen: false,
};

let throttledFetchMerchants = () => {};

class Drawer extends Component<OwnProps, OwnState> {
    private searchBarRef = React.createRef<HTMLDivElement>();

    private readonly selectLanguageRef = React.createRef<HTMLDivElement>();

    constructor(props: OwnProps) {
        super(props);
        this.state = {
            ...initialState,
        };
        throttledFetchMerchants = throttle(this.fetchMerchants, 1000);
    }

    componentDidMount(): void {
        const { authenticatedUser } = this.props;

        if (authenticatedUser?.role !== UsersRoles.Admin) return;
        this.fetchMerchants();
        this.prepareMerchantSelected();
    }

    onLanguageChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
        const { changeLanguage } = this.props;
        changeLanguage(event.target.value as Language);
    };

    onSearchMerchantPopperClick = () => {
        const { merchants, isMerchantSearchOpen } = this.state;

        this.setState({ isMerchantSearchOpen: !isMerchantSearchOpen });
        if (!merchants) {
            this.fetchMerchants();
        }
    };

    onMerchantsSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            merchantSearchInputValue: event.target.value,
        }, throttledFetchMerchants);
    };

    onSelectMerchant = (merchant: Merchant) => {
        const { setSelectedMerchantId } = this.props;

        setSelectedMerchantId(merchant.id);
        this.setState({
            merchantSelected: merchant,
            isMerchantSearchOpen: false,
        });
    };

    handleClickOutsideSearchList = () => {
        const { isMerchantSearchOpen } = this.state;
        if (isMerchantSearchOpen) this.setState({ isMerchantSearchOpen: false });
    }

    prepareMerchantSelected = async () => {
        const { getMerchant, selectedMerchantId } = this.props;

        if (!selectedMerchantId) {
            this.setState({
                merchantSelected: null,
            });
            return;
        }

        const merchantResponse = await getMerchant(selectedMerchantId);
        this.setState({
            merchantSelected: merchantResponse,
        });
    };

    setDrawer = (value: boolean, event?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        const {
            history, location, selectedMerchantId, authenticatedUser,
        } = this.props;
        if (!selectedMerchantId && event && authenticatedUser?.role === UsersRoles.Admin) {
            event.preventDefault();
            return;
        }
        history.replace({ ...location, state: {} });
        this.setState({ mobileExpanded: value });
    };

    fetchMerchants = () => {
        const { getMerchants } = this.props;
        const { merchantSearchInputValue } = this.state;
        this.setState({ isFetchingMerchants: true });

        const params = {
            _q: merchantSearchInputValue,
            _order: OrderQuery.Ascending,
            _sort: 'name',
        };

        getMerchants(params).then(merchantsData => {
            if (merchantsData) {
                this.setState({
                    merchants: merchantsData.data,
                });
            }
            this.setState({ isFetchingMerchants: false });
        });
    };

    renderLanguageSelector = () => {
        const { language, t } = this.props;
        const { isLanguageSelectOpen } = this.state;
        const { width, height } = this.selectLanguageRef.current?.getBoundingClientRect() || {};

        const languages: SelectOption[] = [
            { label: t(`drawer.${Language.EN}`), value: Language.EN },
            { label: t(`drawer.${Language.PT}`), value: Language.PT },
        ];

        return (
            <Select
                name="language"
                data-testid="language-select"
                onChange={this.onLanguageChange}
                value={language}
                variant="outlined"
                open={isLanguageSelectOpen}
                startAdornment={<img src={LanguageIcon} alt="Language icon" />}
                className={`drawer__bottom-container__select-language ${isLanguageSelectOpen && 'drawer__bottom-container__select-language--active'}`}
                IconComponent={() => (isLanguageSelectOpen ? <ExpandLess /> : <ExpandMore />)}
                onClick={() => this.setState({ isLanguageSelectOpen: !isLanguageSelectOpen })}
                ref={this.selectLanguageRef}
                MenuProps={{
                    anchorEl: this.selectLanguageRef.current,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                    transformOrigin: {
                        horizontal: 'center',
                        vertical: 'top',
                    },
                    transitionDuration: 300,
                    className: 'drawer__bottom-container__select-language__menu',
                    getContentAnchorEl: null,
                    PaperProps: {
                        style: { minWidth: width, marginTop: (height || 0) + 10 },
                    },
                }}
            >
                {languages.map(lang => (
                    <MenuItem key={lang.value} value={lang.value}>
                        {lang.label}
                    </MenuItem>
                ))}
            </Select>
        );
    }

    render() {
        const {
            authenticatedUser,
            submitLogout,
            selectedMerchantId,
            t,
        } = this.props;

        const {
            mobileExpanded,
            isMerchantSearchOpen,
            merchantSearchInputValue,
            isFetchingMerchants,
            merchants,
            merchantSelected,
        } = this.state;

        return (
            <div className={`drawer ${mobileExpanded ? 'drawer--expanded' : ''}`}>
                <div className="drawer__top-container">
                    <MenuIcon
                        className="drawer__mobile-toggle"
                        onClick={() => this.setDrawer(!mobileExpanded)}
                        data-testid="menu-button"
                    />
                    <NavLink
                        onClick={() => this.setDrawer(false)}
                        to={AppRoute.Dashboard}
                        data-testid="dashboard-link"
                    >
                        <img src={BoserLogo} alt="Boser" />
                    </NavLink>
                    <Divider />
                    <NavLink
                        onClick={() => this.setDrawer(false)}
                        to={AppRoute.Profile}
                        className="user-info"
                        data-testid="profile-link"
                    >
                        <img
                            src={`${authenticatedUser?.avatar}?${authenticatedUser?.lastUpdate}`}
                            alt="avatar"
                        />
                        <div className="user-info__name-wrapper">
                            <p>{`${authenticatedUser?.firstName} ${authenticatedUser?.lastName}`}</p>
                            <p>{authenticatedUser?.email}</p>
                        </div>
                    </NavLink>
                    <Divider />
                    <Can
                        actions={[Permission.CLIENTS_VIEW_LIST]}
                        data-testid="clients-list-button"
                        yes={() => (
                            <NavLink
                                onClick={() => this.setDrawer(false)}
                                to={AppRoute.Clients}
                                activeClassName="drawer--active"
                            >
                                <img src={PersonIcon} alt="Person icon" />
                                {t('drawer.clients')}
                            </NavLink>
                        )}
                    />
                    <Can
                        actions={[Permission.MERCHANT_LIST_VIEW]}
                        data-testid="clients-list-button"
                        yes={() => (
                            <NavLink
                                onClick={() => this.setDrawer(false)}
                                to={AppRoute.Merchants}
                                activeClassName="drawer--active"
                            >
                                <img src={BriefcaseIcon} alt="Briefcase icon" />
                                {t('drawer.merchants')}
                            </NavLink>
                        )}
                    />
                    {authenticatedUser?.role === UsersRoles.Merchant && (
                        <>
                            <NavLink
                                onClick={() => this.setDrawer(false)}
                                to={AppRoute.BookingsCalendar}
                                activeClassName="drawer--active"
                                data-testid="drawer-calendar-button"
                            >
                                <img src={CalendarIcon} alt="Calendar icon" />
                                {t('drawer.calendar')}
                            </NavLink>
                            <NavLink
                                onClick={() => this.setDrawer(false)}
                                to={AppRoute.Businesses}
                                activeClassName="drawer--active"
                                data-testid="drawer-business-button"
                            >
                                <img src={StoreIcon} alt="Store icon" />
                                {t('drawer.businesses')}
                            </NavLink>
                            <NavLink
                                onClick={() => this.setDrawer(false)}
                                to={AppRoute.Areas}
                                activeClassName="drawer--active"
                                data-testid="drawer-areas-button"
                            >
                                <img src={PinIcon} alt="Pin icon" />
                                {t('drawer.areas')}
                            </NavLink>
                            <NavLink
                                onClick={() => this.setDrawer(false)}
                                to={AppRoute.Bookings}
                                activeClassName="drawer--active"
                                data-testid="drawer-bookings-button"
                            >
                                <img src={NotebookIcon} alt="Notebook icon" />
                                {t('drawer.reservations')}
                            </NavLink>
                            <NavLink
                                onClick={() => this.setDrawer(false)}
                                to={AppRoute.ReservationManagers}
                                activeClassName="drawer--active"
                            >
                                <img src={UsersIcon} alt="Reservation Managers icon" />
                                {t('drawer.reservationManagers')}
                            </NavLink>
                        </>
                    )}
                    {authenticatedUser?.role === UsersRoles.ReservationManager && (
                        <>
                            <NavLink
                                onClick={() => this.setDrawer(false)}
                                to={AppRoute.BookingsCalendar}
                                activeClassName="drawer--active"
                                data-testid="drawer-calendar-button"
                            >
                                <img src={CalendarIcon} alt="Calendar icon" />
                                {t('drawer.calendar')}
                            </NavLink>
                            <NavLink
                                onClick={() => this.setDrawer(false)}
                                to={AppRoute.Bookings}
                                activeClassName="drawer--active"
                                data-testid="drawer-bookings-button"
                            >
                                <img src={NotebookIcon} alt="Notebook icon" />
                                {t('drawer.reservations')}
                            </NavLink>
                        </>
                    )}
                </div>
                <Can
                    actions={[Permission.MERCHANT_LIST_VIEW]}
                    data-testid="merchants-search-section"
                    yes={() => (
                        <>
                            <Divider />
                            <h5 className="drawer__merchant-title">{t('drawer.merchant')}</h5>
                            <div
                                ref={this.searchBarRef}
                                className="drawer__search-wrapper"
                                onClick={this.onSearchMerchantPopperClick}
                            >
                                {merchantSelected ? (
                                    <div className="drawer__search-wrapper__selected">
                                        <span
                                            data-testid="merchant-selected-name"
                                        >{`${merchantSelected.firstName} ${merchantSelected.lastName}`}
                                        </span>
                                        <span>{merchantSelected.email}</span>
                                    </div>
                                ) : (
                                    <p>{t('drawer.selectMerchant')}</p>
                                )}
                                <IconButton
                                    data-testid="open-search-popper-button"
                                    onClick={this.onSearchMerchantPopperClick}
                                >
                                    <UnfoldMore />
                                </IconButton>
                            </div>
                        </>
                    )}
                />
                <Popper
                    open={isMerchantSearchOpen}
                    anchorEl={this.searchBarRef.current}
                    placement="bottom"
                    className="drawer__search"
                >
                    <ClickAwayListener onClickAway={this.handleClickOutsideSearchList}>
                        <div className="drawer__search-wrapper__popper">
                            <Input
                                className="drawer__search-wrapper__popper__input-wrapper"
                                value={merchantSearchInputValue}
                                onChange={this.onMerchantsSearchChange}
                                placeholder={t('drawer.search')}
                                disableUnderline
                                data-testid="merchant-search-input"
                                startAdornment={<SearchIcon />}
                                endAdornment={isFetchingMerchants
                                    ? <CircularProgress size={20} /> : null}
                            />
                            <div className="drawer__search-wrapper__popper__list-wrapper">
                                <List disablePadding data-testid="list-wrapper">
                                    {merchants.map((merchant: Merchant) => (
                                        <ListItem key={merchant.id}>
                                            <ListItemText
                                                primary={`${merchant.firstName} ${merchant.lastName}`}
                                                secondary={merchant.email}
                                                onClick={() => this.onSelectMerchant(merchant)}
                                                data-testid="merchant-list-element"
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </div>
                        </div>
                    </ClickAwayListener>
                </Popper>
                <Can
                    actions={[Permission.ALL_RESERVATION_MANAGERS]}
                    data-testid="reservation-managers-list-button"
                    yes={() => (
                        <Tooltip
                            disableFocusListener
                            disableTouchListener
                            title={selectedMerchantId || authenticatedUser?.role !== UsersRoles.Admin ? '' : t('drawer.selectAMerchantFor')}
                        >
                            <NavLink
                                className={selectedMerchantId || authenticatedUser?.role !== UsersRoles.Admin ? '' : 'drawer--no-hover'}
                                onClick={event => this.setDrawer(false, event)}
                                to={AppRoute.Businesses}
                                activeClassName="drawer--active"
                                data-testid="drawer-business-button"
                            >
                                <img src={StoreIcon} alt="Store icon" />
                                {t('drawer.businesses')}
                            </NavLink>
                        </Tooltip>
                    )}
                />
                <Can
                    actions={[Permission.ALL_RESERVATION_MANAGERS]}
                    data-testid="reservation-managers-list-button"
                    yes={() => (
                        <Tooltip
                            disableFocusListener
                            disableTouchListener
                            title={selectedMerchantId || authenticatedUser?.role !== UsersRoles.Admin ? '' : t('drawer.selectAMerchantFor')}
                        >
                            <NavLink
                                className={selectedMerchantId || authenticatedUser?.role !== UsersRoles.Admin ? '' : 'drawer--no-hover'}
                                onClick={event => this.setDrawer(false, event)}
                                to={AppRoute.Areas}
                                activeClassName="drawer--active"
                                data-testid="drawer-areas-button"
                            >
                                <img src={PinIcon} alt="Pin icon" />
                                {t('drawer.areas')}
                            </NavLink>
                        </Tooltip>
                    )}
                />
                <Can
                    actions={[Permission.ALL_RESERVATION_MANAGERS]}
                    data-testid="reservation-managers-list-button"
                    yes={() => (
                        <Tooltip
                            disableFocusListener
                            disableTouchListener
                            title={selectedMerchantId || authenticatedUser?.role !== UsersRoles.Admin ? '' : t('drawer.selectAMerchantFor')}
                        >
                            <NavLink
                                className={selectedMerchantId || authenticatedUser?.role !== UsersRoles.Admin ? '' : 'drawer--no-hover'}
                                onClick={event => this.setDrawer(false, event)}
                                to={AppRoute.Bookings}
                                activeClassName="drawer--active"
                                data-testid="drawer-bookings-button"
                            >
                                <img src={NotebookIcon} alt="Notebook icon" />
                                {t('drawer.reservations')}
                            </NavLink>
                        </Tooltip>
                    )}
                />
                <Can
                    actions={[Permission.ALL_RESERVATION_MANAGERS]}
                    data-testid="reservation-managers-list-button"
                    yes={() => (
                        <Tooltip
                            disableFocusListener
                            disableTouchListener
                            title={selectedMerchantId || authenticatedUser?.role !== UsersRoles.Admin ? '' : t('drawer.selectAMerchantFor')}
                        >
                            <NavLink
                                className={selectedMerchantId || authenticatedUser?.role !== UsersRoles.Admin ? '' : 'drawer--no-hover'}
                                onClick={event => this.setDrawer(false, event)}
                                to={AppRoute.ReservationManagers}
                                activeClassName="drawer--active"
                            >
                                <img src={UsersIcon} alt="Reservation Managers icon" />
                                {t('drawer.reservationManagers')}
                            </NavLink>
                        </Tooltip>
                    )}
                />
                <Can
                    actions={[Permission.ALL_RESERVATION_MANAGERS]}
                    data-testid="reservation-managers-list-button"
                    yes={() => (
                        <Tooltip
                            disableFocusListener
                            disableTouchListener
                            title={selectedMerchantId || authenticatedUser?.role !== UsersRoles.Admin ? '' : t('drawer.selectAMerchantFor')}
                        >

                            <NavLink
                                className={selectedMerchantId || authenticatedUser?.role !== UsersRoles.Admin ? '' : 'drawer--no-hover'}
                                onClick={event => this.setDrawer(false, event)}
                                to={AppRoute.BookingsCalendar}
                                activeClassName="drawer--active"
                                data-testid="drawer-calendar-button"
                            >
                                <img src={CalendarIcon} alt="Calendar icon" />
                                {t('drawer.calendar')}
                            </NavLink>
                        </Tooltip>
                    )}
                />
                <Divider />
                <div className="drawer__bottom-container">
                    {this.renderLanguageSelector()}
                    <button
                        type="button"
                        className="hidden-button"
                        onClick={() => {
                            submitLogout();
                            this.setDrawer(false);
                        }}
                    >
                        <img src={LogoutIcon} alt="Logout icon" />
                        {t('drawer.logout')}
                    </button>
                </div>
            </div>
        );
    }
}

export default withRouter(withMerchantsContext(withAuthenticationContext(withTranslationContext(withPreferencesContext(withAuthorizationContext(Drawer))))));
