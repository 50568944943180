/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import { API_URL } from '../settings';
import { KeyedObject } from '../types/general';
import { objectToParams } from '../utils/misc';
import { BookingPaymentStatus } from '../types/bookings';

const api = 'reservations';

export const bookingsURL = (filters?: KeyedObject): string => {
    const params = objectToParams(filters);
    return `${API_URL}/${api}${params}`;
};

export const createBookingURL = (): string => {
    return `${API_URL}/${api}/merchant`;
};

export const createBookingUnregisteredURL = (): string => {
    return `${API_URL}/${api}/merchant/unregistered`;
};

export const createBookingMultipleDaysURL = (): string => {
    return `${API_URL}/${api}/multiple-days`;
};

export const bookingURL = (bookingId: string): string => {
    return `${API_URL}/${api}/${bookingId}`;
};

export const bookingObservationsURL = (bookingId: string): string => {
    return `${API_URL}/${api}/${bookingId}/observations`;
};

export const bookingDaysURL = (bookingId: string, filters?: KeyedObject): string => {
    const params = objectToParams(filters);
    return `${API_URL}/${api}/${bookingId}/days${params}`;
};

export const cancelReservationURL = (id: React.Key): string => {
    return `${API_URL}/${api}/merchant/${id}/cancel`;
};

export const bookingPaymentStatusURL = (id: React.Key, paymentStatus: BookingPaymentStatus): string => {
    const params = objectToParams({ paymentStatus });
    return `${API_URL}/${api}/${id}/paymentStatus${params}`;
};

export const reservationBollardOccupationURL = (): string => {
    return `${API_URL}/${api}/bollard-occupation`;
};
