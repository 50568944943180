/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, FormEvent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Backdrop, Button, CircularProgress, FormGroup } from '@material-ui/core';
import PhotoIcon from '@material-ui/icons/InsertPhoto';
import PersonIcon from '@material-ui/icons/Person';
import UploadIcon from '@material-ui/icons/CloudUpload';
import axios from 'axios';

import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { withMerchantsContext, MerchantsContext } from '../controllers/MerchantsContext';
import FormTextField from '../elements/FormTextField';
import { KeyedObject, SelectOption, Country } from '../../types/general';
import { displayNotification, NotificationType } from '../../utils/notifications';
import { handleFormSubmitFailure } from '../../utils/validations';
import FormSelectField from '../elements/FormSelectField';
import { countriesURL } from '../../services/general';
import { AppRoute } from '../../types/routes';
import Loader from '../elements/Loader';

import boserLogo from '../../assets/images/logo.png';

enum MerchantFormField {
    FirstName = 'firstName',
    LastName = 'lastName',
    Email = 'email',
    PhoneNumber = 'phoneNumber',
    Code = 'phoneCountry',
}

export interface MerchantFormFields {
    [MerchantFormField.FirstName]: string;
    [MerchantFormField.LastName]: string;
    [MerchantFormField.Email]: string;
    [MerchantFormField.PhoneNumber]: string;
    [MerchantFormField.Code]: string;
}

interface OwnProps extends TranslationContext, MerchantsContext, RouteComponentProps {
    merchantId?: string;
}

interface OwnState {
    isReady: boolean;
    validated: boolean | null;
    fields: MerchantFormFields;
    errors: KeyedObject | null;
    countries: SelectOption[];
    avatar: File | null;
    avatarUrl: string;
    fetchingMerchant: boolean;
    failMerchantFetch: boolean;
}

const initialState: OwnState = {
    isReady: false,
    validated: null,
    fields: {
        [MerchantFormField.FirstName]: '',
        [MerchantFormField.LastName]: '',
        [MerchantFormField.Email]: '',
        [MerchantFormField.PhoneNumber]: '',
        [MerchantFormField.Code]: 'PT',
    },
    countries: [{ label: 'PT +351', value: 'PT' }],
    errors: null,
    avatar: null,
    avatarUrl: '',
    fetchingMerchant: true,
    failMerchantFetch: false,
};

class MerchantForm extends Component<OwnProps, OwnState> {
    constructor(props: OwnProps) {
        super(props);
        const { merchantId } = props;
        this.state = {
            ...initialState,
            fetchingMerchant: merchantId !== undefined,
        };
    }

    fetchCountries = () => {
        const options: SelectOption[] = [];
        axios.get(countriesURL()).then(({ data: countries }) => {
            countries.forEach((country: Country) => {
                options.push({
                    label: `${country.code} ${country.phoneCode}`,
                    value: country.code,
                });
            });
            options.sort((a, b) => a.label.localeCompare(b.label));
            this.setState({ countries: options }, this.fetchMerchant);
        });
    };

    fetchMerchant = () => {
        const { getMerchant, merchantId } = this.props;
        if (merchantId !== undefined) {
            getMerchant(merchantId).then((merchant) => {
                if (merchant === null) {
                    this.setState({ fetchingMerchant: false, failMerchantFetch: true });
                } else {
                    this.setState({
                        avatarUrl: merchant.avatar,
                        fetchingMerchant: false,
                        fields: {
                            ...this.state.fields,
                            [MerchantFormField.FirstName]: merchant[MerchantFormField.FirstName],
                            [MerchantFormField.LastName]: merchant[MerchantFormField.LastName],
                            [MerchantFormField.Email]: merchant[MerchantFormField.Email],
                            [MerchantFormField.PhoneNumber]: String(merchant[MerchantFormField.PhoneNumber]),
                            [MerchantFormField.Code]: merchant[MerchantFormField.Code],
                        },
                    });
                }
            });
        }
    };

    componentDidMount() {
        this.fetchCountries();
    }

    onInputChange = (name: string, value: string): void => {
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                [name]: value,
            },
        });
    };

    onFileChange = (evt: any) => {
        this.setState({
            avatar: evt.target.files[0],
            avatarUrl: URL.createObjectURL(evt.target.files[0]),
        });
    };

    onFormSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        const { fields, avatar } = this.state;
        const {
            submitNewMerchant,
            submitEditMerchant,
            validateNewMerchant,
            validateEditMerchant,
            merchantId,
        } = this.props;

        const errors = merchantId !== undefined ? validateEditMerchant(fields) : validateNewMerchant(fields);

        this.setState(
            {
                ...this.state,
                errors: errors,
            },
            () => {
                if (!errors) {
                    const formData = new FormData();

                    formData.append(
                        'merchant',
                        new Blob([JSON.stringify(fields)], {
                            type: 'application/json',
                        })
                    );

                    if (avatar !== null) {
                        formData.append('avatar', avatar);
                    }

                    merchantId !== undefined
                        ? submitEditMerchant(merchantId, formData, this.onFormSubmitSuccess, this.onFormSubmitFailure)
                        : submitNewMerchant(formData, this.onFormSubmitSuccess, this.onFormSubmitFailure);
                }
            }
        );
    };

    onFormSubmitSuccess = () => {
        const { t, history, merchantId } = this.props;
        displayNotification({
            message: merchantId === undefined ? t('merchantForm.success') : t('merchantForm.successEdit'),
            type: NotificationType.Success,
        });

        history.push(AppRoute.Merchants);
    };

    onFormSubmitFailure = () => {
        const { t, createMerchantErrors, updateMerchantErrors } = this.props;

        this.setState({ errors: handleFormSubmitFailure(t, createMerchantErrors, updateMerchantErrors) });
    };

    getImage = () => {
        const { avatar, avatarUrl } = this.state;

        if(avatar) return URL.createObjectURL(avatar);
        if(avatarUrl) return `${avatarUrl}?${Date.now()}`;
        return boserLogo;
    }

    render() {
        const { t, createMerchantFetching, updateMerchantFetching, merchantId } = this.props;
        const { fields, errors, countries, fetchingMerchant, failMerchantFetch } = this.state;

        if (fetchingMerchant) {
            return <Loader />;
        }

        if (failMerchantFetch) {
            return <h3>{t('merchantForm.invalidMerchant')}</h3>;
        }

        return (
            <form onSubmit={this.onFormSubmit} className="wide-form" data-testid="merchant-form">
                <Backdrop open={createMerchantFetching || updateMerchantFetching}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <span className="section-header">
                    <PhotoIcon /> {t('profileForm.photoSection')}
                </span>
                <div className="wide-form__picture-container">
                    <img
                        src={this.getImage()}
                        alt="avatar"
                    />
                    <div className="file-input-wrapper">
                        <label className="file-input-wrapper__button" htmlFor="file">
                            <UploadIcon />
                            {t('areaForm.changeButton')}
                        </label>
                        <input
                            className="file-input-wrapper__input"
                            accept=".jpg,.png"
                            type="file"
                            id="file"
                            onChange={this.onFileChange}
                            data-testid="file-input"
                            disabled={createMerchantFetching || updateMerchantFetching}
                        />
                        <p>
                            {t('general.format')} <br /> {t('general.image')}
                        </p>
                    </div>
                </div>
                <div className="wide-form__grid-container">
                    <span className="section-header">
                        <PersonIcon /> {t('profileForm.personalSection')}
                    </span>
                    <FormTextField
                        name={MerchantFormField.FirstName}
                        value={fields[MerchantFormField.FirstName]}
                        onChange={this.onInputChange}
                        placeholder={t('profileForm.firstNameLabel')}
                        label={`${t('profileForm.firstNameLabel')}*`}
                        errors={errors}
                        disabled={createMerchantFetching || updateMerchantFetching}
                    />
                    <FormTextField
                        name={MerchantFormField.LastName}
                        value={fields[MerchantFormField.LastName]}
                        onChange={this.onInputChange}
                        placeholder={t('profileForm.lastNameLabel')}
                        label={`${t('profileForm.lastNameLabel')}*`}
                        errors={errors}
                        disabled={createMerchantFetching || updateMerchantFetching}
                    />
                    <FormTextField
                        name={MerchantFormField.Email}
                        value={fields[MerchantFormField.Email]}
                        onChange={this.onInputChange}
                        placeholder={t('profileForm.emailLabel')}
                        label={`${t('profileForm.emailLabel')}*`}
                        errors={errors}
                        disabled={createMerchantFetching || updateMerchantFetching || merchantId !== undefined}
                    />
                    <div className="wide-form__grid-container__double-input">
                        <FormSelectField
                            name={MerchantFormField.Code}
                            errors={errors}
                            disabled={createMerchantFetching || updateMerchantFetching || merchantId !== undefined}
                            onChange={this.onInputChange}
                            options={countries}
                            value={fields[MerchantFormField.Code]}
                            testId="phone-code-select"
                        />
                        <FormTextField
                            name={MerchantFormField.PhoneNumber}
                            value={fields[MerchantFormField.PhoneNumber]}
                            onChange={this.onInputChange}
                            placeholder={t('profileForm.phoneLabel')}
                            label={`${t('profileForm.phoneLabel')}*`}
                            errors={errors}
                            disabled={createMerchantFetching || updateMerchantFetching || merchantId !== undefined}
                        />
                    </div>
                </div>
                <FormGroup row className="register-bottom">
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={createMerchantFetching || updateMerchantFetching}
                    >
                        {t('profileForm.submitButton')}
                    </Button>
                </FormGroup>
            </form>
        );
    }
}

export default withTranslationContext(withMerchantsContext(withRouter(MerchantForm)));
