/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';

import { ConnectedAuthenticationController } from './AuthenticationController';
import { KeyedObject } from '../../types/general';
import { LoginFormFields } from '../views/LoginForm';
import { RegisterFormFields } from '../views/RegisterForm';
import { LoginRequestPayload, ForgotPassRequestPayload, ResetPassRequestPayload } from '../../types/authentication';
import { RecoverFormFields } from '../views/RecoverForm';
import { ResetFormFields } from '../views/ResetForm';
import { ProfileFormFields } from '../views/ProfileForm';
import { ApiError } from '../../types/errors';
import { User } from '../../types/users';

export interface AuthenticationContext {
    authenticatedUser: User | null;
    token: string | null;
    isAuthenticated: boolean;
    loginFetching: boolean;
    loginErrors: ApiError | null;
    registrationFetching: boolean;
    registrationErrors: ApiError | null;
    forgotPassFetching: boolean;
    forgotPassErrors: ApiError | null;
    resetPassFetching: boolean;
    resetPassErrors: ApiError | null;
    profileChangeFetching: boolean;
    profileChangeErrors: ApiError | null;
    validateLogin(fields: LoginFormFields): KeyedObject | null;
    validateRegistration(fields: RegisterFormFields, acceptInvite: boolean): KeyedObject | null;
    validateForgotPass(fields: RecoverFormFields): KeyedObject | null;
    validateResetPass(fields: ResetFormFields): KeyedObject | null;
    validateProfileChange(fields: ProfileFormFields): KeyedObject | null;
    submitLogin(payload: LoginRequestPayload, onSuccess: () => void, onFailure: () => void): void;
    submitRegistration(payload: FormData, onSuccess: () => void, onFailure: () => void): void;
    submitForgotPass(
        payload: ForgotPassRequestPayload,
        onSuccess: Function,
        onFailure: Function
    ): void;
    submitResetPass(
        payload: ResetPassRequestPayload,
        onSuccess: Function,
        onFailure: Function
    ): void;
    submitProfileChange(id: string, payload: FormData, onSuccess: () => void, onFailure: () => void): void;
    submitLogout(): void;
}

export const authenticationContextDefaultValue = {
    authenticatedUser: null,
    token: null,
    isAuthenticated: false,
    loginFetching: false,
    loginErrors: null,
    registrationFetching: false,
    registrationErrors: null,
    forgotPassFetching: false,
    forgotPassErrors: null,
    resetPassFetching: false,
    resetPassErrors: null,
    profileChangeFetching: false,
    profileChangeErrors: null,
    validateLogin: () => null,
    validateRegistration: () => null,
    validateForgotPass: () => null,
    validateResetPass: () => null,
    validateProfileChange: () => null,
    submitLogin: () => {},
    submitRegistration: () => {},
    submitForgotPass: () => {},
    submitResetPass: () => {},
    submitProfileChange: () => {},
    submitLogout: () => {},
};

const AuthenticationContextInstance = createContext<AuthenticationContext | null>(authenticationContextDefaultValue);

export const AuthenticationContextProvider = AuthenticationContextInstance.Provider;
export const AuthenticationContextConsumer = AuthenticationContextInstance.Consumer;

export const withAuthenticationContext = <P extends object>(
    Component: ComponentType<P>
): FC<Omit<P, keyof AuthenticationContext>> => (props) => (
    <ConnectedAuthenticationController>
        <AuthenticationContextConsumer>
            {(ctx) => <Component {...(props as P)} {...ctx} />}
        </AuthenticationContextConsumer>
    </ConnectedAuthenticationController>
);
