/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import OptionsIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ActivateIcon from '@material-ui/icons/Done';
import BlockIcon from '@material-ui/icons/Block';
import EditIcon from '@material-ui/icons/Edit';
import { throttle } from 'lodash';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Lottie from 'react-lottie';
import FormSelectField from '../elements/FormSelectField';
import FormTextField from '../elements/FormTextField';

import { AppRoute } from '../../types/routes';
import { Area, AreaParams, AreaStatus } from '../../types/areas';
import { Business } from '../../types/businesses';
import { buildRoute } from '../../utils/misc';
import { withTranslationContext, TranslationContext } from '../controllers/TranslationContext';
import { withAreasContext, AreasContext } from '../controllers/AreasContext';
import { withBusinessesContext, BusinessesContext } from '../controllers/BusinessesContext';
import { SelectOption, Order, OrderQuery } from '../../types/general';
import { displayNotification, NotificationType } from '../../utils/notifications';
import { handleFormSubmitFailure } from '../../utils/validations';
import { Permission } from '../../types/authorization';
import Can from '../containers/Can';
import Loader from '../elements/Loader';
import { MerchantsContext, withMerchantsContext } from '../controllers/MerchantsContext';
import NoDataLottie from '../../assets/lottie/no-data.json';

interface OwnProps extends TranslationContext, AreasContext, BusinessesContext, MerchantsContext {}

interface OwnState {
    anchorElement: any;
    order: Order;
    areas: Area[];
    businessOptions: SelectOption[];
    params: AreaParams;
    totalAreas: number;
    currentArea: Area | null;
    statusModal: boolean;
    isFetching: boolean;
}

const initialState: OwnState = {
    anchorElement: null,
    order: Order.Ascending,
    areas: [],
    businessOptions: [],
    totalAreas: 0,
    params: {
        _limit: 20,
        _page: 0,
        _order: OrderQuery.Ascending,
        _q: '',
        _sort: 'name',
        status: AreaStatus.All,
        businessId: '0',
    },
    currentArea: null,
    statusModal: false,
    isFetching: true,
};

enum BookingFilterName {
    Business = 'businessId',
}

let throttledFetchAreas = () => {};

class AreasScreen extends Component<OwnProps, OwnState> {
    constructor(props: OwnProps) {
        super(props);
        this.state = {
            ...initialState,
        };
        throttledFetchAreas = throttle(this.fetchAreas, 500);
    }

    fetchAreas = () => {
        const { getBusinessAreas } = this.props;
        const { params } = this.state;
        getBusinessAreas(params.businessId, params).then(areasData => {
            if (areasData) {
                this.setState({
                    areas: areasData.data,
                    totalAreas: areasData.total,
                });
            }
        });
    };

    fetchBusinesses = () => {
        const { getBusinesses, selectedMerchantId } = this.props;
        getBusinesses({
            _limit: 999, _sort: 'name', _order: 'Ascending', merchantId: selectedMerchantId,
        }).then(businessesData => {
            const businessOptions: SelectOption[] = [];
            if (businessesData && businessesData.data.length > 0) {
                businessesData.data.forEach((business: Business) => {
                    if (business.numberOfAreas > 0) {
                        businessOptions.push({ label: business.name, value: business.id });
                    }
                });

                if (businessOptions.length > 0) this.onParamsChange(BookingFilterName.Business, businessOptions[0].value);
            } else {
                this.setState({
                    areas: [],
                    totalAreas: 0,
                });
            }

            this.setState({ businessOptions, isFetching: false });
        });
    };

    componentDidMount() {
        this.fetchBusinesses();
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>) {
        const { selectedMerchantId } = this.props;
        if (selectedMerchantId !== prevProps.selectedMerchantId) this.fetchBusinesses();
    }

    onParamsChange = (name: string, value: string, throttle = false): void => {
        this.setState(
            {
                params: {
                    ...this.state.params,
                    _page: throttle ? 0 : this.state.params._page,
                    [name]: value,
                },
            },
            () => (throttle ? throttledFetchAreas() : this.fetchAreas()),
        );
    };

    openStatusModal = () => {
        this.setState({
            statusModal: true, anchorElement: null,
        });
    };

    toggleStatus = async () => {
        const { submitEditArea, getArea, t } = this.props;
        const { currentArea } = this.state;

        if (!currentArea) return;

        const areaData = await getArea(currentArea.business.id, currentArea.id);

        if (!areaData) return;

        const formData = new FormData();

        formData.append(
            'businessArea',
            new Blob(
                [
                    JSON.stringify({
                        ...areaData,
                        status: areaData.status === AreaStatus.Active ? AreaStatus.Disabled : AreaStatus.Active,
                        reservationPeriods: [],
                        reservationPeriodsToDelete: [],
                    }),
                ],
                {
                    type: 'application/json',
                },
            ),
        );

        submitEditArea(
            areaData.business.id,
            areaData.id,
            formData,
            () => {
                this.fetchAreas();
                displayNotification({
                    message:
                        areaData.status === AreaStatus.Active
                            ? t('areasScreen.successDisable')
                            : t('areasScreen.successEnable'),
                    type: NotificationType.Success,
                });
            },
            () => {
                this.handleToggleFailure();
            },
        );
    };

    handleToggleFailure() {
        const { t, createAreaErrors, updateAreaErrors } = this.props;
        handleFormSubmitFailure(t, createAreaErrors, updateAreaErrors);
    }

    activateMenu = (evt: React.MouseEvent<SVGSVGElement>, area: Area) => {
        evt.stopPropagation();
        this.setState({
            anchorElement: evt.currentTarget,
            currentArea: area,
        });
    };

    resetMenu = () => {
        this.setState({
            anchorElement: null,
            currentArea: null,
        });
    };

    onPageChange = (_: any, page: number) => {
        const { params } = this.state;
        this.setState(
            { params: { ...params, _page: page } },
            this.fetchAreas,
        );
    };

    onRowsPerPageChange = (evt: any) => {
        const { params } = this.state;
        this.setState(
            { params: { ...params, _limit: evt.target.value } },
            this.fetchAreas,
        );
    };

    selectSort = (col: string) => {
        const { order } = this.state;
        const sort = this.state.params._sort;
        const newOrder = sort !== col ? Order.Ascending : order === Order.Ascending ? Order.Descending : Order.Ascending;
        const newOrderParam = newOrder === Order.Ascending ? OrderQuery.Ascending : OrderQuery.Descending;
        this.setState(
            {
                order: newOrder,
                params: {
                    ...this.state.params,
                    _sort: col,
                    _order: newOrderParam,
                },
            },
            this.fetchAreas,
        );
    };

    renderTableHeadCell(name: string, label?: string): ReactElement {
        const { order } = this.state;
        const sort = this.state.params._sort;
        const { t } = this.props;
        return (
            <TableCell align="center">
                <TableSortLabel
                    active={sort === name}
                    direction={sort === name ? order : Order.Ascending}
                    onClick={() => this.selectSort(name)}
                    data-testid="sortable_column"
                >
                    {t(`areasScreen.${label || name}`)}
                </TableSortLabel>
            </TableCell>
        );
    }

    renderTable(): ReactElement {
        const { t } = this.props;
        const {
            areas, anchorElement, totalAreas, params, currentArea,
        } = this.state;

        if (areas.length === 0) {
            const defaultOptions = {
                loop: true,
                autoplay: true,
                animationData: NoDataLottie,
                rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                },
            };

            return (
                <div className="placeholder-no-data">
                    <Lottie
                        options={defaultOptions}
                        height={194}
                        width={194}
                    />
                    <p className="placeholder-no-data__title">{t('areasScreen.noDataTitle')}</p>
                    <p className="placeholder-no-data__message">{t('areasScreen.noDataMessage')}</p>
                </div>
            );
        }

        return (
            <Paper>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {this.renderTableHeadCell('name')}
                                {this.renderTableHeadCell('business.name', 'business')}
                                {this.renderTableHeadCell('business.address.city', 'city')}
                                {this.renderTableHeadCell('status')}
                                <TableCell align="center">{t('areasScreen.options')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {areas.map(area => {
                                return (
                                    <TableRow
                                        key={area.id}
                                        className={
                                            area.status === AreaStatus.Active
                                                ? 'table-row--active'
                                                : 'table-row--disabled'
                                        }
                                    >
                                        <TableCell align="center">{area.name}</TableCell>
                                        <TableCell align="center">{area.business.name}</TableCell>
                                        <TableCell align="center">{area.business.address.city}</TableCell>
                                        <TableCell align="center">{t(`status.${area.status}`)}</TableCell>
                                        <TableCell align="center">
                                            <OptionsIcon
                                                className="options-icon"
                                                onClick={evt => this.activateMenu(evt, area)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                    <Menu
                        className="area-menu-items"
                        anchorEl={anchorElement}
                        keepMounted
                        open={!!anchorElement}
                        onClose={this.resetMenu}
                    >
                        <Can
                            actions={[Permission.AREA_EDIT]}
                            data-testid="area-edit-button"
                            yes={() => (
                                <Link
                                    to={buildRoute(AppRoute.EditArea, {
                                        businessId: currentArea?.business.id,
                                        areaId: currentArea?.id,
                                    })}
                                >
                                    <MenuItem>
                                        <EditIcon />
                                        {t('areasScreen.edit')}
                                    </MenuItem>
                                </Link>
                            )}
                        />
                        {currentArea?.status !== AreaStatus.Pending && (
                            <Can
                                actions={[Permission.AREA_ACTIVATE]}
                                data-testid="area-activate-button"
                                yes={() => (
                                    <MenuItem onClick={this.openStatusModal}>
                                        {currentArea?.status === AreaStatus.Active ? (
                                            <>
                                                <BlockIcon />
                                                {t('areasScreen.deactivate')}
                                            </>
                                        ) : (
                                            <>
                                                <ActivateIcon />
                                                {t('areasScreen.activate')}
                                            </>
                                        )}
                                    </MenuItem>
                                )}
                            />
                        )}
                    </Menu>
                </TableContainer>
                <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 20]}
                    count={totalAreas}
                    rowsPerPage={params._limit}
                    page={params._page}
                    onPageChange={this.onPageChange}
                    onRowsPerPageChange={this.onRowsPerPageChange}
                />
            </Paper>
        );
    }

    renderStatusModal(): ReactElement {
        const { t } = this.props;
        const { statusModal, currentArea } = this.state;
        return (
            <Dialog
                open={statusModal}
                onClose={() => this.setState({ statusModal: false })}
                aria-labelledby="status modal"
            >
                <DialogTitle id="alert-dialog-title">
                    {currentArea?.status === AreaStatus.Active
                        ? t('areasScreen.disableTitle')
                        : t('areasScreen.enableTitle')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {currentArea?.status === AreaStatus.Active
                            ? t('areasScreen.disableText')
                            : t('areasScreen.enableText')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.setState({ statusModal: false })} color="primary">
                        {t('general.no')}
                    </Button>
                    <Button
                        onClick={() => {
                            this.toggleStatus();
                            this.setState({ statusModal: false });
                        }}
                        color="primary"
                        autoFocus
                    >
                        {t('general.yes')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    render() {
        const { businessOptions, params: { businessId, status, _q }, isFetching } = this.state;
        const { t } = this.props;
        if (isFetching) {
            return <Loader />;
        }

        const statuses: SelectOption[] = [
            { label: t('statuses.all'), value: AreaStatus.All },
            { label: t('statuses.active'), value: AreaStatus.Active },
            { label: t('statuses.disabled'), value: AreaStatus.Disabled },
            { label: t('statuses.pending'), value: AreaStatus.Pending },
        ];

        return (
            <div className="areas-page">
                <div className="areas-page__header">
                    <h2>{t('areasScreen.title')}</h2>
                    <div>
                        <Can
                            actions={[Permission.AREA_CREATE]}
                            data-testid="area-create-button"
                            yes={() => (
                                <Link to={AppRoute.AddArea}>
                                    <Button variant="contained" color="primary" type="submit">
                                        {t('areasScreen.addButton')}
                                    </Button>
                                </Link>
                            )}
                        />
                    </div>
                </div>
                <div className="areas-page__filters">
                    <div className="areas-page__filters__input-group">
                        <FormSelectField
                            name="status"
                            onChange={(name: string, value: string) => this.onParamsChange(name, value, true)}
                            options={statuses}
                            value={status}
                            errors={null}
                            label={t('areasScreen.statusFilter')}
                            testId="areas-status-filter"
                        />
                        <FormSelectField
                            name="businessId"
                            onChange={(name: string, value: string) => this.onParamsChange(name, value, true)}
                            options={businessOptions}
                            value={businessId}
                            errors={null}
                            label={t('areasScreen.businessFilter')}
                            testId="areas-business-filter"
                        />
                    </div>
                    <FormTextField
                        name="_q"
                        value={_q}
                        onChange={(name: string, value: string) => this.onParamsChange(name, value, true)}
                        placeholder={t('areasScreen.search')}
                        label={t('areasScreen.search')}
                        errors={null}
                        icon={<SearchIcon />}
                    />
                </div>
                {this.renderTable()}
                {this.renderStatusModal()}
            </div>
        );
    }
}

export default withBusinessesContext(withAreasContext(withMerchantsContext(withTranslationContext(AreasScreen))));
