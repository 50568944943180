/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import ProfileForm from '../views/ProfileForm';

interface OwnProps {}

interface OwnState {}

const initialState: OwnState = {};

class ProfileScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    render() {
        return (
            <div className="profile-screen">
                <ProfileForm />
            </div>
        );
    }
}

export default ProfileScreen;
