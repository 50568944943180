/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import axios, { AxiosError } from 'axios';

import { BusinessesContextProvider } from './BusinessesContext';
import { AppState } from '../../reducers/types';
import { businessesListURL, businessURL, updateBusinessStatusURL } from '../../services/businesses';
import { KeyedObject, ListResponse } from '../../types/general';
import { Business, BusinessRequestPayload, UpdateBusinessStatus } from '../../types/businesses';
import { validateForm } from '../../utils/validations';
import { ApiError } from '../../types/errors';
import { requestCreateBusiness, requestUpdateBusiness } from '../../actions/businesses';
import { validations } from '../../types/validations';
import { GenericFunction, validateIBAN, validatePtNif } from '../../utils/misc';
import { COUNT_HEADER } from '../../utils/constants';

interface StateProps {
    createBusinessFetching: boolean;
    createBusinessErrors: ApiError | null;
    updateBusinessFetching: boolean;
    updateBusinessErrors: ApiError | null;
}

interface DispatchProps {
    dispatchRequestCreateBusiness: Function;
    dispatchRequestUpdateBusiness: Function;
}

interface OwnProps {
    children: any;
}

interface OwnState {}

type Props = OwnProps & StateProps & DispatchProps;
type State = OwnState;

export class BusinessesController extends Component<Props, State> {
    // Requests

    getBusiness = async (id: string): Promise<Business | null> => {
        try {
            const { data } = await axios.get(businessURL(id));
            return data;
        } catch {
            return null;
        }
    };

    getBusinesses = async (filters?: KeyedObject): Promise<ListResponse<Business> | null> => {
        try {
            const { data, headers } = await axios.get(businessesListURL(filters));
            return { data, total: headers[COUNT_HEADER] ? Number(headers[COUNT_HEADER]) : 0 };
        } catch {
            return null;
        }
    };

    // Create business

    validateNewBusiness = (fields: any, hasEuPago = false): KeyedObject | null => {
        const businessValidations = !hasEuPago ? validations.businessCreate : { ...validations.businessUpdate, ...validations.businessEuPago };
        let errors: KeyedObject | null = validateForm(fields, businessValidations);
        const { fiscalCode, bankAccount } = fields;

        if (fiscalCode) {
            if (!validatePtNif(fiscalCode)) {
                if (!errors) {
                    errors = {};
                }

                errors.fiscalCode = [{ typeOfViolation: 'invalidNIF' }];
            }
        }

        if (bankAccount) {
            if (!validateIBAN(bankAccount)) {
                if (!errors) {
                    errors = {};
                }

                errors.bankAccount = [{ typeOfViolation: 'IBAN' }];
            }
        }

        if (!errors || Object.keys(errors).length === 0) return null;
        return { fields: errors };
    };

    submitNewBusiness = (payload: BusinessRequestPayload, onSuccess: Function, onFailure: Function) => {
        const { dispatchRequestCreateBusiness } = this.props;

        dispatchRequestCreateBusiness(payload, onSuccess, onFailure);
    };

    // Update business

    validateEditBusiness = (fields: any, hasEuPago = false): KeyedObject | null => {
        const businessValidations = !hasEuPago ? validations.businessUpdate : { ...validations.businessUpdate, ...validations.businessEuPago };
        let errors: KeyedObject | null = validateForm(fields, businessValidations);
        const { fiscalCode, bankAccount } = fields;

        if (fiscalCode) {
            if (!validatePtNif(fiscalCode)) {
                if (!errors) {
                    errors = {};
                }

                errors.fiscalCode = [{ typeOfViolation: 'invalidNIF' }];
            }
        }

        if (bankAccount) {
            if (!validateIBAN(bankAccount)) {
                if (!errors) {
                    errors = {};
                }

                errors.bankAccount = [{ typeOfViolation: 'IBAN' }];
            }
        }

        if (!errors || Object.keys(errors).length === 0) return null;
        return { fields: errors };
    };

    submitEditBusiness = (
        businessId: string,
        payload: BusinessRequestPayload,
        onSuccess: Function,
        onFailure: Function,
    ) => {
        const { dispatchRequestUpdateBusiness } = this.props;

        dispatchRequestUpdateBusiness(businessId, payload, onSuccess, onFailure);
    };

    updateBusinessStatus = async (id: number, status: UpdateBusinessStatus, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void> => {
        try {
            await axios.put(updateBusinessStatusURL(id, status), { status });
            onSuccess();
        } catch (error) {
            onFailure((error as AxiosError)?.response?.data?.errors[0]?.errorCode);
        }
    };

    render() {
        const {
            children,
            createBusinessErrors,
            createBusinessFetching,
            updateBusinessErrors,
            updateBusinessFetching,
        } = this.props;

        return (
            <BusinessesContextProvider
                value={{
                    createBusinessErrors,
                    createBusinessFetching,
                    updateBusinessErrors,
                    updateBusinessFetching,
                    getBusiness: this.getBusiness,
                    getBusinesses: this.getBusinesses,
                    validateNewBusiness: this.validateNewBusiness,
                    validateEditBusiness: this.validateEditBusiness,
                    submitNewBusiness: this.submitNewBusiness,
                    submitEditBusiness: this.submitEditBusiness,
                    updateBusinessStatus: this.updateBusinessStatus,
                }}
            >
                {children}
            </BusinessesContextProvider>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => {
    return {
        createBusinessFetching: state.businesses.createBusinessRequest.isFetching,
        createBusinessErrors: state.businesses.createBusinessRequest.errors,
        updateBusinessFetching: state.businesses.updateBusinessRequest.isFetching,
        updateBusinessErrors: state.businesses.updateBusinessRequest.errors,
    };
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => ({
    dispatchRequestCreateBusiness: (payload: BusinessRequestPayload, onSuccess: Function, onFailure: Function) => dispatch(requestCreateBusiness(payload, onSuccess, onFailure)),
    dispatchRequestUpdateBusiness: (
        businessId: string,
        payload: BusinessRequestPayload,
        onSuccess: Function,
        onFailure: Function,
    ) => dispatch(requestUpdateBusiness(businessId, payload, onSuccess, onFailure)),
});

export const ConnectedBusinessesController = connect(mapStateToProps, mapDispatchToProps)(BusinessesController);
