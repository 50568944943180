/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { REHYDRATE } from 'redux-persist';

import { AreasState } from './types';
import { GeneralAction } from '../actions/general_types';
import { AreasAction } from '../actions/areas_types';

export const areasInitialState: AreasState = {
    createAreaRequest: {
        isFetching: false,
        errors: null,
    },
    updateAreaRequest: {
        isFetching: false,
        errors: null,
    },
};

export default function (state = areasInitialState, action: any): AreasState {
    switch (action.type) {
        case REHYDRATE:
            if (action.payload && action.payload.areas) {
                return {
                    ...action.payload.areas,
                    createAreaRequest: areasInitialState.createAreaRequest,
                    updateAreaRequest: areasInitialState.updateAreaRequest,
                };
            }
            return state;
        case AreasAction.CREATE_AREA_REQUEST:
            return {
                ...state,
                createAreaRequest: {
                    ...state.createAreaRequest,
                    isFetching: true,
                },
            };
        case AreasAction.CREATE_AREA_SUCCESS:
            return {
                ...state,
                createAreaRequest: {
                    isFetching: false,
                    errors: null,
                },
            };
        case AreasAction.CREATE_AREA_FAILURE:
            return {
                ...state,
                createAreaRequest: {
                    isFetching: false,
                    errors: action.payload,
                },
            };
        case AreasAction.UPDATE_AREA_REQUEST:
            return {
                ...state,
                updateAreaRequest: {
                    ...state.updateAreaRequest,
                    isFetching: true,
                },
            };
        case AreasAction.UPDATE_AREA_SUCCESS:
            return {
                ...state,
                updateAreaRequest: {
                    isFetching: false,
                    errors: null,
                },
            };
        case AreasAction.UPDATE_AREA_FAILURE:
            return {
                ...state,
                updateAreaRequest: {
                    isFetching: false,
                    errors: action.payload,
                },
            };
        case GeneralAction.STORE_RESET:
            return areasInitialState;
        default:
            return state;
    }
}
