/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import logo from '../../assets/images/logo-green.svg';

import { AuthenticationContext, withAuthenticationContext } from '../controllers/AuthenticationContext';

interface OwnProps extends AuthenticationContext {}

interface OwnState {}

const initialState: OwnState = {};

class NavBar extends Component<OwnProps, OwnState> {
    state = initialState;

    render() {
        const { isAuthenticated } = this.props;
        return (
            <>
                {isAuthenticated ? (
                    <div className="navbar">
                        <img src={logo} alt="Boser" />
                    </div>
                ) : (
                    <React.Fragment />
                )}
            </>
        );
    }
}

export default withAuthenticationContext(NavBar);
