/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, FormEvent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
    Backdrop, Button, CircularProgress, FormGroup,
} from '@material-ui/core';
import axios from 'axios';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import FormTextField from '../elements/FormTextField';
import FormPasswordField from '../elements/FormPasswordField';
import FormCheckbox from '../elements/FormCheckbox';
import FormSelectField from '../elements/FormSelectField';
import { KeyedObject, SelectOption, Country } from '../../types/general';
import { displayNotification, NotificationType } from '../../utils/notifications';
import { AppRoute } from '../../types/routes';
import { countriesURL } from '../../services/general';
import { handleFormSubmitFailure } from '../../utils/validations';
import loginImage from '../../assets/images/login.svg';
import { InvitationsContext, withInvitationsContext } from '../controllers/InvitationsContext';
import { AcceptInvitePayload } from '../../types/invitations';

enum RegisterFormField {
    Email = 'email',
    FirstName = 'firstName',
    LastName = 'lastName',
    Password = 'password',
    RepeatPassword = 'repeatPassword',
    CountryCode = 'phoneCountry',
    PhoneNumber = 'phoneNumber',
    Terms = 'terms',
}

export interface RegisterFormFields {
    [RegisterFormField.Email]: string;
    [RegisterFormField.FirstName]: string;
    [RegisterFormField.LastName]: string;
    [RegisterFormField.Password]: string;
    [RegisterFormField.RepeatPassword]: string;
    [RegisterFormField.CountryCode]: string;
    [RegisterFormField.PhoneNumber]: string;
    [RegisterFormField.Terms]: boolean;
}

interface MatchParams {
    token?: string;
}

interface OwnProps extends TranslationContext, AuthenticationContext, InvitationsContext, RouteComponentProps<MatchParams> {}

interface OwnState {
    isReady: boolean;
    validated: boolean | null;
    fields: RegisterFormFields;
    errors: KeyedObject | null;
    countries: SelectOption[];
    inviteToken: string;
}

const initialState: OwnState = {
    isReady: false,
    validated: null,
    fields: {
        [RegisterFormField.Email]: '',
        [RegisterFormField.FirstName]: '',
        [RegisterFormField.LastName]: '',
        [RegisterFormField.Password]: '',
        [RegisterFormField.RepeatPassword]: '',
        [RegisterFormField.CountryCode]: 'PT',
        [RegisterFormField.PhoneNumber]: '',
        [RegisterFormField.Terms]: false,
    },
    countries: [{ label: 'PT +351', value: 'PT' }],
    errors: null,
    inviteToken: '',
};

class RegisterForm extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount() {
        const { match: { params }, submitLogout, isAuthenticated } = this.props;

        if (params && params.token) {
            if (isAuthenticated) {
                submitLogout();
            }

            this.setState({
                inviteToken: params.token,
            });
        }

        this.fetchCountries();
    }

    onInputChange = (name: string, value: string): void => {
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                [name]: value,
            },
        });
    };

    onCheckChange = (name: string, checked: boolean): void => {
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                [name]: checked,
            },
        });
    };

    submitAcceptInvite = () => {
        const { acceptInvite } = this.props;
        const { fields, inviteToken } = this.state;

        const payload: AcceptInvitePayload = {
            firstName: fields[RegisterFormField.FirstName],
            lastName: fields[RegisterFormField.LastName],
            phoneCountry: fields[RegisterFormField.CountryCode],
            phoneNumber: fields[RegisterFormField.PhoneNumber],
            password: fields[RegisterFormField.Password],
        }

        acceptInvite(inviteToken, payload, this.onFormSubmitSuccess, this.onFormSubmitFailure)
    }

    submitRegistration = () => {
        const { submitRegistration } = this.props;
        const { fields } = this.state;

        const convertedFields = {
            ...fields,
            [RegisterFormField.PhoneNumber]: Number(fields[RegisterFormField.PhoneNumber]),
        };

        const formData = new FormData();

        formData.append(
            'merchant',
            new Blob([JSON.stringify(convertedFields)], {
                type: 'application/json',
            }),
        );

        submitRegistration(formData, this.onFormSubmitSuccess, this.onFormSubmitFailure);
    }

    onFormSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        const { validateRegistration } = this.props;
        const { fields, inviteToken } = this.state;

        const errors: KeyedObject | null = validateRegistration(fields, !!inviteToken);

        this.setState({
            errors,
        });

        if (errors) return;

        if (inviteToken) {
            this.submitAcceptInvite();
        } else {
            this.submitRegistration();
        }
    };

    onFormSubmitSuccess = () => {
        const { history, t } = this.props;

        displayNotification({
            message: t('registerForm.success'),
            type: NotificationType.Success,
        });

        history.push(AppRoute.Login);
    };

    onFormSubmitFailure = () => {
        const { t, registrationErrors } = this.props;

        this.setState({ errors: handleFormSubmitFailure(t, registrationErrors) });
    };

    fetchCountries = () => {
        const options: SelectOption[] = [];
        axios.get(countriesURL()).then((response: any) => {
            const countries: Country[] = response.data;
            countries.forEach((country: Country) => {
                options.push({
                    label: `${country.code} ${country.phoneCode}`,
                    value: country.code,
                });
            });
            options.sort((a, b) => a.label.localeCompare(b.label));
            this.setState({ countries: options });
        });
    };
    
    render() {
        const { t, registrationFetching } = this.props;
        const { fields, errors, countries, inviteToken } = this.state;

        return (
            <form onSubmit={this.onFormSubmit} className="wide-form" data-testid="register-form">
                <Backdrop open={registrationFetching}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <img className="login-image" src={loginImage} alt="login" />
                <div className="wide-form__grid-container register-form-grid">
                    <FormTextField
                        name={RegisterFormField.FirstName}
                        value={fields[RegisterFormField.FirstName]}
                        onChange={this.onInputChange}
                        placeholder={t('registerForm.firstNameLabel')}
                        errors={errors}
                        disabled={registrationFetching}
                    />
                    <FormTextField
                        name={RegisterFormField.LastName}
                        value={fields[RegisterFormField.LastName]}
                        onChange={this.onInputChange}
                        placeholder={t('registerForm.lastNameLabel')}
                        errors={errors}
                        disabled={registrationFetching}
                    />
                    <div className="wide-form__grid-container__double-input">
                        <FormSelectField
                            name={RegisterFormField.CountryCode}
                            errors={errors}
                            disabled={registrationFetching}
                            onChange={this.onInputChange}
                            options={countries}
                            value={fields[RegisterFormField.CountryCode]}
                        />
                        <FormTextField
                            name={RegisterFormField.PhoneNumber}
                            value={fields[RegisterFormField.PhoneNumber]}
                            onChange={this.onInputChange}
                            placeholder={t('registerForm.phoneLabel')}
                            errors={errors}
                            disabled={registrationFetching}
                            blockAutoComplete
                        />
                    </div>
                    {inviteToken ? (
                        <div />
                    ) : (
                        <FormTextField
                            name={RegisterFormField.Email}
                            value={fields[RegisterFormField.Email]}
                            onChange={this.onInputChange}
                            placeholder={t('registerForm.emailLabel')}
                            errors={errors}
                            disabled={registrationFetching}
                        />
                    )}
                    <FormPasswordField
                        name={RegisterFormField.Password}
                        value={fields[RegisterFormField.Password]}
                        onChange={this.onInputChange}
                        placeholder={t('registerForm.passwordLabel')}
                        errors={errors}
                        disabled={registrationFetching}
                    />
                    <FormPasswordField
                        name={RegisterFormField.RepeatPassword}
                        value={fields[RegisterFormField.RepeatPassword]}
                        onChange={this.onInputChange}
                        placeholder={t('registerForm.repeatPasswordLabel')}
                        errors={errors}
                        disabled={registrationFetching}
                    />
                </div>
                <div className="wide-form__grid-container__terms-container">
                    <FormCheckbox
                        name={RegisterFormField.Terms}
                        checked={fields[RegisterFormField.Terms]}
                        onChange={this.onCheckChange}
                        errors={errors}
                        disabled={registrationFetching}
                    />
                    <span>{t('registerForm.read')}</span>
                    <a href="https://boser.pt/?modal=terms" target="_blank" rel="noopener noreferrer" className="wide-form__grid-container__terms-container__terms-link">
                        {t('registerForm.terms')}
                    </a>
                    <span>{t('registerForm.and')}</span>
                    <a href="https://boser.pt/?modal=privacy" target="_blank" rel="noopener noreferrer" className="wide-form__grid-container__terms-container__terms-link">
                        {t('registerForm.policy')}
                    </a>
                </div>
                <FormGroup row className="register-bottom">
                    <Button
                        className="white-button"
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={registrationFetching}
                    >
                        {t('registerForm.submitButton')}
                    </Button>
                </FormGroup>
            </form>
        );
    }
}

export default withTranslationContext(withAuthenticationContext(withRouter(withInvitationsContext(RegisterForm))));
