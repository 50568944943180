/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';

import BusinessForm from '../views/BusinessForm';
import { AppRoute } from '../../types/routes';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import PageHeader from '../elements/PageHeader';

interface OwnProps extends TranslationContext {}

interface OwnState {}

const initialState: OwnState = {};

class AddBusinessScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    render() {
        const { t } = this.props;
        return (
            <div className="businesses-form-container">
                <PageHeader to={AppRoute.Businesses} back={t('general.back')} title={t('addBusinessScreen.title')} />
                <BusinessForm />
            </div>
        );
    }
}

export default withTranslationContext(AddBusinessScreen);
