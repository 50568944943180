/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';
import moment from 'moment';
import { Tooltip } from '@material-ui/core';

import { BollardDayOccupation, BollardOccupationDayStatus } from '../../../types/bollards';

interface OwnProps {
    daysItems: moment.Moment[];
    onDaySelected(day: moment.Moment): void;
    occupationDays: BollardDayOccupation[];
}

const BookingCalendarBiWeeklyRow: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        daysItems, onDaySelected, occupationDays,
    } = props;

    const row: React.ReactNode[] = [];

    if (occupationDays.length > 0) {
        for (let i = 0; i < daysItems.length; i++) {
            const day = daysItems[i];
            const dayOccupation = occupationDays.find(r => r.day === day.format('YYYY-MM-DD'));

            if (dayOccupation) {
                let occupationClass = '';
                if (dayOccupation.status === BollardOccupationDayStatus.Partial) {
                    occupationClass = 'yellow-cell';
                }
                if (dayOccupation.status === BollardOccupationDayStatus.Full) {
                    occupationClass = 'red-cell';
                }
                if (dayOccupation.status === BollardOccupationDayStatus.Unavailable) {
                    occupationClass = 'grey-cell';
                }

                row.push(
                    <Tooltip
                        key={`row-${day.format('DD-MM')}`}
                        arrow
                        title={day.format('DD/MM')}
                        className={occupationClass}
                    >
                        <span
                            data-testid={occupationClass}
                            onClick={dayOccupation.status === BollardOccupationDayStatus.Unavailable ? undefined : () => onDaySelected(day)}
                        >
                            {dayOccupation.designations.join(', ')}
                        </span>
                    </Tooltip>,
                );
            } else {
                row.push(<span key={i} />);
            }
        }
    }

    return (
        <div className="booking-calendar__grid__row booking-calendar__grid__row--bi-weekly">
            {row}
        </div>
    );
};

export default BookingCalendarBiWeeklyRow;
