/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { KeyedObject } from './general';

export interface ClientParams {
    pageSize: number;
    pageIndex: number;
}

export enum ClientStatus {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
    NotValidated = 'NOT_VALIDATED',
}

export interface Client {
    id: number;
    name: string;
    email?: string;
    country?: string;
    phoneNumber?: string;
    phoneNumberCode?: string;
    fiscalCode?: string;
    status?: ClientStatus;
}

export interface ListData {
    clients: Array<KeyedObject>;
    total: number;
}
